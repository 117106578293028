import { Omit } from 'utils/src';
import { NValidate } from 'utils/src/validate_v2';
import { NEvents } from './Events.interface';
import moment from 'moment';

export const startCurrentMinute = moment().seconds(0).milliseconds(0).toDate();

export const EventsUEventValidateRules: NValidate.ValidateRulesObject<NEvents.Create['uevent']> = {
    eventLocation: {
        min: {
            value: 3
        },
        max: {
            value: 180
        }
    },
    duration: {
        notNull: {
            value: true
        }
    }
}

export const EventsValidateRules: NValidate.ValidateRulesObject<Omit<NEvents.Create, 'expires'> & { expires: Date }> = {
    text: {
        notNull: {
            value: true
        },
        min: {
            value: 0
        }
    },
    header: {
        notNull: {
            value: true
        }
    },
    uevent: {
        byRules: EventsUEventValidateRules
    },
    expires: {
        notNull: {
            value: true
        },
        min: {
            value: startCurrentMinute,
            format: 'L HH:mm'
        }
    }
};