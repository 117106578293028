import { mainUrls } from 'utils/src/Urls/main';

// ДЛИННЫЕ ПУТИ ДОЛЖНЫ СТОЯТЬ ПЕРВЫМИ, дальше по уменьшению

const urls = {
  _develop: '/_develop',
  _appsDebug: '/apps/debug',
  login: '/login',
  logout: '/logout',
  dash: '/dash',
  users: mainUrls.users,
  user: mainUrls.user,
  profileEdit: '/profileEdit/:id',
  userNew: ['/usernew/:id/:tab', '/usernew/:id'],
  invite: '/invite',
  groups: '/groups',
  group: ['/group/:id/:tab/:aid/:pid/:fname', '/group/:id/:tab/:aid', '/group/:id/:tab', '/group/:id'],
  messages: ['/messages/:type/:id', '/messages'],
  news: '/news/:id',
  tag: ['/tag/:id/:tab', '/tags/:id/:tab', '/tags/:id', '/tag/:id'],
  badge: '/badge/:id',
  badges: '/badges',
  notificationsSettings: ['/notifications/settings/:id', '/notifications/settings'],
  pages: '/pages/:id+',
  rating: ['/leaderboard/:id', '/rating/:id'],
  mountainRating: '/mountainRating/:id',
  shop: '/shop',
  shopProduct: '/shop/product/:id',
  orders: '/shop/orders',
  competitions: '/competitions',
  ideas: '/ideas',
  birthdays: '/birthdays/:variant?/:date?',
  birthdaysNoMonth: '/birthdays',
  lms: '/lms',
  lmsTest: '/lms/test/:cid',
  lmsResult: '/lms/result/:cid/:sid',
  lmsCourse: '/lms/:id',
  lmsTeacherCourse: '/TeacherOffice/Course/:id',
  lmsTeacherOffice: '/TeacherOffice', 
  lmsCourseCreate: '/TeacherOffice/CourseCreate',
  lmsStatistics: '/TeacherOffice/Statistics/type/:id',
  lmsStatisticsUser: '/TeacherOffice/statistics/user/:id',
  tracksManage: '/TracksManage',
  learningTracks: '/LearningTracks',
  trackManage: '/TrackManage/:id',
  trackView: '/TrackView/:id', 
  photoalbum: '/photoalbum/:id',
  album: '/album/:gid/:aid',
  widgets: ['/widgets/:type/:subtype/:id', '/widgets/:type/:subtype', '/widgets/:type'],
  integrate: '/integrate',
  wdialogs: '/wdialogs',
  questsmoderate: '/quests/moderate',
  events: '/events',
  polls: '/polls',
  creativetasks: '/creativetasks',
  calendar: '/calendar',
  settingsDesign: '/settings/design', 
  notices: '/notices',
  wikilist: ['/wikilist/:collectionAlias', '/wikilist'],
  secretsanta: ['/secretsanta/:id','/secretsanta'],
  board: '/board',
  search: ['/search/:tab', '/search'],
  quiz: '/quiz/go/:id',
  notifiSettings: ['/notifiSettings/:uid/:tab/:gid/:nid', '/notifiSettings/:uid/:tab/:nid', '/notifiSettings/:uid/:tab'],
  notfound: '/404',
  error500: '/500',
  welcome: '/welcome',
  quests: '/quests',
  orgchart: '/orgchart',
  workflows: '/workflows/:type',
  dashboard: '/dashboard',
  statistics: '/statistics',
  survey: '/survey/go/:id',
  widgets_v2: '/widgets_v2',
  widgets_horizontal_v2: '/widgets_horizontal_v2',
  vacancies: '/vacancies',
  vacancy: '/vacancy/:id',
  achievements: '/achievements',
  external: '/external',
  tasks: '/tasks'
}

export const exactPathnames: string[] = [
  urls.shop,
  urls.lms,
  urls.lmsTeacherOffice
]

export default urls;