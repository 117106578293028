import { cn } from "@bem-react/classname";
import { convertFullUserToShort } from "blocks/InfoBlock/UserNew/Avatar/ThankForm/ThankForm";
import { Box, CircularProgress, IconButton, styled } from "muicomponents/src";
import { AskingHand, Bookmark, BookmarkBorder } from "muicomponents/src/Icons";
import { IItemProps } from "muicomponents/src/ItemsListDialog/ItemsListDialog.index";
import { QRCode } from "muicomponents/src/QRCode/QRCode";
import { baseModels } from "News/creator/Creator/Creator.hooks";
import { NewsCreatorButton } from "News/creator/Creator/CreatorButton/CreatorButton";
import React, { FC, useCallback, useMemo, useState } from "react";
import { API, checkResponseStatus, UserListItemActionsButtonIds } from "utils/src";
import { TItemType } from "./List_type_users.index";

const UserAddittionalContentBox = styled(Box)({
  flex: 1,
  display: 'flex'
}) as typeof Box;

const cnUserAddittionalContentAction = cn('UserAddittionalContentAction');

export const UserAddittionalContent: FC<IItemProps<TItemType>> = ({
  actions,
  item
}) => {
  const creatorOptions = useMemo(() => {
    return {
      models: {
        thanks: {
          ...baseModels.thanks, users: [item].map((v: any) => ({ ...convertFullUserToShort(v), type: 'user' }))
        }
      }
    }
  }, [item]);

  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const subscribe = useCallback(async function(newValue: boolean) {
    setSubscribeLoading(true);
    const v = newValue;
    const response = await API.users.subscribe(item.id, v).r;
    if(checkResponseStatus(response, true)) {
      actions.updateItem(
        item,
        {
          ...item,
          actionButtons: item.actionButtons.map(el => {
            if(el.id === UserListItemActionsButtonIds.subscribe) return {
              ...el,
              data: {
                ...el.data,
                isSubscribed: v
              }
            }
            return el;
          })
        }
      )
    }
    setSubscribeLoading(false);
  }, [item]);

  return <UserAddittionalContentBox>
    {
    item.actionButtons.map(el => {
      switch (el.id) {
        case UserListItemActionsButtonIds.qr:
          return null;
        case UserListItemActionsButtonIds.thanks:
          return <NewsCreatorButton 
              key={el.id}
              newsType='thanks'
              iconButton
              creatorOptions={creatorOptions}
              className={cnUserAddittionalContentAction({action: el.id})}
            >
              <AskingHand />
            </NewsCreatorButton>
        case UserListItemActionsButtonIds.subscribe:
          return <IconButton
              key={el.id}
              onClick={() => subscribe(!el.data.isSubscribed)}
              className={cnUserAddittionalContentAction({action: el.id})}
            >
              {
                subscribeLoading
                ? <CircularProgress size={24} />
                : !el.data.isSubscribed
                ? <BookmarkBorder />
                : <Bookmark />
              }
            </IconButton>
        default:
          return null
      }
    })
    }
  </UserAddittionalContentBox>;
}