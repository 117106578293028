import React, { FC, useEffect, memo, useState } from 'react'
import {
    cnClassName,
    IOwnProps
} from './Settings.index'
// import { connect } from 'react-redux';
import './Settings.scss'
import Button from '@material-ui/core/Button';
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { SecretBase } from 'uielements/src/SecretEdit/SecretEdit'
import { EditorState, RawDraftContentState, convertFromRaw, convertToRaw } from 'draft-js'
import { defaultBadge } from 'uielements/src/BadgeSelector/BadgeSelector.index'
import {
    API,
} from 'utils/src/utils'
import { useQuery, useLazyQuery } from 'utils/src/hooks'
import { v1 as uuid } from 'uuid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { i18n } from "localization";

export const Presenter: FC<IOwnProps> = ({
    className,
    setBadge,
    setEditorState,
    pwd,
    state,
    badge,
    editorState,
    setState,
    setPwd,
    secertResult,
    isLoadingSecret,
    showSecretData
}) => {
    const [isLoadingList, badgesListResult, isErrorLoadBadges] = useQuery(() => API.badges.getBadgeList().r as any)
    const badgesList = badgesListResult?.data || []

    useEffect(() => {
        if (secertResult?.data?.secret) {
            setBadge(secertResult.data.secret.badges[0] || defaultBadge)
            setEditorState(EditorState.createWithContent(convertFromRaw(secertResult.data.secret.data)))
        }
    }, [secertResult])
    return <div className={cnClassName({}, [])}>
        {isErrorLoadBadges &&
            <Alert severity="error" className={cnClassName('Alert')}>
                <AlertTitle>
                    {i18n.t(`pryaniky.editor.secrets.edit.error`)}
                </AlertTitle>
                <pre>
                    Badges not loaded.
                    This is internal server error or connection error.
                </pre>
            </Alert>
        }
        <SecretBase
            badge={badge}
            showSecretData={showSecretData}
            isLoadingList={isLoadingList}
            isLoadingSecret={isLoadingSecret}
            badgesList={badgesList}
            btnText={state.buttonText}
            description={state.plainText}
            hideAfterOpening={state.hideAfterOpening}
            pwd={pwd}
            state={editorState}
            setState={setEditorState}
            onChnageBtnText={(e) => setState({ ...state, buttonText: e.target.value })}
            onChnageDescription={(e) => setState({ ...state, plainText: e.target.value })}
            onChnagePwd={(e) => setPwd(e.target.value)}
            setHideAfterOpening={(e) => setState({ ...state, hideAfterOpening: e })}
            setBadge={setBadge}
            img={state.img}
            setImage={(e) => setState({ ...state, img: { id: e.id !== "" ? e.id : null, originalUrl: e.originalUrl1 == "" ? e.originalUrl : null } })}
            setShowImage={(e) => setState({ ...state, showImg: e })}
            showImg={state.showImg}
        />
    </div>
}




export const initWidgetAddition = () => ComponentInjector.getInstance().addComponent('WAddition', Presenter, 'pryaniky/secretwidget')
export const initWidgetSettings = () => ComponentInjector.getInstance().addComponent('WSettings', Presenter, 'pryaniky/secretwidget')