
import { cn } from '@bem-react/classname';


export interface IHeaderWikiWithFiltersNewProps {
    context: any;
    widget: any;
    hasRights: boolean;
    isAdmin: boolean;
    layoutWikilist?: any;

    // breadcrumbsMyWay?: any[]
    dashFolderInfo?:any[]
}


export const cnHeaderWikiWithFiltersNew = cn('HeaderWikiWithFiltersNew');
