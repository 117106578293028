import React, { FC, useState, useEffect, useMemo } from 'react';
import { IPreListFiltersProps, cnPreListFilters } from './PreListFilters.index';
import './PreListFilters.scss';
import i18n from 'localizations/i18n';
import FormControlLabel from '@mui/material/FormControlLabel';
import widgetsActions from 'redux/actionsTypes/Widgets';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox'
import { styled } from 'muicomponents/src/mui/system';
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import { spawn } from 'child_process';
import { RadioGroupMui } from 'muicomponents/src/RadioGroup/RadioGroup';
import { Divider } from 'muicomponents/src/Divider/Divider'
import { useEnvironmentContex } from 'utils/src/EnvironmentContex';
import { CheckButtonSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm';
const StyledFormControlLabel = styled(FormControlLabel)({
    marginRight: 0,
});
const activeFilters = [
    { id: 'all', title: i18n.t('pryaniky.modal.wikipage.filter.archived.all'), value: "", type: 'archived' },
    { id: 'active', title: i18n.t('pryaniky.modal.wikipage.filter.archived.false'), value: 'false', type: 'archived' },
    { id: 'archive', title: i18n.t('pryaniky.modal.wikipage.filter.archived.true'), value: 'true', type: 'archived' }
]

export const PreListfilters: FC<IPreListFiltersProps> = ({ context, widget }) => {
    const [sortFilters, setSortFilters] = useState<any[]>([])

    React.useEffect(() => {
        preparedFilters()
    }, [])

    const setSort = (selected: any) => {
        setSortFilters(sortFilters.map((el: any) => el.id === selected.id ? { ...el, sort: selected.sort === 'down' ? 'up' : 'down' } : el))

    }

    // <FormControlLabel value={'all'} control={<Radio size="small" color="default" />} label={i18n.t('pryaniky.list.wiki.filter.all')} />
    // <FormControlLabel value={'page'} control={<Radio size="small" color="default" />} label={i18n.t('pryaniky.list.wiki.filter.pages')} />
    // <FormControlLabel value={'wikilist'} control={<Radio size="small" color="default" />} label={i18n.t('pryaniky.list.wiki.actions.folders')} />
    // <FormControlLabel value={'url'} control={<Radio size="small" color="default" />} label={i18n.t('pryaniky.list.wiki.actions.url')} />
    // <FormControlLabel value={'file'} control={<Radio size="small" color="default" />} label={i18n.t('pryaniky.list.wiki.actions.file')} />
    const showWikiType = [
        { id: 'all', title: i18n.t('pryaniky.list.wiki.filter.all'), value: 'all' },
        { id: 'page', title: i18n.t('pryaniky.list.wiki.filter.pages'), value: 'page', },
        { id: 'wikilist', title: i18n.t('pryaniky.list.wiki.actions.folders'), value: 'wikilist', },
        { id: 'url', title: i18n.t('pryaniky.list.wiki.actions.url'), value: 'url', },
        { id: 'file', title: i18n.t('pryaniky.list.wiki.actions.file'), value: 'file', },
    ]

    const sortFilt = [
        { id: 'abc', title: i18n.t('pryaniky.modal.wikipage.filter.abc3'), value: 'abc', sort: "down" },
        { id: 'creationdate', title: i18n.t('pryaniky.modal.wikipage.filter.creationdate3'), value: 'creationdate', sort: "down" },
        { id: 'changedate', title: i18n.t('pryaniky.modal.wikipage.filter.changedate3'), value: 'changedate', sort: "down" },
        { id: 'position', title: i18n.t('pryaniky.modal.wikipage.filter.position3'), value: 'position', sort: "down" },
    ]

    const preparedFilters = () => setSortFilters(sortFilt.map(el => ({
        id: el.id,
        type: 'order',
        title: el.title,
        value: el.value,
        sort: el.sort,
    })
    ))
    const dispatch = useDispatch()

    const selectedTypeText = useMemo(() => {
        const selectedType = showWikiType.find(el => el.value === (context.type || 'all'))?.title;
        return `${i18n.t('pryaniky.list.wiki.filter.type.name')} ${selectedType}`;
    }, [context.type]);

    return (
        <Box >
            <Box sx={{display: "flex", flexWrap: 'wrap'}}>
                <StyledFormControlLabel sx={{ paddingBottom: "12px", marginRight: "16px" }} control={<Checkbox
                    checked={context.searchOnlyTitle}
                    onChange={() => {
                     dispatch({
                            type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                [widget.id]: {
                                    searchOnlyTitle: !context.searchOnlyTitle
                                }
                            }
                        })
                    }} />}
                    label={i18n.t("pryaniky.list.wiki.search.only.by.name")} />

                <StyledFormControlLabel sx={{ paddingBottom: "12px" }} control={<Checkbox
                    checked={context.coll}
                    // sx={{ padding: "0" }}

                    onChange={() => {
                        dispatch({
                            type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                [widget.id]: {
                                    coll: !context.coll
                                }
                            }
                        })
                    }} />}
                    label={i18n.t("pryaniky.list.wiki.search.in.collection")} />
            </Box>
            <Divider sx={{ marginBottom: "12px" }} />
            <div className={cnPreListFilters("BulitsForSearch")} >
                <div className={cnPreListFilters("BulitsForSearch-SortFilters")}>
                    <CheckButtonSelector
                        value={context.type}
                        ActionButtonProps={{
                            variant: 'text',
                            className: 'TypeSelector',
                            textColor: undefined,
                            sx: {
                                padding: "0 0 0 8px",
                                marginRight: "11px",
                                fontSize: "16px",
                                fontWeight: "600"
                            }
                        }}
                        options={showWikiType.map(el => {
                            return {
                                label: el.title,
                                value: el.value
                            };
                        })}
                        onChange={(selected) => {
                            dispatch({
                                type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                    [widget.id]: {
                                        type: selected
                                    }
                                }
                            })
                        }}
                        forceText={selectedTypeText}
                    />
                    {sortFilters?.map((el: any, i: any) => <MuiButton
                        // className={cnPreListFilters("BulitsForSearch-SortFilters-Item ")}
                        className={cnPreListFilters("BulitsForSearch-SortFilters-Item ", { active: context.order === el.value })}
                        // variant={"text"}
                        sx={{
                            padding: "0 0 0 8px",
                            marginRight: "11px",
                            textTransform: "inherit",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#000000cc"
                        }}
                        onClick={() => {
                            dispatch({
                                type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                    [widget.id]: {
                                        ["order"]: el.value, ...(el.sort ? { sort: el.sort === 'down' ? 'asc' : "desc" } : {}),
                                    },
                                }

                            })
                            if (context.order === el.value) {
                                dispatch({
                                    type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                        [widget.id]: {
                                            sort: el.sort === 'up' ? 'asc' : "desc"
                                        }
                                    }

                                })
                                setSort(el)
                            }
                        }
                        }
                    >

                        {el.title}
                        {el.sort === "down" ? <ArrowDownwardIcon
                            sx={{ margin: "0 5px" }}
                            color={'action'}
                            fontSize="small" /> : <ArrowUpwardIcon
                            sx={{ margin: "0 5px" }}
                            color={'action'}
                            fontSize="small" />}


                        {/* <IconButton
                            style={{ padding: "5px", }}
                            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                event.stopPropagation();
                                dispatch({
                                    type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                        [widget.id]: {
                                            sort: el.sort === 'up' ? 'asc' : 'desc'
                                        }
                                    }

                                })
                                setSort(el)
                            }}
                        // color={"inherit"}
                        // sx={{context.order === el.value ?color:"primary": "#fff"}}
                        >
                           

                        </IconButton> */}
                        {/* { context.order === el.value && <span></span>} */}
                    </MuiButton>
                    )}
                </div>
            </div>

        </Box>
    )
}

