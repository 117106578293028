import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';
// import i18n from '../../localizations/i18n';
import { IResponseWithData } from 'utils/src/requests/models/api.base';
import { IRatingsData } from 'utils/src/requests/models/api.ratings';
import { ProgressBar, Avatar } from 'uielements/src';
import { Link } from 'react-router-dom';
import i18n from 'localizations/i18n';
import { ListPlaceholder } from 'uielements/src';
import feedPlaceholder from 'assets/svg/feedPlaceholder.svg';
import { CacheHelper } from 'utils/src/CacheHelper'
import {
  mapStateToProps,
  IRatingsWidgetStateProps,
  cnRatingsWidget,
  IRatingsWidgetProps,
  IRatingsWidgetState,
  cnClassNameNoData
} from './RatingsWidget.index';
import './RatingsWidget.scss';
import { Translate } from 'localizations/Translate';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import noDataImage from 'assets/svg/NoData.svg'
import { Box } from 'muicomponents/src';

export class RatingsWidgetPresenter extends React.Component<IRatingsWidgetProps & any, IRatingsWidgetState> {
  // public utils: any = utils;
  public el: HTMLElement | null;
  protected mounted: boolean;

  constructor(props: IRatingsWidgetProps & any) {
    super(props);
    this.mounted = false;
    this.state = {
      ratings: [],
      noData: false,
      width: 0
    };
  }

  public componentDidMount() {
    this.mounted = true;
    this.getData(this.props);
  }

  public componentDidUpdate(pp: IRatingsWidgetProps & any) {
    if (this.props.wcontext.uId !== pp.wcontext.uId) this.getData(this.props);
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const customClassNames = '';
    const { children, className = '', tag: Tag = 'div', settings } = this.props;
    const edit = (this.props as any).edit;
    let { ratings, noData } = this.state;
    const additionalProps: { [s: string]: any } = {
      hide: noData,
    };

    const isBig = this.state.width > 400;
    if (settings?.hideIfNoData && ratings.length === 0 && !edit) return null;

    if (!settings?.hideIfNoData && ratings.length === 0) return <Tag   {...this.props}  {...additionalProps} className={cnClassNameNoData({}, [className])}>
      <div className={cnClassNameNoData('Header-Title')} children={<Translate i18nKey='pryaniky.raiting.my.title' />} />
      <div className={cnClassNameNoData('Header-noData')}> <Avatar precentage={100} shape={'rectangle'}
        imgUrl={noDataImage}
      />
      </div>
      <span className={cnClassNameNoData('Header-Title-Text')}> {i18n.t('pryaniky.widgets.settings.type.birthdays.noData.text')} </span>
    </Tag>;

    if (isBig && ratings.length === 0) {
      return (
        <Tag
          ref={(elem: HTMLDivElement) => (this.state.width === 0 && elem.getBoundingClientRect) && this.setState({ width: elem.getBoundingClientRect().width === 0 ? -1 : elem.getBoundingClientRect().width })}
          {...this.props}
          {...additionalProps}
          className={cnRatingsWidget({}, [customClassNames, className])}>
          <ListPlaceholder
            // key={uuid()}
            imageSrc={feedPlaceholder}
            className={cnRatingsWidget('Placeholder')}
            title={i18n.t('pryaniky.placeholder.widget.nodata')}
            text={i18n.t('pryaniky.ratings.widget.noratings')}
          />
        </Tag>
      )
    }
    // if (this.state.width !== 0 && ratings.length === 0) return null;
    return (
      <Tag
        ref={(elem: HTMLDivElement) => (this.state.width === 0 && elem.getBoundingClientRect) && this.setState({ width: elem.getBoundingClientRect().width === 0 ? -1 : elem.getBoundingClientRect().width })}
        {...this.props}
        {...additionalProps}
        className={cnRatingsWidget({}, [customClassNames, className])}>
        <Box className={cnRatingsWidget('Header-Title')}>
          <span>
            <Translate i18nKey='pryaniky.raiting.my.title' />
          </span>
        </Box>
        {ratings.map(ratingData => (
          <div key={ratingData.groupName} className={cnRatingsWidget('Block', [(isBig ? 'big' : 'small')])}>
            {ratingData.groupName && ratingData.groupName !== '' && (
              <h4 className={cnRatingsWidget('GroupName')}>{ratingData.groupName}</h4>
            )}
            {ratingData.ratingValues.map(rating => {
              return isBig ? this.itemRenderBig(rating) : this.itemRenderSmall(rating)
            })}
          </div>
        ))}
        {children}
      </Tag>
    );
  }

  private itemRenderBig = (rating: any) => {
    const percent: number = parseInt(rating.percantage, 10);
    const Tag: any = rating.isAllowShowLeadersBoard ? Link : 'div';
    return (
      <Tag key={rating.id} to={`/rating/${rating.id}`} className={cnRatingsWidget('Item', ['unstyled'])}>
        <Avatar shape={'square'} size={40} className={cnRatingsWidget('Icon')} imgId={rating.imgId} imgUrl={rating.imgUrl} name={rating.name} />
        <div className={cnRatingsWidget('RatingBox')}>
          <div className={cnRatingsWidget('Description')}>
            <Tooltip
              // className={'InfoIcon'}
              // placement="top"
              title={rating.name}>
              <h4 className={cnRatingsWidget('Name')}>
                {rating.name}
              </h4>
            </Tooltip>
            {/* <p className={cnRatingsWidget('Value')}>{`${utils.formatNumber(rating.curValue)}/${utils.formatNumber(rating.maxValue)}`}</p> */}
            <p className={cnRatingsWidget('Value')}>{rating.value}</p>
          </div>
          <ProgressBar className={cnRatingsWidget('ProgressBar')} percent={percent} color={rating.color} />
        </div>
      </Tag>
    );
  }

  private itemRenderSmall = (rating: any) => {
    const percent: number = parseInt(rating.percantage, 10);
    const Tag: any = rating.isAllowShowLeadersBoard ? Link : 'div';
    return (
      <Tag key={rating.id} to={`/rating/${rating.id}`} className={cnRatingsWidget('Item', ['unstyled'])}>
        {/* <div className={cnRatingsWidget('Header-Title')}> */}
        {/* <Button
            type='rlink'
            theme="unstyled"
            href={'/secretsanta'}
            target="_blank"
          // className={cnSecretSantaWidget('UserAdditionalInfo-Avatar')}
          > */}
        {/* <span><Translate i18nKey='pryaniky.raiting.my.title' /></span> */}
        {/* </Button> */}
        {/* </div> */}
        <div className={cnRatingsWidget('RatingBox')}>
          <Avatar shape={'circle'} size={40} className={cnRatingsWidget('Icon')} imgId={rating.imgId} imgUrl={rating.imgUrl} name={rating.name} />
          <div className={cnRatingsWidget('Description')}>
            <h4 title={rating.name} className={cnRatingsWidget('Name')}>
              {rating.name}
            </h4>
            <p className={cnRatingsWidget('Value')}>{rating.value}</p>
          </div>
        </div>
        <ProgressBar className={cnRatingsWidget('ProgressBar')} percent={percent} color={rating.color} />
      </Tag>
    );
  }

  public getData = (props: IRatingsWidgetProps & any) => {
    const context = this.props.context;

    const cacheKey = `ratings.byUser_${this.props.wcontext.uId || 0}_${context.rIds || 0}`;
    CacheHelper.get('userRating', cacheKey)
      .then((value) => value && this.setState({
        ratings: value.data,
      }))
      .catch(e => console.warn('CacheHelper, userRating:', e))


    utils.API.ratings.byUser(this.props.wcontext.uId, context.rIds).r.then((response) => {
      if (!this.mounted) return;
      if (utils.checkResponseStatus(response)) {
        CacheHelper.set('userRating', cacheKey, utils.clone(response))
          .then((value) => console.log(value))
          .catch(e => console.warn('CacheHelper, userRating:', e))
        this.setState({
          ratings: response.data,
        });
        // setRatings(response.data);
      }
    });
  };
}

export const RatingsWidget = connect<IRatingsWidgetStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(RatingsWidgetPresenter);
