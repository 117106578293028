import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';
import { IPriorityNavTab } from '../PriorityNav/PriorityNav.index';
import { IColumn } from 'i.widgets';
// import { IContext } from 'store/vm';
import { RouteComponentProps } from 'react-router';
import { updateContext } from 'redux/actions/Widgets';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IPrepareTabProps {
  id?: string;
  title?: string;
  tab: string;
  custom?: boolean
  to?: string;
  type?: string;
}

export interface ITabsProps extends ITabsOwnProps, IClassNameProps, IDispatchProps, ITabsStateProps, IActionsDispatch, RouteComponentProps {
  // tag?: 'div';
  // activeTab?: IPriorityNavTab;
  // tabs: IColumn[];
  // // contexts: string[],
  // context: { [s: string]: any };
  // widget: any;
}

export interface ITabsOwnProps {
  tag?: "div";
  activeTab?: IPriorityNavTab;
  tabs: IColumn[];
  // contexts: string[],
  context: { [s: string]: any };
  widget: any;
  disableNavigate?: boolean;
}

export interface ITabsState {
  any?: any;
}

export interface ITabsContext {
  uId: string;
  gId: string;
  tab: string;
  //  {[s: string]: any};
}

export type ITabsStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: any, props: ITabsOwnProps) => ({
  widgets: state.widgets.widgets,
  authUser: getAuthUser(state),
});

export interface IActionsDispatch {
  updateContext?: any;
}

export const mapActionsToProps: IActionsDispatch = {
  updateContext
};

export const cnTabs = cn('Tabs');