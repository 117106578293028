import React, { useCallback, useEffect, useMemo } from 'react';
import { TextField } from 'muicomponents/src/TextField'
import i18n from '../../../../localizations/i18n';
import * as utils from 'utils/src/utils';
import { useDebounce } from 'utils/src/hooks';
import { toast } from 'react-toastify';
import "./WikiCreateNew.scss";
import { ImageLoader } from 'blocks/ImageLoaderWithMui/ImageLoader';
import { UsersSuggester, TagsSuggester, PageCollectionSuggester } from 'muicomponents/src/Suggester';
import { pages } from "../../../../pageStructures/pages";
import { wikilist } from "../../../../pageStructures/wikilist";
import uuid from 'uuid/v1';
import { widgets, generateColumn } from 'i.widgets';
import { history } from 'redux/browserHistory';
import { connect } from "react-redux";
import {
    IWikiCreateNewOwnProps,
    IWikiCreateNewProps,
    cnWikiCreateNewDialog,
    IWikiCreateNewDispatchProps,
    IWikiCreateNewStateProps,
    mapWikiCreateNewStateToProps,
    mapWikiCreateNewDispatchToProps,
} from './WikiCreateNew.index'
import { IStateType as IState } from 'redux/store';
import { Translate } from 'localizations/Translate';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle'
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent'
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { TextError } from 'uielements/src/TextError/TextError';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { useFileUploader } from 'utils/src/hooks'
import { styled } from 'muicomponents/src/mui/system';
import { Icon } from 'uielements/src/index';
import { RadioGroupMui } from 'muicomponents/src/RadioGroup/RadioGroup';
import {
    Divider,
    Box,
    FormControlLabel,
    Checkbox,
    Button,
    FilesUploader,
} from 'muicomponents/src';
import { PreviewSelector } from 'muicomponents/src/PreviewSelector/PreviewSelector'
import { ImageCropperDialog } from 'blocks/ImageLoaderWithMui/ImageCropper/ImageCropper'

import HelpIcon from '@mui/icons-material/Help';
import { getPageBaseType, getWikiUrl } from 'blocks/WikiListNew/utils/Wikilist.utils';
import { PageTypes } from 'utils/src';

const StyledFormControlLabel = styled(FormControlLabel)({
    marginLeft: 0,
    marginRight: 0,
    width: '100%'
});

/**
 * @todorefactoring this block
 * @param param0 
 * @returns 
 */
export const WikiCreateNewDialogPresenter: React.FC<IWikiCreateNewProps> = ({
    data: ddata,
    parentFolderModerators,
    allowComments,
    showInMainManu,
    type,
    handleAccept,
    handleClose,
    changeVm,
    isAdmin,
    onChange = () => { },
    allowFileTypes,
    ...props
}) => {
    const [data, setData] = React.useState<{ [s: string]: any }>({
        ...ddata,
        id: uuid(),
        type: type,
        allowComments: true,
        defaultViewType: 0,
        moderators: parentFolderModerators ? [...parentFolderModerators] : [],
        isInheritingRootRights: ddata.collectionAlias !== "dash" ? true : false
    })
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorLink, setErrorLink] = React.useState(false);
    const [imgChanged, setImgChanged] = React.useState(false);

    const commentTypes = [
        { id: "LeftPanel", title: i18n.t('pryaniky.modal.wikipage.comment.type.LeftPanel'), value: 'LeftPanel' },
        { id: "BottomPanel", title: i18n.t('pryaniky.modal.wikipage.comment.type.BottomPanel'), value: 'BottomPanel' },
    ]

    const {
        isLoading,
        files,
        onFileChange,
        removeFile,
        dndRef,
        dragEntered,
        dndTriggerRef,
        uploadError,
        inputAccept,
        uploadDisabled
    } = useFileUploader({
        // defaultAttachments,
        uploadOnAdd: true,
        maxFilesCount: 1,
        allowTypes: allowFileTypes
    })
    useEffect(() => {
        if (files.length >= 1 && files[0].isUploaded && files[0].response?.data?.[0]) {
            setData({ ...data, file: files[0].response?.data[0] })
            if (data.title === '' || !data.title) {
                setData({ ...data, title: files[0].name, url: files[0].name, file: files[0].response?.data[0] })
            }
        } else {
            setData({ ...data, file: null })
        }
    }, [files])


    // const onIconChange = (selected: string) => {
    //     store.dispatch(modalChangeDate({ id: props.id || '', data: { ...ddata, icon: selected } }))
    // }
    const changeSelect = (selected: any) => {
        setData({ ...data, type: selected })
    };

    const pageType = getPageBaseType(data.type);

    const typePage = pageType === PageTypes.page;
    const typeWikilist = pageType === PageTypes.wikilist;
    const typeUrl = pageType === PageTypes.url;
    const typeFile = pageType === PageTypes.file;

    const sendDataForCreatNewPage = () => {
        setLoading(true)

        let link = getWikiUrl(data as any);

        const structure = pages(typeFile ? widgets.types.file : widgets.types.wiki);
        const wikilistStracture = wikilist()
        const store: { [s: string]: any } = typePage ? { edit: true, editType: 'content' } : { edit: false, editType: '' };
        if (data.selected === 'landing') {
            structure.data = [generateColumn({})];
            store.openedModal = 'layoutSelector';
            store.editType = undefined;
        }

        const dataToCrete = {
            // layout: data.type === "wikilist" ? wikilistStracture : data.commentType === "BottomPanel" ? pageWithComments : structure,
            layout: typeWikilist ? wikilistStracture : structure,
            enableTwoFactorAuth: false,
            context: data.context,
            registerNewWidgets: false,
            description: data.description,
            isTemplate: false,
            id: uuid(),
            type: data.type || PageTypes.page,
            url: link,
            title: data.title,
            moderators: (data.isInheritingRootRights && parentFolderModerators) ? [] : data.moderators,
            tags: data.tags,
            collectionAlias: data.collectionAlias,
            collections: data.collections,
            titleImageId: data.titleImageId,
            isInheritingRootRights: data.collections?.find((el: any) => el.alias === "dash") ? false : data.isInheritingRootRights,
            hasCustomTitleImage: data.titleImageId === null || data.titleImageId === undefined || data.titleImageId === "" ? false : true,
            titleImageUrl: data.titleImageUrl,
            showInMainMenu: data.showInMainMenu,
            allowComments: data.allowComments,
            defaultViewType: data.defaultViewType,
            // positionInCollection: Number(ddata.positionInCollection),
            positionInCollection: Number(data.positionInCollection) ? Number(data.positionInCollection) : 0,
            icon: data.icon,
            viewers: data.viewers,
            file: data.file,
            pageType: data.pageType,
            isDraft: typePage,
            showCategoryAsSelector: data.showCategoryAsSelector
            // commentType: data.commentType


        };
        utils.API.pages.setNew(dataToCrete)
            .r
            .then((response) => {
                if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                else if (response.error_code) {
                    toast.error(response.error_text);
                    setLoading(false)
                }
                else if (response.error_code === 0) {
                    if (typeFile) {
                        history.push(`/pages/files/${data.file.id}`)
                    } else if (typeUrl) {
                        handleAccept(dataToCrete)
                    } else {
                        history.push(link);
                    }
                    // history.push(typePage ? pageUrl :
                    //     typeWikilist ? wikilistUrl :
                    //         typeUrl && data.collectionAlias !== "dash" ? "/wikilist/" + data.collectionAlias : "/wikilist");
                    !typePage && handleClose();
                    changeVm('', store);
                }

            })
    };

    const onChangeShowInMainMenu = (e: any) => {
        setData({ ...data, showInMainMenu: !data.showInMainMenu })
    }

    const onChangeAllowComments = (e: any) => {
        setData({ ...data, allowComments: !data.allowComments })
    }

    const onChangeShowCategoryAsSelector = (e: any) => {
        setData({ ...data, showCategoryAsSelector: !data.showCategoryAsSelector })
    }

    const onChangeHideFooter = () => {
        setData({ ...data, isHideFooter: !data.isHideFooter })
    };

    const onChangeIsInheritingRootRights = (e: any) => {
        setData({
            ...data,
            isInheritingRootRights: !data.isInheritingRootRights,
            moderators: !data.isInheritingRootRights && parentFolderModerators ? [...parentFolderModerators] : data.moderators
        })
    }

    const onChangeLinkInput = (e: any) => {
        const pageUrl = "pages/" + e.currentTarget.value;
        const wikilistUrl = "wikilist/" + e.currentTarget.value;
        !typeUrl && onChangeLinkInputUseDebounce(typePage ? pageUrl : wikilistUrl);
        setData({ ...data, url: e.currentTarget.value })
        if (e.currentTarget.value === "" || e.currentTarget.value === undefined) {
            setError(true)
        } else {
            setError(false)
        }
    }

    // const hasLink =useMemo(()=>{},[])
    const checkUrlIsExist = (value: string) => {
        utils.API.pages.isExists(value)
            .r
            .then((response) => {
                if (!response) {
                    toast.error(i18n.t('pryaniky.toast.error.server'));
                } else if (response.data === true) {
                    setErrorLink(true)
                    toast.error(i18n.t('pryaniky.modal.wikipage.create.url.is.exist'))
                } else if (response.data === false) {
                    setErrorLink(false)
                }
            })
    }


    const Addorment = (typePage || typeFile) ? <p className={cnWikiCreateNewDialog("child")}>/pages/</p> : (typePage || typeWikilist) ? <p className={cnWikiCreateNewDialog("child")}>/wikilist/</p> : (ddata.type === "url" || typeUrl) && undefined

    const onChangeLinkInputUseDebounce = useDebounce(checkUrlIsExist, 1000, []);

    let disableAccept = data.url === '' || data.url === undefined
    disableAccept = typeFile ? !Boolean(data.file) : disableAccept
    disableAccept = isLoading ? true : disableAccept
    disableAccept = loading ? true : disableAccept

    return (
        <>
            <DialogTitle onClose={handleClose}>
                {typePage ? i18n.t("pryaniky.modal.wikipage.create.type.page.new") : typeWikilist ?
                    i18n.t("pryaniky.modal.wikipage.create.type.wikilist.new") : typeUrl ?
                        i18n.t("pryaniky.modal.wikipage.create.type.url.new") : i18n.t("pryaniky.modal.wikipage.create.type.file.new")}

            </DialogTitle>
            <DialogContent className={cnWikiCreateNewDialog("")}>
                <Box className={cnWikiCreateNewDialog("Content")}>

                    <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        <TextField
                            name="title"
                            fullWidth
                            // variant='outlined'
                            value={data.title}
                            className={cnWikiCreateNewDialog("Input")}
                            label={typePage ? i18n.t("pryaniky.modal.wikipage.page.name") : typeWikilist ? i18n.t("pryaniky.modal.wikipage.page.name.wikilist") : typeUrl ? i18n.t("pryaniky.modal.wikipage.page.name.url") : i18n.t("pryaniky.modal.wikipage.page.name.file")}
                            autoFocus
                            onChange={(e: any) => {
                                setData({ ...data, title: e.currentTarget.value })
                            }} />
                    </Box>
                     <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        <TextField
                            name="description"
                            fullWidth
                            // variant='outlined'
                            value={data.description}
                            className={cnWikiCreateNewDialog("Input")}
                            label={i18n.t("pryaniky.modal.wikipage.page.description")}
                            sx={{
                                ".MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline": {
                                    minHeight: "41px"
                                }
                            }}
                            onChange={(e: any) => {
                                setData({ ...data, description: e.currentTarget.value })
                            }} />
                    </Box>
                    <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        {
                            data.type !== 'file' && <TextField
                                id="wikilink"
                                name="url"
                                fullWidth
                                // variant='outlined'
                                value={data.url}
                                // style={{ marginLeft: (ddata.type === "url" || typeUrl) ? "0px" : "47px" }}
                                className={cnWikiCreateNewDialog(error || errorLink ? "ErrorLink" : "InputLink")}
                                label={typePage ? i18n.t("pryaniky.modal.wikipage.page.url") : typeWikilist ? i18n.t("pryaniky.modal.wikipage.page.url.wikilist") : typeUrl && i18n.t("pryaniky.modal.wikipage.page.url.url")}
                                autoFocus={error}
                                onChange={onChangeLinkInput}
                                InputProps={{
                                    startAdornment: Addorment

                                }}
                            />

                        }
                        {error && <TextError data={i18n.t('pryaniky.modal.wikipage.disabled.btn.help')} />}
                        {errorLink && <TextError data={i18n.t('pryaniky.modal.wikipage.create.url.is.exist')} />}
                    </Box>
                    <Box className={cnWikiCreateNewDialog("Content-Item")}>

                        {
                            typeFile && <Box
                                ref={dndTriggerRef}
                                sx={{ position: 'relative' }}
                            >
                                {/* <FileDropZone ref={dndRef} show={dragEntered}  show={true} /> */}
                                <FilesUploader
                                    files={files}
                                    inputAccept={inputAccept}
                                    multiple={false}
                                    showProgressOnNewFiles
                                    selectButtonProps={{
                                        children: i18n.t("pryaniky.modal.wikipage.page.selectFile"),
                                        variant: 'outlined'
                                    }}
                                    error={Boolean(uploadError)}
                                    helperText={uploadError?.error?.error_text}
                                    disabled={uploadDisabled}
                                    // className={cnWikiCreateNewDialog("FileInput")}
                                    // inputAccept="image/jpeg,image/png"
                                    onFileChange={onFileChange}
                                    removeFile={removeFile}
                                />
                            </Box>
                        }
                        {/* {error && <TextError data={i18n.t('pryaniky.modal.wikipage.disabled.btn.help')} />} */}
                    </Box>

                    {data.collectionAlias !== "dash" && isAdmin && <StyledFormControlLabel control={<Checkbox
                        className={cnWikiCreateNewDialog("CheckboxInput")}
                        checked={data.isInheritingRootRights}
                        onChange={onChangeIsInheritingRootRights} />}
                        label={i18n.t("pryaniky.modal.wikipage.isInheritingRootRights")} />}


                    <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        <UsersSuggester
                            TextFieldProps={{
                                label: i18n.t("pryaniky.modal.wikipage.choose.moderator"),
                            }}
                            value={data.moderators}
                            multiple
                            onChange={(_, value) => {
                                setData({ ...data, moderators: value })
                            }}
                            disabled={data.isInheritingRootRights}
                            requestAdditionalParams={{ excludeMe: false, groupid: ddata.gId }}
                        />
                    </Box>

                    <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        <TagsSuggester
                            TextFieldProps={{
                                label: i18n.t("pryaniky.modal.wikipage.choose.tag"),
                            }}
                            multiple
                            onChange={(_, value) => {
                                setData({ ...data, tags: value })
                            }}
                            className={cnWikiCreateNewDialog("InputFolder-Folder")}
                            value={data.tags}


                        // {i18n.t('pryaniky.shop.selectFor')}
                        />
                    </Box>
                    <Box className={cnWikiCreateNewDialog("InputFolder")}>
                        <PageCollectionSuggester
                            TextFieldProps={{
                                label: i18n.t("pryaniky.modal.wikipage.choose.pagecollections"),
                            }}

                            onChange={(event, value) => {
                                if (value) {
                                    setData({ ...data, collections: [value] })
                                } else {
                                    setData({ ...data, collections: [] })
                                }
                            }}
                            className={cnWikiCreateNewDialog("InputFolder-Folder")}
                            value={data?.collections?.length > 0 ? data?.collections?.map((el: any) => ({
                                displayName: el?.name,
                                ...el
                            })) : null}

                        />
                        {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.choose.pagecollections.help')}> */}
                        <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.pagecollections.help')} >
                            <span><HelpIcon sx={{ marginLeft: "10px" }} color="action" /></span>
                        </Tooltip>
                    </Box>
                    <Box className={cnWikiCreateNewDialog("InputFolder")}>
                        <UsersSuggester
                            TextFieldProps={{
                                label: typePage
                                    ? i18n.t('pryaniky.modal.wikipage.page.viewers')
                                    : typeWikilist
                                    ? i18n.t('pryaniky.modal.wikipage.folder.viewers')
                                    : i18n.t('pryaniky.modal.wikipage.link.viewers'),
                            }}
                            value={data.viewers || []}
                            className={cnWikiCreateNewDialog("InputFolder-Folder")}
                            multiple
                            requestAdditionalParams={{ excludeMe: false }}
                            onChange={(_, viewers) => {
                                setData({ ...data, viewers })
                            }}
                            isAdmin

                        />

                        <Tooltip title={i18n.t('pryaniky.modal.wikipage.viewers.help')} >

                            <span><HelpIcon sx={{ marginLeft: "10px" }} color="action" /></span>
                        </Tooltip>
                    </Box>
                    {typeWikilist && <Box className={cnWikiCreateNewDialog("ViewType")}>
                        <h4><Translate i18nKey={'pryaniky.news.view.type.title.default'} /></h4>
                        <Box>

                            <Button variant={data.defaultViewType === 0 ? "contained" : "outlined"} onClick={() => {
                                setData({ ...data, defaultViewType: 0 })
                            }}>
                                <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.full.help')} >

                                    <span><Icon icon={'wiki'}></Icon></span>
                                </Tooltip>
                            </Button>

                            <Button variant={data.defaultViewType === 1 ? "contained" : "outlined"} onClick={() => {
                                setData({ ...data, defaultViewType: 1 })
                            }}>
                                <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.short.help')} >
                                    <span> <Icon icon={'bars'}></Icon></span>
                                </Tooltip>
                            </Button>

                        </Box>
                    </Box>}
                    <Divider variant="inset" sx={{ margin: '14px 0' }} />

                    <Box className={cnWikiCreateNewDialog("PositionInCollection")}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            margin: "16px 0",
                            "input::-webkit-outer-spin-button": {
                                "-webkit-appearance": "none",
                                margin: 0
                            },
                            "input::-webkit-inner-spin-button": {
                                "-webkit-appearance": "none",
                                margin: 0
                            }
                        }}>

                        < TextField
                            className={cnWikiCreateNewDialog("PositionInCollection")}
                            inputProps={{ inputMode: 'numeric' }}
                            suggesterValue={false}
                            label={i18n.t('pryaniky.modal.wikipage.page.positionInCollection')}
                            // style={{ maxWidth: "300px" }}
                            variant='outlined'
                            value={data.positionInCollection ? data.positionInCollection.toString() : ""}
                            onChange={(e) => {
                                if (e.currentTarget.value.match(/[^0-9]/)) {
                                    e.preventDefault();
                                } else {
                                    setData({ ...data, positionInCollection: e.currentTarget.value })
                                }
                            }}
                        />

                    </Box>

                    <StyledFormControlLabel control={<Checkbox

                        // sx={{ padding: "9px 9px 9px 0" }}
                        className={cnWikiCreateNewDialog("CheckboxInput")}
                        checked={data.showInMainMenu}
                        onChange={onChangeShowInMainMenu} />}
                        label={i18n.t("pryaniky.modal.wikipage.showInMainMenu")} />

                    {(typePage || typeFile) && <StyledFormControlLabel control={<Checkbox
                        className={cnWikiCreateNewDialog("CheckboxInput")}
                        checked={data.allowComments}
                        onChange={onChangeAllowComments} />}
                        label={i18n.t("pryaniky.modal.wikipage.allowComments")} />}

                    {/* {data.allowComments && <Box  sx={{ marginLeft: "40px" }}>
                        <RadioGroupMui
                            sx={{ marginLeft: 0 }}
                            className={cnWikiCreateNewDialog("RadioButtonGroup")}
                            items={commentTypes}
                            size='small'
                            onChange={(e, selectedEl: any) => setData({ ...data, commentType: selectedEl })}
                            value={data.commentType}

                        />
                        </Box>
                    } */}

                    {typeWikilist &&
                        <StyledFormControlLabel control={<Checkbox
                            className={cnWikiCreateNewDialog("CheckboxInput")}
                            checked={data.showCategoryAsSelector}
                            onChange={onChangeShowCategoryAsSelector} />}
                            label={i18n.t("pryaniky.modal.wikipage.showCategoryAsSelector")} />
                    }

                    <StyledFormControlLabel control={<Checkbox

                        // sx={{ padding: "9px 9px 9px 0" }}
                        className={cnWikiCreateNewDialog("CheckboxInput")}
                        checked={data.isHideFooter}
                        onChange={onChangeHideFooter} />}
                        label={i18n.t("pryaniky.modal.wikipage.isHideFooter")} />



                    <Divider variant="inset" sx={{ margin: '14px 0' }} />

                    <PreviewSelector
                        {...props}
                        aria-describedby={data.id}
                        className={cnWikiCreateNewDialog({}, [props?.className])}
                        onClick={(e: any) => {

                            ImageCropperDialog({
                                // editOpen: isEdit,
                                onLoaded: (file: any) => {
                                    setData({ ...data, titleImageId: file.id, titleImageUrl: file.originalUrl })
                                    setImgChanged(true)
                                }
                            })

                        }}
                        previewUrl={data.titleImageUrl}
                        newImg={imgChanged}
                        label={typePage ? i18n.t("pryaniky.modal.wikipage.cover") : typeWikilist ?
                            i18n.t("pryaniky.modal.wikipage.cover.wikilist") : typeUrl ?
                                i18n.t("pryaniky.modal.wikipage.cover.url") : i18n.t("pryaniky.modal.wikipage.cover.file")}
                        helperText={i18n.t("pryaniky.modal.wikipage.cover.size")}
                    />
                </Box>

            </DialogContent>
            <DialogActions
                acceptText={i18n.t("pryaniky.modal.wikipage.create")}
                closeText={i18n.t("pryaniky.modal.wikipage.cencel")}
                isLoading={loading || isLoading}
                disableAccept={disableAccept || errorLink}
                onAccept={data.url === '' || data.url === undefined ? () => {
                    typeFile ? sendDataForCreatNewPage() : setError(true);

                } : sendDataForCreatNewPage}
                onClose={handleClose}
            />
        </>
    );
};


export const WikiCreateNewDialog = connect<IWikiCreateNewStateProps, IWikiCreateNewDispatchProps, IWikiCreateNewOwnProps, IState>(
    mapWikiCreateNewStateToProps,
    mapWikiCreateNewDispatchToProps,
)(WikiCreateNewDialogPresenter)

export const DialogWikiCreateNew = createModal(WikiCreateNewDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});













// </DialogContent>


