import React, { useEffect, useState } from 'react';
import { IMakeOrderDialogProps, mapStateToProps, mapDispatchToProps } from './ShopMakeOrder.index';
import { connect } from 'react-redux';
import i18n from 'localizations/i18n';
import { cnDialog, Loading, } from 'uielements/src';
import Characteristics from 'uielements/src/Characteristics'
import { pickSelected } from 'blocks/Shop/utils'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { toast } from 'react-toastify';
import { FieldsEditor, isEmptyMandatory } from 'muicomponents/src/FieldRender';
import { styled } from 'muicomponents/src/mui/system';
import { getProductRequest } from 'utils/src/requests/requests.shop';
import { cloneDeep } from 'lodash';
import { useLazyQuery, dataExtractor } from 'utils/src/hooks'
import { DialogBody } from 'muicomponents/src/DialogParts/DialogBody/DialogBody'
import { Box, TextField, UsersSuggester } from 'muicomponents/src';
import { Typography } from 'muicomponents/src';
import { Translate } from 'localizations/Translate';
import { CountSelector } from 'blocks/ShopMui/CountSelector';
import * as utilsProj from 'utils.project/utils.project';
import { countMissingThanks } from 'blocks/ShopMui/utils';
import { joinReactNodes } from 'utils/src/utils'

const StyledFieldsEditor = styled(FieldsEditor)({
  padding: 16
});


const additionalFieldsMapper = (additionalFields?: any) => {
  return (additionalFields || []).map((category: any) => {
    return {
      ...category,
      fields: category.fields.filter((el: any) => !el.defaultFieldName)
    }
  })
}

const ShopMakeOrderPresenter = ({
  data = {},
  additionalInformationBeforeOrder,
  makeShopOrder,
  currencyFormats,
  userThanksCount,
  useRedux,
  withCount,
  selectedCount,
  maxCount,
  handleAccept,
  handleClose
}: IMakeOrderDialogProps) => {

  const [state, setState] = useState({ ...data })
  const [errors, setErrors] = useState<any>();
  const [count, setCount] = useState<number | undefined>(selectedCount || 1);

  const [
    loadAdditionalFields,
    isLoadingAdditionalFields,
    additionalFields,
    isError,
    _,
    setAdditionalFields
  ] = useLazyQuery(getProductRequest, (value) => {
    const data = dataExtractor(value)
    return data.additionalFields
  })

  useEffect(() => {
    if (!data.additionalFields?.additionalFields) {
      loadAdditionalFields(data.id)
    } else {
      setAdditionalFields(data.additionalFields)
    }
  }, [])

  const selectedParams = pickSelected(state.characteristics)

  const onConfirm = () => {
    const id: string = state.presentFor?.id;
    const result = {
      comment: state.comment,
      presentFor: [{ id }],
      characteristics: selectedParams,
      id: state.id,
      thanksPrice: state.thanksPrice,
      additionalFields: additionalFields ? {
        displayName: additionalFields!.displayName,
        formId: additionalFields!.formId,
        additionalFieldsValues: additionalFields!.additionalFields,
        id: additionalFields!.id
      } : null,
      count,
    }
    if (useRedux) makeShopOrder(result)
    handleAccept(result)
  }

  const showAdditionalInformation = !data.doNotShowAdditionalInformation && (additionalInformationBeforeOrder && additionalInformationBeforeOrder.length > 0)

  const header = i18n.t('pryaniky.shop.dialogTitle') + (showAdditionalInformation ? ' *' : '')

  const missingThanks = countMissingThanks(state.thanksPrice * (count || 1), userThanksCount);
  const userHaventCurrency = missingThanks > 0;

  const disableAccept = withCount
    ? !count || userHaventCurrency || isEmptyMandatory(additionalFields?.additionalFields || [])
    : isEmptyMandatory(additionalFields?.additionalFields || []);

  return <DialogBody
    header={header}
    disableAccept={disableAccept}
    acceptText={userHaventCurrency
      ? <>{i18n.t('missing')} {missingThanks} {utilsProj.getFormattedCurrencyName(currencyFormats, missingThanks, 'genitive')}</>
      : i18n.t('pryaniky.shop.order')
    }
    closeText={i18n.t('pryaniky.shop.cancel')}
    onAccept={onConfirm}
    onClose={handleClose}
    contentProps={{
      sx: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
      }
    }}
  >
    {
      withCount &&
      <>
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'space-between' }}>
          <Translate i18nKey={'count'} />
          <CountSelector
            size={'small'}
            onChange={(e) => {
              setCount(e.target.value);
            }}
            value={count}
            inputProps={{
              max: maxCount
            }}
          />
        </Box>
        {
          maxCount !== undefined
          && maxCount !== 9999
          &&
          <span>
            <Translate i18nKey={'pryaniky.shop.avaliableCount'} />
            :&nbsp;
            <Typography fontSize='14px' component={'b'} sx={{ fontWeight: 'bold' }}>
              {maxCount}
              <Translate i18nKey={'pryaniky.shop.pc'} />
            </Typography>
          </span>
        }
      </>
    }
    {
      isLoadingAdditionalFields && <Loading />
    }
    {
      additionalFields
        ? <StyledFieldsEditor
          fields={additionalFieldsMapper(additionalFields.additionalFields)}
          onError={(errors) => setErrors(errors)}
          errors={errors}
          onChange={(newAdditionalFields: any) => setAdditionalFields({ ...additionalFields, additionalFields: newAdditionalFields })}
        />
        :
        <TextField
          placeholder={i18n.t('pryaniky.shop.comment')}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setState({ ...state, comment: event.target.value })}
          multiline
          fullWidth
        />
    }

    {data.characteristics.length > 0 && <Box>
      <Typography variant='h6' className={cnDialog('CharacteristicsTitle')}>{i18n.t('pryaniky.shop.dialog.characteristics')}</Typography>
      <Characteristics readonly characteristics={selectedParams} onChange={() => { }} />
    </Box>}

    {!isLoadingAdditionalFields && data.isGift && (
      <>
        <Typography variant='subtitle1' color='textSecondary' >{i18n.t('pryaniky.shop.for', { text: data.name })}</Typography>
        <UsersSuggester
          multiple={false}
          requestAdditionalParams={{ excludeme: true }}
          value={state.presentFor}
          placeholder={i18n.t('pryaniky.shop.selectFor')}
          onChange={(event, value) => setState({ ...state, presentFor: value })}
          disablePortal={true}
        />
      </>
    )}

    {showAdditionalInformation &&
      <Box>
        <Typography variant='subtitle1' color='textSecondary' >{joinReactNodes(additionalInformationBeforeOrder.split('\n'), <br />)}</Typography>
      </Box>
    }

  </DialogBody>
}

export const ShopMakeOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopMakeOrderPresenter)

export const openShopMakeOrder = createModal(ShopMakeOrder, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});