import React, { ComponentProps, FC, useMemo, useState } from 'react';
import { DialogTitle, DialogActions, Button, Icon } from 'uielements/src';
import ButtonMUI from 'uielements/src/MaterialElements/Button/Button';
import DropdownWithButton from 'uielements/src/MaterialElements/DropdownWithButton/DropdownWithButton';
import { TextInput } from 'blocks/PryanikyForms';
import { IWidgetsTabsSettingsProps, IWidgetsTabsSettingsState, cnWidgetsTabsSettings } from "./WidgetsTabsSettings.index";
import { wrapDialogToRedux } from "../../DialogWrapper";
import i18n from 'localizations/i18n';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import { generateColumn, generateWidget, widgets, IColumn } from 'i.widgets';
import SortableTree, { NodeData, TreeItem, TreePath, TreeIndex } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { CheckboxInput, ImageLoader } from 'uielements/src';
import './WidgetsTabsSettings.scss';
import { Translate } from 'localizations/Translate';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import List, { ListProps, ListTypeMap } from '@material-ui/core/List';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import { arrayMove } from 'utils/src/utils'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { IAttachement } from 'utils/src/requests/models/api.system';
import { ButtonGroup } from '@material-ui/core';
import { groupTabs, userTabs } from 'pageStructures/__tabs';

// 
const SortableList = SortableContainer<ListProps<React.ElementType<any>>>(List);

const DragHandle = SortableHandle(() => <IconButton><DragIndicatorIcon /></IconButton>);

const NodeCustomTitle: FC<{ node: TreeItem, path: TreePath, treeIndex: TreeIndex, deleteTab: (id: string) => void }> = ({ node: nodeProp, path, treeIndex, deleteTab }) => {
  // типы летят если оставить node
  // TODO проверить типизацию и логику
  const node: any = nodeProp;
  if (!node.changed) node.frontTitle = Translate.t({ i18nKey: `pryaniky${node.name ? '' : '.custom'}.tabs.${node.name || node.id}` });
  // if (node.frontTitle === '' || !node.frontTitle) node.frontTitle = node.name;

  // return <div className={cnWidgetsTabsSettings('EditBox')}>
  return <ListItem className={cnWidgetsTabsSettings('EditBox')} ContainerComponent={'div' as any} role={undefined} dense button>
    <TextInput
      className={cnWidgetsTabsSettings('EditTitle')}
      disabled={node.disableEdit}
      icon={<>
        <ImageLoader
          squarePreview
          fileId={node.iconFile?.id}
          defaultUrl={node.iconFile?.originalUrl}
          className={cnWidgetsTabsSettings('EditImage')}
          onLoaded={(file) => {
            node.itemFileChange(file);
          }}
          onFileDelete={() => {
            node.itemFileChange(null);
          }}
        />
        <Icon icon={!node.disableEdit ? 'edit' : ''} />
      </>}
      onChange={node.changeTitleFunction}
      value={node.frontTitle as string}
      placeholder={'write new column name'} />

    <CheckboxInput
      className={cnWidgetsTabsSettings('EditAccess')}
      onChange={node.itemToggleAccessPolicy}
      checked={Boolean(node.accessPolicy)}
      padding="0"
    >
      <Translate i18nKey='pryaniky.tabs.accessPolicy' />
      <HelpTooltip help={i18n.t('pryaniky.tabs.accessPolicy.help')}>
        <Icon icon="question-circle"></Icon>
      </HelpTooltip>
    </CheckboxInput>

    <ListItemSecondaryAction className={cnWidgetsTabsSettings('EditSecondary')}>
      <Button onClick={() => deleteTab(node.id)} children={<Icon icon='trash' />} />
      <DragHandle />
    </ListItemSecondaryAction>
  </ListItem>
  // </div>
}
const SortableItem = SortableElement(NodeCustomTitle);



const tabs = { group: groupTabs, user: userTabs } as const;

const BaseTabsMenu: FC<{
  onSelect: (column: ReturnType<typeof generateColumn>) => void;
  className?: string;
}> = ({
  onSelect,
  className
}) => {

  const onClick: ComponentProps<typeof DropdownWithButton>['onClick'] = (opt) => {
    if(opt.value === 'new') {
      const column = generateColumn({ items: [generateWidget({ type: widgets.types.layout, data: [generateColumn({})] })] });
      const key = `pryaniky.custom.tabs.${column.id}`;
      Translate.setResorseItem(key, Translate.t({ i18nKey: 'pryaniky.tabs.new' }));
      onSelect(column);
    } else {
      let tabFnc: typeof tabs[keyof typeof tabs][keyof typeof tabs[keyof typeof tabs]] | undefined;
      let pad: any = tabs;
      opt.value.split('.').forEach((key, idx, arr) => {
        if(Object.hasOwn(pad, key)) pad = pad[key];
        if(arr.length - 1 === idx) tabFnc = pad;
      });
      tabFnc && onSelect(tabFnc());
    }
  }

  const options: ComponentProps<typeof DropdownWithButton>['options'] = useMemo(() => {
    return Object.typedKeys(tabs).map(name => {
      const options: ComponentProps<typeof DropdownWithButton>['options'] = [];
      Object.typedKeys(tabs[name]).forEach(tab => {
        options.push({
          title: Translate.t({ i18nKey: `pryaniky.tabs.create.${name}.${tab}`}),
          value: `${name}.${tab}` 
        });
      });
      return options;
    }).reduce((a, c) => ([ ...a, ...c ]), []);
  }, []);


  return (
    <>
      <DropdownWithButton
        removeDefaultFromDropdown
        className={className}
        defaultItemIndex={0}
        onClick={onClick}
        options={[
          {
            title: Translate.t({ i18nKey: 'pryaniky.widgets.add.new.tab'}),
            value: 'new'
          },
          ...options
        ]}
      />
    </>
  )
}

/**
 * @deprecated
 */
class WidgetsTabsSettingsPresenter extends React.Component<IWidgetsTabsSettingsProps, IWidgetsTabsSettingsState> {
  private itemHeight: number = 40;
  private maxTreeHeight: number = 500;

  constructor(props: IWidgetsTabsSettingsProps) {
    super(props);
    this.state = {
      addName: ''
    }
  }

  public render() {
    const { onConfirm, onClose, data } = this.props;
    data.columns.forEach((el: any) => {
      if ((typeof el.e !== 'function' || (
        typeof el.frontTitle === 'string' && typeof el.title === 'string' && el.title !== el.frontTitle
      )) && !el.changed) {
        el.frontTitle = Translate.t({ i18nKey: `pryaniky${el.name ? '' : '.custom'}.tabs.${el.name || el.id}` });;
      }
      el.changeTitleFunction = this.itemTitleEdit(el.id);
      el.itemToggleAccessPolicy = this.itemToggleAccessPolicy(el.id);
      el.itemFileChange = this.itemFileChange(el.id);
      el.title = NodeCustomTitle;
    })
    const treeHeight = data.columns.length * this.itemHeight;
    const onSortEnd = ({ oldIndex, newIndex }: any) => {
      const muved: any = arrayMove(data.columns, oldIndex, newIndex)
      this.onChangeStructure(muved)
      //changeUnitPosition({ oldIndex, newIndex, cid });
    }
    return (
      <>
        <DialogTitle onClose={onClose} children={i18n.t('pryaniky.widgets.add.title')} />
        {/* <SortableTree
          className={cnWidgetsTabsSettings('Tree')}
          style={{ minHeight: `${treeHeight < this.maxTreeHeight ? treeHeight : this.maxTreeHeight}px` }}
          treeData={data.columns}
          onChange={this.onChangeStructure}
          maxDepth={1}
          rowHeight={this.itemHeight}
          generateNodeProps={this.generateNodeProps}
        /> */}
        <SortableList
          // hideSortableGhost={false}
          className={cnWidgetsTabsSettings({}, [])}
          useDragHandle
          component={'div'}
          onSortEnd={onSortEnd}>
          {data.columns.map((val: any, i: number) => (
            <SortableItem key={val.id} node={val} path={'' as any} index={i} treeIndex={i as any} deleteTab={this.deleteTab} />
          ))}
        </SortableList>
        <BaseTabsMenu
          className={cnWidgetsTabsSettings('AddItem')}
          onSelect={this.addNewItem}
        />
        {/* </BaseTabsMenu> */}
        {/* <ButtonGroup>
          <ButtonMUI
            style={{ height: `$ll{this.itemHeight}px` }}
            className={cnWidgetsTabsSettings('AddItem')}
            onClick={this.addNewItem}
          >
            <Translate i18nKey={'pryaniky.widgets.add.new.tab'} />
          </ButtonMUI>
        </ButtonGroup> */}
        {/* <Button style={{ height: `$ll{this.itemHeight}px` }} className={cnWidgetsTabsSettings('AddItem')} onClick={this.addNewItem} children={i18n.t('pryaniky.widgets.add.new.tab')} /> */}
        <DialogActions>
          <Button main padding="lg" onClick={this.onSave} children={i18n.t('ok')} />
          {/* <Button main padding="lg" onClick={onConfirm} children={i18n.t('ok')} /> */}
          <Button padding="lg" onClick={onClose} children={i18n.t('cancel')} />
        </DialogActions>
      </>
    )
  }

  private itemFileChange = (itemId: string) => (file: IAttachement | null) => {
    this.props.data.columns.forEach((el: any) => {
      if (itemId === el.id) {
        el.iconFile = file;
        el.changed = true;
      }
    });
    store.dispatch(modalChangeDate({ id: this.props.id || '', data: { columns: [...this.props.data.columns] } }));
  }

  private itemToggleAccessPolicy = (itemId: string) => () => {
    this.props.data.columns.forEach((el: any) => {
      if (itemId === el.id) {
        el.accessPolicy = el.accessPolicy ? null : {
          PolicyType: "ShowOnlyForOwnerAndAdmin"
        };
        el.changed = true;
      }
    });
    store.dispatch(modalChangeDate({ id: this.props.id || '', data: { columns: [...this.props.data.columns] } }));
  }

  private itemTitleEdit = (itemId: string) => (value: string) => {
    // let key = `pryaniky.custom.tabs.${itemId}`;
    this.props.data.columns.forEach((el: any) => {
      if (itemId === el.id) {
        // if (el.name) key = `pryaniky.tabs.${el.name || el.id}`
        el.frontTitle = value;
        el.changed = true;
      }
    });
    // Translate.setResorseItem(key, );
    store.dispatch(modalChangeDate({ id: this.props.id || '', data: { columns: [...this.props.data.columns] } }));
  }

  private onChangeStructure = (treeData: IColumn[]) => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { columns: treeData } }));

  private addNewItem = (newColumn: ReturnType<typeof generateColumn>) => {
    store.dispatch(modalChangeDate({ id: this.props.id || '', data: { columns: [...this.props.data.columns, newColumn] } }));
  }

  // private addNewItem = () => {
  //   const newColumn = generateColumn({ items: [generateWidget({ type: widgets.types.layout, data: [generateColumn({})] })] });
  //   const key = `pryaniky.custom.tabs.${newColumn.id}`;
  //   Translate.setResorseItem(key, Translate.t({ i18nKey: 'pryaniky.tabs.new' }));
  //   store.dispatch(modalChangeDate({ id: this.props.id || '', data: { columns: [...this.props.data.columns, newColumn] } }));
  // }

  private onSave = () => {
    // добавляем или изменяем ключи элементов которые изменяли
    this.props.data.columns.forEach((element: any) => {
      if (element.changed) {
        const key = `pryaniky${element.name ? '' : '.custom'}.tabs.${element.name || element.id}`;
        Translate.setResorseItem(key, element.frontTitle);
        element.changed = false;
      }
    });
    this.props.onConfirm && this.props.onConfirm(this.props.data);
  }

  private deleteTab = (id: string) => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { columns: [...this.props.data.columns.filter((el: IColumn) => el.id !== id)] } }));

  private generateNodeProps = (d: NodeData) => {
    (d as any).buttons = [<Button onClick={this.deleteTab.bind(this, (d.node as any).id)} children={<Icon icon='trash' />} />];
    return d;
  }
}

/**
 * @deprecated
 */
export const WidgetsTabsSettings = wrapDialogToRedux(WidgetsTabsSettingsPresenter);