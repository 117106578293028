import React, { FC, useEffect, useState, useCallback, useRef, useMemo } from 'react'
import {
    WidgetArticlesBox,
    TypographyArticles,
    ListContentBox
} from './WikiActueleArticlesWidget.styled'
import { SettingsWikiActueleArticlesWidgetWidget, cnSettingsActueleArticles } from './WikiActueleArticlesWidget.index'
import {
    initWidgetSettings,
    initWidgetAddition,
} from './SettingsActueleArticlesDialog/SettingsActueleArticlesDialog'
import { Translate } from 'localization'
import { IRequest } from 'utils/src/requests/service';
import { Box, Typography } from 'muicomponents/src'
import { getWikiPagesActualList } from 'utils/src/requests/requests.wikilist';
import { WikiListItem } from './Item/ItemWikiActualArticles'
import { checkResponseStatus } from 'utils/src';
import { KeyboardArrowLeft, KeyboardArrowRight } from 'muicomponents/src/Icons';
import Skeleton from '@mui/material/Skeleton';
import { prepareData } from 'blocks/WSettings/structures/timeline/schema'
import noData from 'assets/svg/NoData.svg'
import emptyArticlesList from 'assets/png/emptyArticlesList.png'
import { Avatar } from 'muicomponents/src/Avatar';
import MDRender from 'muicomponents/src/CommonmarkRender';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'muicomponents/src/Link/Link';
import { CustomSettings } from 'utils/src';
import { Divider } from 'muicomponents/src/Divider'
import { useNodeMediaQuery } from 'muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher'

const useMoexDesign = CustomSettings.useMoexDesign();

initWidgetSettings();
initWidgetAddition()


let request: IRequest

export const WikiActualeArticlesSkeleton = () => {
    return <Box sx={{ height: "378px", minWidth: 230, maxWidth: 252 }}>
        <Box >
            <Skeleton variant="text" height={188} width={'100%'} sx={{ m: 0, p: 0, mr: 3 }} />

        </Box>
        <Box display='flex' flexDirection={"column"} alignItems='flex-start'>
            <Skeleton variant="text" height={"20px"} width={"20%"} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={"44px"} width={"100%"} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={"44px"} width={"100%"} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={"60px"} width={"100%"} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={"60px"} width={"100%"} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={"60px"} width={"100%"} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={"30px"} width={"20%"} sx={{ flex: 1, mr: 1 }} />
        </Box>
    </Box>
}

const skeletonArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const WikiActueleArticlesWidgetPreseter: FC<SettingsWikiActueleArticlesWidgetWidget.Props> = ({ settings, isEdit }) => {
    const [actualContent, setActualContent] = useState<any[] | undefined>(undefined)
    const [refBreack, point700] = useNodeMediaQuery({ minWidth: 480 });


    console.log("point700", point700)
    const minItemWidth = 230;

    const ref = useRef<HTMLDivElement>();

    const [page, setPage] = useState(0);
    const pageRef = useRef(page);
    pageRef.current = page;

    const [count, setCount] = useState(actualContent?.length || skeletonArr.length);
    const countRef = useRef(count);
    countRef.current = count;

    const calculateItemsCount = useCallback(() => {
        if (!ref.current) return;
        const gap = Number(
            window.getComputedStyle(ref.current).gap.replace("px", "")
        );
        let currentItemsMinWidth = ref.current.clientWidth;
        for (let item of Array.from(ref.current.children)) {
            if (currentItemsMinWidth > item.clientWidth) {
                currentItemsMinWidth = item.clientWidth;
            }
        }
        const itemsForView = Math.floor(ref.current.clientWidth / minItemWidth);
        if (countRef.current !== itemsForView) {
            setCount(itemsForView);
            getActualContent(settings?.collection?.alias, itemsForView)
            if (itemsForView >= (actualContent?.length || skeletonArr.length)) {
                setPage(0);
            } else if (pageRef.current > (actualContent?.length || skeletonArr.length) - itemsForView) {
                setPage((actualContent?.length || skeletonArr.length) - itemsForView);
            }
        }
    }, [actualContent, skeletonArr]);

    const observer = useMemo(() => {
        return new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === ref.current) {
                    calculateItemsCount();
                }
            }
        });
    }, [actualContent, skeletonArr]);

    useEffect(() => {
        calculateItemsCount();
        if (ref.current) {
            observer.observe(ref.current!);
        }

        return () => {
        };
    }, [ref.current, actualContent, skeletonArr, settings]);

    const prepairedArr = useMemo(() => {
        return (actualContent || skeletonArr).slice(0 + page, count + page);
    }, [count, page, actualContent]);


    const backBlock = useMemo(() => {
        return 0 === page;
    }, [count, page]);

    const forwardBlock = useMemo(() => {
        return count + page === (actualContent?.length || skeletonArr);
    }, [count, page, actualContent]);



    const getRubrcsIds = settings?.rubric?.map((el: any) => el.id)

    const getActualContent = useCallback((url: string, countItems: number) => {
        if (!settings?.collection?.alias) return;
        const count = (settings?.rubric?.length && (settings?.rubric?.length > countItems)) ? settings?.rubric?.length : countItems;
        request = getWikiPagesActualList({
            skipCount: 0,
            count: count,
            tagUids: settings.rubric ? getRubrcsIds : undefined,
            alias: settings.collection.alias
        });
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                return setActualContent(d.data);
            } else {
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
            }
        });
    }, [])

    if (!settings?.collection && isEdit) {
        return <Box sx={{ padding: "24px" }}>
            <TypographyArticles>
                <Translate i18nKey='pryaniky.widgets.article.no.settings' />
            </TypographyArticles>
        </Box>
    }
    if (!settings?.collection && !isEdit) {
        return null
    }
    const linkToFolder = settings?.collection?.alias === "dash" ? "wikilist" : `wikilist/${settings?.collection?.alias}`

    const AdditionalButtons = useMemo(() => {
        return <Box sx={{ display: "flex", alignItems: "center" }}>
            {useMoexDesign && <>
                <Link
                    className={cnSettingsActueleArticles("Header-ButtonToAll")}
                    href={"https://support.moex.com/support/Login.aspx?lang=ru&guestlogin=1&singleton=3&id=9355685"} underline='none'
                    sx={{ textTransform: "uppercase" }}>
                    <Translate i18nKey={'pryaniky.widgets.settings.type.offer.news'} />
                </Link>
                <Divider orientation='vertical' sx={{ height: "30px", margin: "0 8px" }} />
            </>}
            <Link
                className={cnSettingsActueleArticles("Header-ButtonToAll")}
                href={`${window.location.origin}/${linkToFolder}`} underline='none'
                sx={{ textTransform: "uppercase" }}>
                <Translate i18nKey={'pryaniky.widgets.settings.type.allMaterials'} />
            </Link>
        </Box>
    }, [useMoexDesign]);

    if (settings?.hideIfNoData && actualContent?.length === 0) return null
    if (!settings?.hideIfNoData && actualContent?.length === 0) return <WidgetArticlesBox className={cnSettingsActueleArticles("EmptyList")}>

        <Box sx={{ padding: "24px", display: "flex", justifyContent: settings?.name !== "" ? "space-between" : "flex-end" }}>
            <Link href={`${window.location.origin}/${linkToFolder}`} underline='none'>
                <TypographyArticles minHeight={32}><MDRender source={settings?.name} type={'inline'} countLineCut={1} /></TypographyArticles>
            </Link>
            <Link href={`${window.location.origin}/${linkToFolder}`} underline='none'>
                <Translate i18nKey={'pryaniky.widgets.settings.type.allMaterials'} />
            </Link>
        </Box>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "24px",
            // paddingBottom: "24px",
            height: "350px",
            maxHeight: "350px",
            overflowX: "hidden",
            overflowY: "auto",
        }}>
            {/* <Avatar
                sx={{ width: '30%', height: '245px',  }}
                variant='square' srcSet={emptyArticlesList} /> */}
            <CardMedia
                component="img"
                className={cnSettingsActueleArticles("EmptyList-Avatar")}
                sx={{
                    width: 'auto',
                    height: "270px",
                    marginBottom: "24px"
                }}
                image={emptyArticlesList}
            />
            <Typography>
                <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
            </Typography>
        </Box>
    </WidgetArticlesBox>

    return (
        <WidgetArticlesBox
            ref={(el) => {
                (refBreack as any).current = el;

            }}>

            <Box
                ref={ref}
                className={cnSettingsActueleArticles("")}>
                {actualContent && <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
                    {/* settings?.collection */}
                    <Box
                        className={cnSettingsActueleArticles("Header")}
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: settings?.name !== "" ? "space-between" : "flex-end",
                            alignItems: "center"

                        }}
                    >
                        <Link
                            className={cnSettingsActueleArticles("Header-Title")}
                            href={`${window.location.origin}/${linkToFolder}`}
                            underline='none'>
                            <TypographyArticles minHeight={32}><MDRender source={settings?.name} type={'inline'} countLineCut={1} /></TypographyArticles>
                        </Link>

                        {point700 && AdditionalButtons}


                        {actualContent?.length > 0 && actualContent?.length > count && <Box sx={{ display: "flex" }}>
                            <div
                                onClick={() => {
                                    if (backBlock) return
                                    setPage(page - 1);
                                }}
                            >
                                < KeyboardArrowLeft color={actualContent?.length <= count || backBlock ? "disabled" : "primary"} />
                            </div>
                            {count === 1 && page + 1 + "/"}
                            {actualContent?.length}
                            <div
                                onClick={() => {
                                    if (forwardBlock) return
                                    setPage(page + 1);
                                }}
                            >
                                <KeyboardArrowRight color={actualContent?.length <= count || forwardBlock ? "disabled" : "primary"} />
                            </div>

                        </Box>}
                    </Box>
                    {!point700 && AdditionalButtons}
                </Box>}
                <ListContentBox>
                    {prepairedArr?.map((el) => actualContent ? <WikiListItem
                        count={count}
                        itemsLength={actualContent?.length}
                        item={el}
                        hideName={settings.hideName}
                        hideDescription={settings.hideDescription} /> : <WikiActualeArticlesSkeleton />)}
                </ListContentBox>
            </Box >

        </WidgetArticlesBox>
    )
}

