import { Box, Button, Typography } from 'muicomponents/src';
import React, { FC, useEffect, useMemo, useState } from 'react';
import * as utils from 'utils/src/utils';
import { i18n, Translate } from 'localization';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';
import SearchBar from './SearchBar/SearchBar';
import { Link } from 'muicomponents/src/Link/Link';
import { ListItemButton } from 'muicomponents/src/ListItem/ListItem';
import { ListItemAvatar } from '@mui/material';
import { UserRatingsSkeleton } from 'blocks/UserRatings/UserRatingsSkeleton';
import { toast } from 'react-toastify';
import { IGroup, IUserGroupsNewProps } from './UserGroupsNew.index';
import { StyledAvatar, StyledListItem, StyledListItemText, StyledSplitButton, ItemDescription, ItemName } from './styled';
import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog';
import VerifiedIcon from '@mui/icons-material/Verified';
import LockIcon from '@mui/icons-material/Lock';
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton';
import { MoreVert, Groups } from 'muicomponents/src/Icons/Icons';
import * as utilsProj from 'utils.project/utils.project';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Tooltip } from 'muicomponents/src/Tooltip';

import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState'
import { GroupListItem } from 'muicomponents/src/ItemsListDialog/GroupListItem/GroupListItem'
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index'
import { useScrollListState } from 'utils/src/hooks'
import { defaultMiddleware } from 'muicomponents/src/ItemsListDialog/defaultListState'
import { list } from 'utils/src/requests/requests.users'
import { mapFromUserList } from 'muicomponents/src/FieldRender/mapUserListFields'
import { IAdditionalField } from 'muicomponents/src/FieldRender'
import { isValidDate } from 'utils/src/utils.dates';
import { useLazyQueryEx } from 'utils/src/hooks'
import { confirm } from 'muicomponents/src/Confirm/Confirm'

const ConfirmBtn = withConfirmDialog(Button)


type TItemType = { url: string, actions: string[] }

async function getGroupsRequest(uId: string, data: any) {
  const result = await utils.API.groups.byUser(uId, 'my', data).r
  if (result.error_code !== 0) throw result

  return result
}

async function leaveGroup(gid: string | number, uid: string) {
  const result = await utils.API.groups.join(gid, uid, false).r
  if (result.error_code !== 0) throw result

  return result
}

type IGroupS = IGroup & { id: string }

export const UserGroupsNewPresenter: FC<any> = ({ context, authUser, ...props }) => {

  const isUserPageOwner = context.uId === authUser.profileData.userId

  return (
    <ItemsList<IGroupS>
      BodyComponent={ItemsListBody}
      listStateHook={({ defaultSelected }, middlewareParams) => {
        const {
          search,
          debouncedSearch,
          changeSearch,
          tabsValue,
          onChangeTab
        } = useDefaultStaticStates(middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id)// состояние для поиска и табов

        // получаем список пользователей и достаём запиханное туда колличество пользователей
        const state = useScrollListState(async function (skipCount, count) {
          const response = await getGroupsRequest(context.uId, {
            skipCount,
            count,
            search: debouncedSearch,
          })
          const result = await response as any as { data: (IGroupS)[] } & IBasicResponse


          return result.data.map((group: { [s: string]: any }) => {
            group.id = group.pkid;
            group.url = '/group/' + group.pkid;
            if (!utils.isEmptyGuid(group.imgId48x48)) group.imgUrl = group.imgUrl48x48;
            return group;
          }) as IGroupS[]
        }, [debouncedSearch], {
          selectableSingle: middlewareParams.selectableSingle
        })

        return {
          middlewareParams: {
            ...middlewareParams,
            itemMiddleware: (props: any) => {

              const { send } = useLazyQueryEx(leaveGroup)

              return {
                ...props,
                options: isUserPageOwner ? [{
                  content: i18n.t("pryaniky.group.actions.leave"), id: 'leave'
                }] : undefined,
                onClickAction: async function (option, item, actions) {

                  const response = await confirm({
                    text: i18n.t("pryaniky.group.actions.leave.confirm")
                  })
                  if ('confirm' === response) {
                    await send(item.id, context.uId)
                    actions.removeItems([item])
                  }
                }
              }
            }
          },
          state,
          defaultSelected,
          search,
          tabsValue,
          changeSearch,
          onChangeTab,
          middleware: defaultMiddleware
        }
      }}
      ItemComponent={GroupListItem}
      listHeaderProps={{ searchLabel: i18n.t('pryaniky.list.groups.search') }}
    />
  );
}


const O_UserGroupsNewPresenter: FC<IUserGroupsNewProps> = (props: IUserGroupsNewProps) => {

  const [searchValue, setSearchValue] = useState('')
  const uId = props.context.uId

  const [data, setData] = useState<IGroup[]>()
  const [loading, setLoading] = useState(false)
  const [itemLoading, setItemLoading] = useState(false)
  const [hoveredItem, setHoveredItem] = useState<number | undefined>()

  const getData = () => {
    if (uId) {
      setLoading(true)
      utils.API.groups.byUser(uId, 'my')
        .r
        .then((d: any) => {
          if (utils.checkResponseStatus(d)) {
            const items = d.data.map((group: { [s: string]: any }) => {
              group.id = group.pkid;
              group.url = '/group/' + group.pkid;
              if (!utils.isEmptyGuid(group.imgId48x48)) group.imgUrl = group.imgUrl48x48;
              return group;
            });
            setData(items);
            setLoading(false)
          }
        });
    }
  }

  const handleMouseOver = (value: number | undefined) => {
    setHoveredItem(value)
  }

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const leaveGroup = (groupId: number, index: number, isMeInGroup: boolean) => {
    utilsProj.confirm({
      text: i18n.t("pryaniky.group.actions.leave.confirm"),
      onConfirm: () => {

        setItemLoading(true)
        utils.API.groups.join(groupId, uId, !isMeInGroup).r.then((d: any) => {
          if (!d) toast.error(i18n.t('pryaniky.toast.error.server'));
          else if (d.error_code) toast.error(d.error_Message);
          else {
            if (data && data[index]) {
              data[index].isMeInGroup = !data[index].isMeInGroup
            }
          }
          setItemLoading(false)
        });
      }
    });
  }

  useEffect(() => {
    getData()
  }, [])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
  }

  const isUserPageOwner = uId === props.authUser.profileData.userId
  const visibleData = isUserPageOwner ? data : data?.filter(group => !group.isHidden || !group.isMeInGroup)

  const searchedGroups = useMemo(() => {
    let filteredGroups: IGroup[] = []
    if (!searchValue) {
      return visibleData
    } else if (searchValue && visibleData) {
      filteredGroups = visibleData.filter(group => group.name.toLowerCase().includes(searchValue))
      return filteredGroups
    }
  }, [searchValue, data])

  if (loading) return <UserRatingsSkeleton />
  if (!visibleData) return <PaperBox>
    <Typography>
      <Translate i18nKey={'pryaniky.placeholder.widget.nodata'} />
    </Typography>
  </PaperBox>

  return <>
    <PaperBox>
      <SearchBar onSearchChange={onSearchChange}
        value={searchValue} />
    </PaperBox>

    <PaperBox onMouseLeave={() => isUserPageOwner && handleMouseOver(undefined)}>

      {searchValue && !searchedGroups?.length ?
        <Typography>{i18n.t("nothing")}</Typography>
        :
        searchedGroups?.map((group, i) => {
          return <>
            <Link href={group.url} underline='none'>
              <StyledListItem alignItems="center" disablePadding divider={true}
                onMouseOver={() => isUserPageOwner && handleMouseOver(group.id)} >

                <ListItemButton sx={{ gap: "16px" }}>
                  <ListItemAvatar  >
                    <StyledAvatar alt={group.name} src={group.imgUrl} />
                  </ListItemAvatar>

                  <StyledListItemText
                    primary={<Box display='flex' alignItems='center' gap="8px" >
                      <Tooltip title={group.name.length > 50 ? group.name : ""}>
                        <span>
                          <ItemName variant='body2' color='primary' >
                            {group.name}
                          </ItemName>
                        </span>
                      </Tooltip>
                      {group.visibilityType === 1 &&
                        <Tooltip title={i18n.t('close_group')}>
                          <LockIcon color='action' style={{ fontSize: "16px" }} />
                        </Tooltip>}
                      {group.isOfficialGroup &&
                        <Tooltip title={i18n.t('pryaniky.group.official.group')}>
                          <VerifiedIcon color='success' style={{ fontSize: "16px" }} />
                        </Tooltip>
                      }

                    </Box>
                    }
                    secondary={<Tooltip title={group.description.length > 110 ? group.description : ""}>
                      <span>
                        <ItemDescription variant='body2' fontSize='small' color='GrayText' >
                          {group.description}
                        </ItemDescription>
                      </span>
                    </Tooltip>
                    }
                  />
                  <Box display='flex' alignItems='center' justifyContent="space-between" >
                    <StyledListItemText
                      primary={<Box display='flex' alignItems='center' color='GrayText'>
                        <Groups sx={{ mr: "5px" }} />

                        <Typography variant='body2' fontSize='small' sx={{ mr: "24px" }}>
                          {/* <Translate i18nKey='number of members' /> */}
                          {group.membersCount}
                        </Typography>
                      </Box>
                      }

                    />

                    <Box onClick={(e) => handleStopPropagation(e)} sx={{ opacity: hoveredItem === group.id ? 1 : 0 }}>
                      <StyledSplitButton
                        icon={<MoreVert style={{ fontSize: "24px" }} />}
                        variant={"text"}
                        options={[{ content: i18n.t("pryaniky.group.actions.leave"), id: group.id.toString() }]}
                        useOpenToggleInButtonPropsOnClick={true}
                        handleMenuItemClick={(e, action) =>
                          leaveGroup(group.id, i, group.isMeInGroup)}
                      >
                      </StyledSplitButton>
                    </Box>
                  </Box>
                </ListItemButton>
              </StyledListItem>
            </Link>
          </>
        }
        )
      }

      {/* <ItemsList
        BodyComponent={ItemsListBody}
        method={async function (skipCount, { count, search }) {
          const response = await utils.API.groups.byUser(props.context.uId, 'my')
          const result = await response.r
          return result.data.map((group: { [s: string]: any }) => {
            group.id = group.pkid;
            group.url = '/group/' + group.pkid;
            group.title = group.name;
            if (!utils.isEmptyGuid(group.imgId48x48)) group.imgUrl = group.imgUrl48x48;
            return group;
          });
        }
        } /> */}

    </PaperBox>
  </>
};

export const UserGroupsNew = React.memo(UserGroupsNewPresenter)