import React, { FC, useEffect, useMemo, useState, useCallback } from 'react'

import { connect } from 'react-redux'
import { SettingsRemindFillProfileWidget, cnSettingsRemindFillProfile } from './RemindFillProfileWidget.index'

import { Button } from 'muicomponents/src/Button/Button'
import { Translate } from 'localization'
import { IRequest } from 'utils/src/requests/service';
import { list } from 'utils/src/requests/requests.tags';
import { checkResponseStatus } from 'utils/src';
import { Box, Typography, Divider } from 'muicomponents/src'
import { getWikiPagesNew, getWikiPagesActualList } from 'utils/src/requests/requests.wikilist';
import { Link, LinkC } from 'muicomponents/src/Link/Link';
import MDRender from 'muicomponents/src/CommonmarkRender';
import { TPage } from 'utils/src/BaseTypes/pages.types';
import * as utils from 'utils/src/utils';
import { useParams, useHistory } from 'react-router';
import noData from 'assets/svg/NoData.svg'
// import emptyArticlesList from 'assets/png/emptyArticlesList.png'
import { Avatar } from 'muicomponents/src/Avatar';
import CircularProgress from '@mui/material/CircularProgress'
import CardMedia from '@mui/material/CardMedia';
import { initWidgetAddition, initWidgetSettings } from './SettingsRemindWidgetDialog/SettingsRemindWidgetDialog'
import { LinearProgress } from 'muicomponents/src/LinearProgress/LinearProgress'
import { Brightness1Outlined, CheckCircle, AccountBalanceWallet } from 'muicomponents/src/Icons/Icons';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedCurrencyName } from 'utils.project/utils.project';
import { IStoreAppSetting } from 'models/store/appSettings';
import { SBadge } from 'utils/src/BaseTypes/badge.types'
import { getCurrencyNames } from 'utils/src/CommonRedux/base/selectors';
import { WidgetCurrencyBox, WidgetWalletBox, WidgetRewardInfoBox, WidgetLinearProgress, WidgetRewardBox, WidgetItemFieldBox } from './RemindFillProfileWidget.styled'
import { PreviewParams } from 'utils/src/PreviewParams';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';

initWidgetSettings();
initWidgetAddition();

const Mui5UserInfoComand = PreviewParams.inst.getShowModule('Mui5UserInfo')
// console.log("Mui5UserInfoComand", Mui5UserInfoComand)
export interface IRemindFillProfileWidgetFieldData {
    name: string,
    displayName: string,
    isCustomField: boolean,
    isFilled: boolean
}

export interface IRemindFillProfileWidgetData {
    bonusReward: SBadge;
    bonusCurrency: number;
    fields: IRemindFillProfileWidgetFieldData[]
    progress: number;
    typeReward: string
}

export const RemindFillProfileWidgetPreseter: FC<SettingsRemindFillProfileWidget.Props> = ({ settings, isEdit, ...props }) => {

    const [data, setData] = useState<IRemindFillProfileWidgetData | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(true)

    const getFillProfileWidgetData = () => {

        utils.API.quests.getFieldsToRemindWidgetRequest()
            .r
            .then((d: any) => {

                if (utils.checkResponseStatus(d)) {
                    setLoading(false)
                    setData(d.data)
                } else {
                    setLoading(false)
                    setData(d.data)
                }
            }).catch(() => {

            })
    }

    useEffect(() => {
        getFillProfileWidgetData()
    }, [])



    const currentUser = useSelector(getAuthUser)
    const currencyNames = useSelector(getCurrencyNames);

    const badgeAdnCurrensyStyles = (data?.typeReward === "SBadge" && data?.bonusCurrency > 0) ? {
        position: "absolute",
        left: "52px"
    } : {}

    const RewardComponent = useMemo(() => {

        if (data?.typeReward) {
            return <>

                <Box className={cnSettingsRemindFillProfile('Rewards', [data?.typeReward])} sx={{
                    display: "flex",
                    gap: "12px"
                }}>
                    {data?.typeReward === "SBadge" &&
                        data?.bonusReward &&
                        data?.bonusReward?.isActive && <Tooltip title={(data?.typeReward === "SBadge" && data?.bonusCurrency > 0) ? (data?.bonusReward as any).displayName : ""}>
                            <span>
                                <Link
                                    href={`/badge/${(data?.bonusReward as any)?.badgeUid as any}`}
                                    underline='none'
                                >
                                    <Avatar
                                        className={cnSettingsRemindFillProfile("ContentBox-InfoBox-BadgeAvatar")}
                                        sx={{ width: "40px", }}
                                        src={(data?.bonusReward as any)?.iconUrl} />
                                </Link>
                            </span>
                        </Tooltip>}
                    {
                        data?.typeReward === "SBadge" &&
                        data?.bonusReward &&
                        data?.bonusReward?.isActive && <WidgetRewardInfoBox>

                            {/* <Typography variant={"subtitle2"} fontWeight="600" lineHeight="25.5px">+1</Typography>
                            <Typography variant={"subtitle2"} lineHeight="25.5px">{Translate.t({ i18nKey: 'pryaniky.widgets.quests.badge' })}</Typography> */}

                            {data?.bonusCurrency <= 0 && <Link
                                href={`/badge/${(data?.bonusReward as any)?.badgeUid as any}`}
                                underline='none'
                            >
                                &laquo;{(data?.bonusReward as any).displayName as any}&raquo;
                            </Link>}

                        </WidgetRewardInfoBox>
                    }
                    {data?.bonusCurrency > 0 &&
                        <WidgetCurrencyBox
                            className={cnSettingsRemindFillProfile('Rewards-Item primaryColor3-lightBbg', ['currency', data?.typeReward])}
                            sx={badgeAdnCurrensyStyles}
                        >

                            <WidgetWalletBox className={cnSettingsRemindFillProfile('Rewards-Item-Icon CustomStyle primaryColor3-bg')} >
                                <AccountBalanceWallet color={"action"} />
                            </WidgetWalletBox>

                            <Typography
                                variant={"subtitle2"}
                                fontWeight="600"
                                className={cnSettingsRemindFillProfile('Rewards-Currency', [data?.typeReward])}
                                sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "190px"
                                }}>
                                {`+${data?.bonusCurrency}`} &nbsp;
                                {getFormattedCurrencyName(currencyNames, Number(data?.bonusCurrency), 'nominative')}

                            </Typography>

                        </WidgetCurrencyBox>}

                </Box>

                {/* {data?.bonusCurrency > 0 && < WidgetRewardInfoBox >

                    <Typography variant={"subtitle2"} fontWeight="600" >{`+${data?.bonusCurrency}`}</Typography>
                    <Typography variant={"subtitle2"}>
                        {getFormattedCurrencyName(currencyNames, Number(data?.bonusCurrency), 'nominative')}
                    </Typography>

                </WidgetRewardInfoBox>
                } */}

            </>
        }
    }, [data])

    if (loading) return <CircularProgress />;
    if (!data || data?.fields.length === 0) return null;
    return (
        <Box {...props} className={cnSettingsRemindFillProfile({})}>
            <Box className={cnSettingsRemindFillProfile("Header")} >

                <Typography sx={{ fontWeight: 700, marginBottom: "8px" }}><Translate i18nKey='pryaniky.widgets.type.FillProfileWidget.title' /></Typography>

                {settings && settings?.titleImageId && <CardMedia
                    component="img"
                    className={cnSettingsRemindFillProfile("Avatar")}
                    sx={{
                        width: '100%',
                        height: "auto",
                        marginBottom: "24px"
                    }}
                    image={settings?.titleImageUrl}
                />}
                <Box sx={{ marginBottom: "8px", display: "flex", flexWrap: "wrap" }}>
                    <Typography variant={"subtitle2"} fontWeight="600" ><Translate i18nKey='pryaniky.widgets.type.FillProfileWidget.filled' />&nbsp;</Typography>
                    <Box display={"flex"} alignItems={"end"}>
                        <Typography variant={"subtitle2"} fontWeight="600"> {data?.progress}</Typography>
                        <Typography variant={"subtitle2"} sx={{ color: "rgba(0, 0, 0, 0.6)" }}>/100%</Typography>
                    </Box>
                </Box>
                <WidgetLinearProgress
                    variant="determinate"
                    value={data?.progress}
                    className={cnSettingsRemindFillProfile('Progress-Line')}
                />

                <Box>
                    {data?.fields.map((el) => <WidgetItemFieldBox >
                        {/* {el.isFilled ? <CheckCircle fontSize="small" htmlColor="#89ca5e" /> : <Brightness1Outlined fontSize="small" htmlColor={"rgba(0, 0, 0, 0.6)"} />} */}
                        <Tooltip title={el.displayName.length > 30 ? el.displayName : ""}>
                            <span>
                                <Link
                                    sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                                    underline='none'
                                    href={Mui5UserInfoComand ? `/profileEdit/${currentUser.baseData.id}` : `/user/${currentUser.baseData.id}?profileEdit=true`}>
                                     &#8226; &nbsp;{el.displayName}
                                </Link>
                            </span>
                        </Tooltip>
                    </WidgetItemFieldBox>)}
                </Box>
                {data?.typeReward && <WidgetRewardBox>
                    <Typography variant={"subtitle2"} fontWeight="600" ><Translate i18nKey='pryaniky.widgets.type.FillProfileWidget.reward' /></Typography>

                    {RewardComponent}

                </WidgetRewardBox>}
            </Box>
        </Box >
    )
}

