import { useContextFiltersChange } from "blocks/Filters/Filters.hooks";
import { cnFilters } from "blocks/Filters/Filters.index";
import React, { ComponentProps, createContext, FC, forwardRef, useCallback, useContext, useMemo, useRef, useState } from "react";
import { useSOCOrgchartUnitsTree } from "./Filters_type_socogchartunits.hooks";
import { FiltersSOCOrgchartUnitsProps } from "./Filters_type_socogchartunits.index";
import { TreeView } from 'muicomponents/src/Tree';
import { ApplyButton, SOCOrgchartUnitsBox, SOCOrgchartUnitsLabelBox, SOCOrgchartUnitsLabelTextBox, StyledCheckbox, StyledFormControlLabel, StyledTreeItem } from "./Filters_type_socogchartunits.styled";
import { Add, Remove } from 'muicomponents/src/Icons';
import { Tooltip } from "muicomponents/src/Tooltip";
import { Button, Checkbox, Divider } from "muicomponents/src";
import { Translate } from "localizations/Translate";
import { LinearProgress } from "muicomponents/src/LinearProgress";
import { useReffedState } from "utils/src/hooks";
import { difference } from 'lodash';

const FilterContext = createContext<ReturnType<typeof useSOCOrgchartUnitsTree>['items'][number]['id'][]>([]);

const FilterItem: FC<ComponentProps<typeof SOCOrgchartUnitsLabelBox> & {
    el: ReturnType<typeof useSOCOrgchartUnitsTree>['items'][number];
    selectUnit: (unitId: ReturnType<typeof useSOCOrgchartUnitsTree>['items'][number]['id']) => void;
    CheckboxProps?: ComponentProps<typeof Checkbox>;
}> = ({
    el,
    selectUnit,
    CheckboxProps,
    ...props
}) => {

    const selectedIds = useContext(FilterContext);

    const checked = useMemo(() => {
        return selectedIds.includes(el.id);
    }, [selectedIds, el.id]);

    return (
            <StyledTreeItem
                nodeId={el.id}
                label={
                    <Tooltip
                        title={el.displayName}
                        disableInteractive
                    >
                        <SOCOrgchartUnitsLabelBox
                            {...props}
                            component={'span'}
                        >
                            <StyledCheckbox
                                size="small"
                                {...CheckboxProps}
                                checked={checked}
                                disableRipple
                                onClick={(e) => {
                                    e.stopPropagation();
                                    selectUnit(el.id);
                                }}
                            />
                            <SOCOrgchartUnitsLabelTextBox>
                                {el.displayName}
                            </SOCOrgchartUnitsLabelTextBox>
                        </SOCOrgchartUnitsLabelBox>
                    </Tooltip>
                }
                ContentProps={{
                    style: {
                        boxSizing: 'border-box'
                    }
                }}
            >
                {el.childrens.map(el => {
                    return (
                        <FilterItem key={el.id} el={el} selectUnit={selectUnit} />
                    )
                })}
            </StyledTreeItem>
    );
};

const FiltersSOCOrgchartUnitsPr: FC<FiltersSOCOrgchartUnitsProps> = ({
    tag: Tag = 'div',
    context,
    relations,
    ...props
}) => {

    const {
        items,
        loading
    } = useSOCOrgchartUnitsTree();

    const [localState, setLocalState, localStateRef] = useReffedState<NonNullable<typeof context.units>>(context.units || []);

    const changed = useMemo(() => {
        return !!difference(localState, context.units || []).length || !!difference(context.units || [], localState).length;
    }, [context.units, localState]);

    const onChange = useContextFiltersChange<typeof context>(relations);

    const selectUnit = useCallback((unitId: typeof items[number]['id']) => {
        setLocalState(localStateRef.current.includes(unitId) ? localStateRef.current.filter(el => el !== unitId) : [...localStateRef.current, unitId]);
    }, []);

    const selectAll = useCallback(() => {
        setLocalState([]);
    }, []);

    const applySelectedUnits = useCallback(() => {
        onChange({
            units: localStateRef.current
        });
    }, []);

    return (
        <FilterContext.Provider value={localState}>
            <Tag {...props} className={cnFilters()}>
                <SOCOrgchartUnitsBox>
                    <Translate i18nKey={'organizations and units'} />
                    <StyledFormControlLabel
                        checked={!localState.length}
                        onClick={selectAll}
                        control={<Checkbox size={'small'} />}
                        label={<Translate i18nKey={'select all'} />}
                    />
                    <Divider />
                    {
                        loading
                        ? <LinearProgress />
                        : <TreeView
                            defaultCollapseIcon={<Remove />}
                            defaultExpandIcon={<Add />}
                        >
                            {items.map(el => {
                                return (
                                    <FilterItem key={el.id} el={el} selectUnit={selectUnit} />
                                )
                            })}
                        </TreeView>
                    }
                    <ApplyButton
                        disabled={!changed}
                        variant={'contained'}
                        size={'small'}
                        onClick={applySelectedUnits}
                    >
                        <Translate i18nKey={'apply'} />
                    </ApplyButton>
                </SOCOrgchartUnitsBox>
            </Tag>
        </FilterContext.Provider>
    );
};

export const FiltersSOCOrgchartUnits = FiltersSOCOrgchartUnitsPr;