import React, { FC, useCallback, useMemo, useState } from 'react';
import { createModal, TransitionComponent, IBodyProps } from 'utils/src/DialogCreator';
import { DialogTitle, DialogContent, DialogActions } from 'muicomponents/src/DialogParts';
import { styled } from 'muicomponents/src/mui/system'
import { Translate } from 'localizations/Translate';
import { getDataFromFrame } from 'utils/src';
import { GetCurrentFrameActions, PostCurrentFrameActions } from './actions';

export type ExternalDialogProps = {
    url: string;
    initData?: boolean;
    withData?: boolean;
    headerText?: string;
    acceptText?: string;
    closeText?: string;
    minHeight?: number;
};

type SendNewsDialogProps = IBodyProps<{}> & ExternalDialogProps & {
    id: string;
};

const StyledDialogContent = styled(DialogContent)({
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16
});

const StyledFrame = styled('iframe')({
    width: '100%',
    height: '100%',
    minHeight: 300
});

const ExternalDialogPr: FC<SendNewsDialogProps> = ({
    handleAccept,
    handleClose,
    id,
    url,
    headerText: headerTextProps,
    initData,
    withData,
    minHeight,
    closeText,
    acceptText
}) => {

    withData = !!initData || withData;

    const [ isLoading, setIsLoading ] = useState(false);

    const prepairedUrl = useMemo(() => {
        const frameIdString = `?frameId=${id}`;
        return url.includes('?') ? url.replace('?', frameIdString) : url += frameIdString;
    }, [id]);

    const headerText = useMemo(() => headerTextProps || <Translate i18nKey={`External dialog`} />, [ headerTextProps ]);

    const handleAcceptWithData = useCallback(async function() {
        setIsLoading(true);
        const data = await getDataFromFrame(id, GetCurrentFrameActions.getDialogData, {}, PostCurrentFrameActions.postDialogData) as {
            values: any,
            valid: boolean
        };
        setIsLoading(false);
        if(!data.valid) {
            return;
        } else {
            handleAccept(data.values);
        }
    }, []);

    return (
        <>
            <DialogTitle
                onClose={handleClose}
            >
                { headerText }
            </DialogTitle>
            <StyledDialogContent>
                <StyledFrame id={id} src={prepairedUrl} sx={{ minHeight: minHeight }} />
            </StyledDialogContent>
            <DialogActions
                isLoading={isLoading}
                onAccept={withData ? handleAcceptWithData : undefined}
                onClose={handleClose}
                acceptText={acceptText}
                closeText={closeText}
            />
        </>
    )
};

export const ExternalDialog = createModal(ExternalDialogPr, {
  maxWidth: 'md',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  TransitionComponent: TransitionComponent,//LeftDirectionTransitionComponent,
  className: 'Pane BackdropOff Right',
  scroll: 'paper',
//   preventClose
});