import React, { FC } from 'react';
import { IBadge } from '../UserBadges.index';
import { Avatar } from 'muicomponents/src/Avatar';
import { Link } from 'muicomponents/src/Link/Link';
import { Box, Divider, Typography } from 'muicomponents/src';
import { ListItem as ListItemMui } from 'muicomponents/src/ListItem/ListItem';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'
import { GridItemDescription, GridItemTitle } from '../GridItem/GridItem.styled';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';

export const ListItem: FC<{ badge: IBadge }> = ({ badge }) => {
  return (
    <>
      <ListItemMui alignItems="center" sx={{ height: '72px' }}>
        <ListItemAvatar>
          <Link href={badge.url} underline='none' >
            <Avatar alt={badge.name} src={badge.badgeImgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} />
          </Link>
        </ListItemAvatar>
        <ListItemText
          primary={
            <GridItemTitle href={badge.url} underline='none' >
              <Typography variant='body2'>
                <Tooltip title={badge?.name?.length > 75 ? badge.name : ""}>
                  <span>
                    {badge.name}
                  </span></Tooltip>
              </Typography>
            </GridItemTitle>
          }
          secondary={
            <GridItemDescription>
              <Tooltip title={badge?.description?.length > 160 ? badge.description : ""}>
                <span>{badge.description}</span>
              </Tooltip>
            </GridItemDescription>}
        // `${badge.description}`}
        />
      </ListItemMui>
      <Divider variant="fullWidth" light={true} />
    </>
  )
};
