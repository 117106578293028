import { IComponentAcceptor } from 'utils/src/ComponentInjector'

export interface IWikiItemActionProps {
    className?: string;
    data: any;
    id?: string;
    type?: string;
    url?: string;
    title?: string;
    moderators?: { [s: string]: any }[];
    authors?: { [s: string]: any }[];
    tags?: { [s: string]: any }[];
    titleImageId?: string;
    titleImageUrl?: string;
    newsId?: string;
    likeCount?: number;
    liked?: boolean;
    isAdmin: boolean,
    news: any,
}
export interface IWikiItemProps extends IWikiItemActionProps, IComponentAcceptor<IWikiItemActionProps>  {
    onEdit: () => void
    btnRender?: (toggleOpen: () => void, ref: any, isOpen: boolean) => void
};

