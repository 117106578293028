import React, { FC } from 'react';
import { NBadgesItem } from '../Badges.index';
import { GridItemBox, GridItemAvatar, GridItemButton, GridItemContent, GridItemDescription, GridItemUserCount, GridItemTitle } from './ItemGrid.styled';
import { Box, Typography } from 'muicomponents/src';
import { People as PeopleIcon } from 'muicomponents/src/Icons';
import { Translate } from 'localization';
import { useSelector } from 'react-redux';
import { IStateType } from 'redux/store';
import { BadgeItemValue } from '../Badges.styled';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';

export const ItemGrid: FC<NBadgesItem.Props> = ({ data, countColumns }) => {

    const currencyName = useSelector((state: IStateType) => state.store.appSettings!!.currencyNameGenitivePlural as string);

    return (
        <GridItemBox countColumns={countColumns!}>
            <GridItemButton href={`/badge/${data.badgeUid}`}>
                <Box sx={{ position: 'relative' }}>
                    <GridItemAvatar src={data.iconUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} />
                    {Boolean(data.totalCount) &&
                        <GridItemUserCount className={"primaryColor3-borderColor"}>
                            <PeopleIcon fontSize='small' />
                            {data.totalCount}
                        </GridItemUserCount>}
                </Box>
            </GridItemButton>

            <GridItemContent>
                <GridItemTitle href={`/badge/${data.badgeUid}`}>
                    <Tooltip title={data?.name?.length > 20 ? data.name : ""}>
                        <span>{data.name}</span>
                    </Tooltip>

                </GridItemTitle>
                <GridItemDescription>
                    <Tooltip title={data?.description?.length > 45 ? data.description : ""}>
                        <span>{data.description}</span>
                    </Tooltip>
                </GridItemDescription>
                {data?.issueCurrency[0]?.key && <Typography fontSize={14}>
                    <Translate i18nKey={'pryaniky.badge.item.earned'} />{' '}
                    <BadgeItemValue>
                        {data?.issueCurrency[0]?.key} {currencyName}
                    </BadgeItemValue>
                </Typography>
                }
            </GridItemContent>
        </GridItemBox>
    )
};