import React, { useEffect, useState } from 'react';
import {  Button, Avatar } from 'uielements/src';
import "./IdeaHistory.scss";
import { cn } from "@bem-react/classname";
import { Dialog } from 'uielements/src';
import i18n from "../../../localizations/i18n";
// import { PostContentRender } from 'blocks/PostContentRender/PostContentRender';
import MDRender from 'uielements/src/CommonmarkRender'
import * as utils from 'utils/src/utils';
import { createModal, TransitionComponent, IBodyProps } from 'utils/src/DialogCreator'
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { formatDateSTD } from 'utils/src/utils.dates';

export const cnIdeaHistory = cn("IdeaHistory");
export interface IIdeaHistoryProps extends IBodyProps<any> {
    newsId: string
}

export const IdeaHistoryDialogPresenter: React.FC<IIdeaHistoryProps> = ({
    handleAccept,
    handleClose,
    newsId
}) => {
    const [data, setData] = useState<any[]>([])
    useEffect(() => {
        utils.API.news.ideas.getIdeasHistory(newsId)
            .r
            .then((d: any) => {
                setData(d.data);
            });
    }, [])
    return (
        <div>
            <DialogTitle onClose={handleClose}>
                    {i18n.t("pryaniky.post.actions.history")}

            </DialogTitle>
            <DialogContent className={cnIdeaHistory('Content')}>
                {data.length === 0 ? <p className={cnIdeaHistory("NotHistory")}>{i18n.t("pryaniky.post.actions.history.new")}</p> : data.map((item: any, index: number) =>
                    <div key={index} className={cnIdeaHistory("history")}>
                         <Button
                                className={cnIdeaHistory("UserName")}
                                key={0}
                                noBorder
                                noPadding
                                noBackground
                                type="rlink"
                                href={'/user/' + item.user.id}
                                children={<Avatar
                                    className={cnIdeaHistory("Avatar")}
                                    imgUrl={item.user.imgUrl}
                                    size={56}
                                    name={item.user.displayUserName}
                                />}
                            />
                        
                        <div className={cnIdeaHistory("post")}>                           
                            <div className={cnIdeaHistory("history_text")}>
                                <MDRender source={item.text} />
                            </div>
                        </div>
                        <div className={cnIdeaHistory("time")}>
                            {formatDateSTD(item.date, true)}
                        </div>
                    </div>
                )}
            </DialogContent>

            <DialogActions
            closeText={i18n.t("close")}
            onClose={handleClose}/>
                {/* <Button onClick={handleClose} padding="md">
                    {i18n.t("close")}
                </Button>
            </DialogActions> */}
        </div>
    );
};


// export const IdeaHistoryDialog = IdeaHistoryDialogPresenter;
// export const IdeaHistoryDialog = wrapDialogToRedux<IIdeaHistoryOwnProps>(
//     connect<IIdeaHistoryStateProps, IDispatchProps, IIdeaHistoryOwnProps, IState>(
//         mapStateToProps,
//         mapDispatchToProps({})
//     )(IdeaHistoryDialogPresenter)
// )
;

export default createModal<any, IIdeaHistoryProps>(IdeaHistoryDialogPresenter, {
    maxWidth: 'md',
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});