import React from 'react'
import { cnSurveyWidgetItem, ISurveyWidgetItem, mapStateToProps, mapDispatchToProps } from './SurveyWidgetItem.index'
import { Button as PrnButton, Avatar } from 'uielements/src'
import i18n from 'localizations/i18n'
import { connect } from 'react-redux'
import { PostContentRender } from '../../PostContentRender/PostContentRender'
import './SurveyWidgetItem.scss'
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import Link from '@material-ui/core/Link';
import { Typography } from 'muicomponents/src/Typography/Typography'
import { styled } from 'muicomponents/src/mui/system';
import { Box, Button as MuiButton } from 'muicomponents/src'

const top = 56;



export const TypographyName = styled(Typography)({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "21.98px",
  letterSpacing: "0.1px",
  marginBottom: "12px"
});
export const TypographyDesc = styled(Typography)({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "19.92px",
  letterSpacing: "0.4px",
  marginBottom: "12px",
  height: "60px",
});
const SurveyWidgetItemPresenter = ({ data, settings }: ISurveyWidgetItem) => {
  const trueShortText = data.textShort.substring(0, 97)
  return (
    <>
      {data && <div className={cnSurveyWidgetItem()}>
        <div className={cnSurveyWidgetItem('SurveyAvatar')}>
          {data.iconImgUrl &&
            <Avatar precentage={100} shape={'rectangle'}
              imgUrl={data.iconImgUrl}
            />
            // <img className={cnSurveyWidgetItem('Title-Image')} src={data.iconImgUrl}/>
          }
          {/* // <Avatar size={250} shape={'square'} className={cnSurveyWidgetItem('Title-Image')} imgUrl={data.iconImgUrl} />} */}
        </div>
        <div className={cnSurveyWidgetItem('Title')}>
          <TypographyName className={cnSurveyWidgetItem('Title-Text')}>{data.name}</TypographyName>
          {settings?.showDesc  && <TypographyDesc className={cnSurveyWidgetItem('Title-Text')}>{trueShortText + (data.textShort.length > 97 ? "..." : "")}</TypographyDesc>}
          <MuiButton
            color='primary'
            className={cnSurveyWidgetItem('Title-GoToSurveyBTN')}
            variant={'contained'}
            href={`/survey/go/${data.id}`}
          >
            {i18n.t('pryaniky.quizwidget.goToQuiz')}
          </MuiButton>
         
        </div>

      </div>}
    </>
  )
}

export const SurveyWidgetItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyWidgetItemPresenter)