
import { cn } from '@bem-react/classname';

export interface IPreListFiltersProps {
    context: any;
    widget: any;
    hasRights: boolean;
    isAdmin: boolean;
    isCollection: boolean;
}

export const cnPreListFilters = cn('PreListFiltersNew');