import { Avatar } from 'muicomponents/src/Avatar';
import React, { useState } from 'react';
import { IHeaderBadgesProps } from './HeaderBadges.index';
import { HeaderTop, HeaderPaper, HeaderFilters, HeaderActions } from './HeaderBadges.styled';
import { Translate } from 'localization';
import avatarIcon from './avatar.svg';
import { Typography } from 'muicomponents/src';
import { IconButton } from 'muicomponents/src/IconButton';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput';
import { useDebouncedQueryState } from 'utils/src/hooks';
import { FilterAlt, GridView, List } from 'muicomponents/src/Icons';
import { PropoverFilters } from '../PopoverFilters/PopoverFilters';


export const HeaderBadges:React.FC<IHeaderBadgesProps> = ({ viewType, setViewType, setCheckedCategories }) => {

  const [search, searchQuery, setSearch] = useDebouncedQueryState('search');

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  

  return (
    <HeaderPaper>
      <HeaderTop>
        <Avatar 
          src={avatarIcon}
          sx={{ width: 64, height: 64 }}
        />
        
        <Typography sx={{fontWeight: 600, fontSize: 20}}>
          <Translate i18nKey={'badges'}/>
        </Typography>
      </HeaderTop>
      
      <HeaderFilters>
        <SearchInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label={<Typography sx={{color: '#000000DE'}}>{Translate.t({i18nKey: 'pryaniky.list.badges.search'})}</Typography>}
        />

        <HeaderActions>
          <IconButton 
            onClick={(event) => setPopoverAnchor(event.currentTarget)}
            color={'primary'}
          >
            <FilterAlt style={{ fontSize: '32px' }} />
          </IconButton>

          <IconButton
            onClick={() => setViewType('grid')}
            color={viewType === 'grid' ? 'primary' : 'default'}
          >
            <GridView style={{ fontSize: '32px' }} />
          </IconButton>

          <IconButton
            onClick={() => setViewType('row')}
            color={viewType === 'row' ? 'primary' : 'default'}
          >
            <List style={{ fontSize: '32px' }} />
          </IconButton>
        </HeaderActions>

        <PropoverFilters 
          popoverAnchor={popoverAnchor}
          setPopoverAnchor={setPopoverAnchor}
          setCheckedCategories={setCheckedCategories}
        />
      </HeaderFilters>
    </HeaderPaper>
  );
};