import { cn } from '@bem-react/classname';


export const cnHierarchyWidgetNew = cn('HierarchyWidgetNew');
export interface IUsersHierarchyWidgetProps {
  context: { [s: string]: any }
  settings?: any
  tag?: React.ComponentType | 'div';
}

export interface IAddFormProps {
  added: boolean
  add: () => void
  newUser: any
  setNewUser: (arg: any) => void
  setAdded: (arg: boolean) => void
  title: string
  items: any[]

}

