import React, { createContext } from "react";
import { wrapDialogToRedux } from "../../DialogWrapper";
import { IWidgetsSettingsProps, IWidgetsSettingsState } from "./WidgetsSettings.index";
import { Button } from 'uielements/src';
import { WSettings } from '../../../WSettings/WSettings';
import i18n from "../../../../localizations/i18n";
import './WidgetsSettings.scss';
import { wdg } from "../Add/WidgetsAdd";
import * as utils from 'utils/src/utils';
import { store } from "redux/store";
import { modalChangeDate } from "redux/actions/Modals";
import { widgets } from '../../../../i.widgets';
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import { isString } from 'lodash'
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { GUID_EMPTY } from "utils/src/constants.prn";
import { SettingsContext } from "./WidgetsSettings.context";

class WidgetsSettingsPresenter extends React.Component<IWidgetsSettingsProps, IWidgetsSettingsState> {
  constructor(props: IWidgetsSettingsProps) {
    super(props);
    if (props.data.wId) this.getWidget(props.data.wId);
  }

  public state: IWidgetsSettingsState = {
    isValid: true,
  }

  /**
   * componenDidUpdate
   */
  public componenDidUpdate(pp: IWidgetsSettingsProps) {
    if (this.props.data.wId && !utils.compareData(this.props.data.wId, pp.data.wId)) this.getWidget(this.props.data.wId);
  }

  /**
   * render
   */
  public render() {
    const { state, props } = this;
    const { onClose, onConfirm, data, getComponent } = props;
    // если есть кастомный компонент - юзаем его.
    // используеся в том случае, если не хватает функциональности от дефолтного JsonForms
    const WSettingsComponent = getComponent(isString(data.selected) ? data.selected : data.selected?.type) || WSettings;
    return (
      <SettingsContext.Provider value={data.context}>
        <div>
          <DialogTitle onClose={onClose}>{i18n.t('pryaniky.widgets.settings.title')}</DialogTitle>
          {data.selected && <WSettingsComponent
            settings={this.props.data.settings || this.props.data.data}
            data={this.props.data.settings || this.props.data.data}
            selected={data.selected}
            onChange={this.onChange}
            setValid={(value) => this.setState((s) => ({ ...s, isValid: value }))}
          />}
          <DialogActions
            disableAccept={!state.isValid}
            acceptText={i18n.t('save')}
            closeText={i18n.t('cancel')}
            onAccept={onConfirm}
            onClose={onClose} />
          {/* <Button main disabled={!state.isValid} onClick={onConfirm} padding="lg">
              {i18n.t('save')}
            </Button>
            <Button onClick={onClose} padding="lg">
              {}
            </Button>
          </DialogActions> */}
        </div>
      </SettingsContext.Provider>
    )
  }

  /**
   * onChange - on settings change
   */
  public onChange = (value: { [s: string]: any }) => {
    const { data } = this.props
    let changedData = { ...value }

    switch (data.selected.type) {
      case widgets.types.users + '/list':
        store.dispatch(
          modalChangeDate({
            id: this.props.id || '',
            data: {
              data: changedData.data
            }
          })
        );
        return;
      case widgets.types.blocks:
        changedData = {
          data: value.settings,
        }
        break;

      // case widgets.types.AlertWidget:
      //   changedData = {
      //     data: value.settings,
      //   }
      //   break;
      case widgets.types.html:
        changedData = {
          data: value.settings,
        }
        break;
      case widgets.types.wiki:
        changedData = {
          data: value.settings,
        }
        break;
      case widgets.types.FloatMessageWidget:
        changedData = {
          data: value.settings,
        }
        break;

      case widgets.types.sliderlink:
        changedData = {
          ...value,
          data: {
            ...value.data,
            slider: {
              ...value.data.slider,
              elements: value.data.slider.elements.filter((el: any) => !el.deleted && el.imgId !== GUID_EMPTY)
            }
          },
        }
        break;

      case widgets.types.virtualUser:
        changedData = {
          ...value,
          data: {
            ...value.data,
            virtualUser: {
              ...value.data.virtualUser,
              elements: value.data.virtualUser.elements.filter((el: any) => !el.deleted)
            }
          },
        }
        break;
    }
    store.dispatch(
      modalChangeDate({
        id: this.props.id || '',
        data: {
          settings: changedData.data
        }
      })
    );
  }

  /**
   * getWidget - get widget from store by id
   * @param {string} id - widget id
   */
  public getWidget = (id: string) => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { selected: wdg(this.jsonformsDataWidgetTypesDiff(store.getState().widgets.widgets[id].type), store.getState().widgets.widgets[id].type) } }));

  /**
   * jsonforms
   */
  public jsonformsDataWidgetTypesDiff(type: string) {
    const thrueType = type.substr(type.indexOf('/') + 1);
    switch (thrueType) {
      case 'list/wikilist': {
        return 'wikilist'
      }
      case 'users/group': {
        return 'usersGroup'
      }
      case 'users/list': {
        return 'usersList'
      }
    }
    return type.split('/')[1];
  }

}

export const WidgetsSettings = withComponentEnjector(wrapDialogToRedux<IWidgetsSettingsProps>(WidgetsSettingsPresenter), ['WSettings'])