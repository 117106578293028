import React, { ComponentProps, FC, useEffect, useState } from 'react'
import { Calendar as BigCalendar } from 'react-big-calendar'
import { connect } from 'react-redux'
import 'moment/locale/ru'
import './Calendar.scss'
import { cnCalendar, mapDispatchToProps, mapStateToProps, ICalendarProps } from './Calendar.index'
import { EventsList } from './EventsList/EventsList'
import { openEventsDialog } from './ShowMoreEventsDialog/ShowMoreEventsDialog'
import { CustomToolbar } from './CustomToolbar/CustomToolbar'
import { IToolbarProps } from './CustomToolbar/CustomToolbar.index'
import { CustomEvent } from './CustomEvent/CustomEvent'
import i18n from 'localizations/i18n'
import { useSearchParams } from 'utils/src/hooks'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography'
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import { useOpenEventCreator, isShowCreateButton, isToDay } from './utils'
import { momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { initCalendarWidgetAddition, initCalendarWidgetSettings } from '../CalendarMini/CalendarMiniSettingsDialog/CalendarMiniSettingsDialog'

initCalendarWidgetAddition()
initCalendarWidgetSettings()

const localizer = momentLocalizer(moment);

const CustomToolbarNoSearch: FC<any> = (props) => {

  return <CustomToolbar {...props} disableSearchParams />
}

const CalendarPresenter: React.FC<ICalendarProps & any> = props => {
  const openCreator = useOpenEventCreator()

  const [searchParams] = useSearchParams()

  const {
    defaultDate,
    defaultView
  } = searchParams

  const { context, calendarEvents, loading, getCalendarEvents, currentCalendarDate, calFilters, language, className, settings } = props
  const [isFirstRequest, setIsFirstRequest] = useState<boolean>(true);

  useEffect(() => {

    const cIds = [...(settings?.calendar?.map((el: any) => el.id) || []), ...(context?.cIds || [])]
    setTimeout(() => {
      if (isFirstRequest) {
        setIsFirstRequest(false);
        getCalendarEvents({ filter: context.filter?.value, cIds, statusIds: context?.statusIds, currentCalendarDate, gid: context.gId });
        return;
      }
      getCalendarEvents({ filter: context.filter?.value, cIds, statusIds: context?.statusIds, currentCalendarDate, gid: context.gId })
    }, 100)

  }, [context.filter, context.cIds, currentCalendarDate.slice(0, 7)])

  CustomToolbarNoSearch.defaultProps = { context }



  return (<Box className={cnCalendar({}, [className])}>
    <Box sx={{ position: 'relative', margin: '24px' }} className={cnCalendar('Main')}>
      <BigCalendar
        culture={language}
        tooltipAccessor={() => ""}
        popup={true}

        messages={{
          showMore: total => {
            return i18n.t('pryaniky.calendar.moreEvents', { total })
          }
        }}
        defaultDate={defaultDate && typeof defaultDate === 'string' && new Date(defaultDate) || undefined}
        defaultView={defaultView as any}
        onShowMore={(events, date) => openEventsDialog({ events, date }).then(() => { }).catch(() => { })}
        popupOffset={10}
        localizer={localizer}
        events={calendarEvents}
        components={{
          toolbar: CustomToolbarNoSearch,
          event: CustomEvent as any,
          month: {
            dateHeader: ({ children, label, ...props }: any) => {
              const showCreateButton = isShowCreateButton(props.date)
              return <Box sx={{
                display: 'flex',
                justifyContent: showCreateButton ? 'space-between' : 'flex-end',
                '&:hover': {
                  '& .OpenCreatorBtn': {
                    opacity: 1
                  }
                }
              }}>
                {showCreateButton && <IconButton color='primary' sx={{ opacity: 0 }} size='small' className={'OpenCreatorBtn'} onClick={() => openCreator(props.date)}>
                  <AddCircleOutlineIcon fontSize='small' />
                </IconButton>}
                <Typography variant='body2' sx={{ marginTop: '4px', marginRight: '2px' }}>{label}</Typography>
              </Box>
            },
            // event: () => <>event</>,
            // header: () => <>header</>
          },
          dateCellWrapper: ({ children, value, ...props }: any) => {
            return <Box sx={{
              flex: '1 0',
              display: 'flex'
            }} className={`rbc-day-bg${isToDay(value) ? ' rbc-today' : ''}`}>
              {/* <AddCircleOutlineIcon onClick={openCreator} /> */}
            </Box>
          }
        }}
      />
      {loading && <CircularProgress
        size={45}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />}
    </Box>
  </Box>
  )
}

export const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarPresenter)