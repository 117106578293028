import React, { FC } from 'react';
import { Translate } from 'localization';
import { GridItemBox, GridItemAvatar, GridItemButton, GridItemContent, GridItemDescription, GridItemCircleCount, GridItemTitle } from './GridItem.styled';
import { Avatar } from 'muicomponents/src/Avatar';
import { Box, Typography } from 'muicomponents/src';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'
import { IBadge } from '../UserBadges.index';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';

export const GridItem: FC<{ badge: IBadge, countColumns: number }> = ({ badge, countColumns }) => {
    return (
        <GridItemBox countColumns={countColumns}>
            <GridItemButton href={`/badge/${badge.badgeUid}`}>
                <Box sx={{ position: 'relative' }}>
                    <GridItemAvatar src={badge.badgeImgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} />
                    {(Boolean(badge.count) && badge.count > 1) && <GridItemCircleCount className={"primaryColor3-borderColor"}>{badge.count}</GridItemCircleCount>}
                </Box>
            </GridItemButton>

            <GridItemContent>
                <GridItemTitle href={`/badge/${badge.badgeUid}`}>
                    <Tooltip title={badge?.name?.length > 20 ? badge.name : ""}>
                        <span>{badge.name}</span>
                    </Tooltip>

                </GridItemTitle>
                <GridItemDescription>
                    <Tooltip title={badge?.description?.length > 45 ? badge.description : ""}>
                        <span>{badge.description}</span>
                    </Tooltip>
                </GridItemDescription>
            </GridItemContent>
        </GridItemBox>
    )
};
