/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
export default {
  selectedTags: [],
  selectedGroups: [],
  selectedNews: 'events',
  once: true,
  viewType: 'content',
  timelineType: 'slider',
  name: '',
  // count: 5,
  dateNow: true, // сделан для идентификации и установки даты при отображении виджета
  hideIfNoData: false,


}