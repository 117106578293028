import { cn } from "@bem-react/classname";
import { IDialog } from "../../DialogWrapper.index";
import { IDispatchProps } from "../../../../redux/connector";
// import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
import { IClassNameProps } from '@bem-react/core';
import { IWikiItem } from 'utils/src/requests/models/api.wikilist';
import { PageTypes } from "utils/src";

export interface IWikiEditProps extends IWikiEditOwnProps, IBodyProps, IWikiEditDispatchProps, IWikiEditStateProps {
    // isShown: boolean;
    // onClose: () => void;
    className?: string;
}
export interface IWikiEditOwnProps extends IDialog{
    sendDataForEditPage?: (data: any) => void;
    context?: any;
    id: string;
    updateItemList: (item: IActualDataWiki, newItem: IActualDataWiki) => void;
    // админом имеющим права на изменение всего кантента в любом типе викистраницы.папки является админ всего портала или Модератор Базы Знаний.
    isAdmin: boolean;
    parentFolderModerators?: any[]


};

interface IActualDataWiki {
    collectionAlias: string,
    id: string,
    description: string,
    url: string,
    title: string,
    moderators: any[],
    tags: any[],
    collections: any[],
    type: PageTypes,
    titleImageId: string,
    hasCustomTitleImage: boolean,
    titleImageUrl: string,
    showInMainMenu: boolean,
    allowComments: boolean,
    defaultViewType: number,
    isArchived: boolean | undefined,
    positionInCollection: number|string,
    viewers: any[] | null,
    file: any,
    isInheritingRootRights:boolean
    isDraft: boolean
    isHideFooter: boolean;
    showCategoryAsSelector: boolean;
}
export type IWikiEditDispatchProps = ReturnType<typeof mapWikiEditDispatchToProps>

export const mapWikiEditDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type IWikiEditStateProps = ReturnType<typeof mapWikiEditStateToProps>;

export const mapWikiEditStateToProps = () => ({});

export const cnWikiEditDialog = cn("WikiEditDialogMui");


