import React, { useEffect, useState, FC } from 'react';
import i18n from "localizations/i18n";
import { connect } from "react-redux";
import {
  IWidgetsAddSettingsDialogDispatchProps,
  IWidgetsAddSettingsDialogOwnProps,
  IWidgetsAddSettingsDialogProps,
  IWidgetsAddSettingsDialogState,
  IWidgetsAddSettingsDialogStateProps,
  WidgetsAddSettingsDialogMapStateToProps,
  mapWidgetsAddSettingsDialogDispatchToProps,
  cnWidgetsAddSettingsDialog,
  IWidgetToSelect
} from './WidgetAddSettingsDialog.index'
import './WidgetAddSettingsDialog.scss'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { Button, Icon } from 'uielements/src';
import Tooltip from "uielements/src/MaterialElements/Tooltip"
import { v1 as uuid } from 'uuid';
import { widgets, IWidgetProps, generateWidget, generateColumn, IWidget } from '../../../../../i.widgets';
import { ISliderLinkItem, emptyItem } from 'blocks/WSettings/structures/sliderlink/uischema';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { pageHeader } from 'pageStructures/pageHeaders';
import { widgetsBaseSettings } from '../../../../../widgets/constants';
import { WSettings } from 'blocks/WSettings/WSettings';
import { widgetSubtype } from 'utils/src/utils';
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { IWSettingsOnlyProps } from '../../../../WSettings/WSettings.index';

import * as utilsProject from 'utils.project/utils.project'

export const wdg = (schema: string, type: any, title?: string): IWidgetToSelect => ({
  schema,
  title: title || i18n.t(`pryaniky.widgets.create.name.${type}`),
  type,
  id: uuid()
})
export const WidgetsAddSettingsDialogPresenter: FC<IWidgetsAddSettingsDialogProps> = ({
  handleAccept,
  handleClose,
  enabledModules,
  data,
  item,
  getComponent,
  columnId,
  modalChangeDate,
  ...props
}) => {

  const CustomComponent = getComponent(item.type);
  const WSettingsComponent = CustomComponent || WSettings;

  const [isValid, setIsValid] = useState<boolean>(true)
  const [widgetOpts, setWidgetOpts] = useState<{ [s: string]: any }>({})



  const filtersWidgetTypes = [
    `${widgets.types.list}/groups`,
    // `${widgets.types.list}/users`,
    `${widgets.types.list}/shop`
  ]

  let startingData;

  if (item && item.type === widgets.types.filters) {
    const currentStructureWidgets = (props as any).getActiveStructureWidgets()
    if (currentStructureWidgets) {
      startingData = {
        type: (props as any).getActiveStructureWidgets().reduce((acc: string | undefined, widget: IWidget) => filtersWidgetTypes.includes(widget.type) ? widgetSubtype(widget.type) || widget.type.split('/')[1] : acc)
      }
    }
  }

  const onChange = (value: { [s: string]: any }) => {
    const opts: IWidgetProps = {
      columnId,
      type: item.type,
      id: item.id,
      ...value
    }
    modalChangeDate({ id: item.id || '', data: generateWidget(opts) });
    validate(value.data);
    setWidgetOpts(opts);
  }
  const checkVariableReqiure = (require: (data: { [s: string]: any }) => boolean | boolean, value: { [s: string]: any }) => {
    switch (typeof require) {
      case 'boolean':
        return require
      case 'function':
        return require(value)
    }
  }

  const makeRez = (value: { [s: string]: any }) => {
    const schema = data;
    const opts: IWidgetProps = {
      columnId,
      type: item.type,
      id: item.id,
    }

    switch (item.type) {
      case widgets.types.timeline:
        if (value.data.selectedNews) opts.newsType = value.data.selectedNews;

        ['selectedUsers', 'selectedTags', 'selectedGroups'].forEach(key => {
          if (!opts.settings) opts.settings = {};
          if (Array.isArray(value.data[key]) ? value.data[key].length : value.data[key]) {
            const settingsKey = key === 'selectedUsers' ? 'uId' : key === 'selectedTags' ? 'tId' : key === 'selectedGroups' ? 'gId' : '';
            if (checkVariableReqiure(schema.schema.properties[key].require, value.data)) {
              opts.settings[settingsKey] = Array.isArray(value.data[key]) ? value.data[key].map((el: { [s: string]: any }) => el.pkid | el.id).join(',') : (value.data[key].pkid || value.data[key].id);
              if (key === "selectedUsers") opts.newsType = 'my';

            }
          }
        });
        // console.log('wasd', value)
        if (value.data.showFilter) {
          if (!opts.settings) opts.settings = {};
          opts.settings.showFilter = value.data.showFilter;
        }

        if (value.data.once) {
          if (!opts.settings) opts.settings = {};
          opts.settings.name = value.data.name;
          opts.settings.once = value.data.once;
          opts.settings.count = value.data.count;
          opts.settings.viewType = value.data.viewType;
          opts.settings.timelineType = value.data.timelineType;
          opts.settings.tagId = value.data.selectedTags?.id;
          opts.settings.newsTypes = value.data.selectedNews;
          opts.settings.hideIfNoData = value.data.hideIfNoData;
          if (value.data.dateNow) {
            opts.settings.dateNow = value.data.dateNow;
            opts.settings.hideIfNoData = value.data.hideIfNoData;
          }
        }

        break;
      case widgets.types.list:
        opts.type = `${item.type}/${value.data.users ? 'users' : value.data.groups ? `groups${value.data.groupsTree ? 'Tree' : ''}` : ''}`;
        break;
      case widgets.types.info:
        if (value.data.type === "ideas") opts.data = pageHeader.ideas;
        if (value.data.type === "creativetasks") opts.data = pageHeader.creativetasks;
        if (value.data.type === "notices") opts.data = pageHeader.notices;
        if (value.data.type === "events") opts.data = pageHeader.events;
        if (value.data.type === "polls") opts.data = pageHeader.polls;
        opts.settings = {
          type: value.data.type
        };
        break;
      case `${widgets.types.list}/shop`:
        opts.settings = value.data;
        if (value.data.mini) opts.type = widgets.types.shop;
        opts.settings = value.data;
        break;
      case widgets.types.shopMui:
        opts.settings = value.data;
        if (value.data.mini) opts.type = widgets.types.shopMui;
        opts.settings = value.data;
        break;
      // case widgets.types.html:
      //   opts.data = '';
      //   break;
      case widgets.types.userfields:
        opts.settings = {
          fieldsIds: value.data.fieldsIds
        };
        break;
      case widgets.types.ratingmini:
        opts.settings = value.data;
        break;
      case widgets.types.ratingBadge:
        opts.settings = value.data;
        break;
      case widgets.types.wikiArticles:
        opts.settings = value.data;
        break;
      case widgets.types.remindFillProfile:
        opts.settings = value.data;
        break;
      case widgets.types.calendarmini:
        opts.settings = value.data;
        break;
      case widgets.types.calendarWidget:
        opts.settings = value.data;
        break;
      // case widgets.types.AlertWidget:
      //   opts.data = '';
      //   opts.settings = value.settings;
      //   break;
      case widgets.types.wiki:
        opts.data = '';
        // opts.settings = value.data;
        opts.settings = value.settings;
        break;
      case widgets.types.html:
        opts.data = '';
        // opts.settings = value.data;
        opts.settings = value.settings;
        break;
      case widgets.types.FloatMessageWidget:
        opts.settings = value.settings;
        // opts.settings = value.data;
        break;
      case widgets.types.wikiActueleArticles:
        opts.settings = value.data;
        break;
      case widgets.types.banner:
        opts.settings = value.data;
        break;
      case widgets.types.album:
        opts.settings = value.data;
        break;
      case `${widgets.types.users}/group`:
        opts.settings = value.data;
        break;
      case `${widgets.types.users}/hierarchy`:
        data.data = value.data
        opts.data = value.data
        opts.settings = value.data;
        break;
      case widgets.types.hierarchyNew:
        data.data = value.data
        opts.data = value.data
        opts.settings = value.data;
        // console.log(" opts.settings ", opts.settings )
        // console.log("  opts.data ",  opts.data )
        break;
      case `${widgets.types.users}/list`:
        opts.data = value.data;
        opts.data.list = opts.data.list.filter((el: any) => !el.deleted);
        break;
      case widgets.types.filters:
        opts.type = `${widgets.types.filters}${value.data.type && value.data.type !== '' ? `/${value.data.type}` : ''}`;
        break;
      case widgets.types.charts:
        opts.type = `${widgets.types.charts}${value.data.type && value.data.type !== '' ? `/${value.data.type}` : ''}`;
        opts.link = value.data.link;
        break;
      // case widgets.types.ratings:
      //   // opts.type = `${widgets.types.filters}${value.data.type && value.data.type !== '' ? `/${value.data.type}` : ''}`;
      //   break;
      case widgets.types.tabs:
        opts.data = [];
        break;
      case widgets.types.tabsControl:
        opts.data = [];
        break;
      case widgets.types.license:
        opts.settings = value.data;
        break;
      case widgets.types.sliderlink:
        opts.settings = {
          slider: {
            aspect: value.data.slider.aspect,
            width: value.data.slider.width,
            elements: (value.data.slider.elements as ISliderLinkItem[]).filter(el => !el.deleted && el.imgId !== GUID_EMPTY)
          }
        };
        break;
      case widgets.types.grid: {
        opts.settings = widgetsBaseSettings.grid
        break;
      };
      case widgets.types.secretSanta: {
        opts.settings = value.data;
        break;
      };
      case widgets.types.secretSantaWidgetList: {
        opts.settings = value.data;
        break;
      };
      case widgets.types.ratings: {
        opts.settings = value.data;
        break;
      };
      case widgets.types.quiz: {
        opts.settings = value.data;
        break;
      };
      case widgets.types.virtualUser:
        opts.settings = {
          virtualUser: {
            imgId: value.data.virtualUser.imgId,
            imgUrl: value.data.virtualUser.imgUrl,
            startText: value.data.virtualUser.startText,
            isShuffle: value.data.virtualUser.isShuffle,
            elements: (value.data.virtualUser.elements as ISliderLinkItem[]).filter(el => !el.deleted)
          }
        };
        break;
      case widgets.types.tagsCloud:
        opts.settings = {
          tagsCloud: {
            collection: value.data?.tagsCloud?.collection,
            color: value.data?.tagsCloud?.color,
            isGroupTags: value.data?.tagsCloud?.isGroupTags
          }
        };
        break;
      case widgets.types.statistic:
        opts.settings = {
          data: { ...value.data.data }
        };
        break;
      case widgets.types.statisticFilters:
        opts.settings = {
          data: [...value.data.data]
        };
        break;
      case widgets.types.quests:
        opts.settings = value.data;
        if (!value.data.mini) opts.type = `${widgets.types.list}/quests`;
        break;
      case widgets.types.birthdays:
        opts.settings = value.data;
        break;
      case widgets.types.coursesMy:
        opts.settings = value.data;
        break;
      case widgets.types.mobileApp:
        opts.settings = value.data;
        break;
      case widgets.types.survey:
        opts.settings = value.data;
        break;
      case widgets.types.userRating:
        opts.settings = value.data;
        break;
      case `${widgets.types.users}/subscriptions`:
        opts.settings = value.data;
        break;
      case `${widgets.types.users}/subscribers`:
        opts.settings = value.data;
        break;
      case widgets.types.layout:
        opts.data = [
          generateColumn({})
        ]
        break;
      case widgets.types.iframe:
        opts.settings = value.data;
        break;
      case widgets.types.badgesList:
        opts.settings = value.data;
        break;
    }
    if (item.type.match(/^pryaniky\/external/)) {
      opts.id = uuid();
      opts.title = item.title;
      opts.settings = item.settings;
    }
    setWidgetOpts(opts);
    validate(value.data);
  }

  const fieldsInfo = (value: { [s: string]: any }) => {
    const schema = data;
    const arrRequire: string[] = [];
    const relations: Record<string, string[]> = {};
    Object.keys(schema.schema.properties).forEach(name => {
      if (checkVariableReqiure(schema.schema.properties[name].require, value)) arrRequire.push(name);
      if (schema.schema.properties[name].relations) relations[name] = schema.schema.properties[name].relations;
    })
    let numberofNotEmpty = 0;
    arrRequire.forEach(name => {
      const fieldValue = value[name];
      if (typeof fieldValue === "string" && fieldValue !== "") {
        numberofNotEmpty += 1;
      } else if (typeof fieldValue === "number" && fieldValue > 0) {
        numberofNotEmpty += 1;
      } else if (Array.isArray(fieldValue) && fieldValue.length > 0) {
        numberofNotEmpty += 1;
      } else if (fieldValue && Object.keys(fieldValue).length > 0) {
        numberofNotEmpty += 1;
      } else if (typeof fieldValue === "boolean" && relations[name]) {
        if (fieldValue || relations[name].reduce((a, crname) => a || value[crname], false as boolean)) numberofNotEmpty += 1;
      }
    });
    setIsValid(numberofNotEmpty === arrRequire.length);
  }

  const validate = (value: { [s: string]: any }) => {
    setIsValid(false)
    switch (item.type) {
      case `${widgets.types.users}/hierarchy`: {
        fieldsInfo(value);
        return null;
      }
      case widgets.types.hierarchyNew: {
        fieldsInfo(value);
        return null;
      }
      case widgets.types.timeline: {
        fieldsInfo(value);
        return null;
      }
      case widgets.types.ratingmini: {
        fieldsInfo(value);
        return null;
      }
      case widgets.types.ratingBadges: {
        fieldsInfo(value);
        return null;
      }
      case widgets.types.filters: {
        fieldsInfo(value);
        return null;
      }
      case `${widgets.types.users}/group`: {
        fieldsInfo(value);
        return null;
      }
      case widgets.types.sliderlink: {
        value.slider.elements.findIndex((i: any) => i.imgId !== GUID_EMPTY) !== -1 && setIsValid(true);
        return null;
      }
      case widgets.types.list: {
        fieldsInfo(value);
        return null;
      }
      case widgets.types.charts: {
        fieldsInfo(value);
        return null;
      }
      case widgets.types.quests: {
        fieldsInfo(value);
        return null;
      }
      case widgets.types.secretSanta: {
        fieldsInfo(value);
        return null;
      }

    }
    setIsValid(true)

  }

  const onConfirm = () => {

    utilsProject.confirm({
      title: i18n.t(`pryaniky.widgets.create`),
      text: i18n.t(`pryaniky.widgets.create.confirm`) + i18n.t(`pryaniky.widgets.create.name.${widgetOpts.type}`),
      onConfirm: () => {
        handleAccept(generateWidget(widgetOpts))
      }
    })
  };

  return (
    <div>
      <DialogTitle onClose={handleClose}>
        {i18n.t('pryaniky.widgets.add.title')}



      </DialogTitle>
      <div>
        <div className={cnWidgetsAddSettingsDialog('Settings')}>
          <WSettingsComponent
            setValid={setIsValid}
            data={startingData}
            selected={item}
            onChange={CustomComponent ? onChange : makeRez}
          />
        </div>

      </div>
      <DialogActions
        acceptText={i18n.t('add')}
        onAccept={() => onConfirm()}
        disableAccept={!isValid}
        onClose={handleClose}
        closeText={i18n.t('cancel')}
      />
      {/* {selected && <Button disabled={!isValid} main onClick={onConfirm} padding="lg">
            {i18n.t('add')}
          </Button>}
          <Button onClick={onClose} padding="lg">
            {i18n.t('cancel')}
          </Button> */}
    </div>
  )
}

export const WidgetsAddSettingsDialog = connect<IWidgetsAddSettingsDialogStateProps, IWidgetsAddSettingsDialogDispatchProps, IWidgetsAddSettingsDialogOwnProps>(
  WidgetsAddSettingsDialogMapStateToProps,
  mapWidgetsAddSettingsDialogDispatchToProps
)(withComponentEnjector<IWidgetsAddSettingsDialogProps>(WidgetsAddSettingsDialogPresenter, ["WAddition"]))

// export const WidgetsAddSettingsDialog = withComponentEnjector(wrapDialogToRedux<IWidgetsAddSettingsDialogOwnProps>(
//   connect<IWidgetsAddSettingsDialogStateProps, IWidgetsAddSettingsDialogDispatchProps, IWidgetsAddSettingsDialogOwnProps>(
//     WidgetsAddSettingsDialogMapStateToProps,
//     mapWidgetsAddSettingsDialogDispatchToProps
//   )(WidgetsAddDialogPresenter)
// ), ['WAddition'])

export const DialogWidgetsAddSettings = createModal(WidgetsAddSettingsDialog, {
  maxWidth: 'md',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});