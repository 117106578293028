import { IListProps, IListState } from 'blocks/List/List.index';
import { updateContext } from 'redux/actions/Widgets';
import { IDispatchProps } from 'redux/connector';
import { filters } from 'utils/src/filters.prn';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { RouteComponentProps } from 'react-router'
import { cn } from '@bem-react/classname';
import actions from 'redux/actionsTypes/Widgets';
import { bindActionCreators, Action, Dispatch } from "redux"
export interface IDefaultRequestOption {
    search: string,

}

export interface IListUsersDefaultRequestProps extends IDefaultRequestOption {
    catFilter: string,
    excludeGroup: number,
    rid: string,
    gid: number,
    extended: boolean,
    count: number,

}
export interface IListTypeWikiOwnProps extends RouteComponentProps<{}> {

}

export interface IListTypeWikiProps extends IListProps, IListTypeWikiOwnProps, IWikiDispatchProps, IWikiStateToProps {
    requestOptions: any;
    tag?: 'div';
    wikiData: any;
    newsUid: string;
    searchPage?: boolean;
    coll: boolean
}

export type IWikiDispatchProps = ReturnType<typeof mapDispatchToProps>;


export type IWikiStateToProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);

export const mapStateToProps = (state: any, props: IListTypeWikiOwnProps) => {
    const pageId = state.widgets.structures[props.location?.pathname.substring(1)];
   
    return {
        layoutWikilist: pageId && state.widgets.pages[pageId],
        isAdmin: getAuthUser(state).baseData.isAdmin
    }
};

const fltrs = {
    ...filters,
    catFilter: 'catFilter',
    authors: 'authors',
    order: 'order',
    sort: 'sort',
    collectionAlias: 'collectionAlias',
    archived: 'archived',
    type: 'type',

}
export const cnWikiList = cn('WikiList');


export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFiltersWiki = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);