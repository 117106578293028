import React, { useEffect, useState, FC, useMemo } from 'react';
import i18n from "localizations/i18n";
import { connect } from "react-redux";
import {
  IWidgetAddDialogDispatchProps,
  IWidgetAddDialogOwnProps,
  IWidgetAddDialogProps,
  IWidgetAddDialogState,
  IWidgetAddDialogStateProps,
  WidgetAddDialogMapStateToProps,
  mapWidgetAddDialogDispatchToProps,
  cnWidgetAddDialog,
  IWidgetToSelect
} from './WidgetsAddNew.index'
import './WidgetsAddNew.scss'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle'
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent'
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions'
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import { Button, Icon, Avatar, HelpTooltip } from 'uielements/src';
import Tooltip from "uielements/src/MaterialElements/Tooltip"
import { v1 as uuid } from 'uuid';
import { widgets, IWidgetProps, generateWidget, generateColumn, IWidget } from '../../../../i.widgets';
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import { wrapDialogToRedux } from 'blocks/Dialogs/DialogWrapper';
import { getStructure } from '../../../WSettings/structures/index';
import { DialogWidgetsAddSettings } from './WidgetAddSettingsDialog/WidgetAddSettingsDialog'
import * as utilsProject from 'utils.project/utils.project'
import { isPropertySignature } from 'typescript';
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { IWSettingsOnlyProps } from '../../../WSettings/WSettings.index';
import { getExtendedWigets } from 'utils/src/requests/requests.widgets';
import { checkResponseStatus } from 'utils/src';
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { AccordionMUI } from 'muicomponents/src/Accordion/Accordion';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput'
import { debounce, noop } from 'utils/src/utils';
// import ratings from './images/ratings.svg'
import noAvatar from './images/noAvatar.svg'
import ratings from './images/ratings.svg'
import survey from './images/survey.svg'
import mobileApp from './images/mobileApp.svg'
import birthdays from './images/birthdays.svg'
import timeline from './images/timeline.png'
import groupUserslist from './images/list.png'
import virtcurrency from './images/virtcurrency.png'
import shop from './images/shop.svg'
import wiki from './images/wiki.png'
import sliderlink from './images/sliderlink.png'
import ratingmini from './images/ratingmini.svg'
import usersGroup from './images/usersGroup.png'
import filters from './images/filters.svg'
import myBadges from './images/myBadges.svg'
import quiz from './images/quiz.svg'
import quests from './images/quests.svg'
import layout from './images/layout.svg'
import userAbout from './images/userAbout.png'
// import hierarchy from './images/hierarchy.svg'
import coursesMy from './images/coursesMy.svg'
import usersList from './images/usersList.svg'
import timelineonce from './images/timelineonce.svg'
import timelinehead from './images/timelinehead.svg'
import eventstoday from './images/eventstoday.svg'
import banner from './images/banner.svg'
import userfields from './images/userfields.svg'
import tagsCloud from './images/tagsCloud.svg'
import secretwidget from './images/tagsCloud.svg'
import secretSanta from './images/secretSanta.svg'
import badgesList from './images/badgesList.png'
import blocks from './images/blocks.png'
import QuickLinks from './images/QuickLinks.png'
// import html from './images/html.svg'
import referral from './images/referral.svg'
import mood from './images/mood.svg'
import calendarmini from './images/calendarmini.png'
import adventCalendar from './images/adventCalendar.png'
import skills from './images/skills.png'
import ratingBadge from './images/ratingBadge.png'
import wikiArticles from './images/wikiArticles.png'
import remindFillProfile from './images/remindFillProfile.png'
import wikiActueleArticles from './images/wikiActueleArticles.png'
import pageCommentsWidget from './images/wikiActueleArticles.png'
import { PreviewParams } from 'utils/src/PreviewParams';
import { CustomSettings } from 'utils/src/CustomSettings';

export const wdg = (schema: string, type: any, title?: string, tags?: string[], img?: any, description?: string, settings?: IWidgetToSelect['settings']): IWidgetToSelect => ({
  schema,
  title: title || i18n.t(`pryaniky.widgets.create.name.${type}`),
  description: description || i18n.t(`pryaniky.widgets.create.description.${schema}`),
  tags: tags ? [...tags] : [],
  img,
  type,
  id: uuid(),
  settings
})

export const categories = ["Gamification", "Applications", "EditableСontent", "SystemWidgets", "DynamicСontent", "Users", "Other"]
// export const categories = ["Геймификация", "Приложения", "Редактируемый контент", "Системные виджеты", "Динамический контент", "Пользователи", "Другое"]
export const WidgetsAddDialogPresenter: FC<IWidgetAddDialogProps> = ({
  handleAccept,
  handleClose,
  enabledModules,
  id,
  widgetId,
  addWidget,
  getMethod,
  modalChangeDate,
  columnId,
  ...props }) => {

  const [selected, setSelected] = useState({});

  const selectorRef = React.createRef<HTMLDivElement>();

  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [extendedWidgets, setExtendedWidgets] = useState<ReturnType<typeof wdg>[]>([]);

  const useMui5UserProfile = PreviewParams.inst.getShowModule('Mui5UserInfo') 
  const useRatingWithBadges = CustomSettings.useRatingWithBadges()
  // PreviewParams.inst.getShowModule('Mui5Shop')

  const widgetsList = useMemo(() => {
    let list: ReturnType<typeof wdg>[] = [
      wdg('timeline', widgets.types.timeline, "", ["Динамический контент", "DynamicСontent"], timeline),
      // wdg('timelineCreator', widgets.types.timelineCreator, "", ["Динамический контент", "DynamicСontent"], timeline),
      // wdg('layout', widgets.types.layout),
      // wdg('list', widgets.types.list, "", ["Пользователи", "Users"], groupUserslist),
      wdg('virtcurrency', widgets.types.virtcurrency, "", ["Геймификация", "Gamification"], virtcurrency),
      wdg('shop', widgets.types.shopMui, "", ["Геймификация", "Gamification"], shop),
      wdg('mobileApp', widgets.types.mobileApp, "", ["Системные виджеты", "SystemWidgets"], mobileApp),
      // wdg('info', widgets.types.info),
      // wdg('html', widgets.types.html),
      wdg('wiki', widgets.types.wiki, "", ["Редактируемый контент", "EditableСontent"], wiki),
      wdg('sliderlink', widgets.types.sliderlink, "", ["Редактируемый контент", "EditableСontent"], sliderlink),
      wdg('ratingmini', widgets.types.ratingmini, "", ["Геймификация", "Gamification"], ratingmini),
   
      wdg('usersGroup', widgets.types.users + '/group', "", ["Пользователи", "Users"], usersGroup),
      wdg('filters', widgets.types.filters, "", ["Другое", "Other"], filters),
      wdg('birthdays', widgets.types.birthdays, "", ["Пользователи", "Users"], birthdays),
      //wdg('ratings', widgets.types.ratings, "", ["Геймификация", "Gamification"], ratings),
      wdg('ratings', useMui5UserProfile ? widgets.types.ratingsNew : widgets.types.ratings, "", ["Геймификация", "Gamification"], ratings),
      //wdg('myBadges', widgets.types.userRating, "", ["Геймификация", "Gamification"], myBadges),
      wdg('myBadges', useMui5UserProfile ? widgets.types.badgesNew : widgets.types.userRating, "", ["Геймификация", "Gamification"], myBadges),
      wdg('quiz', widgets.types.quiz, "", ["Приложения", "Applications"], quiz),
      wdg('quests', widgets.types.quests, "", ["Геймификация", "Gamification"], quests),
      wdg('remindFillProfile', widgets.types.remindFillProfile, "", ["Геймификация", "Gamification"], remindFillProfile),
      wdg('layout', widgets.types.layout, "", ["Системные виджеты", "SystemWidgets"]),
      wdg('userAbout', widgets.types.userAbout, "", ["Другое", "Other"], userAbout),
      //wdg('hierarchy', `${widgets.types.users}/hierarchy`, "", ["Пользователи", "Users"]),
      wdg('tagBlock', widgets.types.tagBlock, "", ["Другое", "Other"]),
      wdg('hierarchy', useMui5UserProfile ? widgets.types.hierarchyNew : `${widgets.types.users}/hierarchy`, "", ["Пользователи", "Users"]),
      wdg('coursesMy', `${widgets.types.coursesMy}`, "", ["Приложения", "Applications"], coursesMy),
      wdg('usersList', `${widgets.types.users}/list`, "", ["Пользователи", "Users"], usersList),
      wdg('timelineonce', widgets.types.timeline, i18n.t('pryaniky.widgets.create.name.pryaniky/timeline.content'), ["Динамический контент", "DynamicСontent"], timelineonce),
      wdg('timelinehead', widgets.types.timeline, i18n.t('pryaniky.widgets.create.name.pryaniky/timeline.head'), ["Динамический контент", "DynamicСontent"], timelinehead),
      wdg('eventstoday', widgets.types.timeline, i18n.t('pryaniky.widgets.create.name.pryaniky/timeline.eventstoday'), ["Динамический контент", "DynamicСontent"], eventstoday),
      wdg('banner', widgets.types.banner, "", ["Приложения", "Applications"], banner),
      wdg('userfields', widgets.types.userfields, "", ["Системные виджеты", "SystemWidgets"], userfields),
      wdg('tagsCloud', widgets.types.tagsCloud, "", ["Другое", "Other"], tagsCloud),
      wdg('secretwidget', widgets.types.secretwidget, "", ["Приложения", "Applications"], secretwidget),
      wdg('secretSanta', widgets.types.secretSanta, "", ["Приложения", "Applications"], secretSanta),
      wdg('secretSantaWidgetList', widgets.types.secretSantaWidgetList, "", ["Приложения", "Applications"], secretSanta),
      // wdg('badgesList', widgets.types.badgesList, "", ["Геймификация", "Gamification"], badgesList),
      wdg('survey', widgets.types.survey, "", ["Приложения", "Applications"], survey),
      wdg('pageCommentsWidget', widgets.types.pageCommentsWidget, "", ["Приложения", "Applications"], pageCommentsWidget),
      wdg('blocks', widgets.types.blocks, "", ["Редактируемый контент", "EditableСontent"], blocks),
      wdg('QuickLinks', widgets.types.QuickLinks, "", ["Редактируемый контент", "EditableСontent"], QuickLinks),
      wdg('html', widgets.types.html, "", ["Редактируемый контент", "EditableСontent"], wiki),
      wdg('referral', widgets.types.referral, "", ["Приложения", "Applications"], referral),
      wdg('mood', widgets.types.mood, "", ["Приложения", "Applications"], mood),
      wdg('calendarmini', widgets.types.calendarmini, "", ["Динамический контент", "DynamicСontent"], calendarmini),
      wdg('calendarWidget', widgets.types.calendarWidget, "", ["Динамический контент", "DynamicСontent"], calendarmini),
      wdg('AlertWidget', widgets.types.AlertWidget, "", ["Динамический контент", "DynamicСontent"], calendarmini),
      wdg('FloatMessageWidget', widgets.types.FloatMessageWidget, "", ["Динамический контент", "DynamicСontent"], calendarmini),
      wdg('wikiArticles', widgets.types.wikiArticles, "", ["Динамический контент", "DynamicСontent"], wikiArticles),
      wdg('myTasks', widgets.types.myTasks, "", ["Динамический контент", "DynamicСontent"], wikiArticles),
      wdg('wikiActueleArticles', widgets.types.wikiActueleArticles, "", ["Динамический контент", "DynamicСontent"], wikiActueleArticles),
      wdg('adventCalendar', widgets.types.adventCalendar, "", ["Приложения", "Applications"], adventCalendar),
      wdg('subscriptions', `${widgets.types.users}/subscriptions`, "", ["Пользователи", "Users"]),
      wdg('subscribers', `${widgets.types.users}/subscribers`, "", ["Пользователи", "Users"]),
      wdg('tabs', widgets.types.tabs, "", ["Другое", "Other"]),
      wdg('LoadAchievements', widgets.types.LoadAchievements, "", ["Другое", "Other"]),
      wdg('tabsControl', widgets.types.tabsControl, "", ["Другое", "Other"]),
      wdg('presents', widgets.types.presents, "", ["Другое", "Other"]),
      enabledModules.endorsements && wdg('skills', widgets.types.skills, "", ["Приложения", "Applications"], skills),
      ...extendedWidgets,
    ];
    if(enabledModules.postcards) {
      list.push(wdg('card', widgets.types.card, "", ["Другое", "Other"]));
    }
    if(useRatingWithBadges){
      list = [
        ...list,
        wdg('ratingBadge', widgets.types.ratingBadge, "", ["Геймификация", "Gamification"], ratingBadge),
      ]
    }
    if (window.PRN_SERVICE.dev_features) {
      list = [
        ...list,
        wdg('mindmap', widgets.types.mindMap, "", ["Другое", "Other"]),
        wdg('list', widgets.types.list, "", ["Пользователи", "Users"], groupUserslist),
        wdg('notes', widgets.types.notes, "", ["Другое", "Other"]),
        wdg('topbutton', widgets.types.topbutton, "", ["Другое", "Other"]),
        wdg('reactions', widgets.types.reactions, "", ["Другое", "Other"]),
        wdg('context', widgets.types.context, "", ["Другое", "Other"]),
        wdg('orders', `${widgets.types.list}/orders`, "", ["Другое", "Other"]),
        wdg('charts', widgets.types.charts, "", ["Другое", "Other"]),
        wdg('album', widgets.types.album, "", ["Другое", "Other"]),
        wdg('badgesList', widgets.types.badgesList, "", ["Геймификация", "Gamification"], badgesList),
        wdg('userBadges', widgets.types.userBadges, "", ["Геймификация", "Gamification"]),
        wdg('userRatings', widgets.types.userRatings, "", ["Геймификация", "Gamification"]),
        // wdg('reactions', widgets.types.reactions, "", ["Другое", "Other"]),
        wdg('wikilist', `${widgets.types.list}/wikilist`, "", ["Другое", "Other"]),
        // wdg('subscriptions', `${widgets.types.users}/subscriptions`, "", ["Пользователи", "Users"]),
        wdg('layout/horizontal', `${widgets.types.layout}/horizontal`, "", ["Системные виджеты", "SystemWidgets"]),
        wdg('journal', widgets.types.journal, "", ["Другое", "Other"]),
        // wdg('skills', widgets.types.skills),
        wdg('license', widgets.types.license, "", ["Другое", "Other"]),
        wdg('info', widgets.types.info, "", ["Другое", "Other"]),
        wdg('kpi', `${widgets.types.kpi}`, "", ["Другое", "Other"]),
        wdg('currencyTimeline', widgets.types.currencyTimeline, "", ["Другое", "Other"]),
        wdg('virtualUser', widgets.types.virtualUser, "", ["Другое", "Other"]),
        wdg('iframe', widgets.types.iframe, "", ["Другое", "Other"]),
        // wdg('presents', widgets.types.presents, "", ["Другое", "Other"]),
        wdg('statistic', widgets.types.statistic, "", ["Другое", "Other"]),
        wdg('statisticFilters', widgets.types.statisticFilters, "", ["Другое", "Other"]),
        wdg('files', widgets.types.files, "", ["Другое", "Other"]),
        wdg('lastUserPost', widgets.types.lastUserPost, "", ["Динамический контент", "DynamicСontent"], timeline),
        // wdg('referral', widgets.types.referral)
        // wdg('grid', widgets.types.grid)
      ]
    }
    return list.filter((v: any) => Boolean(v)).sort(function (a, b) {
      let titleA = a.title.toLowerCase();
      let titleB = b.title.toLowerCase();
      if (titleA < titleB) //сортируем строки по возрастанию
        return -1
      if (titleA > titleB)
        return 1
      return 0 // Никакой сортировки
    });
  }, [enabledModules.endorsements, enabledModules.canva, enabledModules.cards, extendedWidgets]);
  // const fieldsCategoryes = useMemo(() =>  ( categories as string[]).reduce<any>((a: any, c: string) => {
  //   const cat:any = widgetsList[0].tags
  //   a[cat] = [...(a[cat] || []), c]
  //   return a
  // }, {}), [])

  const widgetsCat = useMemo(() => categories.reduce((acc: any, cur: any, index: number) => {

    const cat: any = widgetsList.filter((el) => el.tags[1] === cur)
    acc[index] = [...cat]
    return acc
  }, []), [])

  // флаг для проверки посик по категориям или по виджетам для отображения найденной категории или виджета. если массив пустой, то значит нужно проверить по виджетам.
  const catSearch = useMemo(() => widgetsCat.filter((widgetCat: any, index: number) =>
    widgetCat[0].tags.join(" ").toLocaleLowerCase().includes(search)), [search])
  let schemaObjIsEmpty: boolean = false;

  const onInputChange = debounce((e: any) => {

    setSearch(e.target.value.toLocaleLowerCase() || '');
  }, 2000);

  const onSearchWidget = (search: any) => {
    onInputChange(search)
  }

  useEffect(() => {
    getExtendedWigets()
      .then(d => {
        if (checkResponseStatus(d)) {
          const prepairedWidget = d.data.map(e => {
            return wdg('external', e.type, e.displayName, e.description || ' ', undefined, undefined, e.settings)
          });
          setExtendedWidgets(prepairedWidget);
        }
      })
      .catch((e) => {
      });
  }, []);


  const isEmptyObject = (obj: any) => {
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        return schemaObjIsEmpty = false;
      }
    }
    return schemaObjIsEmpty = true;
  }
  const selectItem = (item: any) => {

    // const item = widgetsList.reduce((acc, cur) => cur.id === selected ? cur : acc);
    const schema = getStructure(item.schema)
    // const settingMethod = item

    const settingMethod = (item && getMethod(item.type))

    // const settingMethod =  ComponentInjector.getInstance().getComponentById(['WSettings'], item.type)||undefined
    if (settingMethod) {
      settingMethod({
        selected: item.type,
        // onChange: updateWidget,
        // selected: widget.type,
        data: item,
        onChange: (value: any) => {

        }
      }).then((result: any) => {
        const opts: IWidgetProps = {
          columnId,
          type: item.type,
          id: item.id,
          ...result,
          ...(item.type === `${widgets.types.layout}/horizontal` ? { version: '2' } : {})
        }
        utilsProject.confirm({
          title: i18n.t(`pryaniky.widgets.create`),
          text: i18n.t(`pryaniky.widgets.create.confirm`) + i18n.t(`pryaniky.widgets.create.name.${opts.type}`),
          onConfirm: () => {
            handleAccept(generateWidget(opts))
          }
        })
        // modalChangeDate({ id: item.id || '', data: generateWidget(opts) });
        // this.validate(result.data);
        // this.onChange

      }).catch(() => {
        // this.props.onClose()
      })

      return;
    }

    setSelected(item)
    const opts: IWidgetProps = {
      columnId,
      type: item.type,
      id: item.id,
    }
    if (item.type.match(/^pryaniky\/external/)) {
      opts.id = uuid();
      opts.title = item.title;
      opts.settings = item.settings;
    }

    isEmptyObject(schema.schema.properties)

    switch(item.schema) {
      case 'layout':
        opts.data = [
          generateColumn({})
        ];
        break;
      case 'layout/horizontal': 
        const positionId = uuid();
        const column = generateColumn({});
        opts.data = [
          column
        ];
        opts.settings = {
          sections: {
            [column.id]: [
              {
                id: positionId,
                sx: 12,
                widgetId: null
              }
            ]
          }
        }
        opts.version = '2';
        break;
      case 'tabs':
      case 'tabsControl':
        opts.data = [];
        break;
    }

    if (schemaObjIsEmpty || item.schema === "layout") {
      // return  generateWidget(opts);
      utilsProject.confirm({
        title: i18n.t(`pryaniky.widgets.create`),
        text: i18n.t(`pryaniky.widgets.create.confirm`) + i18n.t(`pryaniky.widgets.create.name.${opts.type}`),
        onConfirm: () => {
          handleAccept(generateWidget(opts))

        }

      })

    } else if (!schemaObjIsEmpty) {
      DialogWidgetsAddSettings({ data: schema, item, columnId }).then((nnn: any) => handleAccept(nnn))
    }
  };


  return (
    <div>
      <DialogTitle onClose={handleClose}>
        {i18n.t('pryaniky.widgets.add.title')}

        <Tooltip className={cnWidgetAddDialog('HelpTooltip')} title={i18n.t('pryaniky.widgets.add.help')}>
          <span>
            <Button target="_blank" type="link" noBackground noBorder href={"https://pryaniky.com/ru-helps/1-%d1%87%d1%82%d0%be-%d1%82%d0%b0%d0%ba%d0%be%d0%b5-%d0%b2%d0%b8%d0%b4%d0%b6%d0%b5%d1%82%d1%8b/"}>
              <Icon className={cnWidgetAddDialog('Icon')} icon={'question-circle'} />
            </Button>
          </span>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "visible", padding: "0" }} className={cnWidgetAddDialog('Content')}>
        <SearchInput

          onChange={(e: any) => {

            onSearchWidget(e)
            setSearchValue(e.target.value)
          }
          }
          label={i18n.t('pryaniky.mui.SearchInput.label')}
          value={searchValue}
        // defaultValue={serch}
        // throttle={0} 
        // className={cnWidgetAddDialog('Search')} 
        />
        {search === "" ? <div ref={selectorRef} className={cnWidgetAddDialog('Selector')}>
          {widgetsCat.map((catName: any, index: any) => <AccordionMUI defaultExpanded={false} sx={{ padding: "0px 0px 16px 0px" }} title={i18n.t(`pryaniky.widgets.category.${catName[0].tags[1]}`)} >
            {catName.map((el: any) => <MuiButton
              sx={{ width: "100%" }}
              key={el.id}
              className={cnWidgetAddDialog('Selector-Item')}
              onClick={() => selectItem(el)}
            >
              <div className={cnWidgetAddDialog('Selector-Item-Content')}>
                <Avatar size={40} shape={"square"} imgUrl={el.img ? el.img : noAvatar} />
                <div >
                  <div className={cnWidgetAddDialog('Selector-Item-Title')}>{el.title}</div>

                  <Tooltip className='TooltipMargin' title={el.description}>
                    <div className={cnWidgetAddDialog('Selector-Item-Description')}>{el.description}</div>
                  </Tooltip>

                </div>
              </div>
            </MuiButton>)
            }
          </AccordionMUI>
          )}
        </div> :
          (catSearch.length > 0) ?
            catSearch.map((catName: any) => <AccordionMUI
              defaultExpanded
              sx={{ padding: "0px 0px 16px 0px" }}
              title={i18n.t(`pryaniky.widgets.category.${catName[0].tags[1]}`)} >
              {catName.map((el: any) => <MuiButton
                sx={{ width: "100%" }}
                key={el.id}
                className={cnWidgetAddDialog('Selector-Item')}
                onClick={() => selectItem(el)}
              >
                <div className={cnWidgetAddDialog('Selector-Item-Content')}>
                  <Avatar size={40} shape={"square"} imgUrl={el.img ? el.img : noAvatar} />
                  <div >
                    <div className={cnWidgetAddDialog('Selector-Item-Title')}>{el.title}</div>

                    <Tooltip className='TooltipMargin' title={el.description}>
                      <div className={cnWidgetAddDialog('Selector-Item-Description')}>{el.description}</div>
                    </Tooltip>

                  </div>
                </div>
              </MuiButton>)}
            </AccordionMUI>) : <div ref={selectorRef} className={cnWidgetAddDialog('Selector')}>
              {widgetsList && widgetsList.filter((el: any) => search ? el.title.toLocaleLowerCase().includes(search) || el.tags.join(" ").toLocaleLowerCase().includes(search) : true).map((el: IWidgetToSelect) => <MuiButton
                key={el.id}
                sx={{ width: "100%" }}
                className={cnWidgetAddDialog('Selector-Item')}
                onClick={() => selectItem(el)}
              >
                <div className={cnWidgetAddDialog('Selector-Item-Content')}>
                  <Avatar size={40} shape={"square"} imgUrl={el.img ? el.img : noAvatar} />
                  <div >
                    <div className={cnWidgetAddDialog('Selector-Item-Title')}>{el.title}</div>

                    <Tooltip className='TooltipMargin' title={el.description}>
                      <div className={cnWidgetAddDialog('Selector-Item-Description')}>{el.description}</div>
                    </Tooltip>

                  </div>
                </div>
              </MuiButton>)}
            </div>

        }
      </DialogContent>
      <DialogActions
        closeText={i18n.t('pryaniky.modal.close')}
        onClose={handleClose} />
    </div>
  )
}

export const WidgetAddDialog = connect<IWidgetAddDialogStateProps, IWidgetAddDialogDispatchProps, IWidgetAddDialogOwnProps>(
  WidgetAddDialogMapStateToProps,
  mapWidgetAddDialogDispatchToProps
)(withComponentEnjector<IWidgetAddDialogProps>(WidgetsAddDialogPresenter, ["WAddition"]))

export const DialogWidgetAdd = createModal(WidgetAddDialog, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
    }
  },
  TransitionComponent,
  scroll: 'body'
});