/**
 * @packageDocumentation
 * @module List_type_wiki
 */
import React, { FC, useState, useMemo, useLayoutEffect, useRef } from 'react';
import * as utils from 'utils/src/utils';
import { IHeaderWikiWithFiltersNewProps, cnHeaderWikiWithFiltersNew } from './HeaderWikiWithFiltersNew.index';
import './HeaderWikiWithFiltersNew.scss';
import i18n from 'localizations/i18n';
import { DialogWikiCreateNew } from 'blocks/Dialogs/WikiCreateNew/Create/WikiCreateNew';
import widgetsActions from 'redux/actionsTypes/Widgets';
import IconButton from '@mui/material/IconButton';
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import { useDebouncedQueryState, useDidUpdateEffect } from 'utils/src/hooks'
import { Translate } from 'localizations/Translate';
import { useDispatch, useSelector } from 'react-redux'
import { PreListFiltersNew } from '../PreListFiltersNew/PreListFiltersNew'
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton';
import { Breadcrumbs } from 'muicomponents/src/Breadcrumbs/Breadcrumbs';
import { Typography } from 'muicomponents/src/Typography/Typography';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Avatar } from 'muicomponents/src/Avatar/Avatar'
import wikilist from '../Items/wikilist.svg'
import { ItemsListHeader } from 'muicomponents/src/ItemsListDialog/ItemsListHeader/ItemsListHeader'
import { SearchWithFiltersBox } from './HeaderWikiWithFiltersNew.styled'
import { toast } from 'react-toastify';
import { i } from 'blocks/Widget/_type/Widget_type_license.index';
import {
    Divider,
    Box,
    FormControlLabel,
    Checkbox,

} from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';
import { formatDateSTD } from 'utils/src/utils.dates';
import { WikilistViewTemplate, WikilistViewTemplateDict } from 'utils/src/BaseTypes/wikilist.types';
import { getPagesTypes } from 'utils/src/requests/requests.wikilist';
import { PageCreateContent, PageTypes } from 'utils/src';
import { getCurrentPageType } from 'redux/actions/Widgets';
import { preapreCollectionAliasByContext } from '../List_type_wiki.utils';
import { useDependedState, useFileUploader, useDropFile, mapFromAttachmentsList, IAttachmentType, ItemType } from 'utils/src/hooks'
import { widgets, generateColumn } from 'i.widgets';
import uuid from 'uuid/v1';
import { pages } from "../../../pageStructures/pages";


const createPageData = (title: string, collectionAlias: string, file: any, viewers?: any[]) => ({
    layout: pages(widgets.types.file),
    description: '',
    enableTwoFactorAuth: false,
    registerNewWidgets: false,
    isTemplate: false,
    id: uuid(),
    type: "file",
    title: title,
    collectionAlias: collectionAlias,
    hasСustomTitleImage: false,
    defaultViewType: 0,
    positionInCollection: 0,
    file: file,
    viewers
})

const newContentTypes = Object.values(PageTypes);
// ['page', 'wikilist', 'url', 'file', 'wikilist.imageGallery', 'wikilist.videoGallery'];

const newContentCreateKey = (type: (typeof newContentTypes)[number], defaultValue?: string) => {
    const typeText = i18n.t(`pryaniky.wikilist.create.type.${type}`, { defaultValue });
    if (typeText === defaultValue) return defaultValue;
    return i18n.t(`pryaniky.wikilist.${type === "file" ? "upload" : "create"}.type`, { type: typeText });
}

const newContentCreate = (type: (typeof newContentTypes)[number], defaultValue?: string) => ({
    id: type,
    content: newContentCreateKey(type, defaultValue)
});

export const HeaderWikiWithFiltersNew: FC<IHeaderWikiWithFiltersNewProps> = ({ context, hasRights, widget, isAdmin, layoutWikilist, dashFolderInfo }) => {

    let collectionAlias = preapreCollectionAliasByContext(context);

    const pageType = useSelector(getCurrentPageType);
    const pageTypeRef = useRef(pageType);
    pageTypeRef.current = pageType;

    const userCanCreateNewContent = useMemo(() => {
        return isAdmin || context.isGroupAdmin || hasRights;
    }, [isAdmin, context.isGroupAdmin, hasRights]);

    const contentTypesRef = useRef<PageCreateContent[]>([]);
    const [createDropdownOptionsLoading, setCreateDropdownOptionsLoading] = useState(false);
    const [createDropdownOptions, setCreateDropdownOptions] = useState<ReturnType<typeof newContentCreate>[]>([]);

    useLayoutEffect(() => {
        async function getData() {
            setCreateDropdownOptions([]);
            setCreateDropdownOptionsLoading(true);
            try {
                const response = await getPagesTypes();
                if (utils.checkResponseStatus(response)) {
                    contentTypesRef.current = response.data;
                    let options = response.data.map(el => newContentCreate(el.type, el.displayName));
                    if (pageTypeRef.current === PageTypes.imageGallery || pageTypeRef.current === PageTypes.videoGallery) {
                        options = options.filter(el => el.id === 'file');
                    }
                    setCreateDropdownOptions(options);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setCreateDropdownOptionsLoading(false);
            }
        };
        if (userCanCreateNewContent) {
            getData();
        }
    }, [userCanCreateNewContent, collectionAlias]);

    const [search, searchQuery, setSearch] = useDebouncedQueryState('search')
    const [searchValue, setSearchValue] = useState(searchQuery?.toString() || '');

    useDidUpdateEffect(() => {
        return setSearch(searchValue)
    }, [searchValue]);

    const dispatch = useDispatch()

    // ---------------------- Изменения типа оторажения списка----------------------------->

    const setWikiviewType = (el: any) => {
        window.localStorage.setItem('prn_local_state_wiki-view-type', JSON.stringify(el));
        dispatch({
            type: widgetsActions.UPDATE_CONTEXTS, payload: {
                [widget.id]: {
                    viewType: el
                }
            }
        });
    }
    let allowFileTypes: Parameters<typeof useFileUploader>['0']['allowTypes']

    const resultRef = useRef<any[]>([])

    async function sendPage(fileData: any) {
        const pd = createPageData(fileData.name,
            collectionAlias,
            fileData,
            collectionAlias?.viewers)
        // const pageResult = await send(pd)

        return { ...pd, id: pd.file.id }
    }

    const {
        openSelectFile
    } = useFileUploader({
        allowTypes: allowFileTypes,
        getParams: { collectionAlias: collectionAlias || 'dash' },
        uploadOnAdd: true,
        maxFilesCount: 10,
        callbacks: {
            loadFinish: async function (item, response) {
                const pd = await sendPage(response?.data[0])
                resultRef.current.push({ ...pd, id: pd.file.id })
                // }
                if (response?.error_code === 3001) {
                    toast.error(i18n.t(`pryaniky.modal.wikipage.file.upload.3001`));
                } else if (response?.error_code !== 0) {
                    toast.error(response?.error_text);
                } else {
                    toast.success(i18n.t(`pryaniky.modal.wikipage.file.upload.success`));
                }
            },
            // loadAbort(item) {

            // },
        }
    })
    const onSelectAction = (action: string) => {

        const createType = contentTypesRef.current.find(el => el.type === action);
        let allowFileTypes: Parameters<typeof DialogWikiCreateNew>['0']['allowFileTypes'];
        if ((pageType as any) === PageTypes.imageGallery) {
            allowFileTypes = ['image/*']
        }
        if ((pageType as any) === PageTypes.videoGallery) {
            allowFileTypes = ['video/*']
        }
        if (action === "file" && (pageType as any) !== PageTypes.videoGallery && (pageType as any) !== PageTypes.imageGallery) {
            openSelectFile()
        } else {
            DialogWikiCreateNew({
                type: action,
                isAdmin: isAdmin,
                allowFileTypes,
                data: {
                    collectionAlias,
                    collections: (dashFolderInfo && dashFolderInfo?.length > 0) ? dashFolderInfo : [{
                        alias: collectionAlias,
                        defaultViewType: layoutWikilist?.defaultViewType,
                        description: layoutWikilist?.description,
                        id: layoutWikilist?.id,
                        imageId: layoutWikilist?.imageId,
                        imageUrl: layoutWikilist?.imageUrl,
                        name: layoutWikilist?.title ? layoutWikilist?.title : "Нет названия",
                    }],
                    pageType: createType
                },
                parentFolderModerators: layoutWikilist?.moderators || []
            }).then((data: any) => {
                data.type === "url" && dispatch({
                    type: widgetsActions.UPDATE_CONTEXTS, payload: {
                        [context.__parent || widget.id]: {
                            "order": "creationdate",
                            "sort": "desc"
                        }
                    }

                })
            }).catch(() => { })
        }
    }

    const isCollection = layoutWikilist && layoutWikilist.collections && layoutWikilist.collections.length > 0;

    const BreadCrumbsComponent = useMemo(() => {
        if (!layoutWikilist?.breadCrumbs) return null;
        return <Breadcrumbs
            maxItems={5}
            separator={<ChevronRight fontSize="small" />}
            sx={{ marginBottom: "20px", }}
            aria-label="breadcrumb">

            {layoutWikilist?.breadCrumbs?.map((item: any, idx: number) => <MuiButton
                sx={{ textTransform: "inherit", fontSize: "16px" }}
                disabled={idx === layoutWikilist.breadCrumbs.length - 1}
                href={item.alias}>
                {/* href={item.alias === "dash" ? "/wikilist" : "/wikilist/" + item.alias}> */}
                {item.alias === "/wikilist/dash" && item.name === "dash" ? i18n.t("pryaniky.list.wiki.dash") : item.name?.length > 0 ? item.name : layoutWikilist?.title}
            </MuiButton>)}

        </Breadcrumbs>
    }, [layoutWikilist])


    const headerActions = useMemo(() => {
        return <Box sx={{ display: "flex", gap: "24px" }}>
            <IconButton
                style={{ padding: "5px" }}
                onClick={() => setWikiviewType(0)}
                color={context.viewType === 0 ? "primary" : "default"}
            >
                <GridViewIcon style={{ fontSize: "30px" }} />
            </IconButton>

            <IconButton
                style={{ padding: "5px" }}
                onClick={() => setWikiviewType(1)}
                color={context.viewType === 1 ? "primary" : "default"}
            >
                <ListIcon style={{ fontSize: "30px" }} />
            </IconButton>
            {
                userCanCreateNewContent && <SplitButton
                    disabled={!createDropdownOptions[0]}
                    loading={createDropdownOptionsLoading}
                    buttonProps={{
                        onClick: () => {
                            onSelectAction(createDropdownOptions[0]?.id)
                        },
                        children: createDropdownOptions[0]?.content
                    }}
                    variant={"outlined"}
                    options={createDropdownOptions.length === 1 ? [] : createDropdownOptions}
                    handleMenuItemClick={(e, action) => onSelectAction(action.id)}
                />
            }
        </Box>

    }, [userCanCreateNewContent, createDropdownOptionsLoading, createDropdownOptions[0], context.viewType])

    return (<div className={cnHeaderWikiWithFiltersNew("")}>
        {isCollection && BreadCrumbsComponent}
        {isCollection && <Box sx={{ display: "flex", borderBottom: "1px solid #E0E0E0", paddingBottom: "20px", marginBottom: "20px" }}>
            <Avatar
                className={cnHeaderWikiWithFiltersNew(`${layoutWikilist.titleImageId ? "WithAvatar" : "NoAvatar"}`)}
                src={layoutWikilist.titleImageId ? layoutWikilist.titleImageUrl : wikilist} sx={{ width: 64, height: 64, marginRight: "16px" }} />
            <Box>
                <Typography variant="h5">{layoutWikilist.title}</Typography>
                <Typography variant={"body2"} color="rgba(0, 0, 0, 0.38)">
                    <Translate i18nKey='pryaniky.wikilist.item.created.new' values={{
                        creationDate: formatDateSTD(layoutWikilist?.creationDate, false),
                        changeDate: formatDateSTD(layoutWikilist?.changeDate, false)
                    }} />
                </Typography>
            </Box>
        </Box>}
        <SearchWithFiltersBox className={cnHeaderWikiWithFiltersNew("Header")}>
            <ItemsListHeader
                searchQuery={searchValue || ""}
                changeSearchQuery={(value) => setSearchValue(value)}
                SearchActions={headerActions}
                bottomContent={
                    <PreListFiltersNew
                        isCollection={isCollection}
                        hasRights={hasRights || layoutWikilist?.editable}
                        isAdmin={isAdmin || hasRights}
                        context={context} widget={widget}
                    />
                }
            />
        </SearchWithFiltersBox>
    </div>
    )
}

