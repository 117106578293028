import React, { FC, useState, useEffect } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { NQuickLinks } from '../QuickLinks.index';
import { Close } from '@material-ui/icons';
import { v1 as uuid } from 'uuid';
// import { Button } from 'uielements/src/MaterialElements/Button/Button';
import Input from 'uielements/src/MaterialElements/Input/Input';
import { ImageLoader } from 'uielements/src';
import { Translate } from 'localizations/Translate';
import { cnQuickLinksSettings, NQuickLinksSettings } from './QuickLinksSettings.index';
import './QuickLinksSettings.scss';
import {
    Checkbox, Button
} from 'muicomponents/src';
import { FormControlLabelStyled } from '../../WikiArticlesWidget/WikiArticlesWidget.styled'
import ReactSortable from 'react-sortablejs';
import { keyBy } from 'lodash';
import { IconButton } from 'muicomponents/src';
import { DragHandle } from 'muicomponents/src/Icons';
const emptyItem: NQuickLinks.Item = {
    id: '',
    file: null,
    imgUrl: '',
    text: '',
    showLinkText: false,
    url: '',
    openInNewTab: false

}

export const QuickLinksSettings: FC<NQuickLinksSettings.Props> = ({
    data,
    onChange,
    ...props
}) => {

    const [items, setItems] = useState<NQuickLinks.Item[]>(data?.items && JSON.parse(JSON.stringify(data?.items)) || []);

    useEffect(() => {
        onChange({
            data: {
                items
            },
            settings: {
                items
            }
        })
    }, [items]);

    const addItem = () => {
        setItems([...items, { ...emptyItem, id: uuid() }]);
    }

    const changeItem = (id: string, value: Partial<NQuickLinks.Item>) => {
        setItems(items.map(el => {
            if (el.id === id) return {
                ...el,
                ...value
            }
            return el;
        }));
    }

    const deleteItem = (id: string) => {
        setItems(items.filter(el => el.id !== id));
    }
    // console.log("items", items)
    return (
        <div className={cnQuickLinksSettings()}>
            {/* <ReactSortable
                className={'QuickLinksSettingsSortable'}
                options={{
                    handle: '.QuickLinksSettingsSortable-Handle',
                    animation: 200
                }}
                onChange={(newItems: string[]) => {
                    // props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                    //     const dict = keyBy((oldData.elements || []).map(convertToItemData), 'id');
                    //     oldData.elements = newItems.map(id => {
                    //         const item = dict[id];
                    //         delete item.id;
                    //         return item;
                    //     });
                    //     console.log('oldData.elements', oldData.elements);
                    //     return { ...oldData };
                    // }));
                }}
            > */}
                {
                    items.map((item, idx) => {
                        return (
                            <div className={cnQuickLinksSettings('Item')}>
                                {/* <IconButton
                                    disableRipple
                                    className={'SliderSettingsSortable-Handle'}
                                >
                                    <DragHandle />
                                </IconButton> */}
                                <IconButton className={'ItemRemove'} onClick={() => deleteItem(item.id)}>
                                    <Close />
                                </IconButton>
                                <ImageLoader
                                    onLoaded={(file) => {
                                        changeItem(item.id, { file, imgUrl: file.originalUrl })
                                    }}
                                    squarePreview
                                    defaultUrl={item.imgUrl}
                                    title={
                                        <>
                                            <Translate i18nKey="pryaniky.widgets.settings.blocks.item.image.title" />
                                        </>
                                    }
                                    description={
                                        <>
                                            <div>
                                                <Translate i18nKey="pryaniky.widgets.settings.blocks.item.image.description" />
                                            </div>
                                            <div>
                                                <Translate i18nKey="pryaniky.widgets.settings.blocks.item.image.aspectratio" />
                                            </div>
                                        </>
                                    }
                                />
                                <Input
                                    placeholder={<Translate i18nKey="pryaniky.widgets.settings.blocks.item.text" />}
                                    value={item.text}
                                    onChange={(text) => changeItem(item.id, { text })}
                                />
                                <FormControlLabelStyled
                                    control={
                                        <Checkbox
                                            checked={item.showLinkText}
                                            onChange={() => changeItem(item.id, { showLinkText: !item.showLinkText })}
                                            name="Shuffle"
                                            color="primary"
                                        />
                                    }
                                    label={<Translate i18nKey='pryaniky.widgets.settings.blocks.showLinkText' />}
                                />
                                <Input
                                    placeholder={<Translate i18nKey="pryaniky.widgets.settings.blocks.item.url" />}
                                    value={item.url}
                                    onChange={(url) => changeItem(item.id, { url })}
                                />
                                <FormControlLabelStyled
                                    control={
                                        <Checkbox
                                            checked={item.openInNewTab}
                                            onChange={() => changeItem(item.id, { openInNewTab: !item.openInNewTab })}
                                            name="Shuffle"
                                            color="primary"
                                        />
                                    }
                                    label={<Translate i18nKey='pryaniky.widgets.settings.blocks.openInNewTab' />}
                                />
                            </div>
                        )
                    })
                }
            {/* </ReactSortable> */}
            <Button className={cnQuickLinksSettings('Add')} onClick={addItem}>
                <Translate i18nKey="pryaniky.widgets.settings.blocks.item.add" />
            </Button>
        </div>
    )
}

export const initWidgetAddition = () => ComponentInjector.getInstance().addComponent('WAddition', QuickLinksSettings, 'pryaniky/QuickLinks')
export const initWidgetSettings = () => ComponentInjector.getInstance().addComponent('WSettings', QuickLinksSettings, 'pryaniky/QuickLinks')