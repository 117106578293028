import React, { FC, useState, useCallback } from 'react';
import { cnClassName, ISecretsWidgetProps } from './SecretsWidget.index';
import './SecretsWidget.scss';
import { } from 'utils/src/utils';
import { SecretBase } from 'uielements/src/SecretBase/SecretBase'
import { initWidgetSettings, initWidgetAddition } from './Settings/Settings'
import { initWidgetSettingsDialog, initWidgetAdditionDialog } from './SettingsDialog/SettingsDialog'
import { useQuery, useLazyQuery } from 'utils/src/hooks'
import {
  API,
} from 'utils/src/utils'
import { secretIsRead } from 'utils/src/requests/admin.secrets'
import { isNull } from 'lodash'
import { Alert, AlertTitle } from '@material-ui/lab';
import { i18n } from "localization";
import { SecretPassDialog } from 'blocks/Dialogs/SecretPassDialog/SecretPassDialog'
// initWidgetAddition()
// initWidgetSettings()
initWidgetSettingsDialog()
initWidgetAdditionDialog()

export const SecretsWidget: FC<ISecretsWidgetProps & any> = ({
  className,
  tag: TagName = 'div',
  widget,
  edit,
  ...props
}) => {
  const [isLoadingRead, readResult, isReadError] = useQuery(() => secretIsRead(widget?.data?.id || ''))
  const [
    loadSecret,
    isLoadingSecret,
    secertResult,
    isError,
    count,
    setResult
  ] = useLazyQuery((pwd: string) => API.pages.getWikiSecret(widget?.data?.id || '', window.location.pathname.slice(1), pwd).r as any)

  const onOpen = useCallback(() => {
    () => SecretPassDialog({}).then(() => { }).catch((pass) => {
      if (isNull(pass)) return;
      loadSecret(pass)
    })
    // const pass = window.prompt(i18n.t(`pryaniky.editor.secrets.pressPassord`))
    // if (isNull(pass)) return;
    // loadSecret(pass)
  }, [])

  const secretBase = {
    buttonText: widget?.data?.buttonText || "",
    id: widget?.data?.id || "",
    plainText: widget?.data?.plainText || "",
    secret: null,
    img: {
      id: widget?.data?.img?.id !== "" ? widget?.data?.img?.id : null,
      originalUrl: widget?.data?.img?.originalUrl !== "" ? widget?.data?.img?.originalUrl : null
    },
    showImg: widget?.data?.showImg || false
  }

  const isServerError = isError || isReadError

  if (!edit && widget?.data?.hideAfterOpening && readResult?.data !== false) return null
  if (!edit && widget?.isHidden) return null

  return <TagName {...props} className={cnClassName({}, [props.className])}>
    {isServerError &&
      <Alert severity="error" className={cnClassName('Alert')}>
        <AlertTitle>
          {i18n.t(`pryaniky.editor.secrets.edit.error`)}
        </AlertTitle>
        <pre>
          internal server error or connection error.
          isError = {isError}
          isReadError = {isReadError}
        </pre>
      </Alert>
    }
    <SecretBase
      secretId={widget?.data?.id}
      className={cnClassName({}, [className])}
      buttonText={secretBase.buttonText}
      description={secretBase.plainText}
      cleanError={() => setResult(null)}
      onEdit={() => { }}
      onHide={() => setResult(null)}
      onOpen={() => widget?.data?.noPassword ? loadSecret('') : SecretPassDialog({}).then((pass) => {
        if (isNull(pass)) return;
        loadSecret(pass)
      }).catch(() => {

      })}
      content={secertResult?.data?.secret?.data}
      error={secertResult?.error_code === 18001 ? i18n.t(`pryaniky.editor.secrets.edit.wrongpassworderror`) : null}
      isLoading={isLoadingSecret}
      readOnly={true}
      img={secretBase.img}
      showImg={secretBase.showImg}
    />
  </TagName>;
};

