import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { setEditType } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators } from 'redux';
import { menuHidden } from '../LeftMenu/redux/actions';

import { leftMenuSetEdit, leftMenuSetReducerField } from '../LeftMenuMui/redux/actions';
import { getLeftMenuReducerField } from '../LeftMenuMui/redux/selectors';
import { getLifeCycol } from 'utils/src/CommonRedux/LifeCycol/selectors';
import { getQuery, setActiceTab } from 'redux/search/actions';
import { getNotificationCount, resetNotificationCount } from 'redux/actions/Notification';
import { TSearchData } from 'utils/src/requests/models/admin.search'; 
import { IEnvironmentContex } from 'utils/src/EnvironmentContex';
import { RouteComponentProps } from 'react-router';

export interface IHeaderProps extends IClassNameProps, HeaderStateProps, IHeaderOwnProps, IHeaderDispathProps, IEnvironmentContex, RouteComponentProps {}

export interface IHeaderOwnProps {
  tag?: 'div';
}

export const mapStateToProps = (state: any) => ({
  edit: state.store.edit as boolean,
  isAdmin: getAuthUser(state).baseData.isAdmin as boolean,
  isEditable: state.store.isEditable as boolean,
  audioPlayList: state.store.audioPlayList,
  homePage: state.store.appSettings?.homePage,
  titleLogo: state.store.appSettings?.titleLogo,
  nameNetwork: state.store.appSettings.title as string,
  showLogoInHeader: state.store.appSettings?.showLogoInHeader,
  chatEnable: state.store.appSettings.enabledModules.chat,
  activePage: state.widgets.active,
  menuEdit: getLeftMenuReducerField('edit')(state) as boolean,
  menuHide: getLeftMenuReducerField('hide')(state) as boolean,
  lifeCycleStructureFromServer: getLifeCycol(state).INIT_STRUCTURE_FROM_SERVER,
  authUser: getAuthUser(state),
  notificationCount: state.store.notificationCount as number,
  messagesCount: state.chat.chatNewChannelCount as number,
  filters: (state.store.appSettings.searchFilters as TSearchData[]).filter((item => item.showAtQuickSearch))
});

export type IHeaderDispathProps = ReturnType<typeof mapDispatchToProps>

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setEditType,
  menuHidden,
  leftMenuSetEdit,
  leftMenuSetReducerField,
  getQuery,
  setActiceTab,
  getNotificationCount,
  resetNotificationCount
}, dispatch);

export type HeaderStateProps = ReturnType<typeof mapStateToProps>

export interface IHeaderState {
  showSearch: boolean;
}

export const cnHeader = cn('Header');