import { connect } from 'react-redux'
import React from 'react'
import { mapDispatchToProps, IDispatchProps } from 'redux/connector'
import { Page } from 'blocks/Page/Page'
import i18n from 'localizations/i18n'
import { setContext } from 'redux/actions/Widgets';
import { NewsCreator } from 'News/creator/Creator';

interface IDevelopPageProps extends IActionsDispatch, IDispatchProps { }
interface IActionsDispatch {
  setContext: any
}

export const DevelopPagePr: React.FC<IDevelopPageProps> = props => {

    return (
        <NewsCreator />
    );
}

export const DevelopPage = connect(
  null,
  mapDispatchToProps({ setContext })
)(DevelopPagePr)