import React, { FC } from 'react'
import { connect } from 'react-redux'
import { mapStateToProps, IListItemProps, cnEventsItem } from './ListItem.index'
import { EventItem } from '../EventItem/EventItem'
// import i18n from 'localizations/i18n'
// import { Translate } from 'localizations/Translate'
import Box from '@mui/material/Box'
// import { styled } from '@mui/material/styles';
// import Typography from '@mui/material/Typography'
import { CalendarIcon } from '../CalendarIcon/CalendarIcon'
// import { Button } from 'uielements/src'
import { Translate } from 'localizations/Translate'
import { Button } from 'muicomponents/src/Button/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { createNewsModal } from 'blocks/Dialogs/News/Create/Create';
import { useOpenEventCreator, isShowCreateButton } from '../utils'
import "./ListItem.scss"

const ListItemPresenter = (props: any) => {
  const openCreator = useOpenEventCreator()
  const items = (props.data && props.data[0] !== 'null') ? props.data : []
  const showCreateButton = isShowCreateButton(props.el)
  if (items.length === 0) return <></>
  return (
    <Box sx={{ marginBottom: "12px", display: 'flex' }} className={cnEventsItem({}, [])} >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CalendarIcon element={props.el} />
        {showCreateButton && <Button sx={{ marginTop: '8px' }} onClick={() => openCreator(new Date(props.el))} startIcon={<AddCircleOutlineIcon />} variant='contained'>
          <Translate i18nKey={'pryaniky.calendar.eventList.addEvent'} />
        </Button>}
      </Box>
      <Box sx={{ flex: '1', marginLeft: '12px' }} className={cnEventsItem('Events')}>

        {items.map((id: string, idx: number) => <EventItem
          className={cnEventsItem('Events-SingleItem')}
          key={idx}
          id={id}
        // el={props.el} 
        />)}

      </Box>
    </Box>
  )
}


export const ListItem = connect(
  mapStateToProps,
  null
)(ListItemPresenter);