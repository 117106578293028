import * as React from 'react';
import * as utils from 'utils/src/utils';
// import { withBemMod } from '@bem-react/core';
import { cnWidget, cnWidgetMui } from '../Widget.index';
import { IWidgetTypeFiltersProps } from './Widget_type_filters.index';
import './Widget_type_filters.scss';
import { default as WidgetPresenter } from '../Widget';
import { widgets } from "i.widgets";
import { FiltersSOCOrgchartUnits } from 'blocks/Filters/_type/Filters_type_socogchartunits';
// import { FiltersTypeShopMui } from 'blocks/Filters/_type/Filters_type_shopMui/Filters_type_shopMui';

const types = {
  common: require('blocks/Filters/Filters').Filters,
  events: require('blocks/Filters/_type/Filters_type_events').FiltersEvents,
  polls: require('blocks/Filters/_type/Filters_type_polls').FiltersPolls,
  creativetasks: require('blocks/Filters/_type/Filters_type_creativetasks').FiltersCreativeTasks,
  ideas: require('blocks/Filters/_type/Filters_type_ideas').FiltersIdeas,
  shop: require('blocks/Filters/_type/Filters_type_shop').FiltersTypeShop,
  // shopMui: FiltersTypeShopMui,
  calendar: require('blocks/Filters/_type/Filters_type_calendar').FiltersCalendar,
  timeline: require('blocks/Filters/_type/Filters_type_timeline').FiltersTypeTimeline,
  // timelineH: require('blocks/Filters/_type/Filters_type_timeline_h').FiltersTypeTimelineH,
  leaderboard: require('blocks/Filters/_type/Filters_type_leaderboard').FiltersTypeLeaderboard,
  subnetworks: require('blocks/Filters/_type/Filters_type_subnetworks').FilterSubnetworks,
  wikilist: require('blocks/Filters/_type/Filters_type_wikilist').FiltersTypeWikilist,
  groups: require('blocks/Filters/_type/Filters_type_groups').FiltersTypeGroups,
  users: require('blocks/Filters/_type/Filters_type_users').FiltersTypeUsers,
  after35: require('blocks/Filters/_type/Filters_type_after35').FiltersTypeAfter35,
  questsmoderate: require('blocks/Filters/_type/Filters_type_questmoderate').FiltersTypeQuestModerate,
  quests: require('blocks/Filters/_type/Filters_type_quests').FiltersTypeQuests,
  tagcat: require('blocks/Filters/_type/Filters_type_tag_cat').FiltersTypeTagCat,
  workflows: require('blocks/Filters/_type/Filters_type_workflows').FiltersWorkflows,
  workflowsBar: require('blocks/Filters/_type/Filters_type_workflows_bar').FiltersWorkflowsBar,
  achievements: require('blocks/Filters/_type/Filters_type_achievements').FiltersTypeAchievements,
  socorgchartunits: FiltersSOCOrgchartUnits
}

const type = 'filters';

export default class WidgetTypeFiltersPresenter extends WidgetPresenter<IWidgetTypeFiltersProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    console.log("subtype", subtype)
    const Component = this.getComponent(types);
    return <Component {...this.props}
      // className={cnWidgetMui({ type, subtype })}
      className={subtype === "timeline" ? cnWidgetMui({ type, subtype }) : cnWidget({ type, subtype })}
      context={this.props.wcontext}
      relations={this.props.widget.relations}
      data={this.props.widget.data}
      tag={widgets.components.common} />
  }

}
