import * as React from 'react';
import { cnLayout } from 'blocks/Layout/Layout.index';
import urls from 'routes/urls';

interface IItem {
  page: string;
  components: Record<string, boolean>;
}

class PageComponents {
  private pages: IItem[] = [
    { page: "/login", components: { header: false, menu: false, projectHeader: false, background: true } },
    { page: "/forgot", components: { header: false, menu: false, projectHeader: false, background: true } },
    { page: "/resetpassword", components: { header: false, menu: false, projectHeader: false, background: true } },
    { page: "/logout", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/dash", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/news", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/employees", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/invite", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/user", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/groups", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/group", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/ratings", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/rating", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/leaderboard", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/messages", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/pages", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/tag", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/tags", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/dashboard", components: { header: true, menu: true, projectHeader: true, background: true } },
    // { page: '/go',
    //   components: {header: true, menu: true, projectHeader: false, background: true}},
    { page: "/search", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/badge", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/birthdays", components: { header: true, menu: true, projectHeader: true, background: true } },
    // { page: "/ideas", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/competitions", components: { header: true, menu: true, projectHeader: true, background: true } },
    {
      page: "/notifications/settings",
      components: { header: true, menu: true, projectHeader: true, background: true }
    },
    { page: "/ideas", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/shop", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/LMS", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/TeacherOffice", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/TrackManage", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/TracksManage", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/TrackView", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/LearningTracks", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/wv", components: { header: false, menu: false, projectHeader: false, background: false } },

    // start pages for integate to ...
    { page: "/widgets", components: { header: false, menu: false, projectHeader: false, background: false } },
    { page: "/integrate", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/wdialogs", components: { header: false, menu: false, projectHeader: false, background: false } },
    // end pages for integate to ...

    // { page: '/widgets', components: { header: true, menu: true, projectHeader: true, background: true } },
    // { page: '/page', components: { header: true, menu: true, projectHeader: false, background: true } },

    { page: "/wikiTP", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/test_code", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/quests/moderate", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/learningblock", components: { header: true, menu: true, projectHeader: false, background: true } },
    
    { page: "/achievements", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/events", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/polls", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/creativetasks", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/calendar", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/settings/design", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/settings", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/notices", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/wikilist", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/board", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/search", components: { header: true, menu: true, projectHeader: true, background: true } },
    { page: "/quiz", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/notifiSettings", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/404", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/500", components: { header: false, menu: false, projectHeader: false, background: true } },
    { page: "/welcome", components: { header: true, menu: false, projectHeader: false, background: true } },
    { page: "/quests", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/workflows", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/orgchart", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/secretsanta", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/statistics", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/survey", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/badges", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/vacancies", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/vacancy", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/widgets_v2", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/widgets_horizontal_v2", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/external", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/userNew", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: "/profileEdit", components: { header: true, menu: true, projectHeader: false, background: true } },
    { page: urls._appsDebug, components: { header: true, menu: true, projectHeader: false, background: true } },
    
  ];

  public getPagesRegExp(type: string) {
    let regExp = "";
    const multipleTypes = type.split("/");
    if (multipleTypes.length > 1) {
      this.pages.forEach(page => {
        let countTypeContains = 0;
        multipleTypes.forEach(typeItem => {
          if (page.components[typeItem]) countTypeContains += 1;
        });
        if (countTypeContains === multipleTypes.length) regExp += page.page + "|";
      });
    } else {
      this.pages.forEach(page => {
        if (page.components[type]) regExp += page.page + "|";
        if (type === "headerMenu" && page.components.header && page.components.menu) {
          regExp += page.page + "|";
        }
      });
    }
    return regExp.substring(0, regExp.length - 1);
  }

  public pathRenderer<TAdditionalProps = Record<string, any>, TProps = Record<string, any>>(
    Component: React.ComponentType<any> | any,
    additionalProps?: TAdditionalProps
  ) {
    return (props: TProps) => (
      <div className={cnLayout("Inner")}>
        <Component {...props} {...additionalProps} />
      </div>
    );
  }
}

export default new PageComponents();
