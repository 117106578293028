import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import i18n from 'localizations/i18n';
import { InputMention } from 'blocks/InputMention/InputMention';
import './Filters_type_statistic.scss';
import { mapStateToProps, IFiltersStateProps, mapDispatchToProps, IFiltersDispatchProps, IFiltersProps, cnStatisticFilters } from './Filters_type_statistic.index'
import { cnFilters } from '../Filters.index'

import Dropdown from 'uielements/src/MaterialElements/Dropdown/Dropdown';
import Suggest from 'uielements/src/MaterialElements/Autocomplete';
import DateRange from 'uielements/src/MaterialElements/DateRange/DateRange';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import Input from 'uielements/src/MaterialElements/Input/Input';
import { AchievementsSelector } from 'uielements/src/AchievementSelector/AchievementSelector';


const Presenter: React.FC<IFiltersProps> = ({ context, changeContextv3, edit, ...props }) => {

  const Tag = props.tag || 'div'
  const contextVariables = props.relations;
  const data = props.data;

  const [state, setState] = useState<any>({
    beginDate: null,
    endDate: null,
    achievementGoals: [],
    quests: [],
    quizzes: [],
    badges: [],
    workflows: [],
    postTypes: [],
    activityVirtualUsers: undefined,
    maxItems: undefined,
  });


  const onChange = (type: string, value: any) => {
    setState((state: any) => ({ ...state, [type]: value }));

    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = {
        [type]: value
      }
      return acc
    }, {} as { [s: string]: any }))
  }

  const onInputFocus = (filter: string) => {
    onChange('focusedFilter', filter);
  }

  const onInputBlur = () => {
    onChange('focusedFilter', undefined);
  }

  const onChangeDates = (dates: { startDate?: Date; endDate?: Date }) => {
    if (dates.startDate) onChange('beginDate', dates.startDate);
    if (dates.endDate) onChange('endDate', dates.endDate);
  }

  if (!data?.length || edit) return <Tag {...props} />

  return (
    <>
      {/* @ts-ignore */}
      <Tag tag='div' context={'statistic'} relations={[]} {...props} className={cnStatisticFilters({}, ['Widget'])} >
        {data && data.map((filter) => {
          switch (filter.type) {
            case 'dates': {
              return <DateRange
              startDate={state.beginDate}
              endDate={state.endDate}
              onChange={onChangeDates}
              onOpen={() => onInputFocus(filter.type)}
              onClose={onInputBlur}
            />
            }

            case 'achievementGoals': {
              return <AchievementsSelector
                goals={state[filter.type] as any[]}
                onChange={(value) => onChange(filter.type, value)}
                onFocus={() => onInputFocus(filter.type)}
                onBlur={onInputBlur}
              />
            }

            case 'quests':
            case 'quizzes':
            case 'badges':
            case 'workflows':
            case 'postTypes': {
              return <Suggest
                value={state[filter.type] || []}
                type={filter.type}
                multiple={true}
                onChange={(value: any) => onChange(filter.type, (filter.multiple ? value : value.length ? [value[value.length - 1]] : undefined))}
                label={i18n.t(`pryaniky.statistics.filter.${filter.type}`)}
                onFocus={() => onInputFocus(filter.type)}
                onBlur={onInputBlur}
              />
            }

            case 'maxItems': {
              return <Input
                value={state.maxItems}
                type='number'
                onChange={value => onChange('maxItems', value)}
                placeholder={i18n.t('pryaniky.statistics.filter.maxItems')}
                onFocus={() => onInputFocus(filter.type)}
                onBlur={onInputBlur}
              />
            }

            case 'activityVirtualUsers': {
              return <Checkbox
                value={state.activityVirtualUsers}
                onChange={value => onChange('activityVirtualUsers', value)}
                label={i18n.t('pryaniky.statistics.filter.activityVirtualUsers')}
                className={cnStatisticFilters('Last')}
                onFocus={() => onInputFocus(filter.type)}
                onBlur={onInputBlur}
              />
            }

            default:
              return <></>;
          }
        })
        }

      </Tag>
    </>
  )
}

export const FiltersStatistic = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps>(
  mapStateToProps,
  mapDispatchToProps
)(Presenter)
