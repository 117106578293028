import * as React from 'react';
import { IEditorTypeNewsProps } from './news.index';
import { UeventNews, defaultUeventNews } from '../../../EventsType'
import { EventsValidator, ueventValidate } from '../../../Events.validate'
import { baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor';
import * as utils from 'utils/src/utils';
import { EditorState } from 'draft-js';
import { makeDefaultStartTime } from '../../../Utils'
import { EditorTypeNewsShvabe } from '../../../EditorShvabe/containers/news/news'
const shvabe = true


export const createData = (date?: Date) => {
    const localData = { ...utils.cloneObject(defaultUeventNews), text: EditorState.createEmpty() };
    localData.uevent.startDateTime = date || makeDefaultStartTime();
    localData.expires = makeDefaultStartTime();
    return localData
}

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = shvabe ? EditorTypeNewsShvabe : ({ onChange: cbChange, data, types }) => {
    let localData = data as any;
    if (data.newstype !== 23) {
        localData = createData()
        // localData = { ...utils.cloneObject(defaultUeventNews), text: EditorState.createEmpty() };
        // localData.uevent.startDateTime = makeDefaultStartTime();
        // localData.expires = makeDefaultStartTime();

    }

    const onChange = (data: any) => {
        const vb = new EventsValidator();
        vb.addData(data)
        vb.setRules([...ueventValidate, ...baseValidate])
        cbChange({ ...data }, vb.validation().length === 0, vb.validation())
    }

    const onChangeTitle = (e: any) => {
        localData.header = e.target.value;
        localData.uevent.title = e.target.value;
        onChange(localData);
    }
    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const expiresChange = (date: Date) => {
        localData.uevent.startDateTime = date;
        localData.expires = date;
        onChange(localData);
    }
    const durationChange = (selected: any) => {
        localData.uevent.duration = selected.value;
        onChange(localData);
    }
    const onChangeLocation = (e: any) => {
        localData.uevent.eventLocation = e.target.value;
        onChange(localData);
    }
    const onChangeUsers = (users: any[]) => {
        localData.groups = users.filter((val: any) => val.type === 'group')
        localData.users = users.filter((val: any) => val.type !== 'group');
        onChange(localData)
    }

    const onChangeVideoConference = (online: boolean) => {
        localData.uevent.meeting = {
            online,
            domain: '',
            meetId: '',
            url: ''
        }
        onChange(localData)
    }

    return <Editor
        durationChange={durationChange}
        expiresChange={expiresChange}
        onChangeLocation={onChangeLocation}
        onChangeText={onChangeText}
        onChangeTitle={onChangeTitle}
        onChangeUsers={onChangeUsers}
        onChangeVideoConference={onChangeVideoConference}
        allowCreateVideoConference={types.eventRenderParams.allowCreateVideoConference}
        data={localData} />
}
