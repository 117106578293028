import React, { useState } from 'react'
import { KeyboardArrowDown, Settings, TagFaces, ArrowRight, ConnectWithoutContact, ExitToApp } from 'muicomponents/src/Icons';
import { ListItemIcon } from 'muicomponents/src/ListItem/ListItem';
import { Menu } from 'muicomponents/src/Menu';
import { AvatarButton, AvatarImage, AvatarImageBox, IconMui, CircleIndicator } from '../Header.styled';
import { MenuItem } from './UserMenu.styled';
import { UserStatusItem } from './UserMenuStatusItem/UserStatusItem';
import { Translate } from 'localization';
import { UserNestedMenu } from './UserNestedMenu/UserNestedMenu';
import { VirtUserSelector } from 'blocks/VirtUserSelector/VirtUserSelector';
import { NetworkSelector } from 'blocks/NetworkSelector/NetworkSelector';
import { IUserMenuProps } from './UserMenu.index';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

// const userStatus = ['В сети', 'Не беспокоить', 'Отсутствую'];

export const UserMenu: React.FC<IUserMenuProps> = ({user, nameNetwork}) => {

  const networks = useSelector((state) => getAuthUser(state).baseData.networks);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [activeStatus, setActiveStatus] = useState<number>(0);
  const history = useHistory();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <AvatarButton onClick={handleClick}>
        <AvatarImageBox>
          <AvatarImage src={user.baseData.userPhotoUrl} />
          {/* Будущий функционал с выбором статуса профиля */}
          {/* <CircleIndicator sx={{bacgroundColor: '#4CAF50'}} /> */}
        </AvatarImageBox>
        <IconMui as={KeyboardArrowDown} />
      </AvatarButton>
      
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          sx: {
            width: 262,
            top: '66px !important'
          }
        }}
      >
        <MenuItem onClick={() => {
          handleClose();
          history.push(`/user/${user.extData.userId}`);
        }}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <Translate i18nKey={'pryaniky.header.menu.item.settings'} />
        </MenuItem>

        <UserNestedMenu
          title={<Translate i18nKey={'pryaniky.header.menu.item.changeUser'}/>}
          text={user.baseData.displayName} 
          icon={<TagFaces />}
        >
          <VirtUserSelector />
        </UserNestedMenu>

        {
          networks.length > 1 &&
          <UserNestedMenu 
            title={<Translate i18nKey={'pryaniky.header.menu.item.changeNetwok'}/>} 
            text={nameNetwork} 
            icon={<ConnectWithoutContact />}
          >
            <NetworkSelector />
          </UserNestedMenu>
        }

        {/* Будущий функционал с выбором статуса профиля */}
        {/* {
          userStatus.map((status, index) => 
            <UserStatusItem 
              title={status} 
              index={index} 
              key={index} 
              setActive={setActiveStatus} 
              active={activeStatus} 
            />)
        } */}

        <MenuItem onClick={() => history.push('/logout')}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <Translate i18nKey={'pryaniky.header.menu.item.logout'} />
        </MenuItem>

      </Menu>
    </>
  )
}