/**
 * @packageDocumentation
 * @module Filter_type_groups
 */
import React from 'react';
import { FiltersPresenter } from '../Filters';
import i18n from "../../../localizations/i18n";
import { IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, mapStateToProps, mapDispatchToProps, cnFilters } from '../Filters.index';
import { IStateType as IState } from 'redux/store';
import { connect } from 'react-redux';
import { Selectize } from 'uielements/src';
import { userRoles } from 'utils/src/constants.prn';
import { Translate } from 'localizations/Translate';
import { withRouter } from 'react-router';

const usersRole = (value?: string) => ({
  type: 'usersRole',
  value
})

/**
 * dv - default values of this filters if dv[key] === 'undefined' default is 'all'
 */
const dv: Record<string, string> = {
  groupsOrder: ''
}

/**
 * h - filters header object
 */
const h: Record<string, string> = {
  'usersCatFilter/usersRole/userBlock': 'pryaniky.filter.users.title',
  'usersCatFilter/usersRole/userBlock/arfilter': 'pryaniky.filter.users.accessrequests.title',
  'usersCatFilter/usersRole/userBlock/arfilter/shopAccess': 'pryaniky.filter.users.shopaccess.title'
}

// accessrequests filters
// export type IRegistrationsFilters = 'All' | 'New' | 'WatingMailApprove' | 'Approved' | 'Canceled';

const usrsFilterClear = ['arfilter', 'usersCatFilter/usersRole/userBlock/arfilter'];
const usrsShopFilterClear = [...'usersRole/userBlock'.split('/'), 'usersCatFilter/usersRole/userBlock'];
const usrsREgistrationsFilterClear = [...'usersRole/userBlock'.split('/'), 'usersCatFilter/usersRole/userBlock'];

/**
 * f - data to this filters
 */
const f: any[][] = [ // all filters
  [
    { id: 'all', title: 'all', type: 'usersCatFilter/usersRole/userBlock', value: '//', clear: usrsFilterClear },
    { id: 'notinitialized', title: 'notinitialized', type: 'usersCatFilter/usersRole/userBlock', value: 'notinitialized', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) }, clear: usrsFilterClear },
    { id: 'initialized', title: 'initialized', type: 'usersCatFilter/usersRole/userBlock', value: 'initialized', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) }, clear: usrsFilterClear },
    { id: 'blocked', title: 'blocked', type: 'usersCatFilter/usersRole/userBlock', value: 'userBlock//on', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) }, clear: usrsFilterClear },
    { id: 'administrators', title: 'administrators', type: 'usersCatFilter/usersRole/userBlock', value: 'role/SiteAdmin', clear: usrsFilterClear }, // roles
    { id: 'premoderated', title: 'premoderated', type: 'usersCatFilter/usersRole/userBlock', value: 'premoderated', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) }, clear: usrsFilterClear },
    { id: 'guest', title: 'guest', type: 'usersCatFilter/usersRole/userBlock', value: 'role/Guest', showProps: { networks: (nets: Record<string, any>[]) => nets.length > 1 }, clear: usrsFilterClear },// roles
    { id: 'user', title: 'user', type: 'usersCatFilter/usersRole/userBlock', value: 'role/Employee', showProps: { networks: (nets: Record<string, any>[]) => nets.length > 1 }, clear: usrsFilterClear },// roles
    { id: 'notAllowedToNetwork', title: 'notAllowedToNetwork', type: 'usersCatFilter/usersRole/userBlock', value: 'role/notAllowedToNetwork', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin), networks: (nets: Record<string, any>[]) => nets.length > 1 }, clear: usrsFilterClear },// roles
  ],
  [
    { id: 'accessrequests/All', title: 'accessrequests.All', type: 'usersCatFilter/usersRole/userBlock/arfilter', value: 'accessrequests///All', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin), enableAutoRegistration: true }, clear: usrsREgistrationsFilterClear },
    { id: 'accessrequests/New', title: 'accessrequests.New', type: 'usersCatFilter/usersRole/userBlock/arfilter', value: 'accessrequests///New', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin), enableAutoRegistration: true }, clear: usrsREgistrationsFilterClear },
    { id: 'accessrequests/WatingMailApprove', title: 'accessrequests.WatingMailApprove', type: 'usersCatFilter/usersRole/userBlock/arfilter', value: 'accessrequests///WatingMailApprove', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin), enableAutoRegistration: true }, clear: usrsREgistrationsFilterClear },
    { id: 'accessrequests/Approved', title: 'accessrequests.Approved', type: 'usersCatFilter/usersRole/userBlock/arfilter', value: 'accessrequests///Approved', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin), enableAutoRegistration: true }, clear: usrsREgistrationsFilterClear },
    { id: 'accessrequests/Canceled', title: 'accessrequests.Canceled', type: 'usersCatFilter/usersRole/userBlock/arfilter', value: 'accessrequests///Canceled', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin), enableAutoRegistration: true }, clear: usrsREgistrationsFilterClear }
  ],
  [
    { id: 'on', title: 'shopaccess.on', type: 'usersCatFilter/usersRole/userBlock/arfilter/shopAccess', value: 'shopaccess////true',showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin)}, clear: usrsShopFilterClear },
    { id: 'off', title: 'shopaccess.off', type: 'usersCatFilter/usersRole/userBlock/arfilter/shopAccess', value: 'shopaccess////false', showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) }, clear: usrsShopFilterClear },

  ],
]

class FiltersTypeUsersPresenter extends FiltersPresenter {
  public defaulValues = {
    'usersCatFilter/usersRole/userBlock': '//'
  };

  /**
   * prepare filters to render data like {}[][]
   */
  public data = f.map(fl => fl.map(el => ({
    id: el.id,
    type: el.type || 'usersCatFilter/usersRole/userBlock',
    title: i18n.t(`pryaniky.filter.users.${el.title}`),
    value: el.value,
    showProps: el.showProps,

    clear: el.clear,
  })));

  public getDataFromContext = (type: string) => {

    if (type === 'roles') {
      return this.props.context[type] || this.props.authUser.baseData.roles || [];
    }
    if (type === 'networks') {
      return this.props.authUser.baseData.networks;
    }
    return this.props.context[type];
  }

  /**
   * render - it is render)
   */
  public render() {
    const { tag: Tag = 'div', context, className, itemsView, authUser } = this.props;
    return (
      <Tag tag='div' {...this.props} className={cnFilters({}, [className])} >
        {
          this.data.map((fl, idx) => {
            const type = fl[0] && fl[0].type;
            // if (active === 'all' && dv[type] !== undefined) active = dv[type];
            const oldData = fl;
            fl = fl.filter(i => Object.keys(i.showProps || {}).reduce((a, k, idx) => {
              if (!a) return a;
              if (typeof (i.showProps[k]) === 'function') return i.showProps[k](this.getDataFromContext(k));
              if (i.showProps[k] !== itemsView[k as keyof typeof itemsView]) return false
              return a;
            }, true as boolean));

            let active = this.getActive(idx);
            if (active && !fl.map(e => e.id).includes(active) && !fl.map(e => e.value).includes(active)) {
              const defObj = fl.reduce((a: any, c) => a || ((this.defaulValues as any)[c.type] === c.value ? c : a), undefined);
              if (defObj) this.handleChange(defObj);
            }
            if (!fl.length) return null;
            // this.data[idx] = fl;
            // active = this.getActive(idx);
            // this.data[idx] = oldData;
            return <div className={cnFilters('Block')} >
              {type && h[type] && <h4 className={cnFilters('Subtitle')}>
                <Translate i18nKey={h[type]} />:
              </h4>}
              <Selectize onClick={this.handleChange} elements={fl} active={active} />
            </div>
          })
        }
      </Tag>
    )
  }
}


/**
 * connected to redux
 */
export const FiltersTypeUsers = withRouter(
  connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, IState>(
    mapStateToProps,
    mapDispatchToProps
  )(FiltersTypeUsersPresenter)
);