import React, { useMemo, useState } from 'react';
import {
    // DialogActions,
    CheckboxInput,
    Input,
    Button as PrnButton,
    ErrorMsg,
    Loading,
    Dropdown
} from 'uielements/src';
import i18n from '../../../../localizations/i18n';
import { toast } from 'react-toastify';
import { InputMention } from '../../../InputMention/InputMention';
import "./WikiEditNew.scss";
import { wrapDialogToRedux } from "../../DialogWrapper";
import { connect } from "react-redux";
import { ImgLoader } from '../../../ImgLoader/ImgLoader';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import {
    IWikiEditOwnProps,
    IWikiEditProps,
    cnWikiEditDialog,
    mapWikiEditDispatchToProps,
    mapWikiEditStateToProps,
    IWikiEditDispatchProps,
    IWikiEditStateProps,
} from './WikiEditNew.index'
import { mapDispatchToProps, IDispatchProps } from "redux/connector";
import { IStateType as IState } from 'redux/store';
import InputMaterial from 'uielements/src/MaterialElements/Input/Input';
import { Translate } from '../../../../localizations/Translate';
import { HelpTooltip, Icon } from 'uielements/src/index';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle'
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent'
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions'
import { TextError } from 'uielements/src/TextError/TextError';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { useFileUploader } from 'utils/src/hooks'
import { styled } from 'muicomponents/src/mui/system';
import { UsersSuggester, TagsSuggester, PageCollectionSuggester } from 'muicomponents/src/Suggester';
import { TextField } from 'muicomponents/src/TextField'
import { BaseSuggester } from 'muicomponents/src/Suggester';
import {
    Divider,
    Box,
    FormControlLabel,
    Checkbox,
    Button,
    FilesUploader,
} from 'muicomponents/src';
import HelpIcon from '@mui/icons-material/Help';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'utils/src/hooks';
import { ImageLoader } from 'blocks/ImageLoaderWithMui/ImageLoader';
import { PreviewSelector } from 'muicomponents/src/PreviewSelector/PreviewSelector'
import { ImageCropperDialog } from 'blocks/ImageLoaderWithMui/ImageCropper/ImageCropper'
import { CheckButtonSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm'
import { TOption } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/ListParamsSelector.index';
import { SelectableObject } from 'utils/src/BaseTypes/selectableObject.types';
import { Select } from 'muicomponents/src/Select/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from 'muicomponents/src/FormControl/FormControl'
import { getPageBaseType, getWikiUrl } from 'blocks/WikiListNew/utils/Wikilist.utils';
import { PageTypes } from 'utils/src';
import { cloneDeep } from 'lodash';

const StyledFormControlLabel = styled(FormControlLabel)({
    marginLeft: 0,
    marginRight: 0,
    width: '100%'

});

let lastName = '';
let id = '';

const statusOption = [
    { value: 'published', title: Translate.t({ i18nKey: 'pryaniky.wikilist.published' }), },
    { value: 'isDraft', title: Translate.t({ i18nKey: 'pryaniky.wikilist.isDraft' }) },
    { value: 'archived', title: i18n.t('pryaniky.wikilist.isArchived'), },
];

export const WikiEditDialogPresenter: React.FC<IWikiEditProps> = ({
    data: pdata,
    context,
    isShown,
    onClose,
    onConfirm,
    onChange = () => { },
    id,
    handleAccept,
    handleClose,
    updateItemList,
    isAdmin,
    parentFolderModerators,
    ...props
}) => {

    const history = useHistory();


    const [loading, setLoading] = React.useState(false);
    const [actualData1, setActualData1] = React.useState<typeof pdata | null>(null);
    const [isInheritingRootRights, setIsInheritingRootRights] = React.useState(false);
    const [moderators, setModerators] = React.useState<any[]>([]);
    const [urlBeforChange, setUrlBeforChange] = React.useState<string>("");
    const [error, setError] = React.useState(false);
    const [imgChanged, setImgChanged] = React.useState(false);

    const pageType = getPageBaseType(actualData1?.type);

    const typePage = pageType === PageTypes.page;
    const typeWikilist = pageType === PageTypes.wikilist;
    const typeUrl = pageType === PageTypes.url;
    const typeFile = pageType === PageTypes.file;

    React.useEffect(() => {
        getActualData(id);
    }, []);

    const {
        isLoading,
        files,
        onFileChange,
        removeFile,
        dndRef,
        dragEntered,
        setFiles,
        dndTriggerRef,
        uploadError,
        uploadDisabled
    } = useFileUploader({
        // defaultAttachments,
        uploadOnAdd: true,
        maxFilesCount: 1
    })
    React.useEffect(() => {
        if (actualData1?.file) {
            setFiles([actualData1?.file])
        }
    }, [actualData1?.file])

    React.useEffect(() => {
        if (actualData1 && (files.length >= 1) && files[0].isUploaded) {
            setActualData1({ ...actualData1, file: files[0].response?.data[0] })
            if (actualData1.title === '' || !actualData1.title) {
                setActualData1({ ...actualData1, title: files[0].name, url: files[0].name, file: files[0].response?.data[0] })
            }
        }
    }, [files])


    const handleCancel = () => {
        handleClose();
    };

    const getActualData = (id: string) => {
        return (
            utils.API.pages.getByIdSkipLayout(id, { collection: context.collectionAlias ? context.collectionAlias : "dash" })
                .r
                .then((response) => {
                    if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                    else if (response.error_code === 0) {
                        if(!response.data) return ;

                        const pageType = getPageBaseType(response.data.type);

                        const typePage = pageType === PageTypes.page;
                        const typeWikilist = pageType === PageTypes.wikilist;
                        const typeUrl = pageType === PageTypes.url;
                        const typeFile = pageType === PageTypes.file;

                        setIsInheritingRootRights(response.data.isInheritingRootRights);

                        setModerators([...response.data.moderators]);

                        setActualData1({
                            ...response.data,
                        });

                        const preparedUrl = typePage
                            ? (
                                response.data.url.indexOf("/pages/") !== -1
                                ? response.data.url.replace("/pages/", "")
                                : response.data.url.replace("pages/", "")
                            )
                            : (
                                response.data.url.indexOf("/wikilist/") !== -1
                                ? response.data.url.replace("/wikilist/", "")
                                : response.data.url.replace("wikilist/", "")
                            );


                        setUrlBeforChange(preparedUrl);
                        
                        if(typePage || typeWikilist) {
                            setActualData1({
                                ...response.data,
                                url: preparedUrl
                            });
                        }
                    }
                }
                )
        )

    }



    const deleteWikiPage = () => {
        utilsProj.confirm({
            text: i18n.t("pryaniky.modal.wikipage.confirm.delete"),
            onConfirm: () =>

                utils.API.pages.deletePage(id)
                    .r
                    .then((response) => {
                        if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                        else if (response.error_code === 0) {
                            toast.success(i18n.t("pryaniky.modal.wikipage.deleted"));
                        }
                    })
        });
        handleClose();
    }

    const sendDataForEditPage = (isArchived?: boolean) => {
        if (actualData1) {
            setLoading(true);
            // const pageUrl = actualData1.url.indexOf("/pages/") === -1 || actualData1.url.indexOf("pages/") === -1 ? "/pages/" + actualData1.url : actualData1.url;
            // const wikilistUrl = "/wikilist/" + actualData1.url;
            // const absoluteUrl = actualData1.url;

            let link = getWikiUrl(actualData1 as any);

            utilsProj.confirm({
                text: i18n.t("pryaniky.modal.wikipage.confirm.edit"),

                onConfirm: () => {

                    return utils.API.pages.setSettings({
                        ...actualData1,
                        collectionAlias: context.collectionAlias ? context.collectionAlias : "dash",
                        id: actualData1.id,
                        url: link,
                        title: actualData1.title,
                        moderators: (isInheritingRootRights && parentFolderModerators) ? [] : moderators,
                        tags: actualData1.tags,
                        collections: actualData1.collections,
                        type: actualData1.type || PageTypes.page,
                        titleImageId: actualData1.titleImageId,
                        hasCustomTitleImage: actualData1.titleImageId === null || actualData1.titleImageId === undefined || actualData1.titleImageId === "" ? false : true,
                        titleImageUrl: actualData1.titleImageUrl,
                        showInMainMenu: actualData1.showInMainMenu,
                        isInheritingRootRights: actualData1.collections?.find((el: any) => el.alias === "dash") ? false : isInheritingRootRights,
                        allowComments: actualData1.allowComments,
                        defaultViewType: actualData1.defaultViewType ? actualData1.defaultViewType : 0,
                        isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived,
                        positionInCollection: Number(actualData1.positionInCollection),
                        viewers: actualData1.viewers,
                        file: actualData1.file,
                        isDraft: actualData1.isDraft

                    })
                        .r
                        .then((response) => {
                            if (!response) {
                                toast.error(i18n.t('pryaniky.toast.error.server'));
                            }
                            if (response.error_code !== 0) {
                                toast.error(response.error_text);
                                setLoading(false);
                            }
                            else if (response.error_code === 0) {
                                toast.success(i18n.t("pryaniky.modal.wikipage.sucсess"));

                                setActualData1({ ...actualData1, isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived })
                                if (actualData1) {
                                    updateItemList(pdata, {
                                        ...actualData1,
                                        collectionAlias: context.collectionAlias ? context.collectionAlias : "dash",
                                        id: actualData1.id,
                                        url: link,
                                        title: actualData1.title,
                                        moderators: actualData1.moderators,
                                        tags: actualData1.tags,
                                        collections: actualData1.collections,
                                        type: actualData1.type || PageTypes.page,
                                        titleImageId: actualData1.titleImageId,
                                        hasCustomTitleImage: actualData1.titleImageId === null || actualData1.titleImageId === undefined || actualData1.titleImageId === "" ? false : true,
                                        titleImageUrl: actualData1.titleImageUrl,
                                        showInMainMenu: actualData1.showInMainMenu,
                                        allowComments: actualData1.allowComments,
                                        defaultViewType: actualData1.defaultViewType ? actualData1.defaultViewType : 0,
                                        isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived,
                                        positionInCollection: Number(actualData1.positionInCollection),
                                        viewers: actualData1.viewers,
                                        file: actualData1.file
                                    });
                                }
                                if (history.location.pathname.includes("/pages") && pdata?.url !== link && (actualData1.type === PageTypes.page || actualData1.type === PageTypes.file || !actualData1.type)) {
                                    history.push(`/${link}`);
                                }
                                handleClose();
                            }
                        });

                },
                onCancel: () => {
                    setLoading(false)
                },
            });
        }

    }
    const onChangeShowInMainMenu = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, showInMainMenu: !actualData1.showInMainMenu })
    }


    const onChangeShowCategoryAsSelector = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, showCategoryAsSelector: !actualData1.showCategoryAsSelector })
    }

    const onChangeAllowComments = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, allowComments: !actualData1.allowComments })
    }

    const onChangeHideFooter = () => {
        if (actualData1) setActualData1({ ...actualData1, isHideFooter: !actualData1.isHideFooter })
    };

    const archivedPage = () => {
        if (actualData1) sendDataForEditPage(!actualData1.isArchived)
    }

    const deleteOptions = [
        {
            title: i18n.t("pryaniky.modal.wikipage.delete"),
            buttonProps: {
                onClick: deleteWikiPage,
            },
        },
        {
            title: i18n.t(`pryaniky.modal.wikipage.${actualData1 && actualData1.isArchived ? 'unarchive' : 'archive'}`),
            buttonProps: {
                onClick: archivedPage,
            },
        },
    ]
    const Addorment = (typePage || typeFile) ? <p className={cnWikiEditDialog("child")}>/pages/</p> : (typePage || typeWikilist) ? <p className={cnWikiEditDialog("child")}>/wikilist/</p> : (typeUrl) && undefined

    const onChangeLinkInput = (e: any) => {
        const pageUrl = "pages/" + e.currentTarget.value;
        const wikilistUrl = "wikilist/" + e.currentTarget.value;
        (!typeUrl && urlBeforChange !== e.currentTarget.value) && onChangeLinkInputUseDebounce(typePage ? pageUrl : wikilistUrl);
        actualData1 && setActualData1({ ...actualData1, url: e.currentTarget.value })
    }

    const checkUrlIsExist = (value: string) => {
        utils.API.pages.isExists(value)
            .r
            .then((response) => {
                if (!response) {

                    toast.error(i18n.t('pryaniky.toast.error.server'));
                } else if (response.data === true) {
                    setError(true)
                    toast.error(i18n.t('pryaniky.modal.wikipage.create.url.is.exist'))
                } else if (response.data === false) {
                    setError(false)
                }
            })
    }

    const onChangeLinkInputUseDebounce = useDebounce(checkUrlIsExist, 1000, []);



    const onChangeIsInheritingRootRights = (e: any) => {
        setIsInheritingRootRights(!isInheritingRootRights)
        setModerators(!isInheritingRootRights && parentFolderModerators ? [...parentFolderModerators] : moderators)
    }

    const StatusSelect = useMemo(() => {
        if (actualData1) {
            return <FormControl className={cnWikiEditDialog("Content-ItemSelect")} sx={{margin: "8px 0 0 0", width:"100%"}}>
                <InputLabel id="demo-simple-select-helper-label">{Translate.t({ i18nKey: 'pryaniky.wikilist.status' })}</InputLabel>

                <Select
                    labelId="demo-simple-select-helper-label"
                    options={statusOption}
                    label={Translate.t({ i18nKey: 'pryaniky.wikilist.status' })}
                    value={statusOption.find((el) => actualData1.isDraft ? el.value === "isDraft" : actualData1.isArchived ? el.value === "archived" : el.value === "published")?.value}
                    onChange={(event) => setActualData1({ ...actualData1, isDraft: event.target.value === "isDraft", isArchived: event.target.value === "archived" })}
                />
            </FormControl>
        }
    }, [actualData1, actualData1?.isArchived, actualData1?.isDraft])
    return (
        <>
            <DialogTitle onClose={handleClose}>
                {typePage ? i18n.t("pryaniky.modal.wikipage.edit.type.page.new") : typeWikilist ?
                    i18n.t("pryaniky.modal.wikipage.edit.type.wikilist.new") : typeUrl ?
                        i18n.t("pryaniky.modal.wikipage.edit.type.url.new") : i18n.t("pryaniky.modal.wikipage.edit.type.file.new")}
            </DialogTitle>
            {actualData1 ? <DialogContent className={cnWikiEditDialog("")}>
                <Box className={cnWikiEditDialog("Content-Item")}>
                    <TextField
                        name="title"
                        fullWidth
                        // variant='outlined'
                        value={actualData1.title}
                        autoFocus
                        className={cnWikiEditDialog("Input")}
                        label={typePage ? i18n.t("pryaniky.modal.wikipage.page.name") : typeWikilist ? i18n.t("pryaniky.modal.wikipage.page.name.wikilist") : typeUrl ? i18n.t("pryaniky.modal.wikipage.page.name.url") : i18n.t("pryaniky.modal.wikipage.page.name.file")}
                        onChange={(e: any) => setActualData1({ ...actualData1, title: e.currentTarget.value })} />
                </Box>

                {typePage && StatusSelect}
                 <Box className={cnWikiEditDialog("Content-Item")}>
                    <TextField
                        name="description"
                        fullWidth
                        multiline
                        variant='outlined'
                        value={actualData1.description}
                        className={cnWikiEditDialog("Input")}
                        sx={{
                            ".MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline": {
                                minHeight: "41px"
                            }
                        }}
                        label={i18n.t("pryaniky.modal.wikipage.page.description")}
                        onChange={(e: any) => {
                            setActualData1({ ...actualData1, description: e.currentTarget.value })
                        }} />
                </Box>
                <Box className={cnWikiEditDialog("Content-Item")}>
                    {
                        !typeFile && <TextField
                            id="wikilink"
                            name="url"
                            fullWidth
                            // variant='outlined'
                            value={actualData1.url}
                            // style={{ marginLeft: (ddata.type === "url" || typeUrl) ? "0px" : "47px" }}
                            className={cnWikiEditDialog(error ? "ErrorLink" : "InputLink")}
                            autoFocus={error}
                            error={error || actualData1.url === ""}
                            helperText={error ? i18n.t('pryaniky.modal.wikipage.create.url.is.exist') :
                                actualData1.url === "" ? i18n.t('pryaniky.modal.wikipage.disabled.btn.help') : ""}
                            label={typePage ? i18n.t("pryaniky.modal.wikipage.page.url") : typeWikilist ? i18n.t("pryaniky.modal.wikipage.page.url.wikilist") : typeUrl && i18n.t("pryaniky.modal.wikipage.page.url.url")}

                            onChange={(e: any) => onChangeLinkInput(e)}
                            InputProps={{
                                startAdornment: Addorment

                            }}
                        />

                    }

                </Box>
                <Box className={cnWikiEditDialog("Content-Item")}>

                    {
                        typeFile && <Box
                            ref={dndTriggerRef}
                            sx={{ position: 'relative' }}
                        >
                            {/* <FileDropZone ref={dndRef} show={dragEntered}  show={true} /> */}
                            <FilesUploader
                                files={files}
                                multiple={false}
                                showProgressOnNewFiles
                                selectButtonProps={{
                                    children: i18n.t("pryaniky.modal.wikipage.page.selectFile"),
                                    variant: 'outlined'
                                }}
                                error={Boolean(uploadError)}
                                helperText={uploadError?.error?.error_text}
                                disabled={uploadDisabled}
                                // className={cnWikiEditDialog("FileInput")}
                                // inputAccept="image/jpeg,image/png"
                                onFileChange={onFileChange}
                                removeFile={removeFile}
                            />
                        </Box>
                    }
                    {uploadError?.error && <TextError data={i18n.t('pryaniky.modal.wikipage.disabled.btn.help')} />}
                </Box>

                {context.collectionAlias !== "dash" && isAdmin && <StyledFormControlLabel control={<Checkbox

                    // sx={{ padding: "9px 9px 9px 0" }}
                    className={cnWikiEditDialog("CheckboxInput")}
                    checked={isInheritingRootRights}
                    onChange={onChangeIsInheritingRootRights} />}
                    label={i18n.t("pryaniky.modal.wikipage.isInheritingRootRights")} />}

                <Box className={cnWikiEditDialog("Content-Item")}>
                    <UsersSuggester
                        disabled={!isAdmin ? true : (isAdmin && isInheritingRootRights) ? true : false}
                        TextFieldProps={{
                            label: i18n.t("pryaniky.modal.wikipage.choose.moderator"),
                        }}

                        value={moderators}
                        multiple
                        onChange={(_, value) => {
                            setModerators([...value as any])
                        }}
                        requestAdditionalParams={{ excludeMe: false, groupid: pdata.gId }}
                    />

                </Box>
                <Box className={cnWikiEditDialog("Content-Item")}>
                    <TagsSuggester
                        TextFieldProps={{
                            label: i18n.t("pryaniky.modal.wikipage.choose.tag"),
                        }}
                        multiple
                        onChange={(_, value) => {
                            setActualData1({ ...actualData1, tags: value as any })
                        }}
                        className={cnWikiEditDialog("InputFolder-Folder")}
                        value={actualData1.tags}


                    // {i18n.t('pryaniky.shop.selectFor')}
                    />
                </Box>
                <Box className={cnWikiEditDialog("InputFolder")}>
                    <PageCollectionSuggester
                        TextFieldProps={{
                            label: i18n.t("pryaniky.modal.wikipage.choose.pagecollections"),
                        }}

                        onChange={(event, value) => {
                            if (value) {
                                setActualData1({ ...actualData1, collections: [value] as any })
                            } else {
                                setActualData1({ ...actualData1, collections: [] })
                            }
                        }}
                        className={cnWikiEditDialog("InputFolder-Folder")}
                        value={actualData1?.collections?.length > 0 ? actualData1?.collections?.map((el: any) => ({
                            displayName: el?.name,
                            ...el
                        })) : null}

                    />
                    {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.choose.pagecollections.help')}> */}
                    <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.pagecollections.help')} >
                        <span><HelpIcon sx={{ marginLeft: "10px" }} color="action" /></span>
                    </Tooltip>
                </Box>
                <Box className={cnWikiEditDialog("InputFolder")}>
                    <UsersSuggester
                        TextFieldProps={{
                            label: typePage || pdata.type === "" ?
                                i18n.t('pryaniky.modal.wikipage.page.viewers') : typeWikilist ?
                                    i18n.t('pryaniky.modal.wikipage.folder.viewers') : i18n.t('pryaniky.modal.wikipage.link.viewers'),
                        }}
                        value={actualData1.viewers || []}
                        className={cnWikiEditDialog("InputFolder-Folder")}
                        multiple
                        requestAdditionalParams={{ excludeMe: false }}
                        onChange={(_, viewers) => {
                            setActualData1({ ...actualData1, viewers: viewers as any })
                        }}
                        isAdmin

                    />

                    <Tooltip title={i18n.t('pryaniky.modal.wikipage.viewers.help')} >

                        <span><HelpIcon sx={{ marginLeft: "10px" }} color="action" /></span>
                    </Tooltip>
                </Box>

                {typeWikilist && <Box className={cnWikiEditDialog("ViewType")}>
                    <h4><Translate i18nKey={'pryaniky.news.view.type.title.default'} /></h4>
                    <Box>

                        <Button variant={actualData1.defaultViewType === 0 ? "contained" : "outlined"} onClick={() => {
                            setActualData1({ ...actualData1, defaultViewType: 0 })
                        }}>
                            <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.full.help')} >

                                <span><Icon icon={'wiki'}></Icon></span>
                            </Tooltip>
                        </Button>

                        <Button variant={actualData1.defaultViewType === 1 ? "contained" : "outlined"} onClick={() => {
                            setActualData1({ ...actualData1, defaultViewType: 1 })
                        }}>
                            <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.short.help')} >
                                <span> <Icon icon={'bars'}></Icon></span>
                            </Tooltip>
                        </Button>

                    </Box>
                </Box>}
                <Divider variant="inset" sx={{ margin: '14px 0' }} />

                <Box className={cnWikiEditDialog("PositionInCollection")}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: "16px 0",
                        "input::-webkit-outer-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0
                        },
                        "input::-webkit-inner-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0
                        }
                    }}>

                    < TextField
                        className={cnWikiEditDialog("PositionInCollection")}
                        inputProps={{ inputMode: 'numeric' }}
                        suggesterValue={false}
                        label={i18n.t('pryaniky.modal.wikipage.page.positionInCollection')}
                        // style={{ maxWidth: "300px" }}
                        variant='outlined'
                        value={actualData1.positionInCollection ? actualData1.positionInCollection.toString() : ""}
                        onChange={(e) => {
                            if (e.currentTarget.value.match(/[^0-9]/)) {
                                e.preventDefault();
                            } else {
                                setActualData1({ ...actualData1, positionInCollection: e.currentTarget.value })
                            }
                        }}
                    />

                </Box>

                <StyledFormControlLabel control={<Checkbox

                    // sx={{ padding: "9px 9px 9px 0" }}
                    className={cnWikiEditDialog("CheckboxInput")}
                    checked={actualData1.showInMainMenu}
                    onChange={onChangeShowInMainMenu} />}
                    label={i18n.t("pryaniky.modal.wikipage.showInMainMenu")} />

                {(typePage || typeFile) && <StyledFormControlLabel control={<Checkbox
                    className={cnWikiEditDialog("CheckboxInput")}
                    checked={actualData1.allowComments}
                    onChange={onChangeAllowComments} />}
                    label={i18n.t("pryaniky.modal.wikipage.allowComments")} />}
                    

                {typeWikilist &&
                    <StyledFormControlLabel control={<Checkbox
                        className={cnWikiEditDialog("CheckboxInput")}
                        checked={actualData1.showCategoryAsSelector}
                        onChange={onChangeShowCategoryAsSelector} />}
                        label={i18n.t("pryaniky.modal.wikipage.showCategoryAsSelector")} />
                }

                <StyledFormControlLabel control={<Checkbox

                    // sx={{ padding: "9px 9px 9px 0" }}
                    className={cnWikiEditDialog("CheckboxInput")}
                    checked={actualData1.isHideFooter}
                    onChange={onChangeHideFooter} />}
                    label={i18n.t("pryaniky.modal.wikipage.isHideFooter")} />

                <Divider variant="inset" sx={{ margin: '14px 0' }} />

                <PreviewSelector
                    {...props}
                    // aria-describedby={actualData1.id}
                    className={cnWikiEditDialog({}, [props?.className])}
                    onClick={(e: any) => {

                        ImageCropperDialog({
                            // editOpen: isEdit,
                            onLoaded: (file: any) => {
                                setActualData1({ ...actualData1, titleImageId: file.id, titleImageUrl: file.originalUrl })
                                setImgChanged(true)
                            }
                        })

                    }}
                    previewUrl={actualData1.titleImageUrl}
                    newImg={imgChanged}
                    label={typePage ? i18n.t("pryaniky.modal.wikipage.cover") : typeWikilist ?
                        i18n.t("pryaniky.modal.wikipage.cover.wikilist") : typeUrl ?
                            i18n.t("pryaniky.modal.wikipage.cover.url") : i18n.t("pryaniky.modal.wikipage.cover.file")}
                    helperText={i18n.t("pryaniky.modal.wikipage.cover.size")}
                />

            </DialogContent> :
                <DialogContent>
                    <div className={cnWikiEditDialog("Loading")}>
                        <Loading text={i18n.t("pryaniky.modal.wikipage.loading.actual.data")} />
                    </div>
                </DialogContent>
            }

            <DialogActions
                acceptText={i18n.t("pryaniky.modal.wikipage.save.changes")}
                closeText={i18n.t("pryaniky.modal.wikipage.cencel")}
                onAccept={() => sendDataForEditPage()}
                disableAccept={actualData1 && (actualData1.url === '' || actualData1.url === undefined) || isLoading || error}
                isLoading={loading || isLoading}
                onClose={handleCancel} />


        </>
    );

};

export const WikiEditDialog = connect<IWikiEditStateProps, IWikiEditDispatchProps, IWikiEditOwnProps, IState>(
    mapWikiEditStateToProps,
    mapWikiEditDispatchToProps
)(WikiEditDialogPresenter)

export const DialogWikiEdit = createModal(WikiEditDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});





// {/* <CheckboxInput
// className={cnWikiEditDialog("CheckboxInput")}
// text={i18n.t("pryaniky.modal.wikipage.showInMainMenu")}
// checked={actualData1.showInMainMenu}
// onChange={onChangeShowInMainMenu}
// /> */}
// <Checkbox
// className={cnWikiEditDialog("CheckboxInput")}
// value={actualData1.showInMainMenu}
// onChange={onChangeShowInMainMenu}
// label={i18n.t("pryaniky.modal.wikipage.showInMainMenu")}
// />
// {/* you can comment page or filePage */}
// {(typePage|| typeFile) && <Checkbox
// className={cnWikiEditDialog("CheckboxInput")}
// value={actualData1.allowComments}
// onChange={onChangeAllowComments}
// label={i18n.t("pryaniky.modal.wikipage.allowComments")}
// />
// }
// <ImgLoader
// className={cnWikiEditDialog("IMG")}
// type={'WikiEditNew'}
// previewUrl={actualData1.titleImageUrl}
// onLoadEnd={(value: any) => setActualData1({ ...actualData1, titleImageId: value.id, titleImageUrl: value.originalUrl })}>
// {<h4 className={cnWikiEditDialog("child")}>{i18n.t("pryaniky.modal.wikipage.cover")}</h4>}
// {<p className={cnWikiEditDialog("child")}>{i18n.t("pryaniky.modal.wikipage.cover.size")}</p>}
// </ImgLoader> */}
