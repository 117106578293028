import { ItemBlockType, ItemRenderType } from "utils/src/requests/models/api.menu";
import { NLeftMenuReducer } from "./interfaces";

export const mainBarId = 'main';

export const leftMenuDefaultBlockType: NLeftMenuReducer.Item['blockType'] = ItemBlockType.common;

export const leftMenuStorageName = 'leftMenu' as const;

export const childrenFixIconBlocks = [ItemBlockType.favourites, ItemBlockType.groups, ItemBlockType.wiki, ItemBlockType.tags];

export const baseLeftMenuReducer: NLeftMenuReducer.Reducer = {
    bars: [  ],
    barsItems: {
        [mainBarId]: []
    },
    edit: false,
    opened: false,
    hide: false,
    save: undefined,
    activeItems: [],
    notificationsItems: [],
    favouriteItemId: ''
};

export const baseLeftMenuItem: NLeftMenuReducer.Item = {
    id: '',
    actionType: null,
    addAuthData: false,
    blockType: leftMenuDefaultBlockType,
    i18nKey: '',
    icon: 'info',
    iconUrl: '',
    isDraggable: true,
    isEditable: true,
    isHidden: false,
    openInNewTab: false,
    parentId: '',
    renderType: ItemRenderType.link,
    showOnMobile: false,
    showOnlyForLanguages: null,
    title: '',
    url: '',
    groups: [],
    children: [],
    notViewedCount: 0,
    isAllowAddToFavorites: true,
    isInFavorites: false
}