import {
  takeEvery,
  put,
  takeLeading,
  takeLatest,
  throttle,
  fork,
  take,
  spawn
} from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';
import { LOCATION_CHANGE, RouterAction, LocationChangeAction } from 'connected-react-router';
import { analytic, eventsTypes } from './analytic';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import actions from 'utils/src/CommonRedux/base/actionsTypes';
import actionsNews from '../../News/redux/actionsTypes/News';
import { IStateType as IState } from 'redux/store';
// import { readNews } from 'redux/actions/News';
import { getAnalytics } from './selectors';
import { Translate } from 'localizations/Translate';
import { IAnalytics } from './intarfaces';
import { getUrlTemplateByPathName } from 'utils.project/utils.project';
import usersActions from 'utils/src/CommonRedux/users/actionsTypes';
import groupsActions from './../actionsTypes/Groups';
import widgetsActions from './../actionsTypes/Widgets';
import urls from 'routes/urls';
import { getNewsById, getCommentById } from '../../News/redux/saga/selectors';
import { ASendLikeNews, ASendLikeReply, NewsViewFull, ReplyViewFull } from '../../News/redux/actions/types';
import { IBaseNews } from '../../News/types/baseNews';
import { IReplyModel } from '../../News/types/reply';
import { newsTraking, pageTraking } from 'utils/src/requests/requests.statistics';
import { pageNames } from 'redux/pageNames';
import { GUID_EMPTY } from 'utils/src/constants.prn';

function errorText(action: string, error: Error) {
  return `analytic action "${action}" error: ${error}`;
}

function* getHewsHeaderText(id: string) {
  const news: IBaseNews = yield* select(getNewsById(id));
  const newsDiv = document.getElementById(id);
  let newsHeaderText: string | undefined;
  if (newsDiv) {
    const headerElem = newsDiv.querySelector('.HeaderLayout-Top');
    newsHeaderText = headerElem && headerElem.textContent && headerElem.textContent.replace(/\n/g, '') || undefined;
  }
  if (news.group) newsHeaderText = newsHeaderText?.replace(news.group.name, Translate.t({ i18nKey: 'in group' }) + ' ' + news.group.name);
  return newsHeaderText;
}

function* getNewsModel(id: string) {
  const news: IBaseNews = yield* select(getNewsById(id));
  let newsHeaderText = yield* getHewsHeaderText(id);
  if (news.group) newsHeaderText = newsHeaderText?.replace(news.group.name, Translate.t({ i18nKey: 'in group' }) + ' ' + news.group.name);
  return {
    news,
    newsHeaderText
  }
}

function* getReplyModel(id: string) {
  const reply: IReplyModel = yield* select(getCommentById(id));
  return {
    reply
  }
}

function* getUser(): any {
  const user = yield* select(getAuthUser);
  return user;
}

let focused = false;
let inited = false;
function* tabFocusBlurInit(action: any) {
  try {
    if (!inited) {
      inited = true;
      const analyt = yield* select(getAnalytics);
      const analytics = analyt as IAnalytics;
      if (!analytics.social) return 0;
      const user = yield* getUser();
      //  select(getAuthUser);
      window.onfocus = (...args: any) => {
        if (!focused) analytic.push(eventsTypes.tabFocus, { user });
        focused = true;
      }
      window.onblur = (...args: any) => {
        let innerVideoFrameInFocus = false;
        Array.from(document.body.querySelectorAll('.VideoWrapper')).forEach(video => {
          if (video.getAttribute('isFocused')) innerVideoFrameInFocus = true
        })
        if (!innerVideoFrameInFocus) {
          analytic.push(eventsTypes.tabBlur, { user });
          focused = false
        }
      }
    }
    return 0;
  } catch (error: any) {
    console.error('location change analytic error:', error);
  }
}

function* locationChange(action: LocationChangeAction) {
  try {
    const { urlModuleName, pathTemplate: urlKey} = getUrlTemplateByPathName(action.payload.location.pathname);
    const analytics = yield* select(getAnalytics);
    // if page haven't structure
    if (!analytics.social) return ;
    const user = yield* getUser();
    const to = action.payload.location.pathname;
    if (!pageNames[urlModuleName]) {
      const response: any = yield* call(pageTraking, { id: GUID_EMPTY, url: to });
    } else {
      // ждем когда установится структура страницы после смены урла, иначе страницу считаем еще не прочитанной
      yield take(widgetsActions.SET_DATA);
      const activePage: string = yield* select((state: any) => state.widgets.active);
      const pageId: string = yield* select((state: any) => state.widgets.structures[activePage]);
      switch (urlKey) {
        case urls.user[urls.user.findIndex(i => i === urlKey)]:
          yield take(usersActions.ADD_USER);
          break;
        case urls.group[urls.group.findIndex(i => i === urlKey)]:
          yield take(groupsActions.ADD_GROUP);
          break;
      }
      const response: any = yield* call(pageTraking, { id: pageId, url: to });
    }
    analytic.push(eventsTypes.locationChange, {
      to,
      user,
      title: document.title
    });
    return 0;
  } catch (error: any) {
    console.error('location change analytic error:', error);
  }
}

function* newsRead(action: any) {
  try {
    const analyt = yield* select(getAnalytics);
    const analytics = analyt as IAnalytics;
    if (!analytics.social) return 0;
    const user = yield* getUser();
    // const user = yield* select(getAuthUser);
    const newsData = yield* getNewsModel(action.payload);
    const response: any = yield* call(newsTraking, { id: newsData.news.id });
    analytic.push(eventsTypes.newsView, {
      ...newsData,
      user,
      title: document.title
    });
    return 0;
  } catch (error: any) {
    console.error('news read analytic error:', error);
  }
}

function* likeNews({ payload: { id, liked } }: ASendLikeNews) {
  try {
    const analyt = yield* select(getAnalytics);
    const analytics = analyt as IAnalytics;
    if (!analytics.social) return 0;
    const user = yield* getUser();
    const newsData = yield* getNewsModel(id);
    newsData.news.liked = !liked;
    analytic.push(eventsTypes.newsLike, {
      ...newsData,
      user,
      title: document.title
    });
  } catch (error: any) {
    console.error(errorText('likeNews', error));
  }
}

function* likeReply({ payload: { replyId, liked } }: ASendLikeReply) {
  try {
    const analyt = yield* select(getAnalytics);
    const analytics = analyt as IAnalytics;
    if (!analytics.social) return 0;
    const replyData = yield* getReplyModel(replyId);
    replyData.reply.liked = !liked;
    const user = yield* getUser();
    analytic.push(eventsTypes.replyLike, {
      ...replyData,
      user,
      title: document.title
    });
  } catch (error: any) {
    console.error(errorText('likeReply', error));
  }
}

function* showFullNews({ payload }: ReturnType<NewsViewFull>) {
  try {
    const analyt = yield* select(getAnalytics);
    const analytics = analyt as IAnalytics;
    if (!analytics.social) return 0;
    const newsData = yield* getNewsModel(payload);
    const user = yield* getUser();
    analytic.push(eventsTypes.newsViewFull, {
      ...newsData,
      user,
      title: document.title
    });
  } catch (error: any) {
    console.error(errorText('showFullNews', error));
  }
}

function* showFullReply({ payload }: ReturnType<ReplyViewFull>) {
  try {
    const analyt = yield* select(getAnalytics);
    const analytics = analyt as IAnalytics;
    if (!analytics.social) return 0;
    const replyData = yield* getReplyModel(payload);
    const user = yield* getUser();
    analytic.push(eventsTypes.newsViewFull, {
      ...replyData,
      user,
      title: document.title
    });
  } catch (error: any) {
    console.error(errorText('showFullReply', error));
  }
}

/**
 * module root saga
 */
const root = function* root() {
  yield takeLeading(LOCATION_CHANGE, locationChange);
  yield takeLeading(actions.CHANGE_STATE, tabFocusBlurInit);
  yield takeEvery(actionsNews.SET_READED, newsRead);
  yield takeLeading(actionsNews.LIKE_NEWS, likeNews);
  yield takeLeading(actionsNews.LIKE_REPLY, likeReply);
  yield takeLeading(actionsNews.NEWS_VIEW_FULL, showFullNews);
  yield takeLeading(actionsNews.REPLY_VIEW_FULL, showFullReply);
  // yield takeEvery('UPDATE_NEWS', updateNews);
};

/**
 * export root saga
 */
export default root;