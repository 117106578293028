/**
 * @packageDocumentation
 * @module List_type_wiki
 */
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from '../../../../redux/connector';
import { mapStateToProps, IListStateProps, IListProps, IListState, cnList, mapActionsToProps } from './../../List.index';
import { IListTypeWikiProps, avalibleFiltersWiki, listTypeWikiMapStateToProps } from './List_type_wiki.index';
import './List_type_wiki.scss';
import { AbstractList } from '../../List';
import { IListResponseWithData } from 'utils/src/requests/models/api.base';
import { Header } from '../../Header/List-Header';
import { ListLite, TypedListPlaceholder, CheckboxInput, Button, Icon } from 'uielements/src';
import i18n from '../../../../localizations/i18n';
import { Masonry } from 'uielements/src/Masonry/Masonry';
import { Item as WikiItem } from '../../../WIKIList/Item/Item';
import { ItemList as WikiItemList } from '../../../WIKIList/ItemList/ItemList';
import { toast } from 'react-toastify';
import { IStateType as IState } from 'redux/store';
import { DialogWikiCreate } from '../../../Dialogs/WikiCreate/Create/WikiCreate';
import StubListPlaceholder from '../../../../assets/svg/listPlaceholder.svg';
import { store } from "../../../../redux/store";
import { withRouter } from "react-router"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import widgetsActions from 'redux/actionsTypes/Widgets';
import { DropUploader } from 'blocks/WIKIList/DropUploader/DropUploader'
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from 'muicomponents/src/mui/system';
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox'
import { preapreCollectionAliasByContext } from 'blocks/WikiListNew/List_type_wiki.utils';

const StyledFormControlLabel = styled(FormControlLabel)({
    marginRight: 0,
    paddingRight: 0,
    width: "fit-content"
});


/**
 * @deprecated
 */
export class ListWikiPresenter extends AbstractList<IListTypeWikiProps, IListState> {
    public static defaultProps = {
        requestOptions: {
            skipCount: 0,
            count: 6,
            search: '',
        },
    };

    public type = 'wikilist';



    public search = (text: string) => {
        const { toggle } = this.props;
        this.searchString = text;
        toggle({
            id: this.listId,
            data: {
                hideData: text.length !== 0,
                clearData: true,
                isFinished: false
            }
        });
        setTimeout(this.getData, 10);
    };


    public getData = () => {
        const { requestOptions, context, edit } = this.props;
        const { clearData, isLoading } = this.props;
        const { clearDataFromList, getWikiList, toggle } = this.props;
        const id = this.listId;
        if (edit || isLoading) return;
        const opts = Object.assign({}, requestOptions);
        let collectionAlias = !context.coll ? "all" : context.collectionAlias ? context.collectionAlias : "dash";
        // let collectionAlias = context.collectionAlias ? context.collectionAlias : "all";
        Object.keys(context).forEach((cKey: string) => {
            if (avalibleFiltersWiki[cKey]) opts[avalibleFiltersWiki[cKey]] = context[cKey];
        });
        if (context.tId && !opts['catFilter']) {
            opts['catFilter'] = context.tId
        }
        //    const WikiListData = utils.API.pages.get("wikilist/" + collectionAlias)
        if (this.searchString && this.searchString !== '') opts.search = this.searchString;
        if (context.search && context.search !== '') opts.search = context.search;

        toggle({
            id: this.listId,
            data: {
                isLoading: true,
                isFinished: false
            }
        });
        if (clearData) clearDataFromList({ id, data: null });

        if (context.gId) collectionAlias = `group/${context.gId}`

        getWikiList.call(this, collectionAlias, opts);
        // this.changeDefaultViewType();
    };

    public componentDidMount() {
        super.componentDidMount()
        setTimeout(() => this.getData(), 10);
        utils.API.system.sagas.getCheckRights('PagesV2').then((value) => {
            if (value.error_code === 0) {
                this.setState({
                    createRights: value.data
                })
            }
        }).catch(() => {
            console.error('error')
        })
    }

    public componentDidUpdate(pp: IListTypeWikiProps) {
        const { context: c, toggle, isLoading } = this.props;
        const { context: pc } = pp;


        if (c.search !== undefined || c.coll !== undefined || c.searchOnlyTitle !== undefined || c.tId !== undefined) {
            // проверка выше добавлена т.к.,
            // при создании новой страницы (с типом  файл, страница или урл, контекст стирался и его изменение(на undefined, вызывало действие ниже,  в результате чего isLoading становился тру, но не мог поменятся на false 
            // потому что запрос без полей контектса падал )) из-за этого не отправлялась новый запрос для обновления данных при нажатии кнопки НАЗАД в браузере.  https://tracker.yandex.ru/COMMON-10084
            if (
                Object.keys(avalibleFiltersWiki).filter((fk) => c[fk] !== pc[fk] ? true : false).length ||
                c.search !== pc.search ||
                c.coll !== pc.coll ||
                c.searchOnlyTitle !== pc.searchOnlyTitle ||
                c.tId !== pc.tId

            ) {
                toggle({
                    id: this.listId,
                    data: {
                        hideData: true,
                        clearData: true,
                        isFinished: false,

                    }
                });
                // this.getData()
                setTimeout(() => this.getData(), 10);
            }
        }
    }

    public changeWikiItem = () => {
        const { id } = this;
        return (itemData: {}) => {
            this.props.changeListItem({
                id,
                data: itemData
            })
        }
    }

    public renderChildren = () => {
        const { data, isFinished, isLoading, additionalParams, layoutWikilist, context, newsUid, uIsAdmin: isAdmin, dispatch, searchPage } = this.props;
        const rawViewType = window.localStorage.getItem('prn_local_state_wiki-view-type');
        // Parse stored json or if none return initialValue
        const viewType = rawViewType ? JSON.parse(rawViewType) : context.viewType;

        const isCollection = layoutWikilist && layoutWikilist.collections && layoutWikilist.collections.length > 0

        const masonry = <ListLite
            tag={Masonry}
            tagAdditionalProps={{ breakPoints: [450] }}
            className={'rounded-bottom'}
            skeleton="shop"
            skeletonCount={6}
            isFinished={isFinished}
            isLoading={isLoading}
            loadMore={this.getData}>
            {data && data.map((el: any) => <WikiItem
                key={el}
                showControls={isAdmin || context.isGroupAdmin}
                data={this.props.getListItem(this.listId, el)}
                changeItem={this.changeWikiItem()}
                context={{
                    collectionAlias: preapreCollectionAliasByContext(context),
                    gId: context.gId
                }}
            />

            )}
        </ListLite>
        return (

            <React.Fragment>
                {isAdmin || context.isGroupAdmin || this.state.createRights &&
                    <DropUploader currentPageId={context.currentPageId} collectionAlias={context.collectionAlias} />}
                {!searchPage && <>
                    {isCollection && <div className={"Crumbs"}>{isCollection && layoutWikilist.collections.map((el: any) =>
                        <Button type={"rlink"} noBorder noBackground padding="md" href={el.name === "dash" ? "/wikilist" : "/wikilist/" + el.alias}>
                            <Icon icon={'folder-minus'} />{el.name === "dash" ? i18n.t("pryaniky.list.wiki.common") : el.name}
                            {/* className={cnItem('ActionsIcon')} */}

                        </Button>
                    )}
                    </div>}
                    <Header
                        className={isCollection && viewType === 1 ? 'shortWithCrumbs' :
                            isCollection ? 'withCrumbs' :
                                viewType === 1 ? "shortList" : 'rounded'}
                        type={'common'}
                        search={{
                            placeholder: i18n.t('pryaniky.list.wiki.search'),
                            onChange: this.search,
                        }}
                        actions={isAdmin || context.isGroupAdmin || this.state.createRights ? {
                            options: [
                                {
                                    title: i18n.t('pryaniky.list.wiki.actions.create'),
                                    buttonProps: {
                                        onClick: () => DialogWikiCreate({
                                            isShown: this.state.showDialog,
                                            onClose: this.closeDialog,
                                            data: {
                                                collectionAlias: preapreCollectionAliasByContext(context),
                                                gId: context.gId
                                            }
                                        }).then((data: any) => {
                                            data.type === "url" && this.props.dispatch({
                                                type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                                    [context.__parent || this.props.widget.id]: {
                                                        "order": "creationdate",
                                                        "sort": "desc"
                                                    }
                                                }

                                            })
                                        }).catch(() => { }),
                                    },
                                },

                            ],

                        } : undefined} />

                    <div className={viewType === 0 ? "bulitsFull" : "bulitsShort"}>

                        <StyledFormControlLabel control={<Checkbox
                            checked={context.searchOnlyTitle}
                            onChange={() => {
                                this.props.dispatch({
                                    type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                        [this.props.widget.id]: {
                                            searchOnlyTitle: !context.searchOnlyTitle
                                        }
                                    }
                                })
                            }} />}
                            label={i18n.t("pryaniky.list.wiki.search.only.by.name")} />

                        <StyledFormControlLabel control={<Checkbox
                            checked={context.coll}
                            onChange={() => {
                                this.props.dispatch({
                                    type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                        [this.props.widget.id]: {
                                            coll: !context.coll
                                        }
                                    }
                                })
                            }} />}
                            label={i18n.t("pryaniky.list.wiki.search.in.collection")} />
                        <div className={"bulitsForSearch"}>
                            <span className="wikiType" >{i18n.t('pryaniky.list.wiki.filter.type.name')}</span>
                            <RadioGroup
                                row={true}
                                //   className={classes.formControlVisibilityType}
                                name={i18n.t('pryaniky.list.wiki.filter.all')}
                                //   value={this.state.wikiShowType || 'all'}
                                value={context.type}
                                onChange={(event: any) => this.props.dispatch({
                                    type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                        [this.props.widget.id]: {
                                            type: event.target.value
                                        }
                                    }
                                })
                                }
                            >
                                <FormControlLabel value={'all'} control={<Radio color="default" />} label={i18n.t('pryaniky.list.wiki.filter.all')} />
                                <FormControlLabel value={'page'} control={<Radio color="default" />} label={i18n.t('pryaniky.list.wiki.filter.pages')} />
                                <FormControlLabel value={'wikilist'} control={<Radio color="default" />} label={i18n.t('pryaniky.list.wiki.actions.folders')} />
                                <FormControlLabel value={'url'} control={<Radio color="default" />} label={i18n.t('pryaniky.list.wiki.actions.url')} />
                                <FormControlLabel value={'file'} control={<Radio color="default" />} label={i18n.t('pryaniky.list.wiki.actions.file')} />
                            </RadioGroup>
                        </div>
                    </div>
                </>
                }
                {({
                    0: masonry,
                    1: <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData} noSkeletons={isFinished}>
                        {data && data.map(el => (
                            <WikiItemList
                                key={el}
                                showControls={isAdmin || context.isGroupAdmin}
                                data={this.props.getListItem(this.listId, el)}
                                changeItem={this.changeWikiItem()}
                                context={{
                                    collectionAlias: preapreCollectionAliasByContext(context),
                                    gId: context.gId
                                }}
                            />
                        ))}

                    </ListLite>
                })[viewType as 0 | 1] || masonry}

                {isFinished && data.length === 0 && <div className={context.viewType === 1 ? "stubShort" : "stub"}>
                    <img src={StubListPlaceholder} alt="" />
                    {
                        !context.coll && !context.search && !this.searchString ?
                            <>
                                <h1>{i18n.t('pryaniky.list.wiki.emptyList.withoutcollection.title')}</h1>
                                <p>{i18n.t('pryaniky.list.wiki.emptyList.withoutcollection.text')}</p>
                            </>
                            :
                            <>
                                <h1>{i18n.t('pryaniky.list.wiki.emptyList.title')}</h1>
                                <p>{i18n.t('pryaniky.list.wiki.emptyList.text')}</p>
                            </>
                    }
                </div>}
            </React.Fragment>
        );
    };
    protected closeDialog = () => this.setState({ showDialog: false });

    protected searchInCollection = () => this.setState({ coll: true });

}

/**
 * @deprecated
 */
export const List = withRouter(connect<IListStateProps, IDispatchProps, IListTypeWikiProps, IState>(
    // mapStateToProps,
    (...args) => {
        return {
            ...mapStateToProps(...args),
            ...listTypeWikiMapStateToProps(...args)
        }
    },
    mapDispatchToProps(mapActionsToProps)
)(ListWikiPresenter));
