/**
 * @packageDocumentation
 * @module List_type_wiki
 */
import React, { FC, useState, useEffect, ComponentProps, MouseEvent, useMemo, Fragment } from 'react';
import * as utils from 'utils/src/utils';
import { IWikiListHeaderProps, cnWikiListHeader } from './HeaderWiki.index';
import './HeaderWiki.scss';
import i18n from 'localizations/i18n';
import { DialogWikiCreateNew } from 'blocks/Dialogs/WikiCreateNew/Create/WikiCreateNew';
import { RadioGroupMui } from 'muicomponents/src/RadioGroup/RadioGroup';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import widgetsActions from 'redux/actionsTypes/Widgets';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput'
import IconButton from '@mui/material/IconButton';
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import { Popover } from 'muicomponents/src/Popover';
import { UsersSuggester } from 'muicomponents/src/Suggester';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useDebouncedQueryState } from 'utils/src/hooks'
import { Translate } from 'localizations/Translate';
import { useDispatch } from 'react-redux'
import { PreListfilters } from '../PreListFilters/PreListFilters'
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton';
import { Breadcrumbs } from 'muicomponents/src/Breadcrumbs/Breadcrumbs';
import { Typography } from 'muicomponents/src/Typography/Typography';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Avatar } from 'muicomponents/src/Avatar/Avatar'
import wikilist from '../Items/wikilist.svg'
import { i } from 'blocks/Widget/_type/Widget_type_license.index';
import {
    Divider,
    Box,
    FormControlLabel,
    Checkbox,

} from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';
import { formatDateSTD } from 'utils/src/utils.dates';
import { preapreCollectionAliasByContext } from '../List_type_wiki.utils';

const dropdown = [
    { content: i18n.t('pryaniky.modal.wikipage.create.type.page.new'), id: 'page' },
    { content: i18n.t('pryaniky.modal.wikipage.create.type.wikilist.new'), id: 'wikilist', },
    { content: i18n.t('pryaniky.modal.wikipage.create.type.url.new'), id: 'url', },
    { content: i18n.t('pryaniky.modal.wikipage.create.type.file.new'), id: 'file', },

]
// const activeFilters = [
//     { id: 'active', title: i18n.t('pryaniky.modal.wikipage.filter.archived.false'), value: 'false', type: 'archived' },
//     { id: 'archive', title: i18n.t('pryaniky.modal.wikipage.filter.archived.true'), value: 'true', type: 'archived' },
//     { id: 'draft', title: i18n.t('pryaniky.modal.wikipage.filter.draft.true'), value: "draft", type: 'draft' },
// ]
const activeFilters = [
    { id: 'active', title: i18n.t('pryaniky.modal.wikipage.filter.archived.false'), value: 'active', type: 'active' },
    { id: 'archive', title: i18n.t('pryaniky.modal.wikipage.filter.archived.true'), value: 'archived', type: 'archived' },
    { id: 'draft', title: i18n.t('pryaniky.modal.wikipage.filter.draft.true'), value: "draft", type: 'draft' },
]

const StyledFormControlLabel = styled(FormControlLabel)({
    marginLeft: 0,
    marginRight: 0,
    width: '100%'

});
export const WikiListHeader: FC<IWikiListHeaderProps> = ({ context, hasRights, widget, isAdmin, layoutWikilist, dashFolderInfo }) => {
    const [search, searchQuery, setSearch] = useDebouncedQueryState('search')
    const [categories, setCategories] = useState<any[]>([])
    const dispatch = useDispatch()
    const [popoverAnchor, setPopoverAnchor] = useState<{ top: number; left: number } | null>(null);
    // console.log("context",context)
    const [filters, setFilters] = useState({
        catFilter: context.catFilter,
        archived: context.archived,
        authors: context.authors,
        authorsObj: context.authorsObj,
        draft: context.draft
    })

    const getCategories = () => utils.API.tags.list(0, 20, {
        url: preapreCollectionAliasByContext(context),
    })
        .r
        .then((d) => utils.checkResponseStatus(d) && setCategories(
            [{ id: 'all', title: i18n.t('pryaniky.filter.wikilist.all'), type: 'catFilter', value: "" },
            ...d.data.map((el: any) => ({ id: el.id.toString(), title: el.displayName, value: el.id, type: 'catFilter' }))]
        ))

    // ----------------------функции для работы с поповером Popover ----------------------------->

    const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        getCategories()
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverAnchor({ top: rect.top + (rect.height / 2), left: rect.left + (rect.width / 2) });
    };

    const closePopover = () => {
        setPopoverAnchor(null);
    }


    // ---------------------- конец функций для работы с поповером Popover ----------------------------->

    // ---------------------- Изменения типа оторажения списка----------------------------->

    const setWikiviewType = (el: any) => {
        window.localStorage.setItem('prn_local_state_wiki-view-type', JSON.stringify(el));
        dispatch({
            type: widgetsActions.UPDATE_CONTEXTS, payload: {
                [widget.id]: {
                    viewType: el
                }
            }
        });
    }

    const onSelectAction = (action: string) => {
        DialogWikiCreateNew({
            type: action,
            isAdmin: isAdmin,
            data: {
                collectionAlias: preapreCollectionAliasByContext(context),
                collections: (dashFolderInfo && dashFolderInfo?.length > 0) ? dashFolderInfo : [{
                    alias: preapreCollectionAliasByContext(context),
                    defaultViewType: layoutWikilist?.defaultViewType,
                    description: layoutWikilist?.description,
                    id: layoutWikilist?.id,
                    imageId: layoutWikilist?.imageId,
                    imageUrl: layoutWikilist?.imageUrl,
                    name: layoutWikilist?.title ? layoutWikilist?.title : "Нет названия",

                }]
            },
            parentFolderModerators: layoutWikilist?.moderators || []
        }).then((data: any) => {
            data.type === "url" && dispatch({
                type: widgetsActions.UPDATE_CONTEXTS, payload: {
                    [context.__parent || widget.id]: {
                        "order": "creationdate",
                        "sort": "desc"
                    }
                }

            })
        }).catch(() => { })

    }

    const isCollection = layoutWikilist && layoutWikilist.collections && layoutWikilist.collections.length > 0;
    const filtersNotChanged = filters.archived === context.archived && filters.authors === context.authors && filters.catFilter === context.catFilter && filters.authorsObj === context.authorsObj && filters.draft === context.draft

    const BreadCrumbsComponent = useMemo(() => {
        if (!layoutWikilist?.breadCrumbs) return null;
        return <Breadcrumbs
            maxItems={5}
            separator={<ChevronRight fontSize="small" />}
            sx={{ marginBottom: "20px", }}
            aria-label="breadcrumb">

            {layoutWikilist?.breadCrumbs?.map((item: any, idx: number) => <MuiButton
                sx={{ textTransform: "inherit", fontSize: "16px" }}
                disabled={idx === layoutWikilist.breadCrumbs.length - 1}
                href={item.alias === "dash" ? "/wikilist" : "/wikilist/" + item.alias}>
                {item.alias === "dash" && item.name === "dash" ? i18n.t("pryaniky.list.wiki.dash") : item.name?.length > 0 ? item.name : layoutWikilist?.title}
            </MuiButton>)}

        </Breadcrumbs>
    }, [layoutWikilist])

    return (<div className={cnWikiListHeader("")}>
        {isCollection && BreadCrumbsComponent}
        {isCollection && <Box sx={{ display: "flex", borderBottom: "1px solid #E0E0E0", paddingBottom: "20px", marginBottom: "20px" }}>
            <Avatar src={layoutWikilist.titleImageId ? layoutWikilist.titleImageUrl : wikilist} sx={{ width: 64, height: 64, marginRight: "16px" }} />
            <Box>
                <Typography variant="h5">{layoutWikilist.title}</Typography>
                <Typography variant={"body2"} color="rgba(0, 0, 0, 0.38)">
                    <Translate i18nKey='pryaniky.wikilist.item.created.new' values={{
                        creationDate: formatDateSTD(layoutWikilist?.creationDate, false),
                        changeDate: formatDateSTD(layoutWikilist?.changeDate, false)
                    }} />
                </Typography>

            </Box>
        </Box>}
        <div className={cnWikiListHeader("Header")}>
            <SearchInput
                value={search}
                onChange={(e) => { setSearch(e.target.value) }}
                label={i18n.t('pryaniky.list.wiki.search')}
                sx={{
                    flex: 1,
                    minWidth: '175px'
                }}
            />

            <span style={{
                display: 'flex'
            }}>
                <IconButton
                    style={{ padding: "5px", margin: "0 24px" }}
                    onClick={(event: MouseEvent<HTMLButtonElement>) => openPopover(event)}
                    color={"primary"}
                >
                    <FilterAltIcon style={{ fontSize: "30px" }} />
                </IconButton>

                <Popover
                    open={popoverAnchor ? true : false}
                    anchorReference="anchorPosition"
                    anchorPosition={popoverAnchor || { top: 0, left: 0 }}
                    onClose={closePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        sx: {
                            minWidth: 0,
                            display: 'flex',
                            flexDirection: 'column'
                        }
                    }}
                >
                    <div className={cnWikiListHeader("Popover")}>
                        <DialogTitle onClose={() => closePopover()}>
                            {i18n.t("pryaniky.modal.wikipage.filter.settings")}
                        </DialogTitle>
                        <div className={cnWikiListHeader("Popover-Content")}>
                            {categories && <FormControl>
                                <FormLabel className={cnWikiListHeader("Popover-Content-Label")} id="demo-controlled-radio-buttons-group">{`${i18n.t('pryaniky.modal.wikipage.categories.filter')}:`}</FormLabel>
                                <RadioGroupMui
                                    className={cnWikiListHeader("Popover-Content-Filters")}
                                    items={categories}
                                    size='small'
                                    onChange={(e, selectedEl: any) => setFilters({ ...filters, catFilter: selectedEl })
                                    }
                                    value={filters.catFilter}

                                />

                            </FormControl>}

                        </div>
                        {(isAdmin || context.isGroupAdmin || hasRights) && <div className={cnWikiListHeader("Popover-Content")}>

                            <FormControl>
                                <FormLabel className={cnWikiListHeader("Popover-Content-Label")} id="demo-controlled-radio-buttons-group">{`${i18n.t('pryaniky.modal.wikipage.filter.archived')}`}</FormLabel>
                                <RadioGroupMui
                                    className={cnWikiListHeader("Popover-Content-Filters")}
                                    items={activeFilters}
                                    size='small'
                                    onChange={(e, selectedEl: any) => {
                                        console.log("selectedEl", selectedEl)

                                        setFilters({ ...filters, archived: selectedEl === "archived" ? true : false, draft: selectedEl === "draft" ? true : false })
                                    }}
                                    value={filters.draft ? "draft" :
                                        filters.archived ? "archived" : "active"}

                                />

                            </FormControl>
                            {/* <StyledFormControlLabel
                                sx={{ marginTop: "16px", }}
                                control={<Checkbox
                                    sx={{
                                        padding: "9px 9px 9px 0"
                                    }}
                                    className={cnWikiListHeader("CheckboxInput")}
                                    checked={filters.draft}
                                    onChange={() => setFilters({ ...filters, draft: !filters.draft })} />}
                                label={i18n.t("pryaniky.modal.wikipage.filter.draft.true")} /> */}
                        </div>}

                        <div className={cnWikiListHeader("Popover-Content")}>
                            <UsersSuggester
                                TextFieldProps={{
                                    label: i18n.t('pryaniky.modal.wikipage.filter.author'),
                                }}
                                value={filters.authorsObj}
                                onChange={(e, value: any) => {
                                    if (value) {
                                        setFilters({ ...filters, authors: value.id, authorsObj: value })
                                    } else {
                                        setFilters({ ...filters, authors: "", authorsObj: null })
                                    }
                                }}
                            />
                        </div>
                        <div className={cnWikiListHeader("Popover-Content-ActionButtons")}>
                            <MuiButton
                                className={cnWikiListHeader("CleanFilter")}
                                variant={'outlined'}
                                color={'primary'}
                                sx={{ marginRight: "5px" }}
                                onClick={() => setFilters({
                                    ...filters, catFilter: "",
                                    archived: "",
                                    authors: "",
                                    authorsObj: null
                                })
                                }
                            >
                                <CleaningServicesIcon color={'primary'} />
                                {i18n.t("pryaniky.modal.wikipage.filter.clean")}
                            </MuiButton>
                            <MuiButton
                                className={cnWikiListHeader("CleanFilter")}
                                variant={'contained'}
                                disabled={filtersNotChanged}
                                color={'primary'}
                                onClick={() => {
                                    dispatch({
                                        type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                            [widget.id]: { ...filters }
                                        }

                                    });
                                    closePopover();
                                }}
                            >

                                {i18n.t("pryaniky.modal.wikipage.filter.apply")}
                            </MuiButton>
                        </div>
                    </div>
                </Popover>

                <IconButton
                    style={{ padding: "5px" }}
                    onClick={() => setWikiviewType(0)}
                    color={context.viewType === 0 ? "primary" : "default"}
                >
                    <GridViewIcon style={{ fontSize: "30px" }} />
                </IconButton>

                <IconButton
                    style={{ padding: "5px", margin: "0 24px" }}
                    onClick={() => setWikiviewType(1)}
                    color={context.viewType === 1 ? "primary" : "default"}
                >
                    <ListIcon style={{ fontSize: "30px" }} />
                </IconButton>
            </span>


            {(isAdmin || context.isGroupAdmin || hasRights) && <SplitButton
                buttonProps={{
                    onClick: () => {
                        onSelectAction("page")
                    },
                    children: i18n.t('pryaniky.modal.wikipage.create.type.page.new')
                }}
                variant={"outlined"}
                options={dropdown}
                handleMenuItemClick={(e, action) => onSelectAction(action.id)}
            >
            </SplitButton>}
        </div>
        <PreListfilters context={context} widget={widget} />
    </div>
    )
}

