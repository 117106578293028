/**
 * @packageDocumentation
 * @module List_type_group_users_selector
 */
import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from 'redux/connector';

import { mapStateToProps, IListStateProps, IListProps, mapActionsToProps, IListOwnProps } from './../../List.index';

import { list } from 'utils/src/requests/requests.users'
import { joinUsers, action } from 'utils/src/requests/requests.groups'
import { getIsGroupAdminByPKID } from 'redux/sagas/Groups/selectors'
import { IStateType as IState } from 'redux/store';
import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index'
import dialogUserInvite from 'blocks/Dialogs/GroupInvite/GroupInvite';
import { Button } from 'muicomponents/src/Button/Button';
import { Box } from 'muicomponents/src/Box/Box';
import i18n from 'localizations/i18n';
import { useScrollListState } from 'utils/src/hooks'
import { useLazyQueryEx } from 'utils/src/hooks'
import { defaultMiddleware } from 'muicomponents/src/ItemsListDialog/defaultListState'
import { toast } from 'react-toastify';
import { IAdditionalField } from 'muicomponents/src/FieldRender'
import { mapFromUserList } from 'muicomponents/src/FieldRender/mapUserListFields'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

async function joinUsersPromise(groupId: (string | number), uids: string[], join?: boolean) {
  const response = await joinUsers(groupId, uids, join)
  return await response.r
}
async function actionPromise(id: string, groupId: (string | number)[], params: any) {
  const response = await action(id, groupId as any, params)
  return await response.r
}

const InvaiteButton: FC<{ isAdmin?: boolean, gid: string | number }> = ({ isAdmin = false, gid }) => {
  async function onClick() {
    try {
      await dialogUserInvite({
        isAdmin,
        gid,
      })
    } catch (error) { }
  }
  return <Button
    sx={{ marginLeft: '8px' }}
    color='primary'
    variant='contained'
    onClick={onClick}>{i18n.t('pryaniky.list.users.actions.invite')}</Button>
}

type TItemType = IBaseUser & { url: string, actions: string[], isGroupAdmin?: boolean, fields?: IAdditionalField[] }

export const ListGroupUsersSelectorPresenter: FC<any> = ({ context, isGroupAdmin, authUser, uIsAdmin, ...props }) => {
  return (
    <ItemsList<TItemType>
      BodyComponent={ItemsListBody}
      listStateHook={({ defaultSelected }, middlewareParams) => {
        const {
          search,
          debouncedSearch,
          changeSearch,
          tabsValue,
          onChangeTab
        } = useDefaultStaticStates(middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id)// состояние для поиска и табов

        //храним общие кол-во пользователей в группе(только ради этого переопределяем listStateHook). 
        //Лучше бы оно приходило в информации о группе...
        const [totalUserCount, setTotalUserCount] = useState(0)

        //исключение пользователей
        const { send: sendJoinUsers, isLoading: isSendingRemove } = useLazyQueryEx(joinUsersPromise, { allowUndefinedData: true })

        //действия над пользователем
        const { send: sendAction } = useLazyQueryEx(actionPromise, { allowUndefinedData: true })

        // получаем список пользователей и достаём запиханное туда колличество пользователей
        const state = useScrollListState(async function (skipCount, count) {
          const response = await list({
            skipCount,
            count,
            search: debouncedSearch,
            gid: context.groupId,
            extended: true
          })
          const result = await response.r as any as { data: (IBaseUser & { fields?: any })[], totalUserCount: number } & IBasicResponse
          if (result.error_code !== 0) throw result
          setTotalUserCount(result.totalUserCount)
          return result.data.map((item) => ({ ...item, url: `/user/${item.id}`, fields: mapFromUserList(item.fields || []) })) as TItemType[]
        }, [debouncedSearch], {
          selectableSingle: middlewareParams.selectableSingle
        })

        return {
          middlewareParams: {
            ...middlewareParams,
            listHeaderProps: {
              ...middlewareParams.listHeaderProps,
              /**
               * @todo semin - text localize
               */
              summaryText: 'Пользователей — ' + totalUserCount,
              disableActionsButton: isSendingRemove
            },
            itemClickAction: async function (option, item, actions) {
              if (option.id === 'removefromgroup') {
                await sendJoinUsers(context.groupId, [item.id], false)
                actions.removeItems([item])
                toast.success(i18n.t(`pryaniky.list.users.actions.removeusers`, { count: 1 }));
              } else {
                await sendAction(option.id, context.groupId, { userIds: [item.id] })
                toast.success(i18n.t(`pryaniky.list.users.actions.${option.id}.success`, { count: 1 }));
              }
            },
            itemMiddleware: (props) => {
              return {
                ...props,
                additionalContentStart: props.item.isGroupAdmin
                  ? <Box sx={{ padding: '2px', marginRight: '8px' }}>
                    <AdminPanelSettingsIcon fontSize='small' />
                  </Box>
                  : undefined,
                inProgress: state.select.isSelected(props.item) ? isSendingRemove : false,
                options: props.item.actions.map(action => ({
                  content: i18n.t('pryaniky.list.users.actions.' + action, { count: 1 }),
                  id: action
                }))
              }
            },
            primaryActionClick: async function (select, actions) {
              try {
                await sendJoinUsers(context.groupId, select.selected.map(v => v.id), false)
                actions.removeItems(select.selected)
                toast.success(i18n.t(`pryaniky.list.users.actions.removeusers`, { count: select.selected.length }));
              } catch (error) {
                toast.error('pryaniky.toast.error.server');
                console.error('ERROR: primaryActionClick ', error)
              }

            },
          },
          state,
          defaultSelected,
          search,
          tabsValue,
          changeSearch,
          onChangeTab,
          middleware: defaultMiddleware
        }
      }}
      selectable={isGroupAdmin || uIsAdmin}
      ItemComponent={UsersListItem}
      listHeaderProps={{
        /**
         * @todo semin - check logic isGroupAdmin as isAdmin for add user list
         */
        SearchActions: isGroupAdmin && <InvaiteButton gid={context.groupId} isAdmin={isGroupAdmin} />,
        actionButtonProps: {
          children: i18n.t('pryaniky.list.users.actions.remove')
        }
      }}
    />
  );
}

const stateToProps = (state: IState, props: IListOwnProps) => {
  const gid = props.context.gId
  const isGroupAdmin = gid ? getIsGroupAdminByPKID(gid)(state) : false
  return {
    isGroupAdmin,
    ...mapStateToProps(state, props)
  }
}
export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  stateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListGroupUsersSelectorPresenter);
