/**
 * @packageDocumentation
 * @module Widget_Settings
 */
import common from './common';
import list from './list';
import timeline from './timeline';
import timelineonce from './timelineonce';
import timelinehead from './timelinehead';
import eventstoday from './eventstoday';
import shop from './shop';
import info from './info';
import mindmap from './mindmap';
import notes from './notes';
import sliderlink from './sliderlink';
import ratingmini from './ratingmini';
import usersGroup from './usersGroup';
import filters from './filters';
import charts from './charts';
import album from './album';
import hierarchy from './hierarchy';
import license from './license';
import quests from './quests';
import virtualUser from './virtualuser';
import tagsCloud from './tagsCloud';
import iframe from './iframe';
import usersList from './usersList';
import statistic from './statistic';
import statisticFilters from './statisticFilters';
import userfields from './userfields';
import layout from './layout';
import blocks from './blocks';
import badgesList from './badgesList';
import birthdays from './birthdays';
import mobileApp from './mobileAppWidget';
import secretSanta from './secretSanta';
import secretSantaWidgetList from './secretSantaWidgetList';
import loader from './loader';
import survey from './survey'
import myBadges from './myBadges'
import subscribers from './subscribers'
import subscriptions from './subscriptions'
import ratings from './ratings'
import quiz from './quiz'
import coursesMy from './coursesMy'
import banner from './banner';
import ratingBadge from './ratingBadge';
import wikiArticles from './wikiArticles';
import wikiActueleArticles from './wikiActueleArticles';
import remindFillProfile from './remindFillProfile';
import calendarmini from './calendarmini';
import calendarWidget from './calendarWidget';
import AlertWidget from './AlertWidget';
import FloatMessageWidget from './FloatMessageWidget';
import QuickLinks from './QuickLinks'
import hierarchyNew from './hierarchyNew'
import wikilist from './wikilist';

const structures: { [s: string]: any } = {
  loader,
  common,
  list,
  timeline,
  timelineonce,
  timelinehead,
  eventstoday,
  shop,
  info,
  sliderlink,
  ratingmini,
  usersGroup,
  filters,
  charts,
  album,
  hierarchy,
  license,
  quests,
  virtualUser,
  tagsCloud,
  iframe,
  usersList,
  statistic,
  statisticFilters,
  userfields,
  layout,
  blocks,
  badgesList,
  birthdays,
  mobileApp,
  secretSanta,
  secretSantaWidgetList,
  survey,
  myBadges,
  subscribers,
  subscriptions,
  ratings, 
  quiz,
  coursesMy,
  banner,
  ratingBadge,
  wikiArticles,
  wikiActueleArticles,
  remindFillProfile,
  calendarmini,
  calendarWidget,
  AlertWidget,
  FloatMessageWidget,
  QuickLinks,
  hierarchyNew,
  wikilist
}

export const getStructure = (name: string) => structures[name] || structures.common

export default structures;