import React, {FC} from 'react'
import { OrderBtn } from './OrderBlock.styled'
import { Typography } from 'muicomponents/src'


interface IOrderBlockButtonProps {
    text: string
    id: string | number
    variant?: 'contained' | 'outlined' | 'text'
    disabled?: boolean
    errorColor?: boolean
    cb?: () => void
}

export const OrderBlockButton: FC<IOrderBlockButtonProps> = ({
    text,
    id,
    variant = 'contained',
    disabled,
    errorColor = false,
    cb,
}: IOrderBlockButtonProps) => {

    return <OrderBtn variant={variant} fullWidth
        disabled={disabled}
        to={`/shop/product/${id}`}
        onClick={() => cb && cb() }
    >
        <Typography color={errorColor ? 'error' : 'unset'} fontSize='small'>
            {text}
        </Typography>
    </OrderBtn>
}