import { connect } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18n from 'localizations/i18n';
import { Avatar, SearchInput } from 'uielements/src'
import { Icon, Button as PryanikyButton, SplitButton } from 'uielements/src';
import { Layout } from '../../Layout/InfoBlock-Layout';
import { mapStateToProps, IGroupStateProps, IGroupProps, mapDispatchToProps } from './Edit.index';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
// import { Button } from 'uielements/src/Button/Button';
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput';
import { cnGroup } from '../InfoBlock-Group.index';
import './InfoBlock-Group_edit_true.scss';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress, TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { InputMention } from 'blocks/InputMention/InputMention';
import { ImgLoader } from 'blocks/ImgLoader/ImgLoader';
import Button from '@material-ui/core/Button';
import { myTheme, useStyles } from './InfoBlock-Group_edit_true.style'
import { Select as PrnSelect } from 'blocks/PryanikyForms'
import { renderChipDND } from 'blocks/PryanikyForms/renderers'
import { BadgeSelector } from 'uielements/src/BadgeSelector/BadgeSelector'
import { defaultBadge } from 'blocks/NewsTypes/Badges/Badges.type'
import { Bable } from 'blocks/Bable/Bable'
import { UserMention } from 'uielements/src/UserMention/UserMention'
import { toast } from 'react-toastify';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { DialogComments as MoveGroupToAllNetworkDialog } from 'blocks/Dialogs/Comments/Comments';
import autocompleteWithHelp from 'uielements/src/MaterialElements/withSuggester'
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import Skelet from '../../Skelet'
import PB from 'uielements/src/MaterialElements/ProgressButton'
import withGroupProcessing from 'redux/sagas/Groups/HOCs/withGroupProcessing'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Translate } from '../../../../localizations/Translate';
import { ErrorMsg } from 'uielements/src'
import Suggest from 'uielements/src/MaterialElements/Autocomplete';

import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { NodeBulder } from 'uielements/src/CommonmarkRender'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { rawToMd } from 'uielements/src/PryanikyEditorV1/converter/convertorConfigs'
import { videoUrls } from 'utils/src/videoUrls';
import createModal from 'uielements/src/Dialog/VideoDilog/VideoDialog';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { BaseSuggester, GroupsSuggester, NotificationSuggester } from 'muicomponents/src/Suggester';
import { LoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton';
import { AlertMessage } from 'muicomponents/src/AlertMessage/AlertMessage'
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { SSelectableObject } from 'utils/src';
const ProgressButton = withGroupProcessing(PB)
// import './InfoBlock-Group.scss';

const postTypesToSelectableObject = (postType: any): SSelectableObject => {
    return {
        id: postType.id,
        displayName: postType.displayName,
        type: 'newsType',
        imgId: GUID_EMPTY,
        imgUrl: ''
    }
};


export const GroupPresenter: React.FC<IGroupProps> = ({
    className,
    groupId,
    settings,
    authUser,
    loadSettings,
    updateSettings,
    postTypes,
    applyTemplateToAll,
    testAutoInclude,
    setAutoInclude,
    appSettings,
    sendIntercompanyRequest,
    cancleIntercompanyRequest,
    applyEdit,
    cancleEdit,
    parentNetwork,
    selectTagsAuto,
    loadGroup,
    group
}) => {
    useEffect(() => {
        loadSettings(groupId)
        loadGroup(groupId)
    }, [])
    const classes = useStyles();
    const [requestAllNetwrksDialog, setRequestAllNetwrksDialog] = useState(false);
    const showModal = () => {
        createModal({
            data: {
                title: "videohelp",
                type: 'vimeo',
                url: videoUrls.group.edit
            }
        }).then(() => console.log("modal closed"))
    }
    const isAdmin = authUser.baseData.isAdmin;

    if (!group?.pkid) return (<div className={cnGroup({}, [className])}><Skelet /></div>)
    if (!group?.isGroupAdmin && !isAdmin) return (<div className={cnGroup({}, [className])}>
        <AlertMessage severity='error' variant='filled'>
            <Translate i18nKey='pryaniky.group.settings.forbidden' />
        </AlertMessage>
    </div>)
    if (!settings) return (<div className={cnGroup({}, [className])}><Skelet /></div>)



    const applyTemplate = () => applyTemplateToAll(groupId)
    const testInclude = () => testAutoInclude(groupId)
    const setInclude = () => setAutoInclude(groupId)

    const update = (key: string, value: any) =>
        updateSettings(groupId, { ...settings, data: { ...settings.data, [key]: value } })

    const changeText = (property: string) => (e: any) => update(property, e.target.value)
    const changeRadio = (property: string) => (e: any) => update(property, e.target.value)
    const changeArray = (property: string) => (array: any[]) => update(property, array)
    const changeObject = (property: string) => (value: any) => update(property, value)
    const changeSelect = (property: string) => (event: React.ChangeEvent<{ value: unknown }>) => update(property, event.target.value)
    const changeCheck = (property: string) => () => update(property, !settings.data[property])

    const handleMoveNewsType = (dragIndex: any, hoverIndex: any, dragItem: string) => {
        let r = [...settings.data.groupNewsTypes]
        r.splice(dragIndex, 1)
        r.splice(hoverIndex, 0, settings.data.groupNewsTypes[dragIndex])
        update('groupNewsTypes', r)
    }

    const imgLoaded = (loadedImg: { [s: string]: any }) => {
        updateSettings(groupId, {
            ...settings, data: {
                ...settings.data,
                imgId198x198: loadedImg.id,
                imgId: loadedImg.id,
                imgUrl198x198: loadedImg.originalUrl,
                imgUrl: loadedImg.originalUrl
            }
        })
    }

    let showBadge = !settings.data.showGroupInSubnetworks && isAdmin
    showBadge = (settings.data.showGroupInSubnetworks && !parentNetwork) ? true : showBadge

    return (
        <div className={cnGroup({}, [className])}>
            <Layout
                left={
                    <div className={'InfoBlock-Img'}>
                        <ImgLoader
                            type={'profileEdit'}
                            previewUrl={settings.data.imgUrl198x198}
                            onLoadEnd={imgLoaded}
                        />
                    </div>
                }
                right={
                    <div className={cnGroup('Data')}>
                        <Icon style={{ color: "#999", position: "absolute", top: "1px", right: "45px", fontSize: "26px", cursor: "pointer" }}
                            className={cnGroup('HelpTooltip')}
                            icon='video'
                            onClick={() => showModal()} />
                        {/* <HelpTooltip style={{ color: "#999", position: "absolute", top: "-4px", right: "3px", fontSize: "26px" }}
                            className={cnGroup('HelpTooltip')}
                            help={i18n.t('pryaniky.group.edit.help')}> */}

                        <PryanikyButton target="_blank" type="link" noBackground noBorder href={"https://pryaniky.com/ru-helps/groups-5/"}>
                            <Tooltip
                                style={{ color: "#999", position: "absolute", top: "0px", right: "3px", fontSize: "26px" }}
                                title={i18n.t('pryaniky.group.edit.help')}>
                                <span>  <Icon style={{ color: "#999" }} className={cnGroup('Icon')} icon={'question-circle'} /></span>
                            </Tooltip>
                        </PryanikyButton>

                        {/* </HelpTooltip> */}
                        <MuiThemeProvider theme={myTheme}>
                            <div className={classes.root}>

                                <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                                    <TextField fullWidth
                                        label={i18n.t('pryaniky.modal.groupCreate.name')}
                                        // inputProps={{ className: classes.formControlName }}
                                        // variant={'outlined'}
                                        className={classes.formControlName}
                                        value={settings.data.displayName}
                                        onChange={changeText('displayName')}
                                    />
                                </FormControl>
                                <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                                    <PryanikyEditor
                                        // maxFileSize={maxFileSize}
                                        enableModules={markdownPreset}
                                        // toolBarStyle={{ top: 108 }}
                                        value={settings.data.descriptionDraft}
                                        setActionBtn={(v: any) => { }}
                                        onChange={changeObject('descriptionDraft')}
                                    />
                                    {/* <TextField fullWidth
                                        multiline={true}
                                        label={i18n.t('description')}
                                        value={settings.data.description}
                                        onChange={changeText('description')}
                                    /> */}
                                </FormControl>

                                <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                                    <Suggest
                                        allowCreate
                                        type='tags'
                                        value={settings.data.analyticTags || []}
                                        onChange={changeArray('analyticTags')}
                                        label={i18n.t('Выберите теги')}
                                    />
                                </FormControl>

                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.confirmBtn}
                                    onClick={() => selectTagsAuto({ groupPKID: groupId })}
                                >
                                    {i18n.t('auto tags selection')}
                                </Button>

                                <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                                    <RadioGroup
                                        row={true}
                                        className={classes.formControlVisibilityType}
                                        name="visibilityType"
                                        value={settings.data.visibilityType.toString()}
                                        onChange={changeRadio('visibilityType')}
                                    >
                                        <FormControlLabel value={'0'} control={<Radio color="default" />} label={i18n.t('public group')} />
                                        <FormControlLabel value={'1'} control={<Radio color="default" />} label={i18n.t('private group')} />
                                    </RadioGroup>
                                </FormControl>

                                <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                    <FormControlLabel control={<Checkbox
                                        checked={settings.data.isHidden}
                                        onChange={changeCheck('isHidden')}
                                        color="default"
                                        inputProps={{
                                            'aria-label': 'checkbox with default color',
                                        }}
                                    />} label={i18n.t('visibli_only_for_members')} />
                                </FormControl>

                                <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                    <FormControlLabel control={<Checkbox
                                        checked={settings.data.isContentDisabled}
                                        onChange={changeCheck('isContentDisabled')}
                                        color="default"
                                        inputProps={{
                                            'aria-label': 'checkbox with default color',
                                        }}
                                    />} label={<Translate i18nKey={'pryaniky.group.settings.forbidContentOnGroup'} />} />
                                </FormControl>

                                <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                    <FormControlLabel control={<Checkbox
                                        checked={settings.data.disallowUserPublications}
                                        onChange={changeCheck('disallowUserPublications')}
                                        color="default"
                                        inputProps={{
                                            'aria-label': 'checkbox with default color',
                                        }}
                                    />} label={<Translate i18nKey={'pryaniky.group.settings.disallowUserPublications'} />} />
                                </FormControl>

                                <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                    <FormControlLabel control={<Checkbox
                                        checked={settings.data.disallowUserComments}
                                        onChange={changeCheck('disallowUserComments')}
                                        color="default"
                                        inputProps={{
                                            'aria-label': 'checkbox with default color',
                                        }}
                                    />} label={<Translate i18nKey={'pryaniky.group.settings.disallowUserComments'} />} />
                                </FormControl>

                                {isAdmin && appSettings.enabledModules && appSettings.enabledModules.chat &&
                                    <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                        <FormControlLabel control={<Checkbox
                                            checked={settings.data.chatEnabled}
                                            onChange={changeCheck('chatEnabled')}
                                            color="default"
                                            inputProps={{
                                                'aria-label': 'checkbox with default color',
                                            }}
                                        />} label={i18n.t('chat_enable')} />
                                        {settings.data.chatEnabled && <TextField disabled={!settings.data.chatEnabled} fullWidth
                                            label={i18n.t('chat_chanel_name')}
                                            value={settings.data.chatChannelName}
                                            onChange={changeText('chatChannelName')}
                                        />}
                                    </FormControl>
                                }

                                {isAdmin &&
                                    <>
                                        <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                            <FormControlLabel control={<Checkbox
                                                checked={settings.data.isSystemGroup}
                                                onChange={changeCheck('isSystemGroup')}
                                                color="default"
                                                inputProps={{
                                                    'aria-label': 'checkbox with default color',
                                                }}
                                            />} label={i18n.t('system group')} />
                                        </FormControl>

                                        <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                            <FormControlLabel control={<Checkbox
                                                checked={settings.data.isOfficialGroup}
                                                onChange={changeCheck('isOfficialGroup')}
                                                color="default"
                                                inputProps={{
                                                    'aria-label': 'checkbox with default color',
                                                }}
                                            />} label={i18n.t('official group')} />
                                        </FormControl>
                                    </>
                                }

                                <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                    <BaseSuggester
                                        fullWidth
                                        TextFieldProps={{
                                            label: i18n.t('news_types'),
                                            variant: 'standard'
                                        }}
                                        multiple
                                        withCheckboxes
                                        sortableValue
                                        value={
                                            settings.data.groupNewsTypes.map((postTypeId: string) => {
                                                const postType = postTypes.find(el => el.id === postTypeId);
                                                return !postType ? undefined : postTypesToSelectableObject(postType);
                                            }).filter(Boolean)
                                        }
                                        onChange={(event, value) => {
                                            if(Array.isArray(value)) {
                                                changeArray('groupNewsTypes')(value.map(el => el.id));
                                            }
                                        }}
                                        options={postTypes.map((el: any) => {
                                            let displayName = el.displayName;
                                            if(
                                                el.id === 'thanks'
                                                && appSettings.disableThanks
                                            ) {
                                                displayName = `${displayName} ${Translate.t({ i18nKey: 'pryaniky.settings.disableThanksInfo' })}`
                                            }
                                            return postTypesToSelectableObject({
                                                ...el,
                                                displayName
                                            });
                                        })}
                                    />
                                </FormControl>
                                
                                {
                                    isAdmin &&
                                    <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                        <GroupsSuggester
                                            fullWidth
                                            TextFieldProps={{
                                                label: i18n.t('parent_group'),
                                                variant: 'standard'
                                            }}
                                            value={settings.data.rootGroup}
                                            onChange={(event, value) => {
                                                if(!Array.isArray(value)) {
                                                    changeObject('rootGroup')(value);
                                                }
                                            }}
                                            requestAdditionalParams={{
                                                excludeMe: false,
                                                excludeSystemGroups: true
                                            }}
                                        />
                                    </FormControl>
                                }

                                {/* <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                    <label className={classes.formLabel}>{i18n.t('news_types')}</label>
                                    <PrnSelect
                                        placeholder={i18n.t('news_types')}
                                        openToggler={(open: boolean, setOpen: any) => <span onClick={() => setOpen(!open)}><Icon icon={'chevron-down'} /></span>}
                                        onChange={changeArray('groupNewsTypes')}
                                        disasbleFilter={true}
                                        className={cnGroup('GroupNewsTypes')}
                                        closeOnSelect={false}
                                        excludeFilter={(values: any) => values}
                                        value={settings.data.groupNewsTypes.filter((el: string) => postTypes.map((value: any) => value.id).includes(el))}
                                        variants={postTypes.map((value: any) => value.id)}
                                        itemChip={renderChipDND(handleMoveNewsType, (val: any) => {
                                            const currentType = postTypes.find((v: any) => v.id === val)
                                            return currentType.displayName
                                        })}
                                        items={(values: any, onClick: (item: any) => () => void, focusIndex: number = 0, selected: any[] = []) =>
                                            values.map((value: any, i: any) => {
                                                const currentType = postTypes.find((v: any) => v.id === value)

                                                return <div className={cnGroup('GroupNewsTypesItem') + (i === focusIndex ? ' focused_item' : '')} onClick={onClick(value)}>
                                                    <Checkbox
                                                        checked={selected.includes(value)}
                                                        onChange={(e: any) => { onClick(value); e.stopPropagation() }}
                                                        color="default"
                                                        inputProps={{
                                                            'aria-label': 'checkbox with default color',
                                                        }}
                                                    />
                                                    <span>
                                                        {currentType.displayName}
                                                        {value === 'thanks' && appSettings.disableThanks && <Translate i18nKey={'pryaniky.settings.disableThanksInfo'} />}
                                                    </span>
                                                </div>
                                            })}
                                    />
                                </FormControl> */}

                                {/* {isAdmin && <>
                                    <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                        <Autocomplete
                                            fullWidth
                                            type={'groups'}
                                            multiple={false}
                                            label={i18n.t('parent_group')}
                                            value={settings.data.rootGroup}
                                            additionalProps={{ oldModel: true }}
                                            onChange={changeObject('rootGroup')}
                                            additionalRequestProps={{ excludeSystemGroups: 'true' }}
                                        />
                                    </FormControl></>
                                } */}
                                <h4>{i18n.t('pryaniky.group.settings.optional')}</h4>
                                <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                    <InputLabel htmlFor="defaultNotifyTemplate">
                                        <Translate i18nKey={'pryaniky.group.settings.notificationSettings'} />
                                    </InputLabel>
                                    <Select
                                        id={'defaultNotifyTemplate'}
                                        value={settings.data.defaultNotifyTemplate}
                                        onChange={changeSelect('defaultNotifyTemplate')}
                                        input={<Input id="select-multiple-checkbox" />}
                                        renderValue={(selected) => <div className={classes.typesSelect}>{i18n.t('pryaniky.notifications.settings.template.' + selected as string)}</div>}
                                    >
                                        {["all", "common", "none"].map((name: any) => (
                                            <MenuItem key={name} value={name}>
                                                <ListItemText primary={i18n.t('pryaniky.notifications.settings.template.' + name)} />
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {isAdmin &&
                                        <div className={classes.peopleAutoIncludeActions}>
                                            <Button id='group-edit-saveEdit'
                                                className={classes.peopleAutoIncludeBtn}
                                                color="primary"
                                                variant="contained"
                                                onClick={applyTemplate}
                                            >{i18n.t('pryaniky.group.settings.applyNotificationsTemplate')}</Button>
                                        </div>
                                    }

                                    <NotificationSuggester
                                        fullWidth
                                        TextFieldProps={{
                                            label: <Translate i18nKey='pryaniky.group.settings.welcomeMailTemplateName' />,
                                            sx: {
                                                marginTop: '16px'
                                            }
                                        }}
                                        value={settings.data.welcomeMailTemplateName && {
                                            id: 'selected',
                                            displayName: settings.data.welcomeMailTemplateName,
                                            type: 'messageTemplate'
                                        }}
                                        onChange={(e, value) => {
                                            if (!Array.isArray(value)) {
                                                changeObject('welcomeMailTemplateName')(value?.displayName || null)
                                            }
                                        }}
                                        requestAdditionalParams={{
                                            prefix: 'groups-'
                                        }}
                                    />
                                </FormControl>

                                <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                    <FormControlLabel control={<Checkbox
                                        checked={settings.data.isModeratedGroup}
                                        onChange={changeCheck('isModeratedGroup')}
                                        color="default"
                                        inputProps={{
                                            'aria-label': 'checkbox with default color',
                                        }}
                                    />} label={i18n.t('moderating_in_group')} />
                                </FormControl>

                                {settings.data.isModeratedGroup &&
                                    <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                        <Autocomplete
                                            fullWidth
                                            type={'users'}
                                            label={i18n.t('pryaniky.group.settings.addModerators')}
                                            value={settings.data.moderators}
                                            onChange={changeArray('moderators')}
                                        />
                                        {/* <PrnUserInput
                                            hideDialog
                                            onChange={changeArray('moderators')}
                                            placeholder={i18n.t('pryaniky.group.settings.addModerators')}
                                            value={settings.data.moderators}
                                            className={cnGroup('ModeratorSelect')}
                                            excludeUsers={settings.data.moderators} /> */}
                                    </FormControl>
                                }

                                {
                                    settings.data.showGroupInSubnetworks && i18n.t('pryaniky.subnetworks.intercompanyRequestAprooved')
                                }


                                {
                                    (isAdmin && (!appSettings.parentNetwork || appSettings.parentNetwork === null)) &&
                                    <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                        <FormControlLabel control={<Checkbox
                                            checked={settings.data.showGroupInSubnetworks}
                                            onChange={changeCheck('showGroupInSubnetworks')}
                                            color="default"
                                            inputProps={{
                                                'aria-label': 'checkbox with default color',
                                            }}
                                        />} label={i18n.t('pryaniky.group.settings.showGroupInSubnetworks')} />
                                    </FormControl>
                                }

                                {
                                    (appSettings.parentNetwork) &&
                                    <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                                        {/* <MoveGroupToAllNetworkDialog
                                            isShown={requestAllNetwrksDialog}
                                            onClose={() => setRequestAllNetwrksDialog(false)}
                                            onConfirm={(data) => sendIntercompanyRequest(groupId, data)}
                                            data={{}}
                                        /> */}
                                        {
                                            settings.data.showGroupInSubnetworks || (settings.data.groupTransferRequest &&
                                                (settings.data.groupTransferRequest.status.toString() === '1' ||
                                                    settings.data.groupTransferRequest.status.toString() === 'RequestApproved')) &&
                                            i18n.t('pryaniky.subnetworks.intercompanyRequestAprooved')
                                        }
                                        {
                                            !settings.data.showGroupInSubnetworks &&
                                            (
                                                // RequestAdded = 0, RequestApproved = 1, RequestDeclined = 2
                                                !settings.data.groupTransferRequest ||
                                                (settings.data.groupTransferRequest &&
                                                    (settings.data.groupTransferRequest.status.toString() === '2' ||
                                                        settings.data.groupTransferRequest.status.toString() === 'RequestDeclined'))
                                            ) &&
                                            <>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => MoveGroupToAllNetworkDialog({}).then((data: any) => sendIntercompanyRequest(groupId, data)).catch(() => { })}
                                                    children={i18n.t('pryaniky.subnetworks.intercompanyRequestSend')} />
                                                {/* <Button color="primary" variant="contained" onClick={() => setRequestAllNetwrksDialog(true)} children={i18n.t('pryaniky.subnetworks.intercompanyRequestSend')} /> */}
                                                {
                                                    settings.data.groupTransferRequest && (
                                                        <div>
                                                            {i18n.t('pryaniky.subnetworks.intercompanyRequestDeclined')}
                                                            <div>
                                                                {`${i18n.t('pryaniky.subnetworks.intercompanyRequestDeclined.adminComment')}: ${settings.data.groupTransferRequest.adminComment}`}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        }
                                        {
                                            !settings.data.showGroupInSubnetworks &&
                                            settings.data.groupTransferRequest &&
                                            (settings.data.groupTransferRequest.status.toString() === '0' ||
                                                settings.data.groupTransferRequest.status.toString() === 'RequestAdded') &&
                                            <>
                                                <Button color="primary" variant="contained" onClick={() => {
                                                    utilsProj.confirm({
                                                        text: i18n.t("pryaniky.subnetworks.intercompanyRequestCancel.confirm"),
                                                        onConfirm: () => cancleIntercompanyRequest(groupId, i18n.t('pryaniky.subnetworks.intercompanyRequestCancelComment'))
                                                    });
                                                }} children={i18n.t('pryaniky.subnetworks.intercompanyRequestCancel')} />
                                                <div children={i18n.t('pryaniky.subnetworks.intercompanyRequestOnModeration')} />
                                            </>
                                        }
                                    </FormControl>
                                }

                                {isAdmin &&
                                    <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                                        <TextField fullWidth
                                            multiline={true}
                                            label={i18n.t('pryaniky.group.settings.autoInclude')}
                                            value={settings.data.peopleAutoIncludeExpression}
                                            onChange={changeText('peopleAutoIncludeExpression')}
                                        />
                                        <div className={classes.peopleAutoIncludeActions} >
                                            {(settings.data.peopleAutoIncludeExpression && '' !== settings.data.peopleAutoIncludeExpression)
                                                &&
                                                <LoadingButton
                                                    id='group-edit-saveEdit'
                                                    className={classes.peopleAutoIncludeBtn}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={testInclude}
                                                    disabled={settings.testAutoIncludeProgress}
                                                    loading={settings.testAutoIncludeProgress}
                                                >
                                                    {i18n.t('pryaniky.group.settings.autoInclude.test')}
                                                </LoadingButton>
                                            }

                                            {(settings.data.peopleAutoIncludeExpression && '' !== settings.data.peopleAutoIncludeExpression)
                                                &&
                                                <LoadingButton
                                                    id='group-edit-saveEdit'
                                                    className={classes.peopleAutoIncludeBtn}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={setInclude}
                                                    disabled={settings.setAutoIncludeProgress}
                                                    loading={settings.setAutoIncludeProgress}
                                                >
                                                    {i18n.t('pryaniky.group.settings.autoInclude.add')}
                                                </LoadingButton>
                                            }

                                            {/* <FormControl margin="normal" component="fieldset" className={classes.formControl}> */}
                                            <FormControlLabel control={<Checkbox
                                                checked={settings.data.removeUsersByAutoInclude}
                                                onChange={changeCheck('removeUsersByAutoInclude')}
                                                color="default"
                                                inputProps={{
                                                    'aria-label': 'checkbox with default color',
                                                }}
                                            />} label={<Translate i18nKey={'pryaniky.group.settings.autoInclude.excludeOnMismatch'} />} />
                                            {/* </FormControl> */}
                                        </div>


                                    </FormControl>
                                }

                                {settings.testAutoInclude &&
                                    <div className={'AutoIncludeList'}>
                                        <div>
                                            <Translate i18nKey={'pryaniky.group.settings.autoInclude.testRezult'} />:
                                        </div>
                                        {
                                            settings.testAutoInclude?.length === 0 &&
                                            <Translate i18nKey={'pryaniky.group.settings.autoInclude.testRezult.null'} />
                                        }
                                        {settings.testAutoInclude.map((val: any) => <Bable className={'AutoIncludeUser'}>
                                            <UserMention className={'UserMention-displayName'} name={val.displayName} id={val.id} noLink={true} />
                                        </Bable>)}
                                    </div>}

                                {showBadge &&
                                    <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                                        <BadgeSelector
                                            selectedBadge={settings.data.badge || defaultBadge}
                                            badgeList={{ badgeRenderParams: { badges: settings.badges } }}
                                            onChange={changeObject('badge')}
                                        />
                                    </FormControl>
                                }

                                {settings.data.badge &&
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormControlLabel control={<Checkbox
                                            checked={settings.data.removeLinkedBadgeOnLeave}
                                            onChange={changeCheck('removeLinkedBadgeOnLeave')}
                                            color="default"
                                            inputProps={{
                                                'aria-label': 'checkbox with default color',
                                            }}
                                        />} label={<Translate i18nKey={'pryaniky.group.settings.removaBadgeOnExit'} />} />
                                    </FormControl>}

                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel control={<Checkbox
                                        checked={settings.data.createCalendarByDefault}
                                        onChange={changeCheck('createCalendarByDefault')}
                                        color="default"
                                        inputProps={{
                                            'aria-label': 'checkbox with default color',
                                        }}
                                    />} label={<Translate i18nKey={'pryaniky.group.settings.createCalendarByDefault'} />} />
                                </FormControl>


                            </div>
                            {settings.data.groupNewsTypes.length === 0 && <ErrorMsg><Translate i18nKey={'pryaniky.group.settings.error.not_selected_posttypes'} /></ErrorMsg>}
                            <div className={classes.confirmBox}>
                                <Button
                                    id='group-edit-cancleEdit'
                                    className={classes.confirmBtn}
                                    disableElevation
                                    onClick={() => cancleEdit(groupId)}
                                >{i18n.t('cancel')}</Button>
                                <ProgressButton
                                    disabled={settings.data.groupNewsTypes.length === 0}
                                    groupPKID={groupId}
                                    pid={"APPLY_EDIT"}
                                    id='group-edit-saveEdit'
                                    className={classes.confirmBtn}
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    onClick={() => applyEdit(groupId)}
                                >{i18n.t('save')}</ProgressButton>
                            </div>
                        </MuiThemeProvider>
                    </div>

                } />
        </div>
    );
};

export const Group = connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupPresenter);
