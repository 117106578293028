import { Box, Paper } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';

export const HeaderPaper = styled(Paper)({
  padding: '24px',
  marginBottom: '24px'
}) as typeof Paper;

export const HeaderTop = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  columnGap: '16px',
  marginBottom: '24px'
}) as typeof Box;

export const HeaderFilters = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  columnGap: '20px'
}) as typeof Box;

export const HeaderActions = styled(Box)({
  flexShrink: 0,
  display: 'flex',
  columnGap: 10
}) as typeof Box;

