import { bindActionCreators } from 'redux';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'
import {
    UeventNews
} from './EventsType'

export interface IEventsPropsType extends NewsContextProps {
}

export type TStateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
export interface IEventsStateType {
}

const newsParams = [
    'text',
    'id',
    'uevent', 
    'header',
    'attachments'
] as const

export const mapStateToProps = (state: any, { newsId }: IEventsPropsType) => ({
    userData: getCurrentUser(state),
    ...getNewsParamsById<UeventNews, typeof newsParams[number]>(newsId, newsParams)(state)
})


export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);