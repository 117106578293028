import React, { FC, useEffect, useState } from 'react';
import { IBadgeWidgetNewProps } from './BadgeWidgetMui.index'
import { Translate } from 'localizations/Translate';
import noData from 'assets/svg/NoData.svg'
import { Box, Typography } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link/Link';
import { WidgetTypeSkeletonMUI } from 'blocks/Widget/_type/Widget_type_skeletonMUI';
import { Avatar } from 'muicomponents/src/Avatar';
import { CountTypography, FlexBox, HeaderFlexBox } from 'blocks/RatingsWidgetMui/styled';
import { BadgesTooltipList } from './BadgesList/BadgesTooltipList';
import { BadgeItemMui } from './BadgesList/BadgeItem/BadgeItemMui';
import { TwoItemsBox } from './../styled';
import { IBadgeItem } from './BadgesList/BadgesTooltipList.index';
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';
export interface ICountBlockProps {
    visibleBadges: number
    badgesAmount: number
    uid: string
}

const BadgeHeader = () => {
    return <Typography fontWeight='700' >
        <Translate i18nKey={'pryaniky.search.tab.rewards'} />
    </Typography>
}

const CountBlock: FC<ICountBlockProps> = ({ visibleBadges, badgesAmount, uid }: ICountBlockProps) => {

    return <HeaderFlexBox>
        <FlexBox>
            <Link href={`${window.location.origin}/user/${uid}/badges`} underline='none'>
                <BadgeHeader />
            </Link>
        </FlexBox>
        {visibleBadges !== badgesAmount && <FlexBox>
            <CountTypography >{visibleBadges}</CountTypography>
            <CountTypography color='gray' >{"/" + badgesAmount}</CountTypography>
        </FlexBox>}
    </HeaderFlexBox>
}

export const BadgeWidgetMui: FC<IBadgeWidgetNewProps> = ({
    uid,
    isLoading,
    loadBadges,
    badges,
    settings,
    removeBadge,
}) => {

    const [visibleBadges, setVisibleBadges] = useState(1)

    const remove = (bid: string) => {
        removeBadge && removeBadge(uid, bid)
    }

    useEffect(() => {
        loadBadges(uid)
    }, [uid]);

    if (isLoading) return <WidgetTypeSkeletonMUI />

    if (settings?.hideIfNoData && badges.length === 0) return null
    if (!settings?.hideIfNoData && badges.length === 0) return <>
        <BadgeHeader />
        <CardMedia
            component="svg"
            className={"EmptyList-Avatar"}
            sx={{
                width: '100%',
                height: "107px",
                marginBottom: "12px"
            }}
            image={EmptyWidget}
        />
        <Typography>
            <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
        </Typography>
    </>

    // в соответствии с макетом разные сетки на 1, 2-3 и множество бейджей 
    const iconSize = badges.length === 1 ? 100 : badges.length > 2 ? 64 : 40

    if (badges.length === 1) return <>
        <CountBlock visibleBadges={1} badgesAmount={badges.length} uid={uid} />
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} >
            <BadgeItemMui item={badges[0]} size={iconSize} onRemove={removeBadge && remove} />
            <Typography variant='subtitle2' fontWeight={600} >{badges[0].name}</Typography>
            <Typography variant='caption' noWrap >{badges[0].description}</Typography>
        </Box>
    </>

    if (badges.length === 2) return <>
        <CountBlock visibleBadges={2} badgesAmount={badges.length} uid={uid} />
        <TwoItemsBox>
            {badges.map((badge: IBadgeItem) => <Box>
                <BadgeItemMui item={badge} size={iconSize} onRemove={removeBadge && remove} />
                <Box overflow='hidden' >
                    <Typography variant='subtitle2' fontWeight={600}>{badge.name}</Typography>
                    <Typography textOverflow='ellipsis' variant='caption' paragraph >
                        {badge.description}
                    </Typography>
                </Box>
            </Box>
            )}
        </TwoItemsBox>
    </>

    return <Box>
        <CountBlock visibleBadges={visibleBadges} badgesAmount={badges.length} uid={uid} />
        <Box display='flex'>
            <BadgesTooltipList
                items={badges}
                onRemove={removeBadge && remove}
                size={iconSize}
                setVisibleBadges={setVisibleBadges}
            />
        </Box>
    </Box>
}