import React, { ReactText } from 'react';
import { connect } from 'react-redux';
import { Translate } from '../../../localizations/Translate';
import { Button, Icon } from 'uielements/src';
import { AuthorsList } from '../WikiAuthorList/WikiAuthorList';
import './Item.scss';
import { DialogWikiEdit } from '../../Dialogs/WikiCreate/Edit/WikiEdit';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { Actions } from '../../NewsView/Actions/Actions';
import { Like } from '../../NewsView/Actions/Like/Like'
import MDRender from 'uielements/src/CommonmarkRender'
import Folder from 'assets/svg/Folder.svg'
import {
    IWikiItemProps,
    IWikiItemState,
    mapStateToProps,
    mapDispatchToProps,
    cnItem
} from './Item.index';
import { UserList } from 'uielements/src/UserList/UserList';
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import { ContextMenu } from '../ContextMenu/ContextMenu'
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import { formatDateSTD } from 'utils/src/utils.dates';

export class WikiItemPresenter extends React.Component<IWikiItemProps, IWikiItemState>{
    constructor(props: any) {
        super(props);
        this.state = {
            showDialog: false,
            liked: false,

        };
    }


    public render() {
        const { tag: TagName = 'div', className = '', data, changeItem, showControls, context } = this.props;

        const { likeCount = 0, liked = false } = this.props.news;

        const outLink = (data.url.indexOf('http') === 0 || data.url.indexOf('//') === 0) && !data.url.includes(window.location.hostname);

        let href = outLink || data.url[0] === "/" ? data.url : "/" + data.url;

        if (!outLink && data.url.includes(window.location.hostname)) href = href.replace(href.substr(0, href.indexOf(window.location.hostname) + window.location.hostname.length), '');


        const isImg = data.titleImageId === GUID_EMPTY || data.titleImageId === null

        return (
            <>
                {/* <WikiEditDialog onConfirm={this.props.changeItem} context={context} isShown={this.state.showDialog} onClose={this.closeDialog} data={this.props.data} id={this.props.data.id} /> */}

                <div className={cnItem({}, [className])}>

                    {showControls || data.editable ? <div className={cnItem("Actions_Hedaer")}>
                        {/* <Button className={cnItem('Settings')} type="rlink" onClick={() => this.setState({ showDialog: true })}>
                            <Icon icon='ellipsis-v' className={cnItem('isShowOnTop')} />
                        </Button> */}
                        <ContextMenu {...this.props}
                            isAdmin={showControls}
                            onEdit={() => DialogWikiEdit({
                                onConfirm: changeItem,
                                context: context,
                                isShown: this.state.showDialog,
                                onClose: this.closeDialog,

                                data: data,
                                id: data.id
                            }).then(() => { }).catch(() => { })} />

                    </div> : undefined}
                    {/* {data.titleImageId === GUID_EMPTY || data.titleImageId === null ? undefined : } */}
                    {
                        data.type === "wikilist" ? <Button className={cnItem('Picture-BTN')} type={outLink ? "link" : "rlink"} href={href} noBorder noPadding>
                            <img className={cnItem('Picture-Img')} src={!isImg ? data.titleImageUrl : Folder} alt="img" />
                        </Button> : data.type !== "url" ?
                            <Button className={cnItem(isImg ? 'Picture-BTNNoImg' : 'Picture-BTN')} type={outLink ? "link" : "rlink"} href={href} noBorder noPadding>
                                <img className={cnItem(isImg ? 'Picture-Img1' : 'Picture-Img')} src={data.titleImageUrl} alt="img" />
                            </Button> :
                            isImg ? undefined :
                                <Button className={cnItem('Picture-BTN')} type={outLink ? "link" : "rlink"} href={href} noBorder noPadding>
                                    <img className={cnItem('Picture-Img')} src={data.titleImageUrl} alt="img" />
                                </Button>
                    }

                    <Button type={outLink ? "link" : "rlink"} href={href} noBorder noPadding>
                        {
                            data.title === '' && data.type !== "url" ?
                                <h3>
                                    <Translate i18nKey={'pryaniky.wikilist.item.emptyName'} />
                                </h3> :
                                data.type === "url" && data.title === '' ? undefined :
                                    <h3>
                                        {data.title}
                                    </h3>
                        }
                    </Button>

                    {
                        data.type !== "url" && <p className={cnItem('CreationDate')}>
                            <Icon icon={'calendar'} className={cnItem('ActionsIcon')} />
                            <Translate i18nKey='pryaniky.wikilist.item.created.old' values={{ date: formatDateSTD(data.creationDate, true, true) }} />
                        </p>
                    }
                    {
                        data.type !== "url" && <p className={cnItem('CreationDate')}>
                            <Icon icon={'edit'} className={cnItem('ActionsIcon')} />
                            <Translate i18nKey='pryaniky.wikilist.item.changed.old' values={{ date: formatDateSTD(data.changeDate, true, true) }} />
                        </p>
                    }


                    {
                        data.highlightedText && data.highlightedText !== null &&
                        <p className={cnItem('SearchMarkdown')}>
                            {/* ... <MDRender source={data.highlightedText} />... */}
                            {data.highlightedText && <MDRender type={'inline'} source={data.highlightedText === null ? "" : data.highlightedText} />}
                        </p>
                    }



                    {data.type === "url" && data.title === '' && <div className={cnItem("TypeUrl")}>
                        <Button type={outLink ? "link" : "rlink"} noBorder noBackground padding="md" href={href}>
                            {href}
                        </Button>

                    </div>}
                    {data.type !== "url" && <div className={cnItem('Authors')} >
                        <div className='AuthorsModerators'>
                            <h3 className='AuthorsTitle'>
                                <Translate i18nKey={'pryaniky.wikilist.item.authors'} />
                            </h3>
                            <AuthorsList authors={data.authors} className={cnItem('AuthorsList')} limit={5} /></div>

                        {data.moderators && data.moderators.length === 0 ? undefined :
                            <div className='AuthorsModerators'>
                                <h3 className='AuthorsTitle'>
                                    <Translate i18nKey={'pryaniky.wikilist.item.moderators'} />
                                </h3>
                                <UserList maxVisible={3}
                                    cut={true}
                                    action={false}
                                    activeAction={false}
                                    data={data.moderators} />
                            </div>
                        }
                    </div>}

                    <div className={cnItem("Actions_Bottom")}>

                        <Actions>
                            <Like
                                newsId={this.props.news.id}
                                likeCount={likeCount}
                                liked={liked}
                                onClick={this.changeLike}
                                className={'Action'} />
                        </Actions>
                        {data.type !== "url" ? <MuiButton className={cnItem('Start')}
                            variant={'contained'}
                            color={'primary'}
                            to={href}>
                            <span><Translate i18nKey={'pryaniky.wikilist.item.read'} /></span>
                        </MuiButton> : undefined}
                    </div>
                </div>
            </>
        )
    }
    private closeDialog = () => this.setState({ showDialog: false });
    private changeLike = (likeCount: number, liked: boolean) => {
        this.props.likeWikiRequest(this.props.news.id, likeCount, liked)

    }


}


export const Item = connect(
    mapStateToProps,
    mapDispatchToProps
)(WikiItemPresenter)



