import { GET_QUERY, SET_ACTIVE_TAB, SET_FILTERS, SET_ADDITIONAL_FILTERS, CLEAR_ADDITIONAL_FILTERS } from './actions';
import { ISearchState, TAdditionalPageFilter, TAdditionalNewsFilter, TAdditionalFileFilter, TSearchValue } from './interfaces';
import { ISearchActions } from './actions.interfaces';
import { sub, format } from 'date-fns';
import { Translate } from 'localization';

export const defaultAdditionalPageFilter: TAdditionalPageFilter = {
  author: null,
  searchOnlyTitle: false,
  categoryType: { id: 'all',  label: Translate.t({ i18nKey: 'pryaniky.filter.wikilist.all' }), value: 'all' },
  materialType: { id: 'all', label: Translate.t({ i18nKey:'pryaniky.list.wiki.filter.all'}), value: 'all' },
  order: 'abc',
  sort: 'asc',
  
}


const getToday = () => {
  const todayDate = new Date().getDate()
  const todayMonth = new Date().getMonth()
  const todayYear = new Date().getFullYear()
  return new Date(todayYear, todayMonth, todayDate, 3, 0, 0, 0)
}

const today = new Date()
const defaultStartDate = sub(getToday(), { months: 6 })

// console.log("today", today.toISOString())
export const defaultAdditionalNewsFilter: TAdditionalNewsFilter = {
  author: null,
  type: undefined,
  types:[],
  startDate: defaultStartDate.toISOString(),
  endDate: today.toISOString()
}

export const defaultAdditionalFileFilter: TAdditionalFileFilter = {
  searchOnlyName: false
}

const defaultState: ISearchState = {
  query: '',
  activeTab: 'all',
  filters: []
}

export const searchReducer = (state = defaultState, action: ISearchActions): ISearchState => {
  switch (action.type) {

    case GET_QUERY: {

      return {
        ...state,
        query: action.payload,
      }
    }

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }

    case SET_FILTERS:
      console.log("action.payload", action.payload)
      return {
        ...state,
        filters: action.payload.map((filter) => {
          switch (filter.filterName) {
            case 'pages': {
              return {
                ...filter,
                ...defaultAdditionalPageFilter
              }
            }
            case 'news': {
              return {
                ...filter,
                ...defaultAdditionalNewsFilter
              }
            }
            case 'files': {
              return {
                ...filter,
                ...defaultAdditionalFileFilter
              }
            }
            default: return filter
          }
        })
      }

    case SET_ADDITIONAL_FILTERS:
      return {
        ...state,
        filters: state.filters.map((filter) => {
          if (action.payload.filterName === filter?.filterName) {
            return {
              ...filter,
              ...action.payload.value
            }
          } else {
            return filter
          }
        })
      }

    case CLEAR_ADDITIONAL_FILTERS:
      return {
        ...state,
        filters: state.filters.map((filter) => {
          if (action.payload.filterName === 'pages') {
            return {
              ...filter,
              ...defaultAdditionalPageFilter
            }
          } else if (action.payload.filterName === 'news') {
            return {
              ...filter,
              ...defaultAdditionalNewsFilter
            }
          } else if (action.payload.filterName === 'files') {
            return {
              ...filter,
              ...defaultAdditionalFileFilter
            }
          } else {
            return filter
          }
        })
      }

    default:
      return state;
  }
}
