import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import { toast } from 'react-toastify';
import * as utils from 'utils/src/utils';
// import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";
import i18n from 'localizations/i18n';
import { Page } from '../Page/Page';
import { mapStateToProps, IPagesStateProps, cnPages, IPagesProps, IPagesState, PagesActionsToProps } from './Pages.index';
import './Pages.scss';
import { IAdditionalMenuItem } from 'utils/src/CommonRedux/base/reducer';
import { Icon } from 'uielements/src';
import { DialogWikiEdit } from '../Dialogs/WikiCreate/Edit/WikiEdit';
import pageComponents from 'pageComponents';
import { PageComments } from './PageComments/PageComments';
import { PageHistory } from './PageHistory/PageHistory';
const pageName = 'pages';

export class PagesPresenter extends React.Component<IPagesProps, IPagesState> {
  // public utils: any = utils;
  public el: Element | Text | null;
  
  private editPageButton: IAdditionalMenuItem = {
    id: 'editWikiPage',
    onClick: () => this.showEdit(),
    children: <Icon icon={'ellipsis-h'} />
  }

  private showCommentsButton: IAdditionalMenuItem = {
    id: 'showComments',
    onClick: () => this.commentsShow(),
    children: <Icon icon={'comment-lines'} />
  }
  
  constructor(props: IPagesProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {
      editShow: false,
      showComments: false,
    };
  }
  
  componentDidMount() {
  //  this.props.addToAdditionalMenu({ item: this.editPageButton, position: 1 });
    // this.props.addToAdditionalMenu({ item: this.showCommentsButton, position: 0 });
    // this.props.addToAdditionalMenu({ item: this.showCommentsButton, position: 0 });
  }
  
  componentWillUnmount() {
    this.props.removeFromAdditionalMenu({ id: this.editPageButton.id });
    this.props.removeFromAdditionalMenu({ id: this.showCommentsButton.id });
  }

  private initPageEdit = () => {
    this.setState({ dt: this.props.getLoadedPage(`${pageName}/${this.props.match.params.id}`) },
    () => {
      this.state.dt && this.state.dt.allowComments && this.props.addToAdditionalMenu({ item: this.showCommentsButton, position: 0 });
      (this.props.isAdmin || this.state.dt.isEditable) && this.props.addToAdditionalMenu({ item: this.editPageButton, position: 1 });
    });
  }

  showEdit = () => DialogWikiEdit({
    onConfirm: () => {},
    context: {},
    isShown: this.state.editShow,
    onClose: this.closeEdit,
    data:this.state.dt,
    id: this.state.dt.id
}).then(() => { }).catch(() => { })

  closeEdit = () => this.setState({ editShow: false });


  commentsShow = () => this.setState({ showComments: true });
  commentsClose = () => this.setState({ showComments: false });
  
  public render() {
    const customClassNames = '';
    const { initPageEdit } = this;
    const { tag: TagName = 'div', children, className = '' } = this.props;
    const { dt } = this.state;
    const showVersions = Boolean(dt && dt.versions && dt.versions.length);
    return (
      <TagName ref={el => this.el = el} className={cnPages({}, [customClassNames, className])}>
        {/* {dt && dt.collections.map((el:any) => el.name)} */}
        {/* <div className={cnPages("Breadcrumbs")}></div> */}
        {/* {
         dt && <WikiEditDialog onConfirm={() => {}} isShown={this.state.editShow} context={{}} onClose={this.closeEdit} data={dt} id={dt.id} />
        } */}
        
        {
           dt && this.state.showComments === true ? 
          <PageComments 
            parentRefEl={this.el}
            
              isShown={this.state.showComments} 
            onClose={this.commentsClose} 
            data={dt.news} /> : undefined
        }
        
        {
          showVersions &&
          <PageHistory 
            id={dt.id}
          />
        }
        <Page
          className={cnPages({ id: this.props.match.params.id })}
          page={{
            name: pageName + '.' + this.props.match.params.id,
            title: this.props.authUser.baseData.id === this.props.match.params.id ? i18n.t('me') : i18n.t('colleague'),
            params: { id: this.props.match.params.id, authUser: this.props.authUser, pageId: this.props.match.params.id }
          }}
          cbAfterGetData={initPageEdit} />
        {children}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Pages = connect(
    mapStateToProps,
    // mapDispatchToProps({})
    PagesActionsToProps
  )(PagesPresenter)
//   compose(
//   withBemMod(cnPages(), {})
// )(PagesPresenter))
