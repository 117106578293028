import React, { FC, useState, memo } from 'react';
import { IPrnUserInputProps } from './PrnUserInput.index';
import './PrnUserInput.scss';
import { Select } from 'blocks/PryanikyForms/Select/Select';
// import { ItemChip } from 'blocks/PryanikyForms/ItemChip/ItemChip'
import { Icon, Button, Spinner } from 'uielements/src';
import { searchUsers } from './PrnUserInput.sides';
import { items, itemChip } from './renders';
import { DialogUserInput } from './UserInputDialog';

export const excludeFilter = (values: any[], selected: any[]) =>
  values.filter((value: any) => selected.findIndex((val: any) => val.id === value.id) === -1);


export const excludeFilterPKID = (values: any[], selected: any[]) =>
  values.filter((value: any) => selected.findIndex((val: any) => val.pkid === value.pkid) === -1);

export const PrnUserInput: FC<IPrnUserInputProps> = memo(({
  onChange,
  value,
  excludeUsers: excludeVariants,
  excludeMe,
  placeholder,
  maxSelect: maxSelectCount = -1,
  hideDialog = false,
  type = 'users',
  network,
  className,
  disabledVariants = []
}) => {
  const [filterValue, setFilterStr] = useState<string>('');
  const [variants, setVariants] = useState<any[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false)

  const closeDialog = () => setShowDialog(false);
  const openDialog = () => setShowDialog(true);

  const onChangeFilter = (str: string) => {
    setLoading(true)
    searchUsers(str, (data: any) => { setVariants(data); setLoading(false) }, excludeMe, type, network);
    setFilterStr(str);
  };

  const openToggler = () => <div style={{
    position: 'relative',
    width: '24px',
    height: '24px',
    marginTop: '-5px',
  }}>{isLoading && <Spinner fontSize={16} />}</div>

  return (
    <div className={className}>
      {/* {!hideDialog && <UserInputDialog
        network={network}
        isShown={showDialog}
        onClose={closeDialog}
        onChange={onChange}
        selected={value}
        excludeVariants={excludeVariants}
        excludeMe={excludeMe}
        showGroups={type !== 'users'}
        disabledVariants={disabledVariants}
      />} */}
      <Select
        {...{
          openToggler,
          maxSelectCount,
          excludeFilter: (type === 'groups' || type === 'mygroups') ? excludeFilterPKID : excludeFilter,
          placeholder,
          excludeVariants,
          itemChip,
          items,
          onChangeFilter,
          filterValue,
          onChange,
          value,
          variants,
          disabledVariants
        }}
        icon={
          !hideDialog && <Button className={'PrnUserInput-Btn'} onClick={()=>DialogUserInput({network:network,
            // onChange:onChange,
            selected:value,
            excludeVariants:excludeVariants,
            excludeMe:excludeMe,
            showGroups:type !== 'users',
            disabledVariants:disabledVariants}).then(({result})=>{
              onChange(result)
            }).catch(()=>{})}>
            <Icon icon="user-add" />
          </Button>
        }
      />
    </div>
  );
});
