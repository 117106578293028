import { IDialog } from "../../DialogWrapper.index";
import { cn } from "@bem-react/classname";
import { IDispatchProps } from "../../../../redux/connector";
import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
import actions from 'utils/src/CommonRedux/base/actionsTypes';

export interface IWikiCreateProps extends IWikiCreateOwnProps, IBodyProps, IWikiCreateDispatchProps, IWikiCreateStateProps {
    isShown: boolean;
    onClose: () => void;
    className?: string;
    onChange?: (field: any) => void;
    showInMainManu?: boolean;
    allowComments?: boolean;
    type?: string;

}
export interface IWikiCreateOwnProps extends IDialog { };

export type IWikiCreateDispatchProps = ReturnType<typeof mapWikiCreateDispatchToProps>

export const mapWikiCreateDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    changeVm: (propPath: string, value: any) => dispatch({
        type: actions.CHANGE_STATE, payload: { propPath, value } 
      })
}, dispatch);

export type IWikiCreateStateProps = ReturnType<typeof mapWikiCreateStateToProps>;

export const mapWikiCreateStateToProps = () => ({});

export const cnWikiCreateDialog = cn("WikiCreateDialog");