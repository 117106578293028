import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateContexts } from "redux/actions/Widgets";
import { IFilterContext } from "./Filters.index";

/**
 * ONE TIME create function for change context by relations, if relations change that relations patth to function with ref
 * @param relations 
 * @returns 
 */
export const useContextFiltersChange = <S extends IFilterContext, > (relations: string[]) => {
  const dispatch = useDispatch();

  const relationsRef = useRef(relations || []);
  relationsRef.current = relations;

  return useCallback((newState: S) => {
    dispatch(updateContexts(relationsRef.current.reduce((a, c) => {
      a[c] = newState;
      return a;
    }, {} as any)));
  }, []);
};