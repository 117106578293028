import { IdeaFields, Field } from './Fields/Types'
import { ReactNode } from 'react';
import { IdeaNews } from './IdeaType'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById,
    getNewsById
} from 'News/redux/saga/selectors'
import {
    updateNews,
    pathNews
} from 'News/redux/actions'
import { bindActionCreators } from 'redux';
export interface IIdeasPropsType extends NewsContextProps {

}

export type TStateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export interface IIdeasStateType {
    actionComponent: React.ReactNode;
    controlComponent: React.ReactNode;
    actionComponentOpened?: boolean;
    data: any;
    // showChangeStatusButton: boolean;
}

const newsParams = [
    'text',
    'id',
    'header',
    'isNeedApprove'
] as const

export const mapStateToProps = (state: any, { newsId }: IIdeasPropsType) => ({
    userData: getCurrentUser(state),
    currencyName: state.store.appSettings.currencyNameNominativeSingular,
    currencyNamePlural: state.store.appSettings.currencyNameGenitivePlural,
    news: getNewsById<IdeaNews>(newsId)(state)
    // ...getNewsParamsById<IdeaNews, typeof newsParams[number]>(newsId, newsParams)(state)
})

////////////////

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    updateNews,
    pathNews
}, dispatch);



export interface RowValues {
}


















//////////////////////////////////////////////


export interface User2 {
    status: string;
    id: string;
    alias: string;
    displayName: string;
    position: string;
    division: string;
    imgId: string;
    isMan: boolean;
    statusText: string;
    sex: string;
    userPhotoUrl: string;
    userLargePhotoUrl: string;
}

export interface Expertise {
    isCompleted: boolean;
    expStatus: string;
    timestamp: Date;
    value: number;
    user: User2;
    field?: any;
}

export interface FieldAnswers {
    field: Field;
    expertAnswers: any[];
}

export interface FieldsCategory {
    category: string;
    fields: FieldAnswers[];
}

export interface ExpertiseResults {
    showExpertiseButton: boolean;
    isExpertEvaluation: boolean;
    expertises: Expertise[];
    fields: FieldsCategory[];
}


export interface RewardUsers {
    id: string;
    value: number;
}

