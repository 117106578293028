import { IShop, IShopProduct, IShopProducts, ShopListRouteParams } from './interfaces';
import { IShopItem } from 'utils/src/requests/models/api.shop';
import {
  SHOP_SET_SHOP_PRODUCT,
  SHOP_SET_SHOP_CAT_PRODUCTS,
  SHOP_SET_SHOP_LOADING,
  SHOP_CLEAR_SHOP_PRODUCT,
  SHOP_CLEAR_SHOP_CAT_PRODUCTS,
  SHOP_PATH_OPEN_PRODUCT,
  SHOP_SET_SHOP_CHOSEN_FILTERS,
  SHOP_CLEAR_SHOP_FILTERS
} from './actions';
import { IShopActions } from './actions.interfaces';
import { isArray } from 'lodash';
import { prepareLocationSearchToFilters } from './shop.utils';


const baseState: IShop = {
  categoryProducts: {} as IShopProducts,
  openShopProduct: {
    data: null,
    currentImgUrl: '',
    isHidePricesAndButtonBuy: false,
    allowOrder: true,
    errorMessage: '',
  } as IShopProduct,
  chosenFilters: prepareLocationSearchToFilters(window.location.search)
}

export const shopReducer = (state = baseState, action: IShopActions): IShop => {
  switch (action.type) {

    case SHOP_SET_SHOP_PRODUCT: {
      return {
        ...state,
        openShopProduct: {
          ...state.openShopProduct,
          ...action.payload
        }
      }
    }

    case SHOP_CLEAR_SHOP_PRODUCT: {
      return {
        ...state,
        openShopProduct: {
          ...baseState.openShopProduct
        }
      }
    }

    case SHOP_SET_SHOP_LOADING: {
      return {
        ...state,
        categoryProducts: {
          ...state.categoryProducts,
          ...action.payload
        }
      }
    }

    case SHOP_SET_SHOP_CAT_PRODUCTS: {
      return {
        ...state,
        categoryProducts: {
          ...state.categoryProducts,
          ...action.payload,
        }
      }
    }

    case SHOP_CLEAR_SHOP_CAT_PRODUCTS: {
      return {
        ...state,
        categoryProducts: {
          ...state.categoryProducts, data: [],
        }
      }
    }

    case SHOP_CLEAR_SHOP_FILTERS: {
      return {
        ...state,
        chosenFilters: baseState.chosenFilters
      }
    }

    case SHOP_PATH_OPEN_PRODUCT: {
      const { key, value } = action.payload
      return {
        ...state,
        openShopProduct: {
          ...state.openShopProduct,
          data: {
            ...state.openShopProduct.data!,
            [key]: value
          }
        }
      }
    }

    case SHOP_SET_SHOP_CHOSEN_FILTERS: {
      if (isArray(action.payload)) {
        const chosenFilters = action.payload.reduce((a, { key, value }) => {
          return {
            ...a,
            [key]: value,
          }
        }, {})
        return {
          ...state,
          chosenFilters: {
            ...state.chosenFilters,
            ...chosenFilters
          }
        }
      }
      const { key, value } = action.payload
      return {
        ...state,
        chosenFilters: {
          ...state.chosenFilters,
          [key]: value,
        }
      }
    }


    default:
      return state;
  }
}
