import React, { FC, useState, useEffect, createRef } from 'react';
import { IBadgeWidgetNewProps } from './BadgeWidgetNew.index'
import './BadgeWidgetNew.scss'
// import { Badge } from './Badge/Badge'
import { BadgeIconNew } from 'uielements/src/BadgeIconNew/BadgeIconNew'
import { Button, Spinner } from 'uielements'
// import { Button, Spinner } from 'uielements/src'
import { ListPlaceholder } from 'uielements/src';
import feedPlaceholder from 'assets/svg/feedPlaceholder.svg';
import { Translate } from 'localizations/Translate';
import { useHistory } from "react-router-dom";
import { cn } from '@bem-react/classname';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import noData from 'assets/svg/NoData.svg'
import { Typography } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link/Link';
import { WidgetTypeSkeletonMUI } from 'blocks/Widget/_type/Widget_type_skeletonMUI';
import { Avatar } from 'muicomponents/src/Avatar';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';
const cnBadgeWidgetNew = cn('BadgeWidgetNew');

export const BadgeWidgetNew: FC<IBadgeWidgetNewProps> = (props) => {
    const { uid, isLoading, isComplite, loadBadges, cats, catsList, badges, settings, sortedBadgesList, removeBadge, tag: Tag = 'div' } = props;
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const createClickBadge = (bid: string) => () => {
        history.push("/badge/" + bid);
    }

    const lastRef = createRef<HTMLDivElement>();

    const [countLastInline, setCountLastInline] = useState(sortedBadgesList.length);

    useEffect(() => {
        setCountLastInline(sortedBadgesList.length);
    }, [sortedBadgesList.length]);

    const resizeRecount = () => {
        if (!lastRef.current) return;
        const width = lastRef.current.clientWidth;
        const itemWidth = lastRef.current.children[0]?.clientWidth || 0;
        if (!itemWidth) return;
        const count = Math.floor(width / itemWidth);
        if (count !== countLastInline && count <= sortedBadgesList.length) setCountLastInline(count);
    };

    useEffect(() => {
        resizeRecount();
        window.addEventListener("resize", resizeRecount, false);
        return () => {
            window.removeEventListener("resize", resizeRecount, false);
        }
    }, [lastRef]);

    useEffect(() => {
        // !isComplite && 
        loadBadges(uid)
    }, [uid]);

    if (isLoading) return <WidgetTypeSkeletonMUI />
    {/* <Tag className={'BadgeWidgetNew'}><Spinner /></Tag> */ }

    const remove = (bid: string) => {
        removeBadge && removeBadge(uid, bid)
    }
    if (settings?.hideIfNoData && sortedBadgesList.length === 0) return <Tag {...props} hide={props.edit} className={cnBadgeWidgetNew()} />
    // if (settings?.hideIfNoData && sortedBadgesList.length === 0) return <Tag {...props} hide={true} className={'BadgeWidget'}/>
    if (!settings?.hideIfNoData && sortedBadgesList.length === 0) return <PaperBox>
        <Typography fontWeight='700' color='primary' >
            <Translate i18nKey={'pryaniky.badge.widget.latestTitle'} />
        </Typography>
        <Avatar sx={{ width: '100%', height: 'auto' }} variant='square' srcSet={noData} />
        <Typography>
            <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
        </Typography>
    </PaperBox>

    // if (sortedBadgesList.length === 0) return <Tag {...props} className={'BadgeWidgetNew'}>

    //     <div className={'BadgeWidgetNew-Latest'}>
    //         <h4 className={'BadgeWidgetNew-LatestTitle'}>
    //             <Translate i18nKey={'pryaniky.badge.widget.nobadges'} />
    //         </h4>
    //         <div className={'BadgeWidgetNew-LatestList'}>
    //             <Translate i18nKey={'pryaniky.placeholder.widget.nodata'} />
    //         </div>
    //     </div>
    // </Tag>

    return <Tag {...props} className={cnBadgeWidgetNew()}>

        <div className={cnBadgeWidgetNew('Bages')}>
            <div className={cnBadgeWidgetNew('Latest')}>
                <div className={cnBadgeWidgetNew('Latest-Header')}>
                    <Link href={`${window.location.origin}/user/${uid}/badges`} underline='none'>
                        <Typography fontWeight='700' mb='8px' color='primary' >
                            <Translate i18nKey={'pryaniky.badge.widget.latestTitle'} />
                        </Typography>
                    </Link>
                    <div>
                        <span className={cnBadgeWidgetNew('Latest-Header-NamberOfLastBadges')}>{sortedBadgesList.length}</span>
                        <span className={cnBadgeWidgetNew('Latest-Header-NumberOfAll')}>{"/" + Object.keys(badges).length}</span>
                    </div>
                </div>
                <div ref={lastRef} className={cnBadgeWidgetNew('LatestList')}>
                    {/* .filter((_, idx) => idx < countLastInline) */}
                    {sortedBadgesList.map(value => <BadgeIconNew
                        className={cnBadgeWidgetNew(sortedBadgesList.length === 1 ? 'BadgeIconOnlyOne' : sortedBadgesList.length > 2 ? 'BadgeIconMany' : 'BadgeIcon')}
                        key={value}
                        onClick={createClickBadge(badges[value].badgeUid)}
                        badgeImgUrl={badges[value].badgeImgUrl}
                        badgeUid={badges[value].badgeUid}
                        description={badges[value].description}
                        imgId={badges[value].imgId}
                        name={badges[value].name}
                        count={badges[value].count}
                        showName
                        //size={sortedBadgesList.length === 1 ? 140 : sortedBadgesList.length === 2 ? 40 : 64}
                        size={40}
                        removeBadge={removeBadge && remove}
                        length={sortedBadgesList.length}
                    />)}
                </div>
            </div>
        </div>
    </Tag>
}