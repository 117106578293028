import { Box, Typography, FormControlLabel } from 'muicomponents/src'
import { styled } from 'muicomponents/src/mui/system';
import { PageCollectionSuggester as PageCollectionSuggesterMui, BaseSuggester as BaseSuggesterMui } from 'muicomponents/src/Suggester';
import { Tabs as MuiTabs } from 'muicomponents/src/Tabs/Tabs'
import { BaseListItem } from 'muicomponents/src/ItemsListDialog/BaseListItem/BaseListItem'
// const defaultSuggesterStyle = {
//   '& .MuiInputBase-root': {
//     paddingRight: '39px !important'
//   }
// }
// export const BaseListItemBox = styled(BaseListItem)({
//   "&>.MuiBox-root:last-child": {
//     width: "100%"
//   }
// }) as typeof BaseListItem;
// export const WidgetArticlesBox = styled(Box)({
//   display: 'flex',
//   flexDirection: "column",
//   // flexWrap: 'wrap',
//   // columnGap: '36px',
//   // rowGap: '24px',
//   borderRadius: "4px",
//   background: "#FFFFFF",
//   boxShadow: " 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
//   // gap: "16px",
//   padding: '24px',
//   boxSizing: "border-box"
// })
// export const WidgetDialogBox = styled(Box)({
//   display: 'flex',
//   flexDirection: "column",
//   gap: "16px",
//   padding: '24px'
// })
// export const FormControlLabelStyled = styled(FormControlLabel)({
//   marginLeft: 0
// })

// export const TypographyArticles = styled(Typography)({
//   fontWeight: "700"
// })
// export const TypographyDateArticles = styled(Typography)({
//   fontWeight: "400",
//   fontSize: '12px',
//   lineHeight: "166%",
//   letterSpacing: "0.4px",
//   color: "rgba(0, 0, 0, 0.38)",
// })

export const StyledTabs = styled(MuiTabs)({
  fontWeight: "500",
  lineHeight: "24px",
  letterSpacing: "0.4px",
  textAlign: "center",
  position: 'relative',
  ".MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary": {
    padding: "8px 7px",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    textAlign: "center",
  },
  ".MuiTabScrollButton-root": {
    opacity: "1"
  },
  [`& .MuiTabs-scrollButtons`]: {
    position: 'absolute',
    top: 0,
    height: '100%',
    background: '#fff',
    zIndex: 1,
    border: "1px solid #000",

    "&.Mui-disabled": {
      display: 'none'
    },
    "&:first-child": {
      left: 0,
      borderRadius: " 4px 0px 0px 4px ",
    },
    "&:last-child": {
      right: 0,
      borderRadius: "0px 4px 4px 0px",
    }
  },
  [`& .MuiTabs-scroller`]: {
    // borderLeft: "1px solid #0000004D",
    // borderRight: "1px solid #0000004D"
  },
});

// export const BaseSuggester = styled(BaseSuggesterMui)({
//   ...defaultSuggesterStyle
// }) as typeof BaseSuggesterMui;

// export const PageCollection = styled(PageCollectionSuggesterMui)({
//   ...defaultSuggesterStyle
// }) as typeof PageCollectionSuggesterMui;