import * as actionsTypesCreator from './actionsTypes.newsCreator';
import { NNewsCreateActions } from './actions.types.newsCreator';
import { takeLatest, takeLeading, debounce } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  call,
  put,
  select,
} from 'utils/src/saga.effects';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { getCurrentGroup } from 'News/redux/saga/selectors';
import * as ACT from 'News/redux/actions';
import { v1 as uuid } from 'uuid';
import { API, checkResponseStatus, DeepRequired } from 'utils/src';
import { normalizeNews } from 'News/redux/saga/utils';
import i18n from 'localizations/i18n';

// validate rules to news types

import { NewsValidateRules } from 'News/creator/types/News/News.validate';
import { ThanksValidateRules } from 'News/creator/types/Thanks/Thanks.validate';
import { AchievementsValidateRules } from 'News/creator/types/Achievements/Achievements.validate';
import { BadgesValidateRules } from 'News/creator/types/Badges/Badges.validate';
import { NoticesValidateRules } from 'News/creator/types/Notices/Notices.validate';
import { AnswerValidateRules, PollsValidateRules } from 'News/creator/types/Polls/Polls.validate';
import { CreativeTasksValidateRules } from 'News/creator/types/CreativeTasks/CreativeTasks.validate';
import { EventsValidateRules } from 'News/creator/types/Events/Events.validate';

import { IdeasValidateRules } from 'News/creator/types/Ideas/Ideas.validate';
import { converAdditionalFieldsValuesV1ToAdditionalFieldsValues } from 'News/creator/types/Ideas/Ideas.sides';
import { validateField, ValidateMatrixFieldsObject } from 'muicomponents/src/FieldRender/FieldRender.validate';

import { validate_v2 } from 'utils/src/validate_v2';
import { cloneDeep, fromPairs, isEqual, merge, omit, pick, toPairs } from 'lodash';
import { newsCreatorItemChange, newsCreatorItemNewsChange } from './actions.newsCreator';
import { getNewsCreateItemFields, getNewsCreateItemNews } from './selectors.newsCreate';
import { useThanksChange } from '../types/Thanks/Thanks.hooks';
import { changeUserThanksCount } from 'utils/src/CommonRedux/users/actions';
import { NThanks } from '../types/Thanks/Thanks.interface';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { IField } from 'muicomponents/src/FieldRender';

import { removeLinebreakBeforeBlock, linesTrim } from 'uielements/src/CommonmarkRender/MDNodeBulder'
// end validate rules to news types


/**
 * saga for send news & recalculate thanks count by wallets
 * @param action 
 */
function* handleSendNews(action: NNewsCreateActions.Action<'NEWS_CREATOR_POST_NEWS'>) {
  try {
    const createId = action.payload;

    // get filled news model
    const { news } = yield* select(getNewsCreateItemFields(['news'])(createId));

    // map news object to trim all string values
    yield* put(newsCreatorItemNewsChange({
      id: createId,
      news: fromPairs(toPairs(news).map(([key, value]) => {
        if (typeof value === 'string') value = value.trimEnd ? value.trimEnd() : value;
        return [key, value];
      }))
    }));

    switch (news.componentRenderName) {
      case 'workflow': {
        // check workflow typeof field data and if it is string then make trim
        yield* put(newsCreatorItemNewsChange({
          id: createId,
          news: {
            additionalFields: {
              ...news.additionalFields,
              additionalFieldsValues: news.additionalFields.additionalFieldsValues?.map(category => {
                return {
                  ...category,
                  fields: category.fields?.map(field => {
                    if (typeof field.data === 'string') {
                      return {
                        ...field,
                        data: field.data.trimEnd()
                      }
                    }
                    return field;
                  }) || []
                }
              }) || null
            }
          }
        }));
        break;
      }
      case 'events': {
        if (!news.uevent.eventLocation) break;
        // polls eventLocation trim
        yield* put(newsCreatorItemNewsChange({
          id: createId,
          news: {
            uevent: {
              eventLocation: news.uevent.eventLocation.trimEnd()
            }
          }
        }));
        break;
      }
      case 'polls': {
        if (!news.poll.answers || !news.poll.answers.length) break;
        // polls answers trim
        yield* put(newsCreatorItemNewsChange({
          id: createId,
          news: {
            poll: {
              answers: news.poll?.answers.map(el => ({ ...el, text: el.text?.trimEnd() }))
            }
          }
        }));
        break;
      };
    };


    // finaly validate before send;
    yield* validateCreateNews({ type: actionsTypesCreator.NEWS_CREATOR_VALIDATE_NEWS, payload: createId });

    // get filled news model
    const { news: currentNews, errors } = yield* select(getNewsCreateItemFields(['news', 'errors'])(createId));

    // check workflows at empty field value without requred field
    if (!!Object.typedKeys(errors).length && currentNews.componentRenderName === 'workflow') {
      const allFieldsIsEmpty = currentNews.additionalFields.additionalFieldsValues?.map((collection) => {
        const isCollectionIsEmpty = collection.fields.map(field => {
          switch (field.type) {
            case 'Single':
            case 'Multi': {
              //ckeck why types not working
              return !!field.data.map((el: any) => el.selected).filter((e: any) => e).length;
            };
            case 'MatrixOnePerRow':
            case 'Matrix': {
              //ckeck why types not working
              return !!field.rows.map((el: any) => el.selectedAnswer !== GUID_EMPTY).filter((e: any) => e).length;
            };
            default: {
              if (Array.isArray(field.data)) return !!field.data.length;
              return field.data;
            }
          };
        }).filter(e => e).length === 0;
        return !isCollectionIsEmpty;
      }).filter(e => e).length === 0;
      if (allFieldsIsEmpty) {
        yield put(newsCreatorItemChange({
          id: createId,
          item: {
            isValid: false
          }
        }));
        return;
      }
    }

    // get current user
    const currentUser = yield* select(getCurrentUser);
    // if errors if defined then stop sending
    if (Object.typedKeys(errors).filter(key => errors[key]).length) return;
    // get current news group to prepend new news to valid list
    const currentGroup = yield* select(getCurrentGroup);
    // TODO check types
    const response = yield* call(currentNews.componentRenderName === 'achievements' && currentNews.users?.length ? API.news.sagas.achievementByModerator : API.news.sagas.post, {
      ...omit(currentNews, ['draftRawState']),
      text: removeLinebreakBeforeBlock(linesTrim(currentNews.text)),
      id: uuid(),
      user: currentUser.baseData
    }) as any;
    if (checkResponseStatus(response)) {
      // if send is success then up clearEditor flag to clear draft state
      yield* put(newsCreatorItemChange({
        id: createId,
        item: {
          clearEditor: true
        }
      }));
      // add news to first place in timeline
      if (currentNews.componentRenderName === 'achievements' && currentNews.users?.length) {
        yield* put(ACT.prependNewsList({ ...normalizeNews(response.data), group: currentGroup }));
      } else {
        yield* put(ACT.prependNewsList({ ...normalizeNews(response.news), group: currentGroup }));
      }
      // recalculate user currency count if news is thanks.
      if (currentNews.componentRenderName === 'thanks') {
        // get avaliable count in wallet after sended news
        const {
          thanksToUse,
          selectedCollection,
          thanksToSubUsers
        } = useThanksChange({
          user: currentUser,
          users: currentNews.users || [],
          groups: currentNews.groups || [],
          rewardReasons: (currentNews as any as NThanks.Create).rewardReasons,
          thanksCount: currentNews.thanksCount
        });
        if (!selectedCollection || selectedCollection === 'all') {
          yield* put(changeUserThanksCount({ count: - (thanksToUse - thanksToSubUsers), thanksForSubordinate: - thanksToSubUsers }));
        } else {
          yield* put(changeUserThanksCount({ count: - thanksToUse, wallet: selectedCollection }));
        }
      }
      yield put(newsCreatorItemChange({
        id: createId,
        item: {
          sended: new Date().toISOString()
        }
      }));
      // проверка для селикдара по тикету COMMON-8078 "Исчезают публикации пользователя под модерацией после обновления страницы"
      if (response.data.newstype === 19 && response.data.group?.visibilityType === 1 && response.data.isNeedApprove && response.data.group !== {}) {
        toast.success(i18n.t('pryaniky.toast.success.post.achivment.in.hidden.group'))
      }
    } else {
      if (response.error_code === 5004) {
        toast.error(response.error_text);
      } else {
        toast.error(i18n.t(`send news error: ${response.error_text}`));
      }
    }
  } catch (error) {
    console.error('error on send news creation form', error);
  }
};


type AdditionalNewsWindowValidate = {
  news: typeof NewsValidateRules,
  thanks: typeof ThanksValidateRules,
  achievements: typeof AchievementsValidateRules,
  badges: typeof BadgesValidateRules,
  notices: typeof NoticesValidateRules,
  creativetasks: typeof CreativeTasksValidateRules,
  polls: typeof PollsValidateRules,
  events: typeof EventsValidateRules,
  ideas: typeof IdeasValidateRules
};

const validators = {
  news: NewsValidateRules,
  thanks: ThanksValidateRules,
  achievements: AchievementsValidateRules,
  badges: BadgesValidateRules,
  notices: NoticesValidateRules,
  creativetasks: CreativeTasksValidateRules,
  polls: PollsValidateRules,
  events: EventsValidateRules,
  ideas: IdeasValidateRules
} as const;

/**
 * validate news create form on data edit
 * валидирует измененные данные в форме отправки сообщения
 */
function* validateCreateNews(action: NNewsCreateActions.Action<"NEWS_CREATOR_ITEM_NEWS_CHANGE"> | NNewsCreateActions.Action<"NEWS_CREATOR_VALIDATE_NEWS">) {
  try {
    // get current user
    const currentUser = yield* select(getCurrentUser);
    let isValid = true;
    let createId = '';
    let data: NNewsCreateActions.Action<"NEWS_CREATOR_ITEM_NEWS_CHANGE">['payload']['news'] = {};
    switch (action.type) {
      case actionsTypesCreator.NEWS_CREATOR_VALIDATE_NEWS:
        // is action is VALIDATE_CREATOR_NEWS then get all currentNews and valide it
        // если экшн является VALIDATE_CREATOR_NEWS то берем полную модель и валидируем ее
        // const { currentNews } = yield select(getCreatorParams('currentNews'));
        createId = action.payload.toString();
        const currentNews = yield* select(getNewsCreateItemNews(createId));
        data = currentNews;
        isValid = false;
        break;
      case actionsTypesCreator.NEWS_CREATOR_ITEM_NEWS_CHANGE:
        // if action is PATH_CREATOR_NEWS & payload keys length > 1 then that is first init and we do not need to validate
        // если идет изменние модели всей новости, а не одного элемента, то считаем это инициализацией и не валидируем
        // if(Object.typedKeys(action.payload).length > 1) return;
        // if action is PATH_CREATOR_NEWS then validate only changed data
        createId = action.payload.id.toString();
        data = action.payload.news;
        break;
    };
    const { errors: currentErrors, selectedNews: currentType, validFile } = yield* select(getNewsCreateItemFields(['errors', 'selectedNews', 'validFile'])(createId));

    let validateRules = validators[currentType as keyof typeof validators];

    const additionalNewsValidateRules = (window as any).additionalNewsValidateRules as AdditionalNewsWindowValidate | undefined;

    if (additionalNewsValidateRules && validateRules) {
      const additionalRulesByType = additionalNewsValidateRules[currentType as keyof typeof additionalNewsValidateRules];
      if (!isEqual(validateRules, additionalRulesByType)) {
        // merge mutate validateRules
        merge(validateRules, additionalRulesByType);
      }
    }

    // валидация полей конкретного типа
    const errors = validate_v2(data, validateRules);

    // валидация файлов
    const filesErrors = validate_v2(
      {
        attachments: validFile ? [1] : []
      },
      {
        attachments: {
          notNull: {
            value: true,
            errorMessageKey: 'pryaniky.news.files.validate.error'
          }
        }
      }
    );

    // валидация AdditionalFields и благордарности
    const fieldsErrors: { [key: string]: ReturnType<typeof validateField> } = {};
    // push use currentType only for thanks and if componentRenderName is undefined
    const definedType = data.componentRenderName || (currentType === 'thanks' ? currentType : undefined);
    switch (definedType) {
      case 'thanks': {
        const thanks = (yield* select(getNewsCreateItemNews(createId))) as NThanks.Create;

        // then valuedate by all thanks count
        const {
          thanksToUse,
          thanksToSubUsers,
          allThanksCount
        } = useThanksChange({
          user: currentUser,
          users: thanks.users,
          groups: thanks.groups,
          rewardReasons: thanks.rewardReasons,
          thanksCount: thanks.thanksCount
        });

        // const thanks = data;
        // if on change doesn't have thanksCount param, then we need separetly validate it
        if (!data.thanksCount && thanks.thanksCount) {
          const thanksCountError = validate_v2({
            thanksCount: /*!!thanksToSubUsers ? thanksToSubUsers : */thanks.thanksCount,
          } as any, validateRules);
          if (!errors.thanksCount && thanksCountError.thanksCount) {
            errors.thanksCount = thanksCountError.thanksCount as any as typeof errors['thanksCount']
          }
        }

        if (!errors.thanksCount && thanks.thanksCount) {
          if (thanksToUse > allThanksCount) {
            (errors as any).thanksCount = {
              errorMessageKey: 'pryaniky.news.create.thanks.thanksCountError',
              variables: {
                count: thanks.thanksCount - (allThanksCount)
              }
            }
          } else {
            (errors as any).thanksCount = undefined;
          }
        }
        break;
      }
      case "ideas": {
        type FieldsNonNullableType = DeepRequired<typeof data>['idea']['additionalFieldsValues'][number]['v'];
        const additionalFieldsV1 = data.idea?.additionalFieldsValues?.reduce((a, cat) => [...a, ...(cat?.v || []) as FieldsNonNullableType], [] as FieldsNonNullableType);
        additionalFieldsV1?.map(converAdditionalFieldsValuesV1ToAdditionalFieldsValues).forEach(field => {
          if (field.isMandatory) {
            const data = pick(field, 'data');
            switch (field.type) {
              case 'Single':
              case 'Multi':
                data.data = data.data?.map((el: any) => el.selected ? el.id : undefined).filter((e: any) => e);
                fieldsErrors[field.id] = validateField(data);
                break;
              case "MatrixOnePerRow": {
                const selected = {
                  data: field.rows?.map(el => el.selectedAnswer).filter(el => el !== GUID_EMPTY)
                }
                fieldsErrors[field.id] = validateField(
                  selected as any,
                  {
                    data: {
                      min: {
                        ...ValidateMatrixFieldsObject.rows?.min,
                        value: field.rows?.length
                      }
                    }
                  } as any
                );
                break;
              }
              default: {
                fieldsErrors[field.id] = validateField(data);
                break;
              }
            }
          }
        });
        break;
      }
      case "workflow": {
        data.additionalFields?.additionalFieldsValues?.reduce((a: any, cat: any) => [...a, ...cat.fields], [])
          .forEach((field: any) => {
            if (field.isMandatory) {
              const data = pick(field, 'data');
              switch (field.type) {
                case 'Label':
                  break;
                case 'Single':
                case 'Multi':
                  data.data = data.data?.map((el: any) => el.selected ? el.id : undefined).filter((e: any) => e);
                  break;
                case "MatrixOnePerRow": {
                  const selected = {
                    data: field.rows?.map((el: any) => el.selectedAnswer).filter((el: any) => el !== GUID_EMPTY)
                  }
                  fieldsErrors[field.id] = validateField(
                    selected as any,
                    {
                      data: {
                        min: {
                          ...ValidateMatrixFieldsObject.rows?.min,
                          value: field.rows?.length
                        }
                      }
                    } as any
                  );
                  break;
                }
                default: {
                  fieldsErrors[field.id] = validateField(data);
                  break;
                }
              }
            }
          });
        break;
      }
    }


    let customTypesValidation: ReturnType<typeof validate_v2> = {};

    // polls news type answers validate
    if (
      data.poll?.answers
    ) {
      let answers: typeof data.poll.answers = [];
      switch (action.type) {
        case actionsTypesCreator.NEWS_CREATOR_ITEM_NEWS_CHANGE:
          if (action.payload.reason?.type === 'pollAnswerChange') {
            const reason = action.payload.reason;
            answers = data.poll?.answers.filter(el => el?.id === reason.id);
          }
          break;
        case actionsTypesCreator.NEWS_CREATOR_VALIDATE_NEWS:
          answers = data.poll?.answers;
          break;
      }
      customTypesValidation = fromPairs(answers?.map(answer => {
        if (answer) {
          let answerErrors = validate_v2(answer, AnswerValidateRules);
          if (!Object.typedKeys(answerErrors).filter(key => answerErrors[key]).length) return [`pollAnswer.${answer.id}`, undefined];
          return [`pollAnswer.${answer.id}`, answerErrors];
        }
        return [];
      }) || []);
    }

    const newErrors = {
      ...currentErrors,
      ...filesErrors,
      ...errors,
      ...fieldsErrors,
      ...customTypesValidation
    };

    isValid = !isValid && !Boolean(Object.typedKeys(newErrors).filter(key => newErrors[key]).length) || isValid;
    yield put(newsCreatorItemChange({
      id: createId,
      item: {
        isValid,
        // TODO check types
        errors: newErrors as any
      }
    }));

  } catch (error) {
    console.error('error on validate news creation form', error);
  }
};

/**
 * module root saga
 */
export const newsCreatorSaga = function* root() {

  // news form actions
  // yield throttle(1000, actionsTypesCreator.NEWS_CREATOR_ITEM_NEWS_CHANGE, validateCreateNews);
  yield debounce(250, actionsTypesCreator.NEWS_CREATOR_ITEM_NEWS_CHANGE, validateCreateNews);
  // yield takeLatest([ actionsTypesCreator.NEWS_CREATOR_ITEM_NEWS_CHANGE, actionsTypesCreator.NEWS_CREATOR_VALIDATE_NEWS ], validateCreateNews);
  yield takeLeading(actionsTypesCreator.NEWS_CREATOR_POST_NEWS, handleSendNews);
  // end new form actions

};