import { bindActionCreators } from "redux"
import { setActiceTab, getQuery, setFilters, setAdditionalFilter, clearAdditionalFilter } from 'redux/search/actions';
import { getActiveTabSelector, getQuerySelector, getFilters } from 'redux/search/selectors';
import { defaultAdditionalNewsFilter } from 'redux/search/reducer'
import { cn } from '@bem-react/classname';

export interface ISearchMuiProps extends TMapStateToProps, TMapDispatchToProps, ISearchMuiOwnProps { }

interface ISearchMuiOwnProps { }

export const mapStateToProps = (state: any) => ({
  tabs: (state.store.appSettings.searchFilters as any).filter((filter: any) => filter.showAtSearchPage), // TODO: типизировать 
  filters: getFilters(state),
  newsFilter: getFilters(state).find((filter) => filter.filterName === 'news') || defaultAdditionalNewsFilter,
  context: state.widgets.contexts.common,
});

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setAdditionalFilter,
  clearAdditionalFilter
}, dispatch);

export type TMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export type TMapStateToProps = ReturnType<typeof mapStateToProps>;

export type TListContext = {
  search: string,
  network: string,
  viewType: number,
  contentType?: string,
  isSearchPageQuery: boolean,
  count?: number
}

export const cnSearchMui = cn('SearchMui');