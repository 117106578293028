import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators, Action, Dispatch } from "redux";
import { makeShopOrder } from 'redux/shop/actions';
import { IBodyProps } from 'utils/src/DialogCreator';


export interface IMakeOrderDialogProps extends IMakeOrderDialogStateProps, IMakeOrderDialogOwnProps, IMakeOrderDialogDispatchProps {
  data?: any;
  additionalInformationBeforeOrder?: string
  useRedux?: boolean;
  withCount?: boolean;
  selectedCount?: number;
  maxCount?: number;
}

export interface IMakeOrderDialogOwnProps extends IBodyProps { }

export interface IMakeOrderDialogState {
}

export type IMakeOrderDialogStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: any) => {
  return {
    userThanksCount: getAuthUser(state)?.extData?.mythanksCount || 0,
    currencyFormats: state.store.appSettings,
  }
};

export type IMakeOrderDialogDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  makeShopOrder
}, dispatch);
