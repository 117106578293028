/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
export default {
  list: [
    {
      title: '',
      deleted: false,
      list: []
    }
  ],
}