import moment from "moment";
import { useEffect, useState } from "react"
import { checkResponseStatus } from "utils/src";
import { getCalendarEventsShortRequest } from "utils/src/requests/requests.calendar";

let calRequest: ReturnType<typeof getCalendarEventsShortRequest> | undefined;

export const useClendarEvents = (date: Date, gid?: number, cIds?: string[]) => {
    const [events, setEvents] = useState<Awaited<ReturnType<typeof getCalendarEventsShortRequest>['r']>['data']['events']>({});
    const [dates, setDates] = useState({
        start: moment(date).startOf('month').toISOString(),
        end: moment(date).endOf('month').toISOString()
    });

    useEffect(() => {
        async function fetchData() {
            if (calRequest) calRequest.ac.abort();
            calRequest = getCalendarEventsShortRequest({ start: dates.start, end: dates.end, gid: (cIds && cIds?.length > 0) ? undefined : gid, cIds });
            const response = await calRequest.r;
            if (checkResponseStatus(response)) {
                setEvents(response.data.events);
            } else {
                throw new Error(`Get calendar short events response - error_code = ${response.error_code}`);
            }
        }
        fetchData()
            .catch(console.error);;
    }, [dates]);

    return {
        events,
        setDates
    }
}