import React, { FC, useEffect, useState, useMemo, useCallback } from 'react'
import {
    WidgetArticlesBox,
    TypographyArticles,
    TypographyDateArticles,
    CardStyled,
    CardContentStyled,
    ActionBox,
    ActionContetBox,
    LinkStyled,
    ListContentBox
} from '../WikiActueleArticlesWidget.styled'
import { cnSettingsActueleArticles } from '../WikiActueleArticlesWidget.index'
import { useDispatch, useSelector } from "react-redux";
import { Translate } from 'localization'
import { Box, Typography, Button } from 'muicomponents/src'
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { Link } from 'muicomponents/src/Link/Link';
import MDRender from 'muicomponents/src/CommonmarkRender';
import { TPage } from 'utils/src/BaseTypes/pages.types';
import * as utils from 'utils/src/utils';
import CardMedia from '@mui/material/CardMedia';
import { useNodeMediaQuery, useResizeWacther } from 'muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher'
import { isNumber } from 'lodash'
import defaultArticle from '../defaultArticle.png'
import { FavoriteBorder, FavoriteOutlined, ChatBubbleOutline, ChatBubble, RemoveRedEye } from 'muicomponents/src/Icons'
import { likeWikiRequest } from "redux/actions/WikiList";
import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';
import { formatDateNumbers } from 'utils/src/utils.dates';
import { formatDateSTD } from 'utils/src/utils.dates';
import { getProfileFileIcon } from 'utils/src/constants.prn'
import wikilist from 'assets/svg/wikilist1.svg'
import page from 'assets/svg/page1.svg'
import link from 'assets/svg/link1.svg'
import fotoAlbum from 'assets/svg/fotoAlbum.svg'
import videoAlbum from 'assets/svg/videoAlbum.svg'


export interface IItemProps {
    item: TPage;
    count: number;
    itemsLength: number;
    hideName: boolean
    hideDescription: boolean
}
export const WikiListItem: FC<IItemProps> = ({ item, count, itemsLength, hideName, hideDescription }) => {
    const [cardRef/*, toColumn*/] = useNodeMediaQuery<HTMLDivElement>({ maxWidth: 252.33 })
    const [maxWidth, setMaxWidth] = useState<string | number>(252.33)
    const [liked, setLiked] = useState(item?.newsLiked)
    const [likeCountNumber, setLikeCountNumber] = useState(item?.newsLikeCount)

    const fileType = item.type === "file" ? item.title?.split(".") : []
    const Icon = item.type === "file" ? getProfileFileIcon(fileType.length > 1 ? fileType[fileType.length - 1] : item.icon) : null

    const pageType = item.type === "wikilist.imageGallery"
        ? fotoAlbum
        : item.type === "wikilist.videoGallery"
            ? videoAlbum
            : item.type === "wikilist"
                ? wikilist
                : item.type === "page"
                    ? page
                    : item.type === "url"
                        ? link
                        : Icon;

    const minWidth = 230;


    const noImg = !item.titleImageId
    const dispatch = useDispatch();

    const changeLike = (likeCount: number, liked: boolean) => {
        dispatch(likeWikiRequest(item?.newsId, likeCount, liked))
        // setLiked(!liked)
    }

    const changeLikeCount = () => {
        setLiked(!liked)
        setLikeCountNumber(!liked ? likeCountNumber + 1 : likeCountNumber - 1)
    }
    const onOpen = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        likersModal({
            newsId: item?.newsId,
            // replyId
        })
    }, [item?.newsId])

    const componentLike = useMemo(() => {
        return <ActionContetBox
            className={cnSettingsActueleArticles("CardArticleItem-Content-ActionBox-Like primaryColor3-borderColor")}>
            {liked ? <FavoriteOutlined
                color='disabled'
                sx={{
                    fontSize: "20px",
                    cursor: "pointer",
                    marginRight: likeCountNumber > 0 ? "5px" : 0
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    changeLike(item?.newsLikeCount, liked)
                    changeLikeCount()

                }} /> :
                <FavoriteBorder
                    color='disabled'
                    sx={{
                        fontSize: "20px",
                        cursor: "pointer",
                        marginRight: likeCountNumber > 0 ? "5px" : 0
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        changeLike(item?.newsLikeCount, liked)
                        changeLikeCount()

                    }} />
            }

            < Typography
                sx={{
                    color: "rgba(0, 0, 0, 0.26)",
                    opacity: !likeCountNumber ? "0" : "1",
                    paddingTop: "2px",
                    cursor: !likeCountNumber ? "auto" : "pointer"
                }}
                variant='caption'
                onClick={!likeCountNumber ? () => { } : onOpen}
            >
                {likeCountNumber}
            </Typography>
        </ActionContetBox>
    }, [likeCountNumber, liked])

    return <CardStyled ref={cardRef}
        className={cnSettingsActueleArticles("CardArticleItem")}
        sx={{
            maxWidth: itemsLength < count ? maxWidth : "100%",
            minWidth: minWidth,
            flexDirection: 'column',
        }}>

        <LinkStyled href={item.url}
            sx={{
                maxWidth: itemsLength < count ? maxWidth : "100%",
                minWidth: minWidth,
                flexDirection: 'column',
                maxHeight: "376px"
            }}>
            {noImg
                ? <Box
                    className={cnSettingsActueleArticles(noImg ? 'Picture-BTNNoImg' : 'Picture-BTN')}
                    sx={{
                        width: "100%",
                        height: "160px",
                        backgroundImage: noImg ? `url(${pageType})` : `url(${item.titleImageUrl})`,
                        backgroundSize: noImg ? (item.type === "url" ? "70px" : "90px") : "cover",
                        backgroundPosition: noImg ? "center" : "inherit",
                        backgroundRepeat: noImg ? "no-repeat" : "inherit",
                        backgroundColor: noImg ? "#f5f5f5" : "#fff",
                        "&:hover": {
                            backgroundImage: noImg ? `url(${pageType})!important` : `url(${item.titleImageUrl})!important`,
                            backgroundSize: noImg ? (item.type === "url" ? "70px!important" : "90px!important") : "cover!important",
                            backgroundPosition: noImg ? "center!important" : "inherit!important",
                            backgroundRepeat: noImg ? "no-repeat!important" : "inherit!important",
                            backgroundColor: noImg ? "#f5f5f5!important" : "#fff!important",
                        }
                    }} />
                : <CardMedia
                    component="img"
                    className={cnSettingsActueleArticles("CardArticleItem-Avatar")}
                    sx={{
                        width: "auto",
                        height: "auto",
                        minHeight: "160px",
                        maxHeight: "160px"
                        // width: 'auto',
                        // height: (isNumber(maxWidth) ? maxWidth * 0.735812133072407 : 188),
                    }}

                    image={item.titleImageId ? item.titleImageUrl : pageType}
                />
                // <CardMedia

                //     className={cnSettingsActueleArticles("CardArticleItem-Avatar")}
                //     sx={{
                //         width: item.titleImageId ? "auto": "180px",
                //         height: "auto",
                //         minHeight: "160px",
                //         // width: 'auto',
                //         // height: (isNumber(maxWidth) ? maxWidth * 0.735812133072407 : 188),
                //     }}

                //     image={item.titleImageId ? item.titleImageUrl : Icon}
                // />

            }
            <CardContentStyled
                className={cnSettingsActueleArticles("CardArticleItem-Content")}

                children={<Box>
                    <TypographyDateArticles>
                        <Translate i18nKey='pryaniky.wikilist.item.created.new' values={{
                            creationDate: formatDateSTD(item.creationDate, false),
                            changeDate: formatDateSTD(item.changeDate, false)
                        }} />
                    </TypographyDateArticles>
                    {/* <TypographyDateArticles>{formatDateNumbers(item.changeDate || item.creationDate, false, false)}</TypographyDateArticles> */}
                    {!hideName && <Typography
                        variant="subtitle2"
                        fontWeight={"medium"}
                        className={cnSettingsActueleArticles("CardArticleItem-Content-Name")}>
                        <Link href={item.url} sx={{ textDecoration: 'none', fontSize: "14px" }}>
                            <Tooltip title={item.title.length > 40 ? item.title : ""}>
                                <span>
                                    {(item.title && <MDRender source={item.title} type={'inline'} countLineCut={2} />) || <Translate i18nKey={'pryaniky.wikilist.item.emptyName'} />}
                                </span>
                            </Tooltip>
                        </Link>
                    </Typography>}


                    {!hideDescription && <Typography
                        className={cnSettingsActueleArticles("CardArticleItem-Content-Description")}
                        variant='caption'
                        sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
                        <MDRender countLineCut={3} source={item.description || ''} type={'inline'} />
                    </Typography>}
                </Box>
                } />
        </LinkStyled>
        <ActionBox
            className={cnSettingsActueleArticles("CardArticleItem-Content-ActionBox")} >
            <Box sx={{
                display: "flex",
                gap: "8px",
                gridGap: "8px"
            }}>
                {componentLike}

                {(item.type !== "url" && item.allowComments) && <ActionContetBox
                    className={cnSettingsActueleArticles("CardArticleItem-Content-ActionBox-Chat primaryColor3-borderColor")}
                >
                    <Link href={item.url} sx={{ textDecoration: 'none', height: "20px" }}>
                        {item?.newsCommented ? <ChatBubble
                            color={"disabled"}
                            sx={{
                                fontSize: "20px",
                                marginRight: "5px"
                            }} /> : <ChatBubbleOutline
                            color={"disabled"}
                            sx={{
                                fontSize: "20px",
                                marginRight: "5px"
                            }} />}
                    </Link>
                    <Typography
                        sx={{ color: "rgba(0, 0, 0, 0.26)", opacity: !item?.newsCommentsCount ? "0" : "1", paddingTop: "2px" }}
                        variant='caption'
                    >
                        {item?.newsCommentsCount}
                    </Typography>
                </ActionContetBox>}
            </Box>
            <ActionContetBox
                className={cnSettingsActueleArticles("CardArticleItem-Content-ActionBox-Chat primaryColor3-borderColor")} >
                <RemoveRedEye
                    color={"disabled"}
                    sx={{
                        fontSize: "16px",
                        marginRight: "5px"
                    }} />

                < Typography
                    sx={{ color: "rgba(0, 0, 0, 0.26)", opacity: item?.newsViewedCount === 0 ? "0" : "1", paddingTop: "2px" }}
                    variant='caption'
                >
                    {item?.newsViewedCount}
                </Typography>
            </ActionContetBox>
        </ActionBox>
    </CardStyled >

}