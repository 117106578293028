import { connect, ConnectedComponent } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { minColumnSizePrecentage } from 'utils/src/constants.prn';
// import i18n from '../../localizations/i18n';
import ErrorBoundary from 'utils/src/ErrorBoundary'
import { cnWidget, IWidgetProps, IWidgetState, makeMapStateToProps } from './Widget.index';

import './Widget.scss';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { widgets, IColumn, generateColumn } from 'i.widgets';
import i18n from 'localizations/i18n';
import { WidgetsSettings } from 'blocks/Dialogs/Widgets/Settings/WidgetsSettings';
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import { toast } from 'react-toastify';
import { RequestService } from 'utils/src/requests/service';
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { IWSettingsOnlyProps } from '../WSettings/WSettings.index';
import { TabsSettings } from 'blocks/Dialogs/Widgets/TabsSettingsMui';
// import { analytic, eventsTypes as analyticsEventsTypes } from '../../events/analytic';

const Overlay: React.FC<any> = ({ w_coord }) => {
  if (!w_coord) return null;
  const gradient_horizontal = `linear-gradient(to right, rgba(0,0,0,.5) ${Math.round(w_coord.x)}px, transparent ${Math.round(w_coord.x)}px, transparent ${Math.round(w_coord.x + w_coord.width)}px, rgba(0,0,0,.5) ${Math.round(w_coord.x + w_coord.width)}px)`;
  const h_st = {
    background: gradient_horizontal
  }
  const gradient_vertical = `linear-gradient(to bottom, rgba(0,0,0,.5) ${Math.round(w_coord.y)}px, transparent ${Math.round(w_coord.y)}px, transparent ${Math.round(w_coord.y + w_coord.height)}px, rgba(0,0,0,.5) ${Math.round(w_coord.y + w_coord.height)}px)`;
  const v_st = {
    background: gradient_vertical,
    left: `${Math.round(w_coord.x)}px`,
    width: `${Math.round(w_coord.x + w_coord.width) - Math.round(w_coord.x)}px`
  }
  return ReactDOM.createPortal(
    <div style={h_st} className={cnWidget('Overlay-H')}>
      <div className={cnWidget('Overlay-V')} style={v_st} />
    </div>,
    document.getElementById('Layout') as HTMLElement
  )
}

export default class WidgetPresenter<T extends IWidgetProps, T2 extends IWidgetState = any> extends React.PureComponent<
  T,
  T2 | any
> {
  public types = {};
  public subtype: string;
  public el = React.createRef<HTMLDivElement>();
  public overlayRef = React.createRef<HTMLDivElement>();

  constructor(props: T & IWidgetProps) {
    super(props);
    this.state = {
      showOverlay: false,
      w_coords: undefined,
      editHTMLData: false,
    }
  }

  // public componentWillUnmount() {}

  /**
   * componentDidMount
   */
  public componentDidMount() {
    const { widget, edit, openedModal } = this.props;
    // analytic.push(analyticsEventsTypes.widgetIsMounted, { id: widget.id });
    if (widget && widget.type === widgets.types.layout && edit && openedModal === 'layoutSelector') {
      this.layoutSelector();
      this.props.changeVmManualy('', { openedModal: '' });
    }
  }

  public componentDidUpdate(pp: T & IWidgetProps) {
    const { widget } = this.props;
    if (widget && typeof widget.HTMLWidgetContentEdit === 'boolean' && widget.HTMLWidgetContentEdit !== pp.widget.HTMLWidgetContentEdit) {
      this.setState({ editHTMLData: widget.HTMLWidgetContentEdit });
    }
  }

  // public componentDidUpdate(pp: T & IWidgetProps) {
  //   const { widget, edit, openedModal } = this.props;
  //   if (widget.type === widgets.types.layout && edit && openedModal === 'layoutSelector') {
  //     this.layoutSelector();
  //   }
  // }

  public render() {
    // if(window.PRN_SERVICE.dev_features) return this.testRender();
    const { className = '', hide, edit, widget, isHidden, children, viewType, draggingElem } = this.props;
    const dataId = this.props['data-id'];
    if (!widget || (!edit && (widget.isHidden || isHidden))) return null;
    if (!hide || edit) {
      return (<ErrorBoundary>
        <div ref={this.el} data-id={dataId} className={cnWidget({ edit, small: widget.type !== widgets.types.layout && widget.type !== widgets.types.tabs && viewType === 'small' }, [className])}>
          {
            edit &&
            <>
              {
                !this.state.editHTMLData &&
                widget.type !== widgets.types.layout &&
                widget.type !== widgets.types.grid &&
                widget.type !== widgets.types.tabs &&
                widget.type !== widgets.types.tabsControl &&
                widget.type !== widgets.types.adventCalendar &&
                edit &&
                <div className={cnWidget('Blind', { draging: viewType === 'small', draggingElem: draggingElem === widget.id })} />
              }
              <div className={cnWidget('Name')}>
                {
                  widget.title !== 'GeneratedWidget' ?
                    widget.title :
                    i18n.t(`pryaniky.widgets.create.name.${widget.type}${widget.settings?.once ? `.${widget.settings?.dateNow ? 'eventstoday' : widget.settings?.viewType}` : ""}`)
                }

                {widget.type === widgets.types.statistic && <span>
                  {` (${widget.settings?.data?.title || i18n.t(`pryaniky.statistics.title.${widget.settings?.data?.module}.${widget?.settings?.data?.statistic}`)})`}
                </span>}
              </div>

              {/* вывод рекомендуемого размера для слайдера */}
              {widget.type === widgets.types.sliderlink && <span className={cnWidget('SliderSize')}>{i18n.t('pryaniky.widgets.settings.slider.size')} {this.el.current && this.el.current.clientWidth}x{this.el.current && this.el.current.clientHeight}</span>}
              {this.menu}
              {/* {
                widget.type !== widgets.types.layout &&
                widget.type !== widgets.types.tabs &&
                <h4 children={i18n.t(`pryaniky.widgets.create.name.${widget.type}`)} />
              } */}

            </>
          }
          {typeof children === 'function' ?
            children() :
            children}
        </div>
      </ErrorBoundary>);
    }
    return null;
  }

  public get relations() {
    return this.props.widget.relations || [];
  }

  public getComponent = (types: { [s: string]: any }) => {
    if (
      this.props.authUser.baseData.isAdmin && (
        this.props.widget.type === widgets.types.list + '/users' ||
        this.props.widget.type === widgets.types.list + '/groups' ||
        this.props.widget.type === widgets.types.list + '/groupsTree'
      )
    ) {
      return types[utils.widgetSubtype(this.props.widget.type + 'Selector') || 'common'] || types['common'];
    }
    return types[utils.widgetSubtype(this.props.widget.type) || 'common'] || types['common'];
  }

  private get layoutMenu() {
    const { activeModal } = this.state;
    const { widget, addTabToWidget, changeWidgetSettings } = this.props;
    if (!widget || widget.type !== widgets.types.layout) return;
    return (
      <div id={this.props['data-id'] + '-menu'} key={this.props['data-id'] + '-menu'} onMouseEnter={this.showOverlay} onMouseLeave={this.hideOverlay} className={cnWidget('Menu')}>
        {
          this.state.showOverlay && <Overlay w_coord={this.state.w_coord} />
        }
        <Button onClick={() => {
          const { widget } = this.props;
          if (Array.isArray(widget.data) && widget.data.length < 4) {
            this.props.updateWidget({
              ...widget,
              data: [
                ...(Array.isArray(widget.data) ? widget.data : [] as IColumn[]).map((it, i, a) => {
                  if (i === a.length - 1) {
                    const prepareStyles = (itm: IColumn) => {
                      const styles: React.CSSProperties = { ...itm.styles };
                      let width = parseFloat((styles.minWidth || styles.width || minColumnSizePrecentage).toString()) - minColumnSizePrecentage;
                      if (width < minColumnSizePrecentage) width = minColumnSizePrecentage;
                      styles.minWidth = `${width}%`;
                      return styles;
                    }
                    const styles = prepareStyles(it);
                    if (styles.minWidth === `${minColumnSizePrecentage}%`) {
                      for (let idx = i - 1; idx >= 0; idx--) {
                        a[idx].styles = prepareStyles(a[idx]);
                        if (a[idx].styles.minWidth !== `${minColumnSizePrecentage}%`) idx = -1;
                      }
                    }
                    return { ...it, styles }
                  }
                  return it
                }),
                generateColumn({
                  styles: { minWidth: `${minColumnSizePrecentage}%` }
                })
              ]
            });
          } else {
            toast.warn(i18n.t('pryaniky.widgets.settings.collumn.max'));
          }
        }} title={i18n.t('pryaniky.widgets.settings.collumn.add')}>
          <Icon icon='plus' />
        </Button>
        <Button
          onClick={() => {
            const { widget } = this.props;
            if (Array.isArray(widget.data) && widget.data.length > 1) {
              this.props.updateWidget({
                ...widget,
                data: [
                  ...(Array.isArray(widget.data) ? widget.data : [] as IColumn[]).filter((_, i, a) => i < a.length - 1).map((el, idx, arr) => ({
                    ...el,
                    styles: idx < arr.length - 1 ? el.styles : {
                      ...el.styles,
                      minWidth: `${100 - arr.filter((_, i) => i < idx).reduce((a, el) => a + parseFloat(((el.styles || {}).minWidth || (el.styles || {}).width || 0).toString()), 0)}%`
                    }
                  }))
                ]
              });
            } else {
              toast.warn(i18n.t('pryaniky.widgets.settings.collumn.min'));
            }
          }}
          title={i18n.t('pryaniky.widgets.settings.collumn.remove')}>
          <Icon icon='times' />
        </Button>
      </div>
    )
  }

  public componentWillUnmount() {
    const { widget } = this.props;
    widget && RequestService.init().abortByWidgetId(widget.id);
  }

  private get menu() {
    const { activeModal } = this.state;
    const { widget, addTabToWidget, changeWidgetData, changeWidgetSettings, updateWidget, wcontext } = this.props;
    if (!widget) return;

    const settingMethod = ComponentInjector.getInstance().getMethodById(['WSettings'], widget.type)

    return (
      <div id={this.props['data-id'] + '-menu'} key={this.props['data-id'] + '-menu'} onMouseEnter={this.showOverlay} onMouseLeave={this.hideOverlay} className={cnWidget('Menu')}>
        {
          this.state.showOverlay && <Overlay w_coord={this.state.w_coord} />
        }
        {
          widget.type === widgets.types.layout && <Button name={this.props['data-id'] as string} onClick={this.layoutSelector} title={i18n.t('pryaniky.widgets.change.columns.settings')} children={<Icon icon="columns" />} />
        }
        {
          (widget.type === widgets.types.tabs || widget.type === widgets.types.tabsControl) && (
            <>
              <Button name={this.props['data-id'] as string + '_settings'} onClick={() => {
                TabsSettings({
                  tabs: (widget.data || []) as any,
                  wcontext
                })
                .then((rezult) => {
                  // as any because types for action is wrong
                  addTabToWidget({
                    wId: widget.id,
                    columns: rezult
                  } as any);
                })
                .catch(() => {});
              }} title={i18n.t('pryaniky.widgets.tabs.settings')} children={<Icon icon="browser" />} />
            </>
          )
        }
        {
          (
            widget.type === widgets.types.users + '/group' ||
            widget.type === widgets.types.ratingmini ||
            widget.type === widgets.types.sliderlink ||
            widget.type === widgets.types.license ||
            widget.type === widgets.types.iframe ||
            widget.type === widgets.types.virtualUser ||
            widget.type === widgets.types.tagsCloud ||
            widget.type === widgets.types.statistic ||
            widget.type === widgets.types.statisticFilters ||
            widget.type === widgets.types.userfields ||
            widget.type === widgets.types.blocks ||
            widget.type === widgets.types.timeline ||
            widget.type === widgets.types.badgesList ||
            widget.type === widgets.types.users + '/list' ||
            widget.type === widgets.types.birthdays ||
            widget.type === widgets.types.wikiActueleArticles ||
            widget.type === widgets.types.wikiArticles ||
            widget.type === widgets.types.remindFillProfile ||
            widget.type === widgets.types.calendarmini ||
            widget.type === widgets.types.calendarWidget ||
            // widget.type === widgets.types.AlertWidget ||
            widget.type === widgets.types.html ||
            widget.type === widgets.types.wiki ||
            widget.type === widgets.types.FloatMessageWidget ||
            widget.type === widgets.types.QuickLinks ||
            widget.type === widgets.types.hierarchyNew ||
            widget.type === widgets.types.hierarchy ||
            widget.type === widgets.types.list + '/wikilist' ||
            (
              widget.type === widgets.types.layout &&
              widget.relations.includes('common')
            )
          ) && (
            <>
              <WidgetsSettings
                isShown={activeModal === 'widgetSettings'}
                data={{
                  wId: widget.id,
                  data: widget.data,
                  settings: widget.settings,
                  context: this.props.wcontext
                }}
                onConfirm={
                  widget.type === widgets.types.users + '/list'
                  ? changeWidgetData
                  : changeWidgetSettings
                }
                onClose={() => this.setState({ activeModal: '' })}
              />
              <Button name={this.props['data-id'] as string} onClick={() => {
                this.hideOverlay();
                this.setState({ activeModal: 'widgetSettings' })
              }} title={i18n.t('pryaniky.widgets.widget.settings')} children={<Icon icon="cog" />} />
            </>
          )
        }
        {
          settingMethod &&
          <Button name={this.props['data-id'] as string} onClick={() => {
            this.hideOverlay();
            settingMethod({
              onChange: updateWidget,
              selected: widget.type,
              data: widget,
              settings: widget.settings
            }).then((result: any) => {
              updateWidget({
                ...widget,
                ...result
              })
            })
            // this.setState({ activeModal: 'widgetSettings' })
          }} title={i18n.t('pryaniky.widgets.widget.settings')} children={<Icon icon="cog" />} />
        }
        {
          (
            widget.type === 'pryaniky/html' ||
            widget.type === widgets.types.wiki ||
            widget.type === widgets.types.AlertWidget ||
            widget.type === widgets.types.license
          ) && (
            <Button name={this.props['data-id'] as string} onClick={() => {
              this.setState({ editHTMLData: !this.state.editHTMLData }, () => {
                this.props.updateWidget({
                  ...this.props.widget,
                  HTMLWidgetContentEdit: this.state.editHTMLData
                })
              });
            }} title={i18n.t('pryaniky.widgets.widget.settings')} children={<Icon icon="edit" />} />
          )
        }
        {
          !widget.relations.includes('common') &&
          <Button
            name={this.props['data-id'] as string}
            onClick={this.removeWidget}
            title={i18n.t(
              `pryaniky.widgets.delete.${widget.type}${widget.settings?.once ? '.once' : ""}`
            )}
            children={<Icon icon="trash" />} />
        }
      </div>
    );
  }

  private showOverlay = () => this.setState({ showOverlay: true }, () => {
    if (this.state.showOverlay) this.setOverlaySize()
  });

  private hideOverlay = (e?: React.MouseEvent) => {
    const targ = e && e.relatedTarget as any;
    if (!targ || !targ.classList || (!targ.classList.contains(cnWidget('Overlay-H')) && !targ.classList.contains(cnWidget('Overlay-V')))) this.setState({ showOverlay: false });
  }

  private setOverlaySize = () => {
    if (!this.el.current) return;
    this.setState({ w_coord: this.el.current.getBoundingClientRect() })
    // const w_coord = this.el.current.getBoundingClientRect() as DOMRect;
  }

  private removeWidget = (ev: Event) => {
    const wId = (ev.currentTarget as HTMLButtonElement).name;
    const { widget } = this.props;
    // const widgetTitle = widget.title !== 'GeneratedWidget' ? widget.title : i18n.t(`pryaniky.widgets.create.name.${widget.type}${widget.settings?.once ? '.once' : ""}`);
    // const widgetTitle = widget.title !== 'GeneratedWidget' ? widget.title : i18n.t(`pryaniky.widgets.create.name.${widget.type}/${widget.settings?.timelineType}${widget.settings?.dateNow ? '/once' : ""}`);
    const widgetTitle = widget.title !== 'GeneratedWidget' ? widget.title : i18n.t(`pryaniky.widgets.create.name.${widget.type}${widget.settings?.timelineType ? widget.settings?.timelineType : ""}${widget.settings?.dateNow ? '/once' : ""}`);
    utilsProj.confirm({
      title: i18n.t('pryaniky.modal.confirm.delete.widget') + " " + widgetTitle + "?",

      onConfirm: () => { this.props.removeWidget(wId); }
    });
  }

  private layoutSelector = () => {
    const { widget } = this.props;
    this.hideOverlay();

    const okBtnValidate = (data: { [s: string]: any }) => {
      return data.selected;
    };

    const okBtnAction = (data: { [s: string]: any }) => {
      const { selected } = data;
      let { widget } = this.props;
      widget = utils.cloneObject(widget);
      selected.data.forEach((column: IColumn, i: number) => {
        if (widget.data) {
          if (widget.data[i]) {
            widget.data[i] = {
              ...column,
              ...widget.data[i],
              styles: column.styles
            }
          } else {
            widget.data[i] = {
              ...column
            }
          }
        }
      });
      if (widget.data && widget.data.length > selected.data.length) widget.data = widget.data.splice(0, selected.data.length);
      this.props.updateWidget(widget);
      // this.setState({layout: data.selected});
      // this.props.changeVm('structure.' + this.props.page.name, data.selected);
    }

    utilsDep.modal({
      header: i18n.t('pryaniky.modal.layoutGrid.title'),
      type: 'layoutGrid',
      data: {
        selected: widget
      },
      noFooter: true,
      okBtnAction,
      okBtnValidate
    })
  }

  private deleteLayout = () => {
    const onConfirm = () => {
      // this.setState({layout: undefined});
      // this.props.changeVm('page.' + this.props.page.name, undefined);
    }

    utilsProj.confirm({
      text: i18n.t('pryaniky.modal.pageDelete.title'),
      onConfirm
    })
  }

}

export const Widget = connect(
  makeMapStateToProps,
  mapDispatchToProps({})
)(compose()(WidgetPresenter));
// export const WidgetsSettings = withComponentEnjector(wrapDialogToRedux<IWidgetsSettingsProps>(WidgetsSettingsPresenter), ['WAddition', 'WSettings'])
export const withWidget: <T extends IWidgetProps>(asd: React.ComponentType<T> | ConnectedComponent<React.ComponentType<T>, T>, type: string) => React.ComponentType<T> = (Component, type) => {
  return (props) => {
    return (
      <Widget {...props} className={`${props.className || ''} ${type}`.trim()}>
        <Component {...props} />
      </Widget>
    )
  }
}