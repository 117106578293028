import { textTransform } from '@mui/system';
import { styled, Box, Typography, Paper } from 'muicomponents/src'
import { ItemsListHeader } from 'muicomponents/src/ItemsListDialog/ItemsListHeader/ItemsListHeader'
import { UsersSuggester as UsersSuggesterMui, BaseSuggester as BaseSuggesterMui } from 'muicomponents/src/Suggester';

const defaultSuggesterStyle = {
    '& .MuiInputBase-root': {
        paddingRight: '39px !important'
    }
}
export const UsersSuggester = styled(UsersSuggesterMui)({
    ...defaultSuggesterStyle
}) as typeof UsersSuggesterMui;

export const SearchWithFiltersBox = styled(Box)({
    '.ItemsListHeader-SearchBox': {
        columnGap: "inherit",
        marginBottom: 0,
        gap: "24px",
        ".MuiFormControl-root.MuiTextField-root": {
            marginBottom: 0,
        },
        //   ".MuiInputBase-root.MuiOutlinedInput-root": {
        //     borderRadius: "4px 0 0 4px"
        //   },
        //   ".MuiButtonBase-root.MuiButton-root": {
        //     borderRadius: "0 4px 4px 0 ",
        //     height: "41px",
        //     minWidth: "200px",
        //     fontSize: "16px",
        //     justifyContent: "space-between",
        //     "div": {
        //       textTransform: "capitalize"
        //     }

        //   }
    },
    ".ItemsListHeader-HeaderBottomContent": {
        paddingBottom: 0,
        ".MuiBadge-root.BaseBadge-root":{
            width: "100%",
        },
        ".MuiInputBase-root.MuiOutlinedInput-root": {
            height: "40px",
            padding: "3px 8px",
            fontSize: "16px",
            textTransform: "inherit",
            // minWidth: "200px"

        },
        ".PreListFiltersNew-SortFiltersSelector ": {
            ".MuiButtonBase-root.MuiButton-root": {
                height: "40px",
                padding: "3px 8px",
                fontSize: "16px",
                textTransform: "inherit",
                justifyContent: "start",
                // minWidth: "240px",
                width: "100%",
                ".MuiButton-endIcon": {
                    position: "absolute",
                    right: "8px"
                }
            }
        },
        ".MuiButtonBase-root.MuiButton-root": {
            height: "40px",
            padding: "3px 8px",
            fontSize: "16px",
            textTransform: "inherit",
            justifyContent: "space-between",
            // minWidth: "240px",
            width: "100%",

        },
        ".MuiButtonBase-root.MuiButton-root.PreListFiltersNew-CleanButton": {
            // minWidth: "105px",
            width: "100%",

        }
    }

}) as typeof Box;

