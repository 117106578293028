import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ISearchMuiProps, mapDispatchToProps, mapStateToProps, TListContext } from './SearchMui.index';
import { List as UsersSearchList } from 'blocks/ListMui/_type/users/List_users';
import { List as GroupsSearchList } from 'blocks/ListMui/_type/groups/List_groups';
import { List as WikiSearchList } from 'blocks/ListMui/_type/wiki/List_wiki';
import { List as TagsSearchList } from 'blocks/ListMui/_type/tags/List_tags';
import { List as FilesSearchList } from 'blocks/ListMui/_type/files/List_files';
import { List as CoursesSearchList } from 'blocks/ListMui/_type/courses/List_courses';
import Timeline from 'News/containers/TimelineSearch/component';
import TimelineSearch from 'News/containers/TimelineSearch/component';
import { Translate } from 'localizations/Translate';
import { SearchContentBox, SearchTabsBox, SearchWrapper, MarginForBox } from './SearchMui.styled';
import { SearchList } from './SearchList/SearchList';
import { SearchTabs } from './SearchTabs/SearchTabs';
import { useScrollListState, useDebouncedQueryState, useSearchParams, useDidUpdateEffect, useReffedState, useQueuedState } from 'utils/src/hooks'
import { getSearchI18nKeys, minSearchSymblosCount } from './SearchMui.utils';
import { TValue } from 'utils/src';
import { ListPlaceholder } from 'muicomponents/src/ListPlaceholder/ListPlaceholder';
import { NothingFoundText } from 'blocks/ListMui/_type/NotingFoundText';
import { useParams } from 'react-router';
import { TSearchValue } from 'redux/search/interfaces';
import { prepareFilterTypeByExtendedTypes } from 'muicomponents/src/SuggesterQuick/SuggesterQuick.utils';

const SearchMuiPresenter: FC<ISearchMuiProps> = ({
  filters,
  context,
  newsFilter,
  setAdditionalFilter,
  clearAdditionalFilter
}, props) => {

  let { tab: activeTab = 'all' } = useParams<{ tab: TSearchValue | undefined }>();

  const {
    requestType: renderTab
  } = useMemo(() => {
    const currentFilter = filters.find(el => el.filterName === activeTab);
    return prepareFilterTypeByExtendedTypes(activeTab, currentFilter);
  }, [activeTab, filters]);

  const [{ search: searchQuery = '' }, setParams] = useSearchParams() as any as [{ search: string | undefined }, (object: Record<string, any>) => void];
  const [search, setSearch] = useState(searchQuery?.toString() || '');
  // const [search, searchQuery, setSearch] = useDebouncedQueryState('search', 0, '');
  const [listHidden, setListHiddenState, listHiddenRef] = useQueuedState<{ [key in 'news' | 'users' | 'groups' | 'pages' | 'files' | 'tags' | 'course']: boolean }>({
    course: false,
    files: false,
    groups: false,
    news: false,
    pages: false,
    tags: false,
    users: false,
  });

  const [hasMore, setHasMoreState, hasMoreRef] = useQueuedState<{ [key in 'news' | 'users' | 'groups' | 'pages' | 'files' | 'tags' | 'course']: { count: number, hasManyResult: boolean } }>({
    course: { count: 0, hasManyResult: false },
    files: { count: 0, hasManyResult: false },
    groups: { count: 0, hasManyResult: false },
    news: { count: 0, hasManyResult: false },
    pages: { count: 0, hasManyResult: false },
    tags: { count: 0, hasManyResult: false },
    users: { count: 0, hasManyResult: false },
  });

  

  const allListsAreHidded = useMemo(() => {
    return !Object.values(listHidden).includes(false);
  }, [listHidden]);

  const setListHidden = useCallback((listName: keyof typeof listHidden, value: TValue<typeof listHidden>) => {
    if (listHiddenRef.current[listName] === value) return;
    setListHiddenState({
      [listName]: value
    });
  }, []);

  const setHasMore = useCallback((listName: keyof typeof hasMore, value: TValue<typeof hasMore>) => {
    if (hasMoreRef.current[listName] === value) return;
    setHasMoreState({
      [listName]: value
    });
  }, []);

  React.useEffect(() => {
    setSearch(searchQuery?.toString() || '');
  }, [searchQuery]);

  const listContext: TListContext = useMemo(() => {

    const additionalContext: Partial<TListContext> = {};
    if(renderTab === 'pages' && activeTab !== 'pages') {
      additionalContext.contentType = activeTab;
    }

    return {
      search: searchQuery?.toString() || "",
      network: context.network,
      viewType: 1,
      isSearchPageQuery: true,
      count: activeTab === "all" ? 3 : 10,
      ...additionalContext,
    }

  }, [activeTab, renderTab, searchQuery])

  const TimlineList = useMemo(() => {
    const hideSx = listHidden.news ? { display: activeTab === "all" ? 'none' : "block" } : undefined;
    return <SearchList
      searchQuery={searchQuery}
      title={<Translate i18nKey={getSearchI18nKeys('tab.news')} />}
      showAllButton={activeTab === "all"}
      value={"news"}
      sx={hideSx}
      hasMore={activeTab === "all" ? hasMore["news"] : undefined}
      children={
        <TimelineSearch
          isSearchPage={true}
          params={{
            searchText: searchQuery,
            author: newsFilter?.author?.id, // TODO: Сменить на рабочее рабочий параметр
            type: newsFilter?.type?.typeId,
            types: newsFilter?.types?.length > 0 ? newsFilter?.types?.map(((el: any) => el.typeId)) : [],
            startDate: newsFilter?.startDate,
            endDate: newsFilter?.endDate,
            ...listContext
          }}
          afterStateChangedCallback={(state) => {
            setListHidden('news', !state.values?.length && !state.isFailed && !state.isLoading);
          }}
          afterGetDataCallback={(state) => {
            setHasMore('news', { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
          }}
          context={{ postOff: true, searchText: listContext.search, ...listContext }}
          noNewsCreate
          {...props}
        />}
    />
  }, [listHidden.news, activeTab, listContext, searchQuery, newsFilter, hasMore.news])

  const UsersList = useMemo(() => {
    const hideSx = listHidden.users ? { display: activeTab === "all" ? 'none' : "block" } : undefined;
    return <SearchList
      searchQuery={searchQuery}
      title={<Translate i18nKey={getSearchI18nKeys('tab.users')} />}
      showAllButton={activeTab === "all"}
      value={"users"}
      sx={hideSx}
      hasMore={activeTab === "all" ? hasMore["users"] : undefined}

      children={<UsersSearchList
        context={listContext}
        afterStateChangedCallback={(state) => {
          setListHidden('users', !state.values?.length && !state.isError && !state.isLoading);
        }}
        afterGetDataCallback={(state) => {

          setHasMore('users', { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
        }}
        {...props}
      />}
    />
  }, [listHidden.users, activeTab, listContext, searchQuery, hasMore.users])

  const GroupsList = useMemo(() => {
    const hideSx = listHidden.groups ? { display: activeTab === "all" ? 'none' : "block" } : undefined;
    return <SearchList
      searchQuery={searchQuery}
      title={<Translate i18nKey={getSearchI18nKeys('tab.groups')} />}
      showAllButton={activeTab === "all"}
      value={"groups"}
      hasMore={activeTab === "all" ? hasMore["groups"] : undefined}

      sx={hideSx}
      children={<GroupsSearchList
        context={listContext}
        afterStateChangedCallback={(state) => {
          setListHidden('groups', !state.values?.length && !state.isError && !state.isLoading);
        }}
        afterGetDataCallback={(state) => {
          setHasMore('groups', { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
        }}
        {...props}
      />}
    />
  }, [listHidden.groups, activeTab, listContext, searchQuery, hasMore.groups])

  /**
   * явно недоделанно. пока откат
   */
  // const PagesList = useMemo(() => {
  //   const currentFilter = filters.find(el => el.filterName === activeTab);
  //   const hideSx = listHidden.pages ? { display: activeTab === "all" ? 'none' : "block" } : undefined;
  //   return <SearchList
  //     searchQuery={searchQuery}
  //     title={<Translate i18nKey={getSearchI18nKeys(`tab.${currentFilter?.filterName}`)} defaults={currentFilter?.displayName} />}
  //     showAllButton={activeTab === "all"}
  //     value={activeTab}
  //     hasMore={activeTab === "all" ? hasMore["pages"] : undefined}

  //     sx={hideSx}
  //     children={<WikiSearchList
  //       context={listContext}
  //       afterStateChangedCallback={(state) => {
  //         setListHidden('pages', !state.values?.length && !state.isError && !state.isLoading);
  //       }}
  //       afterGetDataCallback={(state) => {
  //         setHasMore('pages', { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
  //       }}
  //       {...props}
  //     />}
  //   />
  // }, [listHidden.pages, activeTab, listContext, searchQuery, hasMore.pages])

  const PagesList = useMemo(() => {
    const hideSx = listHidden.pages ? { display: activeTab === "all" ? 'none' : "block" } : undefined;
    return <SearchList
      searchQuery={searchQuery}
      title={<Translate i18nKey={getSearchI18nKeys('tab.pages')} />}
      showAllButton={activeTab === "all"}
      value={"pages"}
      hasMore={activeTab === "all" ? hasMore["pages"] : undefined}

      sx={hideSx}
      children={<WikiSearchList
        context={listContext}
        afterStateChangedCallback={(state) => {
          setListHidden('pages', !state.values?.length && !state.isError && !state.isLoading);
        }}
        afterGetDataCallback={(state) => {
          setHasMore('pages', { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
        }}
        {...props}
      />}
    />
  }, [listHidden.pages, activeTab, listContext, searchQuery, hasMore.pages])

  const FilesList = useMemo(() => {
    const hideSx = listHidden.files ? { display: activeTab === "all" ? 'none' : "block" } : undefined;
    return <SearchList
      searchQuery={searchQuery}
      title={<Translate i18nKey={getSearchI18nKeys('tab.files')} />}
      showAllButton={activeTab === "all"}
      value={"files"}
      sx={hideSx}
      hasMore={activeTab === "all" ? hasMore["files"] : undefined}

      children={<FilesSearchList
        context={listContext}
        afterStateChangedCallback={(state) => {
          setListHidden('files', !state.values?.length && !state.isError && !state.isLoading);
        }}
        afterGetDataCallback={(state) => {
          setHasMore('files', { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
        }}
        {...props}
      />}
    />
  }, [listHidden.files, activeTab, listContext, searchQuery, hasMore.files])

  const TagsList = useMemo(() => {
    const hideSx = listHidden.tags ? { display: activeTab === "all" ? 'none' : "block" } : undefined;

    return <SearchList
      searchQuery={searchQuery}
      title={<Translate i18nKey={getSearchI18nKeys('tab.tags')} />}
      showAllButton={activeTab === "all"}
      value={"tags"}
      sx={hideSx}
      hasMore={activeTab === "all" ? hasMore["tags"] : undefined}
      children={<TagsSearchList
        context={listContext}
        afterStateChangedCallback={(state) => {
          setListHidden('tags', !state.values?.length && !state.isError && !state.isLoading);
        }}
        afterGetDataCallback={(state) => {
          setHasMore('tags', { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
        }}
        {...props}
      />}
    />
  }, [listHidden.tags, activeTab, listContext, searchQuery, hasMore.tags])

  const CourseList = useMemo(() => {
    const hideSx = listHidden.course ? { display: activeTab === "all" ? 'none' : "block" } : undefined;
    return <SearchList
      searchQuery={searchQuery}
      title={<Translate i18nKey={getSearchI18nKeys('tab.courses')} />}
      showAllButton={activeTab === "all"}
      value={"course"}
      hasMore={activeTab === "all" ? hasMore["course"] : undefined}
      sx={hideSx}
      children={<CoursesSearchList
        context={listContext}
        afterStateChangedCallback={(state) => {
          setListHidden('course', !state.values?.length && !state.isError && !state.isLoading);
        }}
        afterGetDataCallback={(state) => {
          setHasMore('course', { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
        }}
        {...props}
      />}
    />
  }, [listHidden.course, activeTab, listContext, searchQuery, hasMore.course]);

  const HeaderComponent = useMemo(() => {

    return <SearchTabs
      searchQuery={search}
      changeSearchQuery={(value, reason) => {
        if (reason === 'enter') {
          setParams({ search: value });
        } else {
          setSearch(value);
        }
      }}
      activeTab={activeTab}
      setAdditionalFilter={setAdditionalFilter}
      clearAdditionalFilter={clearAdditionalFilter}
    />
  }, [search, activeTab]);

  const hideContentBox = useMemo(() => {
    return searchQuery.length < minSearchSymblosCount;
  }, [searchQuery]);

  return (<MarginForBox >

    {HeaderComponent}

    <SearchWrapper >

      {
        !hideContentBox &&
        <SearchContentBox>
          {
            activeTab === "all" && allListsAreHidded &&
            <ListPlaceholder
              isFinished
              isLoading={false}
              itemsCount={0}
              textEmpty={<NothingFoundText />}
            />
          }
          {activeTab === "all" && <SearchList
            searchQuery={search}
            hasMore={activeTab === "all" ? hasMore["files"] : undefined}

            children={filters.map((filter: any) => {
              switch (filter.filterName) {
                case 'news':
                  return TimlineList;
                case 'users':
                  return UsersList;
                case 'groups':
                  return GroupsList
                case 'pages':
                  return PagesList
                case 'files':
                  return FilesList;
                case 'tags':
                  return TagsList
                case 'course':
                  return CourseList
              }

            }
            )}

          />}
          {renderTab === 'news' && TimlineList}

          {renderTab === 'users' && UsersList}

          {renderTab === 'groups' && GroupsList}

          {renderTab === 'pages' && PagesList}

          {renderTab === 'files' && FilesList}

          {renderTab === 'tags' && TagsList}
          
          {renderTab === 'course' && CourseList}

        </SearchContentBox>
      }
    </SearchWrapper>
  </MarginForBox>
  )
}

export const SearchMui = connect(mapStateToProps, mapDispatchToProps)(SearchMuiPresenter)