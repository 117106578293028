import { Box, Typography } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';

export const BadgesGridItemsBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '36px',
  rowGap: '24px',
  marginTop: '24px'
})

export const BadgeCategoryBox = styled(Box)<{isGrid?: boolean}>(({ isGrid }) => ({
  "&:not(:last-child)": {
    marginBottom: isGrid ? '24px' : '16px'
  }
}));

export const BadgeCategoryTitle = styled(Typography)({
  fontWeight: 600, 
  fontSize: 20,
}) as typeof Typography;

export const BadgeItemValue = styled('span')({
  color: 'initial',
  textTransform: 'lowercase'
});

