import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';
import { v1 as uuid } from 'uuid';

export const wikiTP = (): IWidget<IColumn[]> => {
  const ids = guids([ 'users1' ]);
  return generateWidget({
    type: widgets.types.grid,
    data: [
      generateColumn({
        items: [
          generateWidget({
            id: ids.users1,
            type: widgets.types.list + '/users'
          })
        ],
        styles: {
          width: '100%',
        },
      })
    ],
    settings: {
      breakpoints: { xs: 480 },
      cols: { xs: 12 },
      layouts: {
        xs: [
          { i: ids.users1, x: 0, y: 0, w: 12, h: 10 },
        ]
      }
    }
  });
}