import React, { useState } from 'react';
import {
    // DialogActions,
    CheckboxInput,
    Input,
    Button as PrnButton,
    ErrorMsg,
    Loading,
    Dropdown
} from 'uielements/src';
import Button from '@material-ui/core/Button';
import i18n from '../../../../localizations/i18n';
import { toast } from 'react-toastify';
import { InputMention } from '../../../InputMention/InputMention';
import "./WikiEdit.scss";
import { wrapDialogToRedux } from "../../DialogWrapper";
import { connect } from "react-redux";
import { ImgLoader } from '../../../ImgLoader/ImgLoader';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import {
    IWikiEditOwnProps,
    IWikiEditProps,
    cnWikiEditDialog,
    mapWikiEditDispatchToProps,
    mapWikiEditStateToProps,
    IWikiEditDispatchProps,
    IWikiEditStateProps,
} from './WikiEdit.index'
import { mapDispatchToProps, IDispatchProps } from "redux/connector";
import { IStateType as IState } from 'redux/store';
import InputMaterial from 'uielements/src/MaterialElements/Input/Input';
import { Translate } from '../../../../localizations/Translate';
import { HelpTooltip, Icon } from 'uielements/src/index';
import Suggest from 'uielements/src/MaterialElements/Autocomplete';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import { FilesUploader } from 'muicomponents/src/FilesUploader/FilesUploader'
import { useFileUploader, mapFromAttachmentsList, IAttachmentType } from 'utils/src/hooks'
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'utils/src/hooks';
import { TextError } from 'uielements/src/TextError/TextError';
import { TextField } from 'muicomponents/src/TextField'
import {Box} from 'muicomponents/src'

let lastName = '';
let id = '';
export const WikiEditDialogPresenter: React.FC<IWikiEditProps> = ({
    data: pdata,
    context,
    isShown,
    onClose,
    onConfirm,
    onChange = () => { },
    actualData,
    id,
    handleAccept,
    handleClose,
    ...props
}) => {
    const history = useHistory();

    const [loading, setLoading] = React.useState(false);
    const [actualData1, setActualData1] = React.useState(actualData);
    const [error, setError] = React.useState(false);

    const typePage = actualData1 && actualData1.type === "page"
    const typeWikilist = actualData1 && actualData1.type === "wikilist";
    const typeUrl = actualData1 && actualData1.type === "url";
    const typeFile = actualData1 && actualData1.type === "file";
    React.useEffect(() => {
        getActualData(id)

    }, []);

    const {
        isLoading,
        files,
        setFiles,
        onFileChange,
        removeFile,
        uploadDisabled
    } = useFileUploader({
        // defaultAttachments: actualData1?.file ? [actualData1?.file] : [],
        uploadOnAdd: true,
        maxFilesCount: 1
    })
    React.useEffect(() => {
        if (actualData1?.file) {
            setFiles([actualData1?.file])
        }
    }, [actualData1?.file])
    React.useEffect(() => {
        if (actualData1 && (files.length >= 1) && files[0].isUploaded) {
            setActualData1({ ...actualData1, file: files[0].response?.data[0] })
            if (actualData1.title === '' || !actualData1.title) {
                setActualData1({ ...actualData1, title: files[0].name, url: files[0].name, file: files[0].response?.data[0] })
            }
        }
    }, [files])


    const handleCancel = () => {
        handleClose();
    };
    const getActualData = (id: string) => {
        return (
            utils.API.pages.getByIdSkipLayout(id, { collection: context.collectionAlias ? context.collectionAlias : "dash" })
                .r
                .then((response) => {
                    if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                    else if (response.error_code === 0) {
                        const typePage = response.data && response.data.type === "page"
                        const typeWikilist = response.data && response.data.type === "wikilist";
                        const typeUrl = response.data && response.data.type === "url";
                        setActualData1(response.data);
                        (typePage || response.data.type === "") && setActualData1({ ...response.data, url: response.data.url.indexOf("/pages/") !== -1 ? response.data.url.replace("/pages/", "") : response.data.url.replace("pages/", "") });
                        typeWikilist && setActualData1({ ...response.data, url: response.data.url.indexOf("/wikilist/") !== -1 ? response.data.url.replace("/wikilist/", "") : response.data.url.replace("wikilist/", "") });
                    }
                }
                )
        )

    }
    const deleteWikiPage = () => {
        utilsProj.confirm({
            text: i18n.t("pryaniky.modal.wikipage.confirm.delete"),
            onConfirm: () =>

                utils.API.pages.deletePage(id)
                    .r
                    .then((response) => {
                        if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                        else if (response.error_code === 0) {
                            toast.success(i18n.t("pryaniky.modal.wikipage.deleted"));
                        }
                    })
        });
        handleClose();
    }


    // let link = actualData1?.url

    // const correctLink = () => {
    //     link = actualData1?.type === "page" ? "/pages/" + actualData1.url : link
    //     link = actualData1?.type === 'wikilist' ? "/wikilist/" + actualData1.url : link
    //     link = actualData1?.type === 'file' ? "/pages/" + actualData1.url : link
    //     return link
    // }

    const sendDataForEditPage = (isArchived?: boolean) => {

        let link = actualData1?.url
        link = actualData1?.type === "page" || !actualData1?.type ? "pages/" + actualData1?.url : link
        link = actualData1?.type === 'wikilist' ? "wikilist/" + actualData1.url : link
        link = actualData1?.type === 'file' && files[0] ? "pages/files/" + files[0].id : link
        // link = actualData1?.type === 'file' ? "/pages/" + actualData1.url : link

        if (actualData1) {
            setLoading(true);
            // const pageUrl = actualData1.url.indexOf("/pages/") === -1 || actualData1.url.indexOf("pages/") === -1 ? "/pages/" + actualData1.url : actualData1.url;
            // const wikilistUrl = "/wikilist/" + actualData1.url;
            // const absoluteUrl = actualData1.url;


            utilsProj.confirm({
                text: i18n.t("pryaniky.modal.wikipage.confirm.edit"),

                onConfirm: () => {

                    return utils.API.pages.setSettings({
                        ...actualData1,
                        collectionAlias: context.collectionAlias ? context.collectionAlias : "dash",
                        id: actualData1.id,
                        url: link,//typePage ? pageUrl : typeWikilist ? wikilistUrl : typeUrl ? absoluteUrl : pageUrl,
                        title: actualData1.title,
                        moderators: actualData1.moderators,
                        tags: actualData1.tags,
                        collections: actualData1.collections,
                        type: actualData1.type === "" ? "page" : actualData1.type,
                        titleImageId: actualData1.titleImageId,
                        hasCustomTitleImage: actualData1.titleImageId === null || actualData1.titleImageId === undefined || actualData1.titleImageId === "" ? false : true,
                        titleImageUrl: actualData1.titleImageUrl,
                        showInMainMenu: actualData1.showInMainMenu,
                        allowComments: actualData1.allowComments,
                        defaultViewType: actualData1.defaultViewType ? actualData1.defaultViewType : 0,
                        isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived,
                        positionInCollection: Number(actualData1.positionInCollection),
                        viewers: actualData1.viewers,
                        file: actualData1.file

                    })
                        .r
                        .then((response) => {

                            if (!response) {
                                toast.error(i18n.t('pryaniky.toast.error.server'));
                            }
                            if (response.error_code !== 0) {
                                toast.error(response.error_text);
                                setLoading(false);
                            }
                            else if (response.error_code === 0) {
                                toast.success(i18n.t("pryaniky.modal.wikipage.sucсess"));
                                if (onConfirm) onConfirm(actualData1);
                                setActualData1({ ...actualData1, isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived })
                                if (history.location.pathname.includes("/pages") && pdata?.url !== link && (actualData1.type === "page" || actualData1.type === "file" || !actualData1.type)) {
                                    history.push(`/${link}`);
                                }
                                handleClose();

                            }
                        });

                },
                onCancel: () => {
                    setLoading(false)
                },
            });
        }

    }
    const onChangeShowInMainMenu = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, showInMainMenu: !actualData1.showInMainMenu })
    }

    const onChangeAllowComments = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, allowComments: !actualData1.allowComments })
    }

    const archivedPage = () => {
        if (actualData1) sendDataForEditPage(!actualData1.isArchived)
    }


    const onChangeLinkInput = (e: any) => {
        const pageUrl = "pages/" + e.currentTarget.value;
        const wikilistUrl = "wikilist/" + e.currentTarget.value;
        !typeUrl && onChangeLinkInputUseDebounce(typePage ? pageUrl : wikilistUrl);
        actualData1 && setActualData1({ ...actualData1, url: e.currentTarget.value })
    }

    const checkUrlIsExist = (value: string) => {
        utils.API.pages.isExists(value)
            .r
            .then((response) => {
                if (!response) {

                    toast.error(i18n.t('pryaniky.toast.error.server'));
                } else if (response.data === true) {
                    setError(true)
                    toast.error(i18n.t('pryaniky.modal.wikipage.create.url.is.exist'))
                } else if (response.data === false) {
                    setError(false)
                }
            })
    }

    const onChangeLinkInputUseDebounce = useDebounce(checkUrlIsExist, 1000, []);

    const deleteOptions = [
        {
            title: i18n.t("pryaniky.modal.wikipage.delete"),
            buttonProps: {
                onClick: deleteWikiPage,
            },
        },
        {
            title: i18n.t(`pryaniky.modal.wikipage.${actualData1 && actualData1.isArchived ? 'unarchive' : 'archive'}`),
            buttonProps: {
                onClick: archivedPage,
            },
        },
    ]

    return (
        <>
            <DialogTitle onClose={handleClose}>
                {i18n.t("pryaniky.modal.wikipage.edit.page")}
                {/* <p className="my-1">{i18n.t("pryaniky.modal.inviteUser.text")}</p> */}
            </DialogTitle>
            {actualData1 ? <DialogContent className={cnWikiEditDialog("")}>
                <Input
                    name="title"
                    type="text"
                    value={actualData1.title}
                    className={cnWikiEditDialog("Input")}
                    placeholder={!actualData1.title ? "Нет названия" : actualData1.title}
                    autoFocus
                    icon="heading"
                    onChange={(e: any) => setActualData1({ ...actualData1, title: e.currentTarget.value })} />
                {
                    actualData1.type !== 'file' &&
                    <Input
                        name="url"
                        type="text"
                        value={actualData1.url}
                        style={{ marginLeft: typeWikilist ? "53px" : typePage || actualData1.type === "" ? "52px" : "0px" }}
                        className={cnWikiEditDialog(error ? "ErrorLink" : "InputLink")}
                        placeholder={i18n.t("pryaniky.modal.wikipage.page.url")}
                        autoFocus={error}
                        icon="link"
                        onChange={(e: any) => onChangeLinkInput(e)}>
                        {/* {<p className={cnWikiEditDialog("urlChild")}>/pages/</p>} */}
                        {typePage || actualData1.type === "" ? <p className={cnWikiEditDialog("urlChild")}>/pages/</p> : typeWikilist ? <p className={cnWikiEditDialog("urlChild")}>/wikilist/</p> : typeUrl && undefined}
                    </Input>
                }
                {actualData1.url === "" && <TextError data={i18n.t('pryaniky.modal.wikipage.disabled.btn.help')} />}
                {error && <TextError data={i18n.t('pryaniky.modal.wikipage.create.url.is.exist')} />}
                {
                    actualData1.type === 'file' && <FilesUploader
                        files={files}
                        multiple={false}
                        selectButtonProps={{
                            children: i18n.t("pryaniky.modal.wikipage.page.selectFile"),
                            variant: 'outlined'
                        }}
                        disabled={uploadDisabled}
                        className={cnWikiEditDialog("FileInput")}
                        // inputAccept="image/jpeg,image/png"
                        onFileChange={onFileChange}
                        removeFile={removeFile}
                    />
                }

                <div className={cnWikiEditDialog("InputFolder")}>
                    <InputMention
                        onChange={(value: any) => setActualData1({ ...actualData1, moderators: value })}
                        className={cnWikiEditDialog("InputFolder-Folder")}
                        value={actualData1.moderators}
                        type="users"
                        icon="user-add"
                        placeholder={i18n.t("pryaniky.modal.wikipage.choose.moderator")}
                    // {i18n.t('pryaniky.shop.selectFor')}
                    />
                </div>
                <div className={cnWikiEditDialog("InputFolder")}>
                    <InputMention
                        onChange={(value: any) => setActualData1({ ...actualData1, tags: value })}
                        className={cnWikiEditDialog("InputFolder-Folder")}
                        value={actualData1.tags}
                        type="tags"
                        icon="tags"
                        placeholder={i18n.t("pryaniky.modal.wikipage.choose.tag")}
                    // {i18n.t('pryaniky.shop.selectFor')}
                    />
                </div>
                <div className={cnWikiEditDialog("InputFolder")}>
                    <InputMention
                        onChange={(value: any) => setActualData1({ ...actualData1, collections: value })}
                        className={cnWikiEditDialog("InputFolder-Folder")}
                        value={actualData1.collections}
                        type="pagecollections"
                        icon="folder"
                        placeholder={i18n.t("pryaniky.modal.wikipage.choose.pagecollections")}
                    // {i18n.t('pryaniky.shop.selectFor')}
                    />
                    {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.choose.pagecollections.help')}> */}
                    <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.pagecollections.help')} >
                        <span>  <Icon icon={'question-circle'} /></span>
                    </Tooltip>
                </div>
                <div className={cnWikiEditDialog("InputFolder")}>
                    <Suggest
                        type='usersgroups'
                        multiple={true}
                        label={typePage || actualData1.type === "" ?
                            i18n.t('pryaniky.modal.wikipage.page.viewers') : typeWikilist ?
                                i18n.t('pryaniky.modal.wikipage.folder.viewers') : i18n.t('pryaniky.modal.wikipage.link.viewers')}
                        value={actualData1?.viewers || []}
                        onChange={(viewers: any[]) => setActualData1({ ...actualData1, viewers })}
                    />
                    {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.viewers.help')}> */}
                    <Tooltip title={i18n.t('pryaniky.modal.wikipage.viewers.help')} >
                        <span><Icon icon={'question-circle'} /></span>
                    </Tooltip>
                </div>
                {typeWikilist && <div className={cnWikiEditDialog("ViewType")}>
                    <h4><Translate i18nKey={'pryaniky.news.view.type.title.default'} /></h4>
                    <div>
                        {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.full.help')}> */}
                        <PrnButton main={actualData1.defaultViewType === 0} onClick={() => setActualData1({ ...actualData1, defaultViewType: 0 })}>
                            <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.full.help')} >
                                <span><Icon icon={'wiki'}></Icon></span>
                            </Tooltip>
                        </PrnButton>
                        {/* </HelpTooltip> */}
                        {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.short.help')}> */}
                        <PrnButton main={actualData1.defaultViewType === 1} onClick={() => setActualData1({ ...actualData1, defaultViewType: 1 })}>
                            <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.short.help')} >
                                <span> <Icon icon={'bars'}></Icon></span>
                            </Tooltip>
                        </PrnButton>
                        {/* </HelpTooltip> */}
                    </div>
                </div>}

                <Box className={cnWikiEditDialog("PositionInCollection")}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0 24px",
                        "input::-webkit-outer-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0
                        },
                        "input::-webkit-inner-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0
                        }
                    }}>

                    < TextField
                    
                        className={cnWikiEditDialog("PositionInCollection")}
                        inputProps={{ inputMode: 'numeric' }}
                        suggesterValue={false}
                        label={i18n.t('pryaniky.modal.wikipage.page.positionInCollection')}
                        // style={{ maxWidth: "300px" }}
                        variant='outlined'
                        value={actualData1.positionInCollection ? actualData1.positionInCollection.toString() : ""}
                        onChange={(e) => {
                            if (e.currentTarget.value.match(/[^0-9]/)) {
                                e.preventDefault();
                            } else {
                                setActualData1({ ...actualData1, positionInCollection: e.currentTarget.value })
                            }
                        }}
                    />

                </Box>

                <Checkbox
                    className={cnWikiEditDialog("CheckboxInput")}
                    value={actualData1.showInMainMenu}
                    onChange={onChangeShowInMainMenu}
                    label={i18n.t("pryaniky.modal.wikipage.showInMainMenu")}
                />

                {/* you can comment page or filePage */}
                {(typePage || typeFile) && <Checkbox
                    className={cnWikiEditDialog("CheckboxInput")}
                    value={actualData1.allowComments}
                    onChange={onChangeAllowComments}
                    label={i18n.t("pryaniky.modal.wikipage.allowComments")}
                />
                }
                <ImgLoader
                    className={cnWikiEditDialog("IMG")}
                    type={'WikiEdit'}
                    previewUrl={actualData1.titleImageUrl}
                    onLoadEnd={(value: any) => setActualData1({ ...actualData1, titleImageId: value.id, titleImageUrl: value.originalUrl })}>
                    {<h4 className={cnWikiEditDialog("child")}>{i18n.t("pryaniky.modal.wikipage.cover")}</h4>}
                    {<p className={cnWikiEditDialog("child")}>{i18n.t("pryaniky.modal.wikipage.cover.size")}</p>}
                </ImgLoader>

            </DialogContent> :
                <DialogContent>
                    <div className={cnWikiEditDialog("Loading")}>
                        <Loading text={i18n.t("pryaniky.modal.wikipage.loading.actual.data")} />
                    </div>
                </DialogContent>
            }

            <DialogActions
                acceptText={i18n.t("pryaniky.modal.wikipage.save.changes")}
                closeText={i18n.t("pryaniky.modal.wikipage.cencel")}

                onAccept={() => sendDataForEditPage()}
                disableAccept={(actualData1 && (actualData1.url === '' || actualData1.url === undefined)) || isLoading || error}
                isLoading={loading || isLoading}
                onClose={handleCancel} />


        </>
    );

};

export const WikiEditDialog = connect<IWikiEditStateProps, IWikiEditDispatchProps, IWikiEditOwnProps, IState>(
    mapWikiEditStateToProps,
    mapWikiEditDispatchToProps
)(WikiEditDialogPresenter)

export const DialogWikiEdit = createModal(WikiEditDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});