import { ISagaModule } from 'redux-dynamic-modules-saga';
import { history } from './browserHistory';
import { store } from 'utils/src/CommonRedux/base/reducer';
import { lifeCycolReducer as lifeCycol } from 'utils/src/CommonRedux/LifeCycol/reducer';
import { liReducer } from 'utils/src/CommonRedux/LoadIndicator';
import { chatReducer as chat } from './reducers/Chat';
import { newsReducer } from '../News/redux/reducer';
import { lmsReducer as LMS } from '../LMSModule/redux/reducers/LMS';
import { lmsTeacherOfficeReducer as LMSTO } from '../LMSModule/redux/reducers/LMSTeacherOffice';
import { learningTracksReducer } from '../LMSModule/redux/reducers/TracksManage';
import { learningTrackUseReducer } from '../LMSModule/redux/reducers/TracksUse';
import { widgetsReducer as widgets } from './reducers/Widgets';
import { photoalbumsReducer as photoalbums } from './reducers/Photoalbums';
import { filesReducer as files } from './reducers/Files';
import { usersReducer as users } from 'utils/src/CommonRedux/users/reducer';
import { groupsReducer as groups } from './reducers/Groups';
import { calendarResucer as calendar } from './calendar/reducer';
import { notifiSettingsReducer as notifiSettings } from './notifiSettings/reducer';
import { lists } from './reducers/Lists';
import { modals } from './reducers/Modals';
import { notificationsReducer as notifications } from './reducers/Notifications';
import { jsonformsReducer } from '@jsonforms/core';
import { connectRouter, RouterState } from 'connected-react-router';
import { sliderLinkReducer as sliderLink } from './reducers/Slider';
import { titlesReducer as titles } from './reducers/Titles';
import { searchReducer as search } from './reducers/Search';
import { searchReducer as searchSettings } from './search/reducer';
import { quizReducer as quiz } from './reducers/Quiz';
import { appSettingsReducer as appSettings } from './reducers/AppSettings';
import { shopReducer as shop } from './shop/reducer';
import { journalReducer as journal } from './sagas/Journal/reducer';
// import { i18nReducer as i18n } from 'utils/src/CommonRedux/i18n/reducer';
import { skillsReducer as skills } from './reducers/Skills';
import { hierarchyReducer as hierarchy } from './sagas/Hierarchy/reducer';
import { loadingReducer as loading } from './loading/reducer';
import { currencyTimelineReducer as currencyTimeline } from './currencyTimeline/reducer';
import { questsReducer as quests } from './quests/reducer';
import { kpiReducer } from '../KPIModule/redux/reducer';
import { orgchartReducer as orgchart } from './sagas/orgchart/reducer';
import { surveyReducer as survey } from './sagas/Surveys/reducer';
import { reducerMenu as menu } from '../blocks/LeftMenu/redux/reducer';
import { Reducer, AnyAction } from 'redux';
import { sagas } from './sagas'
import { routerMiddleware } from 'connected-react-router';
import { secretSantaWidgetReducer as secretSantaWidget} from './sagas/SecretSanta/reducer'
import { TitlesReducer as systemTitles } from './sagas/Titles/reducer'
import { dataStorageReducer } from './dataStorage';
import { widgetsReducer } from './../Widgets_v2/redux/reducer';


const reducerMap = {
  store,
  lifeCycol,
  liReducer,
  chat,
  newsReducer,
  LMS,
  LMSTO,
  learningTracksReducer,
  learningTrackUseReducer,
  widgets,
  photoalbums,
  files,
  users,
  groups,
  calendar,
  notifiSettings,
  lists,
  modals,
  notifications,
  jsonforms: jsonformsReducer(),
  router: connectRouter(history),
  sliderLink,
  titles,
  search,
  quiz,
  appSettings,
  shop,
  journal,
  // i18n,  // подключается отдельным модулем
  skills,
  hierarchy,
  loading,
  currencyTimeline,
  quests,
  kpiReducer,
  orgchart,
  secretSantaWidget,
  menu,
  systemTitles,
  dataStorage: dataStorageReducer,
  survey,
  widgets2: widgetsReducer,
  searchSettings
}

type IBaseReduxModule = {[s in keyof typeof reducerMap]: ReturnType<typeof reducerMap[s]>}

// конверт " as {[s in keyof IBaseReduxModule]: Reducer<ReturnType<IBaseReduxModule[s]>, AnyAction>}" сделан для того чтобы типы пробросились нормально, иначе баг не совпадают типы экшнов
export function getBaseModule(): ISagaModule<IBaseReduxModule> {
  return {
    id: "base",
    middlewares: [
      routerMiddleware(history)
    ],
    reducerMap: reducerMap as {[s in keyof IBaseReduxModule]: Reducer<ReturnType<IBaseReduxModule[s]>, AnyAction>},
    sagas
  };
}