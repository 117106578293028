import React, { FC, useState, useEffect, useCallback } from 'react';
import { ListHeader } from 'muicomponents/src/ListHeader/ListHeader'
import { LeaderboardListItem } from 'muicomponents/src/LeaderboardListItem/LeaderboardListItem'
import { InfinityList } from 'muicomponents/src/InfinityList/InfinityList'
import { useScrollListState, useDebouncedQueryState } from 'utils/src/hooks'
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { i18n } from "localization";
import "./Leaderboard_list_new.scss";
import { useLazyQueryEx } from 'utils/src/hooks'
import { orderCancel } from 'utils/src/requests/requests.shop'
import { ILeaderboardProps, mapDispatchToProps, mapStateToProps, cnLeaderboard } from './Leaderboard_list_new.index'
import { leaderboard } from 'utils/src/requests/requests.ratings'
import { IRatingUserInfo, IRatingValue } from 'utils/src/requests/models/api.ratings'
import { ILeaderboardResponse, IRatingsData, IRatingItem } from 'utils/src/requests/models/api.ratings';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import * as utils from "utils/src/utils";
import { styled } from '@mui/material/styles';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import {} from ''
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button } from 'muicomponents/src/Button/Button'
import { Typography } from "muicomponents/src/Typography/Typography"
import { Avatar } from 'muicomponents/src/Avatar/Avatar';
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { Popover } from 'muicomponents/src/Popover';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle/DialogTitle';
import { RadioGroupMui } from 'muicomponents/src/RadioGroup/RadioGroup';
import { FormControl } from 'muicomponents/src/FormControl/FormControl';
import FormLabel from '@mui/material/FormLabel';
import widgetsActions from 'redux/actionsTypes/Widgets';
import { useDispatch } from "react-redux";
import { HeaderLeaderboard } from './HeaderLeaderboard/HeaderLeaderboard'
const dropdown = [
    { content: i18n.t('pryaniky.modal.wikipage.create.type.page.new'), id: 'page' },
    { content: i18n.t('pryaniky.modal.wikipage.create.type.wikilist.new'), id: 'wikilist', },
    { content: i18n.t('pryaniky.modal.wikipage.create.type.url.new'), id: 'url', },
    { content: i18n.t('pryaniky.modal.wikipage.create.type.file.new'), id: 'file', },

]

async function getOrdersPromise(opt: any) {
    const response = await leaderboard(opt)
    return await response.r
}

const method = (rid: string, search?: string) => async function (skipCount: number, count: number) {
    return (await getOrdersPromise({ skipCount, count, search, rid, })).data.users
}


export const LeaderboardListPresenter: React.FC<ILeaderboardProps> = ({ 
    match, 
    curUser, 
    tag: TagName = 'div',
    ...props }) => {
    const [search, searchQuery, setSearch] = useDebouncedQueryState('search')
    const [myRatingValue, setMyRatingValue] = useState<any>()
    const [ratingValue, setRatingValue] = useState<any>()

    const [orderValue, setOrderValue] = useState<boolean>(ratingValue?.reverseOrder || false)
    const [filters, setFilters] = useState<any>([])
    const [categories, setCategories] = useState<any>([])

    const [myPos, setMyPos] = useState<any>(2)
    // const [isVisible, setIsVisible] = useState<any>()


    const [popoverAnchor, setPopoverAnchor] = useState<{ top: number; left: number } | null>(null);
    const dispatch = useDispatch();

    const getData = useCallback(async function (skipCount: number, count: number, order?: boolean) {
        const response = await getOrdersPromise({ skipCount, count, search, rid: match.params.id, gid: (props as any).context.gid, reverse: orderValue })
        if (!myRatingValue) setMyRatingValue(response.data.myRatingValue)
        if (!ratingValue) setRatingValue(response.data.rating)

        // setOrderValue(response.data.rating.reverseOrder)
        return response.data.users
    }, [match.params.id, search, orderValue, (props as any).context.gid])

    const {
        isFinished,
        isLoading,
        loadMore,
        triggerRef,
        values,
        error: {
            withError
        },

    } = useScrollListState(getData, [searchQuery, orderValue, (props as any).context.gid])




    // ---------------------- начало работы с  observer для отображения рейтинга смотрящего даже если он в списке ушел из зоны видимости-----------------------------> 


    let options = {
        rootMargin: '200px',
        threshold: 0.01
    }
    const callback = (entries: any, observer: any) => {
        entries.forEach((entry: any) => {
            if (entry.rootBounds)

                // setIsVisible(entry.intersectionRatio !== 0);
                setMyPos(entry.intersectionRatio === 0 ? ((entry.rootBounds.height / 2) > entry.boundingClientRect.top ? 0 : 2) : 1)

        });
    };
    let observer = new IntersectionObserver(callback, options);

    const setMePostition = (uid: string) => {
        if (myRatingValue && (myRatingValue.id === uid)) return (r: any) => {
            if (r && observer) observer.observe(r);
        }
    }

    // ---------------------- конец работы с  observer для отображения рейтинга смотрящего даже если он в списке ушел из зоны видимости-----------------------------> 

    return (
        <TagName className={cnLeaderboard('')} {...props}>
            {ratingValue && <HeaderLeaderboard changeSearch={(e) => { setSearch(e.target.value) }} searchValueHeader={search} orderValueHeader={orderValue} changeOrder={() => setOrderValue(!orderValue)} widgetId={(props as any).widget.id} context={(props as any).context} ratingValueToHeader={ratingValue} />}

            <Paper
                className={cnLeaderboard('LeaderboardContent')}
                component="div"
                sx={{ marginTop: '16px', padding: '24px' }}
                id={"LeaderbordList"}
            >
                <InfinityList
                    className={cnLeaderboard('LeaderboardContent-List')}
                    isError={withError}
                    triggerRef={triggerRef}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    itemsCount={values.length}
                    titleEmpty={i18n.t('pryaniky.leaderbord.titleEmpty')}
                    textEmpty={(searchQuery && searchQuery !== '') ? i18n.t('pryaniky.leaderbord.textEmpty', { query: searchQuery }) : undefined}
                    loadMore={loadMore}>

                    {
                        ratingValue && ratingValue.ratingType.toLowerCase() === "agregate" &&
                        <>
                            <LeaderboardListItem
                                key={ratingValue.id}
                                data={{

                                    idx: -1,
                                    id: ratingValue.id,
                                    alias: '',
                                    value: ratingValue.value,
                                    color: ratingValue.color,
                                    curValue: ratingValue.curValue,
                                    position: '',
                                    division: '',
                                    displayName: i18n.t('pryaniky.rating.collectivevalue'),
                                    imgId: ratingValue.imgId,//'',
                                    imgUrl: ratingValue.imgUrl,//'',
                                    percantage: ratingValue.percantage,
                                    level: '',
                                }} rating={ratingValue} color={ratingValue.color} maxValue={ratingValue.maxValue} />
                            <Divider variant="inset" sx={{ marginLeft: '0px' }} />

                        </>
                    }
                    {(ratingValue && myRatingValue && myPos === 0) && <LeaderboardListItem
                        className={cnLeaderboard('MyItem myInTop')}
                        rating={ratingValue}
                        data={myRatingValue}
                        color={ratingValue.color}
                        maxValue={ratingValue.maxValue} />

                    }

                    {values?.map((item: any, index: number) => <>


                        <LeaderboardListItem data={item} color={ratingValue.color} setMePostition={setMePostition(item.id)} maxValue={ratingValue.maxValue} rating={ratingValue} />
                        <Divider variant="inset" sx={{ marginLeft: '0px' }} />
                    </>)}
                    {(ratingValue && myRatingValue && myPos === 2) && <LeaderboardListItem
                        className={cnLeaderboard('MyItem')}
                        rating={ratingValue}
                        data={myRatingValue}
                        color={ratingValue.color}
                        maxValue={ratingValue.maxValue} />

                    }
                </InfinityList>
            </Paper>
        </TagName >
    )
}

export const LeaderboardList = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderboardListPresenter))
