import { i18n } from "localization"


interface IValidate {
    customFieldsErrors: { [key: string]: object | undefined }
    mondatorySystemFields: string[]
    baseFieldsState: any
    multiDivisionsAllowed: boolean
    multiPositionsAllowed: boolean
}

const getAdditionalFieldsErrors = (customFieldsErrors: any) => {
    const keys = Object.keys(customFieldsErrors)
    const errArr: string[] = []
    keys.forEach(key => { if (!!customFieldsErrors[key]) errArr.push(key) })
    return !!errArr.length
}

export const validate = ({
    customFieldsErrors,
    mondatorySystemFields,
    baseFieldsState,
    multiDivisionsAllowed,
    multiPositionsAllowed,
}: IValidate) => {

    const errors: { [key: string]: string } = {}
    const multiErrText = i18n.t('pryaniky.userSettings.list.item.multipleValuesNotAllowed')
    const hasAdditionalFieldsErrors = getAdditionalFieldsErrors(customFieldsErrors) || false

    if (!multiDivisionsAllowed && baseFieldsState.divisionTags.length > 1) {
        errors.divisionTags = multiErrText
    }
    if (!multiPositionsAllowed && baseFieldsState.positionTags.length > 1) {
        errors.positionTags = multiErrText
    }
    if (baseFieldsState.eMail.length > 0 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(baseFieldsState.eMail)) {
        errors.eMail = i18n.t('pryaniky.validate.email.error')
    }
    if (mondatorySystemFields.length) {
        const emptyFieldErrorText = i18n.t('pryaniky_validate.string.notNull.error')
        for (let name of mondatorySystemFields) {
            switch (name) {
                case 'Email': {
                    if (!baseFieldsState.eMail.length) {
                        errors.eMail = emptyFieldErrorText
                    }
                    break
                }
                case 'Имя': {
                    if (!baseFieldsState.firstName.length) {
                        errors.firstName = emptyFieldErrorText
                    }
                    if (!baseFieldsState.middleName.length) {
                        errors.middleName = emptyFieldErrorText
                    }
                    if (!baseFieldsState.lastName.length) {
                        errors.lastName = emptyFieldErrorText
                    }
                    break
                }
                case 'Подразделение': {
                    if (!baseFieldsState.divisionTags.length) errors.divisionTags = emptyFieldErrorText
                    break
                }
                case 'Должность': {
                    if (!baseFieldsState.positionTags.length) errors.positionTags = emptyFieldErrorText
                    break
                }
                case 'Номер телефона': {
                    if (!baseFieldsState.phone.length) errors.phone = emptyFieldErrorText
                    break
                }
                case 'День рождения': {
                    if (!baseFieldsState.birthday.length) errors.birthday = emptyFieldErrorText
                    break
                }
            }
        }
    }

    return {
        hasAdditionalFieldsErrors,
        errors
    }
}


