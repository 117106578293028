import { IStateType as IState } from 'redux/store';
import { cn } from '@bem-react/classname';
export const cnEventsItem = cn('EventsItem');

export interface IListItemProps extends IListItemStateProps, IListItemOwnProps {
}

export interface IListItemOwnProps {
  el: string;
  isFirst?: boolean
}

export type IListItemStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: IState, props: IListItemOwnProps) => {
  return {
    data: state.calendar.dates[props.el],
  }
}
