import React from 'react';
// import { List } from "../List/_type/quests/List_type_quests_moderate";
// import { IQuestModerate, IQuestModerateProps, cnList_type_quests_moderate } from "./QuestsModerate.index";
import { Page } from 'blocks/Page/Page';
import { cnWikilist, IWikilistRouteProps } from './WIKIList.index';
import { withRouter, useParams } from 'react-router';
import { pageNames } from 'redux/pageNames';

export const wikilist = () => {

    const { collectionAlias } = useParams<IWikilistRouteProps>();
    
    return (
        <Page page={{ name: `${pageNames.wikilist}${collectionAlias ? `.${collectionAlias}` : ''}` }} className={cnWikilist()} />
    )

}


