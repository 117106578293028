import { useLayoutEffect, useMemo, useState } from "react";
import { checkResponseStatus } from "utils/src";
import { SOCUnitWithChildrenShort } from "utils/src/BaseTypes/units.types";
import { getOrgchartUnitsTree } from "utils/src/requests/requests.orgchart";

const SOCOrgchartUnitsTreeEventName = "unittreerecieved";

class SOCOrgchartUnitsTree {
    private static singleton: SOCOrgchartUnitsTree | null = null;

    _tree: SOCUnitWithChildrenShort[] = [];
    _loading = false;
    _loaded = false;

    public static get inst() {
        if(!SOCOrgchartUnitsTree.singleton) {
            SOCOrgchartUnitsTree.singleton = new SOCOrgchartUnitsTree();
        }
        return SOCOrgchartUnitsTree.singleton;
    }

    public set loading(value: typeof this._loading) {
        this._loading = value;
    }

    public get loading() {
        return this._loading;
    }

    public get loaded() {
        return this._loaded;
    }

    public get tree() {
        return this._tree;
    }

    public async getUnitsTree() {
        if(this.loading) return ;
        this.loading = true;
        const response = await getOrgchartUnitsTree();
        if(checkResponseStatus(response)) {
            this._loaded = true;
            this._tree = !Array.isArray(response.data) ? [response.data] : response.data;
            window.dispatchEvent(new CustomEvent(SOCOrgchartUnitsTreeEventName));
        }
        this.loading = false;
    }
};

export const useSOCOrgchartUnitsTree = () => {

    const [loading, setLoading] = useState(SOCOrgchartUnitsTree.inst.loading);
    const [items, setItems] = useState<SOCOrgchartUnitsTree['tree']>(SOCOrgchartUnitsTree.inst.tree);

    useLayoutEffect(() => {
        window.addEventListener(SOCOrgchartUnitsTreeEventName, (e) => {
            setLoading(false);
            setItems(SOCOrgchartUnitsTree.inst.tree);
        });
        if(!SOCOrgchartUnitsTree.inst.loaded) {
            setLoading(true);
            SOCOrgchartUnitsTree.inst.getUnitsTree();
        }
    }, []);

    return {
        loading,
        items
    };
};