import { connect } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { Item } from './Item/Item';
import { mapStateToProps, IVirtUserSelectorStateProps, cnVirtUserSelector, IVirtUserSelectorProps, mapDispatchToProps } from './VirtUserSelector.index';
import { SUserModelShort } from 'utils/src/BaseTypes/user.types';
import { getCookie } from 'utils/src';


export const VirtUserSelectorPresenter: React.FC<IVirtUserSelectorProps> = ({ className, loadVirtUsers, virtUsers, currentUser }) => {
    useEffect(() => {
        loadVirtUsers()
    }, [])

    const curUser = useMemo(() => {
        return {
            access_token: '',
            cookieName: 'authAt',
            cookieToken: getCookie('authAt'),
            ext_user_id: currentUser.baseData.externalUserId,
            user: {
                ...currentUser.baseData,
                status: currentUser.baseData.status,
                type: 'user',
                isDeleted: false,
                birthday: currentUser.extData.birthday,
            } as unknown as SUserModelShort
        } as unknown as typeof virtUsers[number];
    }, [currentUser])


    return (
        <div className={cnVirtUserSelector({}, [className])}>
            {
                [curUser, ...virtUsers].map(user => (
                    <Item
                        className={cnVirtUserSelector('Item')} 
                        key={user.user.id}
                        user={user}
                        currentUser={currentUser}
                    />
                ))
            }        
        </div>
    );
};

export const VirtUserSelector = connect(
    mapStateToProps,
    mapDispatchToProps
)(VirtUserSelectorPresenter);
