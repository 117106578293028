import React, { useEffect, useState } from 'react';
import {
    Radio, Dialog,
    // DialogTitle,
    // DialogContent,
    // DialogActions,
    Input,
    Button as PrnButton,
    CheckboxInput,
    Textarea,
    ErrorMsg,
    Loading
} from 'uielements/src';
import { cn } from "@bem-react/classname";
import i18n from '../../../../localizations/i18n';
import * as utils from 'utils/src/utils';
import { useDebounce } from 'utils/src/hooks';
import { toast } from 'react-toastify';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import "./WikiCreate.scss";
import { ImageLoader } from '../../../ImageLoader/ImageLoader';
import { InputMention } from '../../../InputMention/InputMention';
import { valueContainerCSS } from 'react-select/src/components/containers';
import { RadioButtons } from '../../../WIKIList/WikiType/RadioButtons'
import {
    mapDispatchToProps,
    IDispatchProps
} from 'redux/connector';
import Button from '@material-ui/core/Button';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import { HelpTooltip, Icon } from 'uielements/src/index';
import { pages } from "../../../../pageStructures/pages";
import { wikilist } from "../../../../pageStructures/wikilist";
import uuid from 'uuid/v1';
import { widgets, generateColumn } from 'i.widgets';
import { history } from 'redux/browserHistory';
import { wrapDialogToRedux } from "../../DialogWrapper";
import { connect } from "react-redux";
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import {
    IWikiCreateOwnProps,
    IWikiCreateProps,
    cnWikiCreateDialog,
    IWikiCreateDispatchProps,
    IWikiCreateStateProps,
    mapWikiCreateStateToProps,
    mapWikiCreateDispatchToProps,
} from './WikiCreate.index'
import { IStateType as IState } from 'redux/store';
import Dropdown from 'uielements/src/MaterialElements/Dropdown/Dropdown';
import { Translate } from 'localizations/Translate';
import InputMaterial from 'uielements/src/MaterialElements/Input/Input';
import Suggest from 'uielements/src/MaterialElements/Autocomplete';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { TextError } from 'uielements/src/TextError/TextError';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { FilesUploader } from 'muicomponents/src/FilesUploader/FilesUploader'
import { useFileUploader, mapFromAttachmentsList, IAttachmentType } from 'utils/src/hooks'
import { pageGrid } from 'pageStructures/pageGrid';
import { useDispatch, useSelector } from "react-redux";
import widgetsActions from 'redux/actionsTypes/Widgets';
import { addDataToList } from 'redux/actions/Lists'
import { TextField } from 'muicomponents/src/TextField'
import {Box} from 'muicomponents/src'

//
export const WikiCreateDialogPresenter: React.FC<IWikiCreateProps> = ({ data: ddata,
    isShown,
    onClose,
    allowComments,
    showInMainManu,
    type,
    handleAccept,
    handleClose,
    changeVm,
    onChange = () => { }, ...props }) => {
    const [data, setData] = React.useState<{ [s: string]: any }>({ ...ddata, id: uuid(), type:"page" })
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorLink, setErrorLink] = React.useState(false);
    const dropdown = [
        { title: i18n.t('pryaniky.modal.wikipage.create.type.page'), value: 'page' },
        { title: i18n.t('pryaniky.modal.wikipage.create.type.wikilist'), value: 'wikilist', },
        { title: i18n.t('pryaniky.modal.wikipage.create.type.url'), value: 'url', },
        { title: i18n.t('pryaniky.modal.wikipage.create.type.file'), value: 'file', },

    ]
    const [options, setOptions] = React.useState(dropdown[0]);
    const dispatch = useDispatch();

    const {
        isLoading,
        // progress,
        // startUpload,
        files,
        onFileChange,
        // dndRef,
        // dragEntered,
        // dndTriggerRef,
        removeFile,
        // totalSize,
        // inputAccept,
        // setFiles,
        // getAttachmentsList,
        uploadError,
        uploadDisabled
    } = useFileUploader({
        // defaultAttachments,
        uploadOnAdd: true,
        maxFilesCount: 1
    })
    useEffect(() => {
        if (files.length >= 1 && files[0].isUploaded && files[0].response?.data?.[0]) {
            setData({ ...data, file: files[0].response?.data[0] })
            if (data.title === '' || !data.title) {
                setData({ ...data, title: files[0].name, url: files[0].name, file: files[0].response?.data[0] })
            }
        } else {
            setData({ ...data, file: null })
        }
    }, [files])


    // const onIconChange = (selected: string) => {
    //     store.dispatch(modalChangeDate({ id: props.id || '', data: { ...ddata, icon: selected } }))
    // }
    const changeSelect = (selected: any) => {
        setData({ ...data, type: selected })
    }

    const typePage = ddata.type === "page" || data.type === "page";
    const typeWikilist = data.type === "wikilist";
    const typeUrl = data.type === "url";
    const typeFile = data.type === "file";


    const sendDataForCreatNewPage = () => {
        setLoading(true)
        // const pageUrl = "/pages/" + data.url;
        // const wikilistUrl = "/wikilist/" + data.url;
        // const absoluteUrl = data.url;

        let link = data.url
        link = data.type === "page" ? "/pages/" + data.url : link
        link = data.type === 'wikilist' ? "/wikilist/" + data.url : link
        link = data.type === 'file' ? `/pages/files/${data.file?.id}` : link
        const structure = data.selected === 'grid'
            ? pageGrid()
            : pages(data.type === 'file' ? widgets.types.file : widgets.types.wiki);
            
        const wikilistStracture = wikilist()

        const store: { [s: string]: any } = typePage ? { edit: true, editType: 'content' } : { edit: false, editType: '' };
        if (data.selected === 'landing') {
            structure.data = [generateColumn({})];
            store.openedModal = 'layoutSelector';
            store.editType = undefined;
        }
        const dataToCrete = {
            layout: data.type === "wikilist" ? wikilistStracture : structure,
            enableTwoFactorAuth: false,
            context: data.context,
            registerNewWidgets: false,
            isTemplate: false,
            id: uuid(),
            type: data.type ? data.type : "page",
            url: link,//typePage ? pageUrl : typeWikilist ? wikilistUrl : absoluteUrl ,
            title: data.title,
            moderators: data.moderators,
            tags: data.tags,
            collectionAlias: data.collectionAlias,
            collections: data.collections,
            titleImageId: data.titleImageId,
            hasCustomTitleImage: data.titleImageId === null || data.titleImageId === undefined || data.titleImageId === "" ? false : true,
            titleImageUrl: data.titleImageUrl,
            showInMainMenu: data.showInMainMenu,
            allowComments: data.allowComments,
            defaultViewType: data.defaultViewType ? data.defaultViewType : 0,
            // positionInCollection: Number(ddata.positionInCollection),
            positionInCollection: Number(data.positionInCollection) ? Number(data.positionInCollection) : 0,
            icon: data.icon,
            viewers: data.viewers,
            file: data.file

        };
        utils.API.pages.setNew(dataToCrete)
            .r
            .then((response) => {
                if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                else if (response.error_code) {
                    toast.error(response.error_text);
                    setLoading(false)
                }
                else if (response.error_code === 0) {
                    if (data.type === 'file') {
                        history.push(`/pages/files/${data.file.id}`)
                    } else if (typeUrl) {
                        handleAccept(dataToCrete)
                    } else {
                        history.push(link);
                    }
              
                    !typePage && handleClose();
                    // устанавливает состояние глобального стора на редактирование страницы если надо
                    changeVm('', store);
                }

            })
    };

    const onChangeShowInMainMenu = (e: any) => {
        setData({ ...data, showInMainMenu: !data.showInMainMenu })
    }

    const onChangeAllowComments = (e: any) => {
        setData({ ...data, allowComments: !data.allowComments })
    }

    const onChangeLinkInput = (e: any) => {
        const pageUrl = "pages/" + e.currentTarget.value;
        const wikilistUrl = "wikilist/" + e.currentTarget.value;
        !typeUrl && onChangeLinkInputUseDebounce(typePage ? pageUrl : wikilistUrl);
        setData({ ...data, url: e.currentTarget.value })
        setError(false)
    }

    const checkUrlIsExist = (value: string) => {
        utils.API.pages.isExists(value)
            .r
            .then((response) => {
                if (!response) {
                    toast.error(i18n.t('pryaniky.toast.error.server'));
                } else if (response.data === true) {
                    setErrorLink(true)
                    toast.error(i18n.t('pryaniky.modal.wikipage.create.url.is.exist'))
                } else if (response.data === false) {
                    setErrorLink(false)
                }
            })
    }



    const onChangeLinkInputUseDebounce = useDebounce(checkUrlIsExist, 1000, []);

    let disableAccept = data.url === '' || data.url === undefined
    disableAccept = data.type === 'file' ? !Boolean(data.file) : disableAccept
    disableAccept = isLoading ? true : disableAccept
    disableAccept = loading ? true : disableAccept
    return (
        <>
            <DialogTitle onClose={handleClose}>
                {i18n.t("pryaniky.modal.wikipage.create.page")}
                {/* <p className="my-1">{i18n.t("pryaniky.modal.inviteUser.text")}</p> */}
            </DialogTitle>
            <DialogContent className={cnWikiCreateDialog("")}>
                <Dropdown

                    label={i18n.t('pryaniky.userSettings.field.fieldType')}
                    value={data.type ? data.type : data.type}
                    onChange={changeSelect}
                    items={dropdown}
                    className={cnWikiCreateDialog('Type')}
                />

                <Input
                    name="title"
                    type="wikiname"
                    value={data.title}
                    className={cnWikiCreateDialog("Input")}
                    placeholder={i18n.t("pryaniky.modal.wikipage.page.name")}
                    autoFocus
                    icon="heading"
                    onChange={(e: any) => {
                        setData({ ...data, title: e.currentTarget.value })
                    }} />
                {
                    data.type !== 'file' && <Input
                        id="wikilink"
                        name="url"
                        // type="wikilink"
                        value={data.url}
                        style={{ marginLeft: (ddata.type === "url" || data.type === "url") ? "0px" : "47px" }}
                        className={cnWikiCreateDialog(error || errorLink ? "ErrorLink" : "InputLink")}
                        placeholder={i18n.t("pryaniky.modal.wikipage.page.url")}
                        autoFocus={error}
                        icon="link"

                        onChange={onChangeLinkInput} >
                        {(ddata.type === "page" || data.type === "page" || data.type === "file") ? <p className={cnWikiCreateDialog("child")}>/pages/</p> : (ddata.type === "wikilist" || data.type === "wikilist") ? <p className={cnWikiCreateDialog("child")}>/wikilist/</p> : (ddata.type === "url" || data.type === "url") && undefined}
                    </Input>
                }
                {
                    data.type === 'file' && <FilesUploader
                        files={files}
                        multiple={false}
                        showProgressOnNewFiles
                        selectButtonProps={{
                            children: i18n.t("pryaniky.modal.wikipage.page.selectFile"),
                            variant: 'outlined'
                        }}
                        error={Boolean(uploadError)}
                        helperText={uploadError?.error?.error_text}
                        disabled={uploadDisabled}
                        className={cnWikiCreateDialog("FileInput")}
                        // inputAccept="image/jpeg,image/png"
                        onFileChange={onFileChange}
                        removeFile={removeFile}
                    />
                }
                {error && <TextError data={i18n.t('pryaniky.modal.wikipage.disabled.btn.help')} />}
                {errorLink && <TextError data={i18n.t('pryaniky.modal.wikipage.create.url.is.exist')} />}
                <div className={cnWikiCreateDialog("InputFolder")}>
                    <InputMention
                        additionalRequestOpts={{ groupid: ddata.gId }}
                        onChange={(value: any) => {
                            setData({ ...data, moderators: value })
                        }}
                        className={cnWikiCreateDialog("InputFolder-Folder")}
                        value={data.moderators}
                        type="users"
                        icon="user-add"
                        placeholder={i18n.t("pryaniky.modal.wikipage.choose.moderator")}
                    // {i18n.t('pryaniky.shop.selectFor')}
                    />
                </div>

                <div className={cnWikiCreateDialog("InputFolder")}>
                    <InputMention
                        onChange={(value: any) => {
                            setData({ ...data, tags: value })
                        }}
                        className={cnWikiCreateDialog("InputFolder-Folder")}
                        value={data.tags}
                        type="tags"
                        icon="tags"
                        placeholder={i18n.t("pryaniky.modal.wikipage.choose.tag")}
                    // {i18n.t('pryaniky.shop.selectFor')}
                    />
                </div>
                <div className={cnWikiCreateDialog("InputFolder")}>
                    <InputMention
                        onChange={(value: any) => {
                            setData({ ...data, collections: value })
                        }}
                        className={cnWikiCreateDialog("InputFolder-Folder")}
                        value={data.collections}
                        type="pagecollections"
                        icon="folder"
                        placeholder={i18n.t("pryaniky.modal.wikipage.choose.pagecollections")}
                    />
                    {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.choose.pagecollections.help')}> */}
                    <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.pagecollections.help')} >
                        <span><Icon icon={'question-circle'} /></span>
                    </Tooltip>
                </div>
                <div className={cnWikiCreateDialog("InputFolder")}>
                    <Suggest
                        type='usersgroups'
                        multiple={true}
                        label={typePage || ddata.type === "" ?
                            i18n.t('pryaniky.modal.wikipage.page.viewers') : typeWikilist ?
                                i18n.t('pryaniky.modal.wikipage.folder.viewers') : i18n.t('pryaniky.modal.wikipage.link.viewers')}
                        value={data.viewers || []}
                        onChange={(viewers: any[]) => {
                            setData({ ...data, viewers })
                        }}
                    />
                    {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.viewers.help')}> */}
                    <Tooltip title={i18n.t('pryaniky.modal.wikipage.viewers.help')} >

                        <span><Icon icon={'question-circle'} /></span>
                    </Tooltip>
                </div>
                {typeWikilist && <div className={cnWikiCreateDialog("ViewType")}>
                    <h4><Translate i18nKey={'pryaniky.news.view.type.title.default'} /></h4>
                    <div>
                        {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.full.help')}> */}
                        <PrnButton main={ddata.defaultViewType === 0} onClick={() => {
                            setData({ ...data, defaultViewType: 0 })
                            // store.dispatch(modalChangeDate({ id: props.id || '', data: { ...ddata, defaultViewType: 0 } }))
                        }}>
                            <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.full.help')} >

                                <span><Icon icon={'wiki'}></Icon></span>
                            </Tooltip>
                        </PrnButton>
                        {/* </HelpTooltip> */}
                        {/* <HelpTooltip className='TooltipMargin' help={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.short.help')}> */}
                        <PrnButton main={ddata.defaultViewType === 1} onClick={() => {
                            setData({ ...data, defaultViewType: 1 })
                            // store.dispatch(modalChangeDate({ id: props.id || '', data: { ...ddata, defaultViewType: 1 } }))
                        }}>
                            <Tooltip title={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.short.help')} >
                                <span> <Icon icon={'bars'}></Icon></span>
                            </Tooltip>
                        </PrnButton>
                        {/* </HelpTooltip> */}
                    </div>
                </div>}
                <Box className={cnWikiCreateDialog("PositionInCollection")}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 24px",
                            "input::-webkit-outer-spin-button": {
                                "-webkit-appearance": "none",
                                margin: 0
                            },
                            "input::-webkit-inner-spin-button": {
                                "-webkit-appearance": "none",
                                margin: 0
                            }
                        }}>

                        < TextField
                            className={cnWikiCreateDialog("PositionInCollection")}
                            inputProps={{ inputMode: 'numeric' }}
                            suggesterValue={false}
                            label={i18n.t('pryaniky.modal.wikipage.page.positionInCollection')}
                            // style={{ maxWidth: "300px" }}
                            variant='outlined'
                            value={data.positionInCollection ? data.positionInCollection.toString() : ""}
                            onChange={(e) => {
                                if (e.currentTarget.value.match(/[^0-9]/)) {
                                    e.preventDefault();
                                } else {
                                    setData({ ...data, positionInCollection: e.currentTarget.value })
                                }
                            }}
                        />

                    </Box>
                <Checkbox
                    className={cnWikiCreateDialog("CheckboxInput")}
                    value={data.showInMainMenu}
                    onChange={onChangeShowInMainMenu}
                    label={i18n.t("pryaniky.modal.wikipage.showInMainMenu")}
                />
                {/* you can comment page or filePage */}
                {(typePage || typeFile) && <Checkbox
                    className={cnWikiCreateDialog("CheckboxInput")}
                    value={data.allowComments}
                    onChange={onChangeAllowComments}
                    label={i18n.t("pryaniky.modal.wikipage.allowComments")}
                />}

                <ImageLoader
                    cropper // ZENKIN TODO DIALOGS Z_INDEX
                    onLoaded={(img: any) => {
                        setData({ ...data, titleImageId: img.id })
                    }}
                    title={i18n.t("pryaniky.modal.wikipage.cover")}
                    description={i18n.t("pryaniky.modal.wikipage.cover.size")}
                />
                <div >
                    {(
                        ddata.type === "page" ||
                        data.type === "page"
                    ) && <RadioButtons onChange={
                        (e: any) => {
                            setData({ ...data, selected: e.target.value })
                            // store.dispatch(modalChangeDate({ id: props.id || '', data: { ...ddata, selected: e.target.value } }))
                        }} selected={data.selected} />}
                </div>
            </DialogContent>
            <DialogActions
                acceptText={i18n.t("pryaniky.modal.wikipage.create")}
                closeText={i18n.t("pryaniky.modal.wikipage.cencel")}
                isLoading={loading || isLoading}
                disableAccept={disableAccept || errorLink}
                onAccept={data.url === '' || data.url === undefined ? () => {
                    data.type === "file" ? sendDataForCreatNewPage() : setError(true);

                } : sendDataForCreatNewPage}
                // onAccept={data.url === '' || data.url === undefined ? () => {
                //     data.type === "file" ? sendDataForCreatNewPage() : setError(true);

                // } : sendDataForCreatNewPage}
                onClose={handleClose}
            />
        </>
    );
};


export const WikiCreateDialog = connect<IWikiCreateStateProps, IWikiCreateDispatchProps, IWikiCreateOwnProps, IState>(
    mapWikiCreateStateToProps,
    mapWikiCreateDispatchToProps,
)(WikiCreateDialogPresenter)

export const DialogWikiCreate = createModal(WikiCreateDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});