import { cn } from "@bem-react/classname";
import { IDialog } from "../../DialogWrapper.index";
import { IDispatchProps } from "../../../../redux/connector";
// import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';

export interface IWikiEditProps extends IWikiEditOwnProps, IBodyProps, IWikiEditDispatchProps, IWikiEditStateProps {
    // isShown: boolean;
    // onClose: () => void;
    className?: string;
}
export interface IWikiEditOwnProps extends IDialog{
    sendDataForEditPage?: (data: any) => void;
    context?: any;
    actualData?: IActualDataWiki;
    id: string;

};

interface IActualDataWiki {
    collectionAlias: string,
    id: string,
    url: string,
    title: string,
    moderators: any[],
    tags: any[],
    collections: any[],
    type: string,
    titleImageId: string,
    hasСustomTitleImage: string,
    titleImageUrl: string,
    showInMainMenu: boolean,
    allowComments: boolean,
    defaultViewType: number,
    isArchived: boolean | undefined,
    positionInCollection: number|string,
    viewers: any[] | null,
    file: any
}
export type IWikiEditDispatchProps = ReturnType<typeof mapWikiEditDispatchToProps>

export const mapWikiEditDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type IWikiEditStateProps = ReturnType<typeof mapWikiEditStateToProps>;

export const mapWikiEditStateToProps = () => ({});

export const cnWikiEditDialog = cn("WikiEditDialog");


