import React from 'react';
// import i18n from '../../../localizations/i18n';
import Suggester from 'uielements/src/MaterialElements/Autocomplete';
import * as UIElements from 'uielements/src';
// import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
// import { DispatchProp } from 'react-redux';
// import { connect } from 'react-redux';
// import { JFdictionary } from "../../WSettings/structures/dictionary";
import { Translate } from '../../../localizations/Translate';
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import { FormControl } from '@material-ui/core';
import PryanikyEditor from 'uielements/src/PryanikyEditorV1';
import { lightMarkdownPreset, allEnabledPreset, markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import { NodeBulder } from 'uielements/src/CommonmarkRender'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { useDebounce } from 'utils/src/hooks';
import { rawToMd } from 'uielements/src/PryanikyEditorV1/converter/convertorConfigs'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { Box, Divider } from 'muicomponents/src'
import Input from 'uielements/src/MaterialElements/Input/Input';


const NB = new NodeBulder('');
const defaultEditorState = EditorState.createWithContent(convertFromRaw(NB.getDraftRaw()));

const baseBlockItem = {
  title: '',
  description: '',
  deleted: false,
  list: []
}

const Editor = ({ content, onChange }: any) => {
  const [description, setDescription] = React.useState(() => {
    const NB = new NodeBulder(content || '')
    return EditorState.createWithContent(convertFromRaw(NB.getDraftRaw()))
  });
  return (
    <PryanikyEditor
      enableModules={lightMarkdownPreset}
      enabledAnvanced={true}
      value={description}
      placeholder={Translate.t({ i18nKey: 'pryaniky.widgets.settings.users.list.block.description' })}
      setActionBtn={<T,>(v: T) => { }}
      onChange={(description: EditorState) => {
        setDescription(description);
        onChange(description);
      }}
    />
  )
}

// UsersListBlockControl
export const usersListBlock = (props: any) => {
  if (!props.visible) return null;
  // const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {}
  return (
    <div>


      {
        (props.data[get_variable_name(props.uischema.scope)] as typeof baseBlockItem[]).filter(el => !el.deleted).map((el, idx) => {

          const chageDescriptionData = (description: any) => {
            props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
              oldData[idx].description = draftToMarkdown(convertToRaw(description.getCurrentContent()), rawToMd);
              return oldData
            }))
          }
          // const chageDescriptionData = useDebounce((description: any) => {
          //   props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
          //     oldData[idx].description = draftToMarkdown(convertToRaw(description.getCurrentContent()), rawToMd);
          //     return oldData
          //   }))
          // }, 500, [])

          return (
            <>
              {/* <Editor
                content={el.description || ' '}
                onChange={chageDescriptionData}
              /> */}


              <div className={'JF-UsersListBlockItem-Header'}>
                <Input
                  multiline
                  value={el.title}
                  onChange={(value: any) => {
                    props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                      oldData[idx].title = value;
                      return oldData
                    }))
                  }}
                  placeholder={Translate.t({ i18nKey: 'pryaniky.widgets.settings.users.list.block.name' })}
                />


                <UIElements.Button
                  theme='unstyled_center'
                  title={Translate.t({ i18nKey: 'pryaniky.widgets.settings.users.list.block.remove' })}
                  onClick={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                    // oldData.splice(idx, 1);
                    oldData[idx].deleted = true;
                    return [...oldData]
                  }))}
                  children={
                    <UIElements.Icon icon={'times'} />
                  } />
              </div>
              <div className={'JF-UsersListBlockItem-Header'}>
              <Input
                multiline
                value={el.description}
                onChange={(value: any) => {
                  props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                    oldData[idx].description = value;
                    return oldData
                  }))
                }}
                placeholder={Translate.t({ i18nKey: 'pryaniky.widgets.settings.users.list.block.description' })}
              />
              </div>
              <Suggester
                // disabled={isLoading}
                // allowCreate={!field.selectOnlyFromCollection}
                // fullWidth
                excludeMe={false}
                type={'users'}
                label={<Translate i18nKey={'pryaniky.widgets.settings.users.list.select.users'} />}
                value={el.list}
                onChange={(items: any[]) => {
                  props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                    oldData[idx].list = items;
                    return oldData
                  }))
                }}
              />
              <Divider sx={{ marginBottom: "24px" }} />
            </>
          )
        })
      }
      <UIElements.Button
        className={'SliderItem-Button_add'}
        onClick={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
          oldData.push(baseBlockItem);
          return [...oldData]
        }))}
      >
        <Translate i18nKey={'pryaniky.widgets.settings.users.list.block.add'} />
      </UIElements.Button>
    </div>
  )
}
// end TableControl