import { Box, Paper, Typography } from 'muicomponents/src';
import React, { FC, useEffect, useMemo, useState } from 'react';
import * as utils from 'utils/src/utils';
import { IBadge, IUserBadgesProps } from './UserBadges.index';
import { i18n, Translate } from 'localization';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';
import SearchBar from './SearchBar/SearchBar';
import { UserRatingsSkeleton } from 'blocks/UserRatings/UserRatingsSkeleton';
import { IconButton } from 'muicomponents/src/IconButton';
import { GridView as GridViewIcon, List as ListIcon } from 'muicomponents/src/Icons';
import { ListItem } from './ListItem/ListItem';
import { GridItem } from './GridItem/GridItem';
import { useListCardResize } from 'muicomponents/src/InfinityList/hook';



const Presenter: FC<IUserBadgesProps> = ({ context, tag, ...props }: IUserBadgesProps) => {

  const Tag = tag || 'div'

  const [loading, setLoading] = useState(true)
  const [badges, setBadges] = useState<{ [s: string]: IBadge[] }>()
  const [catsList, setCatsList] = useState<string[]>()
  const [searchValue, setSearchValue] = useState('')
  const [viewType, setViewType] = useState<'grid' | 'row'>('grid')
  const uid = context.uId

  const {
    count,
    ref: listRef
  } = useListCardResize(3, 220);

  const getData = () => {
    utils.API.badges.byUser({ uid }).r.then((d: any) => {
      if (!d.data || d.error_code !== 0) {
        setLoading(false)
        return;
      }
      setBadges(d.data)
      const categoriesList = Object.keys(d.data)
      setCatsList(categoriesList)
      setLoading(false)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
  }


  const searchedBadges = useMemo(() => {
    let filteredBadges: { [s: string]: IBadge[] } = {}
    if (!searchValue) {
      return badges
    } else if (searchValue && catsList && badges) {
      catsList.forEach(category => {
        filteredBadges[category] = badges[category].filter(badge => badge.name.toLowerCase().includes(searchValue))
      })
      if (Object.keys(filteredBadges).length === 1 && "" in filteredBadges && !filteredBadges[''].length) {
        return null
      }
      return filteredBadges
    }
  }, [searchValue, badges, catsList])

  if (loading) return <UserRatingsSkeleton />

  if (!badges || !catsList?.length) {
    return <PaperBox>
      <Typography>
        <Translate i18nKey={'pryaniky.placeholder.widget.nodata'} />
      </Typography>
    </PaperBox>
  }

  return (
    <Tag {...props}>
      <PaperBox sx={{display: 'flex', alignItems: 'center', columnGap: '24px'}}>
        <SearchBar 
          onSearchChange={onSearchChange}
          value={searchValue} 
        />
        <Box sx={{display: 'flex', columnGap: '10px'}}>
          <IconButton onClick={() => setViewType('grid')}>
            <GridViewIcon sx={{fontSize: '30px'}} color={viewType === 'grid' ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton onClick={() => setViewType('row')}>
            <ListIcon sx={{fontSize: '30px'}} color={viewType === 'row' ? 'primary' : 'inherit'} />
          </IconButton>
        </Box>
        
      </PaperBox>

      <PaperBox sx={ viewType === 'grid' ? {backgroundColor: 'transparent', padding: 0, boxShadow: 'none'} : {}}>

        {catsList?.map((el, i) => {

          return (
            <>
              {el !== '' && (searchedBadges || badges)[el].length ?
                <Typography mt={'16px'} mb={viewType === 'grid' ? '12px' : '0'} variant='h6'>
                  {el}
                </Typography>
                : null
              }
              <Box 
                key={i} 
                ref={listRef} 
                sx={viewType === 'grid' ? 
                  {display: 'flex', flexWrap: 'wrap', gap: '12px'} : {}
                }
              >
                {searchValue && !searchedBadges ?
                  <Typography>{i18n.t("nothing")}</Typography>
                  :
                  (searchedBadges || badges)[el].map(badge => {
                    return viewType === 'row' ? 
                      <ListItem badge={badge} /> :
                      <GridItem badge={badge} countColumns={count}/>
                  })
                }
              </Box>
            </>
          )
        })}
      </PaperBox>
    </Tag>
  )
};

export const UserBadges = React.memo(Presenter)

