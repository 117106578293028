import React, { FC, useState, ComponentProps, useEffect, useCallback, useRef, useMemo } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { SettingsWikiActueleArticlesWidgetWidget } from '../WikiActueleArticlesWidget.index';
import {
    cnSettingsWikiActueleArticlesDialog,
    NSettingsWikiActueleArticlesDialog
} from './SettingsActueleArticlesDialog.index'

import {
    Checkbox,
    TextField,
    PageCollectionSuggester
} from 'muicomponents/src';
import { FormControlLabelStyled } from '../../WikiArticlesWidget/WikiArticlesWidget.styled'
import { Translate } from 'localizations/Translate';
import { NumberTextField } from 'muicomponents/src/TextField/TextField'
import { WidgetDialogBox, PageCollection, BaseSuggester } from '../WikiActueleArticlesWidget.styled'
import { checkResponseStatus, validate_v2 } from 'utils/src';
import { IRequest } from 'utils/src/requests/service';
import { list } from 'utils/src/requests/requests.tags';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { useFieldHelperText } from 'muicomponents/src/FieldRender/FieldRender.hooks';
import { FormControl } from 'muicomponents/src/FormControl';
import { validateField, useActualArticlesListErrors, ActualArticlesListErrorsProvider } from './Validate'

let request: IRequest;

export const WikiActueleArticles: FC<NSettingsWikiActueleArticlesDialog.Props> = ({
    data,
    onChange,
    ...props
}) => {

    const [state, setState] = useState<SettingsWikiActueleArticlesWidgetWidget.Settings>(data || {
        hideIfNoData: false,
        hideName: false,
        hideDescription: false,
        collection: null,
        rubric: null,
        name: ""
    });
    // const HelperText = useFieldHelperText(state.collection, error);
    console.log("state", state)
    console.log("props", props)
    const getCategories = useCallback((url: string,) => {
        if (request) request.ac.abort();
        request = list(0, 20, { url });
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                return d.data;
            } else {
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
            }
        });
    }, [])

    const {
        state: errors,
        setState: setErrors
    } = useActualArticlesListErrors();

    const errorsRef = useRef(errors);
    errorsRef.current = errors;

    useEffect(() => {

        onChange({
            data: {
                hideIfNoData: state.hideIfNoData,
                hideName: state.hideName,
                hideDescription: state.hideDescription,
                name: state.name,
                collection: state.collection,
                rubric: state.rubric
            },
            settings: {
                hideIfNoData: state.hideIfNoData,
                hideName: state.hideName,
                hideDescription: state.hideDescription,
                name: state.name,
                collection: state.collection,
                rubric: state.rubric
            }
        })
        setErrors({
            ...validateField({ collection: state.collection })
        });

    }, [state]);



    const changeHideIfNoData = () => setState({ ...state, hideIfNoData: !state.hideIfNoData })
    const changeHideName = () => setState({ ...state, hideName: !state.hideName })
    const changeHideDescription = () => setState({ ...state, hideDescription: !state.hideDescription })
    const changeName = (event: React.ChangeEvent<{ value: unknown }>) => setState({ ...state, name: event.target.value as string })

    const HelperText = useFieldHelperText("", errorsRef.current?.collection);

    return (<WidgetDialogBox className={cnSettingsWikiActueleArticlesDialog("")}>

        <TextField
            autoComplete="off"

            fullWidth
            label={<Translate i18nKey='pryaniky.widgets.article.settings.name' />}

            variant='outlined'
            value={state.name}
            onChange={changeName} />


        <FormControl
            variant='outlined'
            fullWidth className={cnSettingsWikiActueleArticlesDialog("PageCollectionSuggester")}
            error={!!errorsRef.current?.collection}
        >
            <PageCollectionSuggester
                TextFieldProps={{
                    label: Translate.t({ i18nKey: 'pryaniky.widgets.article.settings.collection' }),
                    error: !!errorsRef.current?.collection

                }}

                value={state.collection as any}
                onChange={(event, value: any) => {
                    setState({ ...state, collection: value });
                }
                }
            />
            {HelperText}
        </FormControl>

        <BaseSuggester
            TextFieldProps={{
                label: Translate.t({ i18nKey: 'pryaniky.widgets.article.settings.rubric' }),
            }}
            //   size={'small'}
            disabled={!state.collection}
            request={(search) => getCategories(state.collection?.alias)}
            value={state.rubric as any}
            multiple
            onChange={(event, value: any) => setState({ ...state, rubric: value })}
        />


        <FormControlLabelStyled
            control={
                <Checkbox
                    checked={state.hideIfNoData}
                    onChange={changeHideIfNoData}
                    name="Shuffle"
                    color="primary"
                />
            }
            label={<Translate i18nKey='pryaniky.widgets.settings.type.shop.noData' />}
        />
        <FormControlLabelStyled
            control={
                <Checkbox
                    checked={state.hideName}
                    onChange={changeHideName}
                    name="Shuffle"
                    color="primary"
                />
            }
            label={<Translate i18nKey='pryaniky.widgets.article.settings.hideName' />}
        />
        <FormControlLabelStyled
            control={
                <Checkbox
                    checked={state.hideDescription}
                    onChange={changeHideDescription}
                    name="Shuffle"
                    color="primary"
                />
            }
            label={<Translate i18nKey='pryaniky.widgets.article.settings.hideDescription' />}
        />


    </WidgetDialogBox>)
}

export const WikiActueleArticlesPresenterWithContext: FC<ComponentProps<typeof WikiActueleArticles>> = ({
    data = { hideIfNoData: false, hideDescription: false, hideName: false, collection: null, rubric: null, name: "" },
    onChange,
    ...props
}) => {

    return (
        <ActualArticlesListErrorsProvider>
            <WikiActueleArticles data={data} onChange={onChange} {...props} />
        </ActualArticlesListErrorsProvider>
    );
};

export const initWidgetAddition = () => ComponentInjector.getInstance().addComponent('WAddition', WikiActueleArticlesPresenterWithContext, 'pryaniky/wikiActueleArticles')
export const initWidgetSettings = () => ComponentInjector.getInstance().addComponent('WSettings', WikiActueleArticlesPresenterWithContext, 'pryaniky/wikiActueleArticles')

