import React, { useEffect, useState } from 'react';
import i18n from 'localizations/i18n';
import "./FeedbackDialog.scss";
import { connect } from "react-redux";
import {
    IFeedbackDialogOwnProps,
    IFeedbackDialogProps,
    cnFeedbackDialog,
    IFeedbackDialogDispatchProps,
    IFeedbackDialogStateProps,
    mapFeedbackDialogStateToProps,
    mapFeedbackDialogDispatchToProps,
} from './FeedbackDialog.index'
import { IStateType as IState } from 'redux/store';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { Box } from 'muicomponents/src'

export const FeedbackDialogPresenter: React.FC<IFeedbackDialogProps> = ({
    feedback,
    handleAccept,
    handleClose,
    changeVm,
    ...props }) => {
    return (
        <>
            <DialogTitle onClose={handleClose}>
                {i18n.t("pryaniky.post.actions.modal.showFeedbackContact.title")}
            </DialogTitle>
            <DialogContent className={cnFeedbackDialog("Content")}>
                <Box> {i18n.t("pryaniky.post.actions.modal.showFeedbackContact.name")}{feedback.senderName}</Box>
                <Box> {i18n.t("pryaniky.post.actions.modal.showFeedbackContact.contact")}{feedback.senderContact}</Box>
            </DialogContent>
            <DialogActions
                closeText={i18n.t("pryaniky.modal.wikipage.cencel")}
                onClose={handleClose}
            />



        </>
    );
};


export const FeedbackDialog = connect<IFeedbackDialogStateProps, IFeedbackDialogDispatchProps, IFeedbackDialogOwnProps, IState>(
    mapFeedbackDialogStateToProps,
    mapFeedbackDialogDispatchToProps,
)(FeedbackDialogPresenter)

export const DialogFeedback = createModal(FeedbackDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});